import { GridRowParams } from '@mui/x-data-grid/models/params';
import { PropsWithChildren, ReactNode, useCallback, useContext } from 'react';
import { IReachOptions } from '@ewb/reach-react';
import { DataGridProps, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import { SlugLevel } from '../../../hooks/useGetApiUrl';
import { IPaginatedSearchObject } from '../../../libraries/reach/usePaginatedSearch';
import {
  ArchiveIconButton,
  CopyIconButton,
  DeleteIconButton,
  EditIconButton,
  GoToIconButtonLink,
  PreviewIconButton,
  TooltipIconButtonProps,
} from '../../actions/icons/Icons';
import { SearchCrudContext } from '../search.utils';
import { DownloadOnClickIcon, IExportIconProps } from '../../actions/icons/ExportIcon';
import { openPreview } from '../../../utils/window';
import { DownloadHrefIcon } from '../../actions/icons/DownloadHrefIcon';
import { reach } from '../../../libraries/reach/reach';
import { useSearchActionProps } from '../../../hooks/useSearchActionProps';

export function useDataGridOnRowClickEdit(disabled?: boolean): DataGridProps['onRowClick'] | undefined {
  const {
    scope,
    items,
    actions: { spawnPatch, spawnDelete },
  } = useContext(SearchCrudContext);
  if (!scope.update || disabled) return undefined;
  return (params: GridRowParams) => {
    const index = items.findIndex((x) => x._id === params.id);
    if (index > -1) {
      spawnPatch(items[index], index, undefined, {
        actionButtonProps: {
          onDelete: (onClose) => spawnDelete(items[index], index, onClose),
        },
      });
    }
  };
}

export interface IGridActionProps {
  params: GridRenderCellParams;
}

export interface IGridActionPropsWithLevelScope extends IGridActionProps {
  checkIfDisabledInProject?: boolean;
}

export const dataGridActionProps = (
  renderCell: (params: GridRenderCellParams) => ReactNode,
  props: Partial<GridColDef> = {}
): GridColDef => ({
  type: 'actions',
  field: 'actions',
  minWidth: 200,
  headerName: '',
  headerAlign: 'right',
  align: 'right',
  disableColumnMenu: true,
  renderCell,
  ...props,
});

export function DataGridActionWrapper(props: PropsWithChildren<{}>) {
  const children = Array.isArray(props.children) ? props.children : [props.children];
  return (
    <Grid container justifyContent="flex-end">
      {children.map((child, i) => (
        <Grid item key={i}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}

type DataGridEditActionProps = IGridActionPropsWithLevelScope;

export function DataGridEditAction({ params, checkIfDisabledInProject }: DataGridEditActionProps) {
  return (
    <SearchCrudEditAction
      id={params.id}
      project={params.row.project}
      checkIfDisabledInProject={checkIfDisabledInProject}
    />
  );
}

export interface SearchCrudActionProps {
  id: string | number;
  project?: string;
  checkIfDisabledInProject?: boolean;
  buttonProps?: Omit<Partial<TooltipIconButtonProps>, 'onClick'>;
  archive?: boolean;
}

export function SearchCrudEditAction({
  id,
  project,
  checkIfDisabledInProject,
  buttonProps,
}: Omit<SearchCrudActionProps, 'archive'>) {
  const { scope, level } = useContext(SearchCrudContext);
  const onAction = useCallback(({ spawnPatch }: any, item: any, index: any) => spawnPatch(item, index), []);
  const onClick = useSearchActionProps(id, onAction);
  if (checkIfDisabledInProject && level > SlugLevel.Organization && !project) return null;
  if (!scope.update) return null;
  return <EditIconButton {...buttonProps} onClick={onClick} />;
}

interface DataGridCopyActionProps<T extends IPaginatedSearchObject> extends IGridActionProps {
  preCopy?: (data: Partial<T>) => Partial<T>;
  getProps?: IReachOptions;
}

export function DataGridCopyAction<T extends IPaginatedSearchObject>({
  params,
  preCopy,
  getProps,
}: DataGridCopyActionProps<T>) {
  const { patchPath, scope, actions } = useContext(SearchCrudContext);
  const { splice } = actions;
  const _preCopy = useCallback(
    async (data: Partial<T>) => {
      let allData: Partial<T>;
      try {
        allData = await reach.api(`${patchPath(data)}/${data._id}`, getProps);
      } catch (e: unknown) {
        allData = data;
      }
      delete allData._id;
      return preCopy ? preCopy(allData) : allData;
    },
    [patchPath, preCopy, getProps]
  );
  const onAction = useCallback(
    async ({ spawnCopy }: any, copy: any, index: any) =>
      spawnCopy((copied: any) => splice(index, 0, copied), await _preCopy(copy)),
    [splice, _preCopy]
  );
  const onClick = useSearchActionProps(params.id, onAction);
  if (!scope.create) return null;
  return <CopyIconButton onClick={onClick} />;
}

export interface DataGridDeleteActionProps extends IGridActionPropsWithLevelScope {
  buttonProps?: Omit<TooltipIconButtonProps, 'onClick'>;
  archive?: boolean;
}

export function DataGridDeleteAction({
  params,
  checkIfDisabledInProject,
  archive,
  buttonProps,
}: DataGridDeleteActionProps) {
  return (
    <SearchCrudDeleteAction
      id={params.id}
      project={params.row.project}
      checkIfDisabledInProject={checkIfDisabledInProject}
      archive={archive}
      buttonProps={buttonProps}
    />
  );
}

export function SearchCrudDeleteAction({
  id,
  project,
  checkIfDisabledInProject,
  buttonProps,
  archive,
}: SearchCrudActionProps) {
  const { scope, level } = useContext(SearchCrudContext);
  const onAction = useCallback(({ spawnDelete }: any, item: any, index: any) => spawnDelete(item, index), []);
  const onClick = useSearchActionProps(id, onAction);
  if (checkIfDisabledInProject && level > SlugLevel.Organization && !project) return null;
  if (!scope.delete) return null;
  if (archive) return <ArchiveIconButton {...buttonProps} onClick={onClick} />;
  return <DeleteIconButton {...buttonProps} onClick={onClick} />;
}

interface IDataGridExportActionProps extends IGridActionProps {
  exportProps?: Omit<IExportIconProps, 'endpoint' | '_id'>;
}

export function DataGridExportAction({ params, exportProps }: IDataGridExportActionProps) {
  const { path } = useContext(SearchCrudContext);
  return <DownloadOnClickIcon endpoint={path} _id={params.row._id} {...exportProps} />;
}

interface DataGridGoToActionProps extends IGridActionProps {
  preTo?: string;
}

export function DataGridGoToAction({ preTo, params }: DataGridGoToActionProps) {
  return <GoToIconButtonLink to={preTo ? `${preTo}/${params.row._id}` : params.row._id} />;
}

export function DataGridDownloadDocumentAction({ params }: IGridActionProps) {
  return <DownloadHrefIcon document={params.row} />;
}

interface DataGridPreviewActionProps {
  to: string;
}

export function DataGridPreviewAction({ to }: DataGridPreviewActionProps) {
  const handleOnClick = useCallback(() => {
    openPreview(to);
  }, [to]);

  return <PreviewIconButton onClick={handleOnClick} />;
}

interface DataGridPatchActionProps<T extends IPaginatedSearchObject> extends IGridActionProps {
  keys?: (keyof T)[];
}

export function DataGridPatchAction<T extends IPaginatedSearchObject>({
  params,
  keys,
}: DataGridPatchActionProps<T>) {
  const { scope } = useContext(SearchCrudContext);
  const onAction = useCallback(
    ({ spawnPatch }: any, item: any, index: any) => spawnPatch(item, index, keys),
    [keys]
  );
  const onClick = useSearchActionProps<T>(params.id, onAction);
  if (!scope.update) return null;
  return <EditIconButton onClick={onClick} />;
}
