import Grid2 from '@mui/material/Grid2';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Decimal from 'decimal.js';
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { AptlyAllowance } from '@aptly-as/types';
import { ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';

type SelectType = keyof Pick<AptlyAllowance, 'amount' | 'multiplier'>;
export function CustomAllowancePrice<T extends { allowance?: AptlyAllowance | null }>({
  crud,
  field,
}: ICustomRenderComponentProps<T, 'allowance'>): ReactNode {
  return <AllowancePrice defaultValue={field.value} onChange={crud.setField('allowance')} />;
}

interface AllowancePriceProps {
  defaultValue?: AptlyAllowance | null;
  onChange: (value: AptlyAllowance | null) => void;
}

export function AllowancePrice({ defaultValue, onChange }: AllowancePriceProps): ReactNode {
  const defaultType: SelectType = defaultValue?.multiplier ? 'multiplier' : 'amount';
  const [type, setType] = useState<SelectType>(defaultType);
  const [value, setValue] = useState<string>('');

  const handleOnAmount = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [type]
  );

  const handleOnType = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setType(e.target.value as SelectType);
  }, []);

  useEffect(() => {
    if (value) {
      const number = Number(value);
      if (isNaN(number)) return;
      const allowance = new Decimal(number).mul(type === 'amount' ? 100 : 1).toNumber();
      if (!allowance) {
        onChange(null);
      } else {
        onChange({ [type]: allowance });
      }
    }
  }, [type, value, onChange]);

  return (
    <>
      <Grid2 container flexWrap="nowrap">
        <Grid2 size={{ xs: 7 }}>
          <TextField
            label={i18n.t('singles.discount')}
            type="number"
            value={value}
            fullWidth
            onChange={handleOnAmount}
            required={!!value}
            slotProps={{
              htmlInput: {
                min: 0,
                max: type === 'multiplier' ? 100 : undefined,
              },
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 5 }}>
          <TextField fullWidth select value={type} onChange={handleOnType}>
            <MenuItem value="amount">,-</MenuItem>
            <MenuItem value="multiplier">%</MenuItem>
          </TextField>
        </Grid2>
      </Grid2>
    </>
  );
}
