import { useFields } from '@ewb/reach-react';
import { IUseCrudProps } from '@ewb/reach-react/core/useCrud.js';
import { PropsWithChildren, useMemo } from 'react';
import ApiError, { IApiError } from '../../components/ApiError.js';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { ICrudUnit, useUnitSchema } from './unit.schema.js';
import { UnitContext } from './UnitContext.js';

export function UnitProvider({ id, children }: PropsWithChildren<{ id?: string }>) {
  const path = useApiUrl(SlugLevel.Project, 'units');
  const unit = useFields<ICrudUnit, IApiError, ICrudFieldData<ICrudUnit>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useUnitSchema(),
    useMemo(
      (): IUseCrudProps<ICrudUnit> => ({
        idKey: '_id',
        initWithGet: true,
        reachOptions: { query: { $populate: 'users;unitTemplate:_id,name' } },
      }),
      []
    )
  );

  const value = useMemo(() => ({ data: unit.state.data, init: true, ...unit }), [unit]);

  if (unit.state.error) return <ApiError error={unit.state.error} />;
  if (!unit.state.data.name) return null;

  return <UnitContext.Provider value={value}>{children}</UnitContext.Provider>;
}
