import { IUseFieldRet } from '@ewb/reach-react';
import { AptlyFieldType } from '@aptly-as/types';
import { IApiError } from '../ApiError.js';
import { ICrudFieldCustomGroupField, ICrudFieldData } from '../crud/utils/crud.utils.js';
import SimpleAmountField from './simple-components/SimpleAmountField.js';
import SimpleAutocompleteQuery from './simple-components/SimpleAutocompleteQuery.js';
import SimpleAutocompleteSelect from './simple-components/SimpleAutocompleteSelect.js';
import SimpleBulkIds from './simple-components/SimpleBulkIds.js';
import SimpleCheckbox from './simple-components/SimpleCheckbox.js';
import SimpleColor from './simple-components/SimpleColor.js';
import SimpleDate from './simple-components/SimpleDate.js';
import SimpleDateTime from './simple-components/SimpleDateTime.js';
import SimpleDocuments from './simple-components/SimpleDocuments.js';
import SimpleFiles from './simple-components/SimpleFiles.js';
import SimpleGooglePlace from './simple-components/SimpleGooglePlace.js';
import SimpleImageMediaUpload from './simple-components/SimpleImageMediaUpload.js';
import SimpleImages from './simple-components/SimpleImages.js';
import SimpleMarkdown from './simple-components/SimpleMarkdown.js';
import SimpleMediaUpload from './simple-components/SimpleMediaUpload.js';
import SimpleSelect from './simple-components/SimpleSelect.js';
import SimpleSort from './simple-components/SimpleSort.js';
import SimpleStringArray from './simple-components/SimpleStringArray.js';
import SimpleSwitch from './simple-components/SimpleSwitch.js';
import SimpleTextArea from './simple-components/SimpleTextArea.js';
import SimpleTextField from './simple-components/SimpleTextField.js';
import SimpleTime from './simple-components/SimpleTime.js';

interface SimpleCrudFieldsProps<T extends object, K extends keyof T> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
  field: ICrudFieldCustomGroupField<T, K>;
  onChange: (v: T[K], autoSave?: boolean) => void;
}

export function SimpleCrudFields<T extends object, K extends keyof T>({
  crud,
  field,
  onChange,
}: SimpleCrudFieldsProps<T, K>) {
  switch (field.type) {
    case AptlyFieldType.Text:
    case AptlyFieldType.Email:
    case AptlyFieldType.Number:
    case AptlyFieldType.Password:
      return <SimpleTextField field={field} onChange={onChange} />;
    case AptlyFieldType.Amount:
      return <SimpleAmountField crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.StringArray:
      return <SimpleStringArray field={field} onChange={onChange} />;
    case AptlyFieldType.File:
      return <SimpleFiles field={field} onChange={onChange} />;
    case AptlyFieldType.Files:
      return <SimpleFiles field={field} onChange={onChange} multiple />;
    case AptlyFieldType.TextArea:
      return <SimpleTextArea field={field} onChange={onChange} />;
    case AptlyFieldType.Markdown:
      return <SimpleMarkdown field={field} onChange={onChange} />;
    case AptlyFieldType.Select:
      return <SimpleSelect crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Image:
      return <SimpleImageMediaUpload crud={crud} field={field} onChange={onChange} simple />;
    case AptlyFieldType.Media:
      return <SimpleMediaUpload crud={crud} field={field} onChange={onChange} simple />;
    case AptlyFieldType.Images:
      return <SimpleImages field={field} onChange={onChange} simple />;
    case AptlyFieldType.Documents:
      return <SimpleDocuments crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Color:
      return <SimpleColor field={field} onChange={onChange} />;
    case AptlyFieldType.BulkIds:
      return <SimpleBulkIds crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Date:
      return <SimpleDate field={field} onChange={onChange} />;
    case AptlyFieldType.DateTime:
      return <SimpleDateTime field={field} onChange={onChange} />;
    case AptlyFieldType.Time:
      return <SimpleTime field={field} onChange={onChange} />;
    case AptlyFieldType.Sort:
      return <SimpleSort field={field} onChange={onChange} />;
    case AptlyFieldType.Switch:
      return <SimpleSwitch field={field} onChange={onChange} />;
    case AptlyFieldType.Checkbox:
      return <SimpleCheckbox field={field} onChange={onChange} />;
    case AptlyFieldType.GooglePlace:
      return <SimpleGooglePlace field={field} onChange={onChange} />;
    case AptlyFieldType.AutocompleteQuery:
      return <SimpleAutocompleteQuery crud={crud} field={field} onChange={onChange} />;
    case AptlyFieldType.Autocomplete:
      return <SimpleAutocompleteSelect field={field} onChange={onChange} />;
    case AptlyFieldType.Hidden:
      return null;
    default:
      return <>Not implemented {field.type}</>;
  }
}
