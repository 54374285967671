import { AptlyOrder, AptlyOrderStatus, AptlyScopes } from '@aptly-as/types';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Grid2 } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CopyButtonWithIcon,
  EditButtonWithIcon,
  SendButtonWithIcon,
} from '../../components/actions/buttons/Buttons.js';
import { EditorText } from '../../components/EditorText.js';
import { useDataGridOnRowClickEdit } from '../../components/Search/search-data-grid/data-grid.actions.js';
import { useDataGridVisibilityModel } from '../../components/Search/search-data-grid/data-grid.hooks';
import Search, { useSearchButtonActions } from '../../components/Search/Search.js';
import { SearchContext, SearchCrudContext } from '../../components/Search/search.utils.js';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import Section from '../../components/Section.js';
import { SimpleMarkdownField } from '../../components/simple/simple-components/SimpleMarkdown.js';
import { useFormat } from '../../containers/Format/Format.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { successNotification } from '../../containers/Notification/notification.utils';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl.js';
import { HideMobileFragment, useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n.js';
import { getId } from '../../libraries/mongoose';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch.js';
import { TextLinkWithIcon } from '../../libraries/router/ComponentLink';
import Paper from '../../mui/Paper.js';
import Table, { TableBody, TableRowCell } from '../../mui/Table.js';
import Typography from '../../mui/Typography';
import { tightSpacing } from '../../utils/spacing.js';
import { AddressLink, PhoneLink } from '../Address/AddressLink';
import { useAlgorithmPrice } from '../Algorithm/useAlgorithms.js';
import { customerToLabel } from '../Customer/customer';
import { DocumentPreviewButton } from '../Document/DocumentPreview.js';
import { InquiryNewLink } from '../Inquiry/InquiryNew';
import { OptionsSearchButton } from '../Option/OptionsSearch';
import { orderStatusLabel, orderStatusPaymentLabel } from '../Order/order.utils.js';
import { UnitContext } from '../Unit/UnitContext.js';
import { offerItemsDesktopColumns, useOfferItemColumns } from './offer.columns';
import { OfferItemSchema, offerItemSchemaFields, useOfferItemSchema } from './offer.schema.js';
import {
  getOfferLevelQuery,
  OfferContext,
  useEditOffer,
  useOfferCopy,
  useOfferItemsMutation,
  useOfferItemsPrice,
} from './OfferContext.js';
import { OfferProvider } from './OfferProvider.js';
import { OfferSend } from './OfferSend.js';

interface OfferProps {
  level: SlugLevel;
}

export default function Offer({ level }: OfferProps) {
  const { offer } = useParams<{ offer: string }>();
  if (!offer) {
    return <>No offer ID in url</>;
  }
  return (
    <OfferProvider key={offer} id={offer!} level={level}>
      <Content />
    </OfferProvider>
  );
}

function Content() {
  const offer = useContext(OfferContext);
  const itemsPath = `offers/${offer.data._id}/items`;
  const path = useApiUrl(SlugLevel.Project, itemsPath);
  const schema = useOfferItemSchema();
  const onEdit = useEditOffer();
  const reach: IUsePaginatedSearchProps<OfferItemSchema> = useMemo(
    () => ({
      query: {
        $populate: 'option:_id,title,name,identification,quantityUnitCode,thumbnail',
        level: getOfferLevelQuery(offer.level),
      },
    }),
    [offer.level]
  );
  const buttonActions = useSearchButtonActions<OfferItemSchema>((items) => {
    const _actions: JSX.Element[] = [];

    if (offer.isTemplate) {
      _actions.push(<OfferCopyAction key="copy" canEdit={offer.canEdit} />);
    }

    if (offer.data.document) {
      _actions.push(
        <DocumentPreviewButton key="document" document={offer.data.document}>
          {i18n.t('singles.document')}
        </DocumentPreviewButton>
      );
    }

    if (offer.canEdit) {
      _actions.push(
        <EditButtonWithIcon
          key="edit"
          onClick={() => onEdit({ items, fields: ['name', 'createdBy', 'expiresAt'] })}
        />
      );
    }

    if (!offer.isTemplate && offer.level !== SlugLevel.Organization) {
      if (!offer.data.unit && offer.canEdit) {
        _actions.push(<SendAction key="send" />);
      }
    }

    return _actions;
  });

  return (
    <Search<OfferItemSchema>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.ProjectOffers}
      header={{ title: offer.data.name }}
      post={{ title: i18n.t('singles.offerItems') }}
      patch={{ title: i18n.t('singles.offerItems') }}
      primaryAction={offer.canEdit ? <AddAction /> : undefined}
      schema={schema}
      fields={offerItemSchemaFields}
      options={{ disableBulk: true }}
      reach={reach}
      buttonActions={buttonActions}
    >
      {() => <OfferDashboard />}
    </Search>
  );
}

function SendAction() {
  const unit = useContext(UnitContext);
  const offer = useContext(OfferContext);
  const { items } = useContext(SearchCrudContext);
  const spawn = useModal(() => <OfferSend offer={offer} unit={unit.init || !!offer.data.unit} />);

  return (
    <SendButtonWithIcon color="primary" variant="outlined" disabled={items.length === 0} onClick={spawn}>
      {i18n.t('actions.send')}
    </SendButtonWithIcon>
  );
}

function AddAction() {
  const { actions } = useContext(SearchCrudContext);
  const itemsQuery = useOfferItemsMutation({
    onSettled: (items) => {
      if (items) actions.push(...items);
    },
  });

  return <OptionsSearchButton onSelect={itemsQuery.mutate} />;
}

function OfferDashboard() {
  const { data, busy } = useContext(SearchContext);
  const isMobile = useMobile();
  const columns = useOfferItemColumns();
  const offer = useContext(OfferContext);
  const onEdit = useEditOffer();
  const onEditPrice = useCallback(() => {
    onEdit({
      items: data,
      fields: ['amount', 'algorithm', '__algorithm'],
    });
  }, [onEdit, data]);

  const toUnit = useTo(SlugLevel.Project, '/enheter', {
    project: offer.data.project,
  });
  const initialState = useDataGridVisibilityModel(offerItemsDesktopColumns, {
    actions: offer.canEdit,
  });
  const handleOnClick = useDataGridOnRowClickEdit(!offer.canEdit);
  const itemsPrice = useOfferItemsPrice(data);
  const price = useAlgorithmPrice(offer.data.amount, offer.data.algorithm);
  const format = useFormat();

  return (
    <Section>
      <Grid2 container spacing={tightSpacing} flexDirection={{ xs: 'column', xl: 'row' }}>
        <Grid2 container flexDirection="column" spacing={tightSpacing} size={{ xs: 12, xl: 4 }}>
          <Grid2 size={{ xs: 12 }}>
            <HideMobileFragment>
              <Typography variant="h2" gutterBottom>
                {i18n.t('singles.info')}
              </Typography>
            </HideMobileFragment>
            <Paper>
              <Table>
                <TableBody>
                  {offer.data.order && typeof offer.data.order === 'object' && (
                    <OrderInfo order={offer.data.order} />
                  )}
                  {offer.data.acceptedAt && (
                    <TableRowCell label={i18n.t('singles.accepted')}>
                      {format.dateTime(offer.data.acceptedAt)}
                    </TableRowCell>
                  )}
                  {offer.data.sentAt && (
                    <TableRowCell label={i18n.t('statuses.sent')}>
                      {format.dateTime(offer.data.sentAt)}
                    </TableRowCell>
                  )}
                  {offer.data.unit && offer.data.customer && (
                    <TableRowCell label={i18n.t('statuses.theirContact')}>
                      <InquiryNewLink
                        unitID={getId(offer.data.unit)}
                        onCreate={() => {
                          successNotification(i18n.t('statuses.sent'));
                        }}
                      >
                        {customerToLabel(offer.data.customer)}
                      </InquiryNewLink>
                    </TableRowCell>
                  )}
                  {offer.data.shipping && (
                    <TableRowCell label={i18n.t('singles.address')}>
                      <AddressLink address={offer.data.shipping} />
                    </TableRowCell>
                  )}
                  {offer.data.customer?.phone && (
                    <TableRowCell label={i18n.t('singles.phone')}>
                      <PhoneLink tel={offer.data.customer.phone} />
                    </TableRowCell>
                  )}
                  {offer.data.unit && typeof offer.data.unit === 'object' && (
                    <TableRowCell label={i18n.t('singles.customer')}>
                      <TextLinkWithIcon to={`${toUnit}/${offer.data.unit._id}`}>
                        {offer.data.unit.name}
                      </TextLinkWithIcon>
                    </TableRowCell>
                  )}
                  {offer.data.number && (
                    <TableRowCell label={i18n.t('singles.offerNumber')}>{offer.data.number}</TableRowCell>
                  )}
                  {offer.data.createdBy && typeof offer.data.createdBy === 'object' && (
                    <TableRowCell label={i18n.t('statuses.ourContact')}>
                      {offer.data.createdBy.fullName}
                    </TableRowCell>
                  )}
                  {offer.data.expiresAt && (
                    <TableRowCell label={i18n.t('statuses.expiresAt')}>
                      {format.date(offer.data.expiresAt)}
                    </TableRowCell>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid2>
          <Grid2>
            <Typography variant="h3" gutterBottom>
              {i18n.t('singles.commentToOffer')}
            </Typography>
            {offer.canEdit ? (
              <SimpleMarkdownField
                field={offer.crud.getField('description')}
                onChange={offer.crud.setField('description')}
                height={isMobile ? '200px' : '300px'}
              />
            ) : (
              <EditorText value={offer.data.description} />
            )}
          </Grid2>
        </Grid2>
        <Grid2 container flexDirection="column" spacing={1} size={{ xs: 12, xl: 8 }}>
          <HideMobileFragment>
            <Grid2 size={{ xs: 12 }}>
              <Typography variant="h2">{i18n.t('singles.orderLines')}</Typography>
            </Grid2>
          </HideMobileFragment>
          <Grid2 size={{ xs: 12 }}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRowCell label={i18n.t('singles.sum')}>
                    <Button
                      variant="text"
                      onClick={onEditPrice}
                      endIcon={<EditIcon />}
                      disabled={!offer.canEdit}
                    >
                      {price.amount ? (
                        <Grid2 container>
                          <s>{format.amount(itemsPrice.amount, itemsPrice.currency)}</s>
                          &nbsp;
                          {format.amount(price.amount, price.currency)}
                        </Grid2>
                      ) : (
                        format.amount(itemsPrice.amount, itemsPrice.currency)
                      )}
                    </Button>
                  </TableRowCell>
                </TableBody>
              </Table>
            </Paper>
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            {!busy && data.length === 0 && (
              <Alert color="info">
                {i18n.t('offer.empty', { action: i18n.t('actions.add').toUpperCase() })}
              </Alert>
            )}
            {!offer.canEdit && (
              <Alert color="info">
                <Trans i18nKey="offer.reset">
                  This offer is a template or sent and you need to copy it to make changes.
                </Trans>
              </Alert>
            )}
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <SearchDataGrid
              key={'offer-' + offer.canEdit}
              columns={columns}
              initialState={initialState}
              onRowClick={handleOnClick}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    </Section>
  );
}

interface OrderInfoProps {
  order: Pick<AptlyOrder, '_id' | 'orderNumber' | 'status' | 'paymentSession'>;
}

function OrderInfo({ order }: OrderInfoProps) {
  const orderTo = useTo(SlugLevel.Project, `/ordre/${order._id}`);

  return (
    <>
      <TableRowCell label={i18n.t('singles.order')}>
        <TextLinkWithIcon to={orderTo}>{order.orderNumber}</TextLinkWithIcon>
      </TableRowCell>
      <TableRowCell label={i18n.t('singles.status')}>{orderStatusLabel(order.status)}</TableRowCell>
      {order.status === AptlyOrderStatus.Payment && order.paymentSession && (
        <TableRowCell label={i18n.t('singles.payment')}>
          {orderStatusPaymentLabel(order.paymentSession.status)}
        </TableRowCell>
      )}
    </>
  );
}

interface OfferCopyProps {
  canEdit?: boolean;
}
function OfferCopyAction({ canEdit }: OfferCopyProps) {
  const onCopy = useOfferCopy();
  if (canEdit) return <CopyButtonWithIcon onClick={onCopy} variant="outlined" />;
  return <EditButtonWithIcon onClick={onCopy} />;
}
