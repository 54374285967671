import { AptlyAutocompleteSelectOption } from '@aptly-as/sdk-react/material';
import { AptlyDocumentAccess, AptlyDocumentType } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import { SelectOption } from '../../mui/Select';

export interface DocumentTypeOption extends AptlyAutocompleteSelectOption {
  value: AptlyDocumentType;
  info?: string;
}

export const defaultDocumentType = (): DocumentTypeOption => ({
  label: i18n.t('singles.document'),
  value: AptlyDocumentType.Document,
});

export const documentTypeOptions = (): DocumentTypeOption[] => [
  defaultDocumentType(),
  {
    value: AptlyDocumentType.AgreementAttachment,
    label: i18n.t('singles.agreementAttachment'),
    info: i18n.t('document.agreementAttachmentInfo'),
  },
  {
    value: AptlyDocumentType.ArchitectDrawing,
    label: i18n.t('singles.architectDrawing'),
  },
  {
    label: i18n.t('singles.bathroomDrawing'),
    value: AptlyDocumentType.BathroomDrawing,
  },
  {
    label: i18n.t('singles.circuitDirectory'),
    value: AptlyDocumentType.CircuitDirectory,
  },
  {
    label: i18n.t('singles.constructionDrawing'),
    value: AptlyDocumentType.ConstructionDrawing,
  },
  {
    label: i18n.t('singles.contract'),
    value: AptlyDocumentType.Contract,
  },
  {
    value: AptlyDocumentType.DeclarationOfConformity,
    label: i18n.t('singles.declarationOfConformity'),
  },
  {
    label: i18n.t('singles.deliveryDescription'),
    value: AptlyDocumentType.DeliveryDescription,
  },
  {
    label: i18n.t('singles.elDrawing'),
    value: AptlyDocumentType.ElDrawing,
  },
  {
    label: i18n.t('singles.endReport'),
    value: AptlyDocumentType.EndReport,
  },
  {
    label: i18n.t('singles.fdvDocument'),
    value: AptlyDocumentType.FDV,
  },
  {
    label: i18n.t('singles.furnishingPlan'),
    value: AptlyDocumentType.FurnishingPlan,
  },
  {
    label: 'GDPR',
    value: AptlyDocumentType.Gdpr,
  },
  {
    label: i18n.t('singles.kitchenDrawing'),
    value: AptlyDocumentType.KitchenDrawing,
  },
  {
    label: i18n.t('singles.invoice'),
    value: AptlyDocumentType.Invoice,
  },
  {
    label: i18n.t('singles.inspectionReport'),
    value: AptlyDocumentType.InspectionReport,
  },
  {
    label: i18n.t('singles.manual'),
    value: AptlyDocumentType.Manual,
  },
  {
    label: i18n.t('singles.floorPlan'),
    value: AptlyDocumentType.UnitPlan,
  },
  {
    label: i18n.t('singles.orderAttachment'),
    value: AptlyDocumentType.OrderAttachment,
  },
  {
    label: i18n.t('statuses.public'),
    value: AptlyDocumentType.Public,
  },
  {
    label: i18n.t('singles.prospect'),
    value: AptlyDocumentType.Prospect,
    info: i18n.t('document.prospectInfo'),
  },
  {
    label: i18n.t('singles.orderReceipt'),
    value: AptlyDocumentType.Receipt,
  },
  {
    value: AptlyDocumentType.RiskAssessment,
    label: i18n.t('singles.riskAssessment'),
  },
  {
    label: i18n.t('singles.signedReceipt'),
    value: AptlyDocumentType.SignedReceipt,
  },
  {
    label: i18n.t('singles.vvsDrawing'),
    value: AptlyDocumentType.VVSDrawing,
  },
];

export function sortedDocumentTypeOptions(): DocumentTypeOption[] {
  return documentTypeOptions().sort((a, b) => a.label.localeCompare(b.label));
}

export function documentTypeLabel(type: AptlyDocumentType): string {
  const typeName = documentTypeOptions().find((x) => x.value === type);
  if (typeName) {
    return typeName.label;
  }
  switch (type) {
    case AptlyDocumentType.EndReport:
      return i18n.t('document.endReport');
    case AptlyDocumentType.ProductAttachment:
      return i18n.t('document.productAttachment');
    case AptlyDocumentType.PaymentReceipt:
      return i18n.t('document.paymentReceipt');
    case AptlyDocumentType.PaymentReport:
      return i18n.t('document.paymentReport');
    case AptlyDocumentType.Report:
      return i18n.t('singles.report');
    default:
      return type;
  }
}

export const documentAccessOptions = (withInfo = false): SelectOption[] => [
  {
    value: AptlyDocumentAccess.Public,
    label: i18n.t('statuses.public') + (withInfo ? ' - ' + i18n.t('paragraphs.documentPublic') : ''),
  },
  {
    value: AptlyDocumentAccess.User,
    label: i18n.t('singles.user') + (withInfo ? ' - ' + i18n.t('paragraphs.documentUser') : ''),
  },
  {
    value: AptlyDocumentAccess.Admin,
    label: i18n.t('singles.admin') + (withInfo ? ' - ' + i18n.t('paragraphs.documentAdmin') : ''),
  },
];

export function documentAccessTranslations(type: string): string {
  const typeName = documentAccessOptions().find((x) => x.value === type);
  return typeName ? typeName.label : type;
}
