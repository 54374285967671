import { AptlyAlgorithm } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import { getId } from '../../../libraries/mongoose';
import Accordion from '../../Accordion';
import { AccordionDetails } from '../../../mui/Accordion';
import Grid from '../../../mui/Grid';
import { TextField } from '../../../mui/Input';
import SimpleCrud from '../../SimpleCrud';
import { minSpacing } from '../../../utils/spacing';

interface Props {
  override: any;
  endpoint: string;
  onCreate?: (product: any) => void;
  product: any;
  section: any;
  onClose?: () => void;
  algorithms: AptlyAlgorithm[];
}

export default function CrudProductOverwrite({
  override,
  endpoint,
  onCreate,
  product,
  section,
  onClose,
  algorithms,
}: Props) {
  return (
    <SimpleCrud
      title={product.isPackage ? i18n.t('actions.overwritePackage') : i18n.t('actions.overwriteProduct')}
      actionLabel={i18n.t('actions.overwrite')}
      id={override ? override._id : undefined}
      endpoint={`${endpoint}/product-overrides`}
      onCreate={onCreate}
      onClose={onClose}
      fields={[
        {
          name: 'sectionProductRef',
          type: 'hidden',
          value: product._id || '',
          label: '',
          forceSave: true,
        },
        {
          name: 'section',
          type: 'hidden',
          value: section._id,
          label: '',
          forceSave: true,
        },
        {
          name: 'hide',
          type: 'checkbox',
          value: override ? override.hide : false,
          label: override ? override.hide.toString() : 'false',
          helpField: i18n.t('actions.hide'),
        },
        {
          name: 'unitCost',
          type: 'number',
          value: override && override.unitCost ? override.unitCost : product.unitCost || 0,
          label:
            override && override.unitCost ? override.unitCost.toString() : (product.unitCost || 0).toString(),
          helpField: i18n.t('singles.netCost'),
        },
        {
          name: 'variantPrices',
          type: 'customComponent',
          value: override ? override.variantPrices : [],
          hidden: !!product.isPackage || !product.product?.variants || product.product.variants.length === 0,
          label: '',
          renderComponent: (setValue, getField) => {
            if (product.product) {
              const variants = product.product.variants.filter((v: any) =>
                product.availableProducts.includes(v._id)
              );

              const data = getField('variantPrices').value;

              return (
                <Accordion title={i18n.t('singles.variantPrices')}>
                  {() => (
                    <AccordionDetails>
                      <Grid container direction="row" spacing={minSpacing}>
                        {variants.map((v: any) => {
                          const currentVariantData = (product.variantPrices || []).find(
                            (vp: any) => vp.variant === v._id
                          );

                          let val = currentVariantData ? currentVariantData.cost : product.unitCost;

                          if (Array.isArray(data)) {
                            const elem = data.find((d) => d.variant === v._id);

                            if (elem) {
                              val = elem.price;
                            }
                          }

                          return (
                            <Grid key={v._id} item sm={12}>
                              <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item style={{ flex: 1 }}>
                                  {v.name}
                                </Grid>
                                <Grid item sm={3}>
                                  <TextField
                                    type="number"
                                    fullWidth
                                    value={val}
                                    onChange={(e) => {
                                      const dataval = Array.isArray(data) ? [...data] : [];

                                      const datavalIndex = dataval.findIndex((d) => d.variant === v._id);

                                      const userData = {
                                        variant: v._id,
                                        price: e.target.value,
                                      };

                                      if (datavalIndex !== -1) {
                                        dataval[datavalIndex] = userData;
                                      } else {
                                        dataval.push(userData);
                                      }

                                      setValue('variantPrices', dataval, '');
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            }

            return null;
          },
        },
        {
          name: 'amount',
          type: 'number',
          value: override && override.amount ? override.amount : product.amount || 0,
          label: override && override.amount ? override.amount.toString() : (product.amount || 0).toString(),
          helpField: i18n.t('singles.amount'),
        },
        {
          name: 'algorithm',
          type: 'select',
          value: override?.algorithm ? getId(override.algorithm) || null : getId(product.algorithm) || null,
          label: override?.algorithm ? (override.amount || 0).toString() : (product.amount || 0).toString(),
          helpField: i18n.t('singles.calculation'),
          options: algorithms.map((x) => ({ value: x._id, label: x.name })),
        },
      ]}
    />
  );
}
