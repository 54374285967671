import { AptlyProduct } from '@aptly-as/types';
import i18n from 'i18next';
import { useCallback } from 'react';
import { SearchButtonWithIcon } from '../../components/actions/buttons/Buttons.js';
import { ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils.js';
import { useModal, useModalFullScreenOpts } from '../../containers/Modal/Modal.js';
import { ProductSearch } from './ProductSearch.js';

export default function ProductsSchemaField<T extends object, K extends keyof T>({
  crud,
  field,
}: ICustomRenderComponentProps<T, K>) {
  const handleOnSave = useCallback(
    async (v: AptlyProduct[]) => {
      let value = v[0] || null;
      if (field.preOnChange) {
        value = field.preOnChange(value, crud);
      }
      crud.setField(field.key)(value as T[K]);
    },
    [crud, field]
  );
  const spawnModal = useModal(
    () => (
      <ProductSearch
        fullScreen
        single
        onSave={handleOnSave}
        defaultSelected={field.value ? [field.value as unknown as AptlyProduct] : []}
      />
    ),
    useModalFullScreenOpts
  );

  const product = field.value as AptlyProduct | null;
  return (
    <SearchButtonWithIcon fullWidth size="large" onClick={spawnModal}>
      {product ? `${product.productNumber}: ${product.name}` : i18n.t('actions.connectProduct')}
    </SearchButtonWithIcon>
  );
}
