import { AptlyAlgorithm, AptlyCurrency } from '@aptly-as/types';
import { useContext, useMemo } from 'react';
import { SlugLevel } from '../../hooks/useGetApiUrl.js';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { ProjectContext } from '../Project/ProjectContext';
import { Algorithm } from './algorithm.pipeline.js';

export function useAlgorithms(level: SlugLevel = SlugLevel.Unit): AptlyAlgorithm[] {
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  return useMemo(() => {
    const algorithms: AptlyAlgorithm[] = [];
    if (level > SlugLevel.Organization && project.data?.algorithms) {
      algorithms.push(...project.data.algorithms);
    } else if (organization.data?.algorithms) {
      algorithms.push(...organization.data.algorithms);
    }
    return algorithms;
  }, [organization, project]);
}

export function useAlgorithm(algorithm?: string | AptlyAlgorithm | null): AptlyAlgorithm | undefined {
  const algorithms = useAlgorithms();
  if (!algorithm) return undefined;
  if (typeof algorithm === 'object') {
    return algorithm;
  }
  return algorithms.find((x) => x._id === algorithm) || undefined;
}

export function useAlgorithmPrice(
  amount?: number,
  algorithm?: string | null,
  quantity = 1
): { amount: number; currency: AptlyCurrency } {
  const algorithms = useAlgorithms();
  return useMemo((): { amount: number; currency: AptlyCurrency } => {
    const found = algorithms.find((x) => x._id === algorithm);
    const algo = new Algorithm(found).amount(amount || 0).quantity(quantity);
    return {
      amount: algo.getTotalAmount(),
      currency: algo.currency,
    };
  }, [algorithms, amount, algorithm, quantity]);
}
