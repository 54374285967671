import { AptlyScopes } from '@aptly-as/types';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import useSlugLevel from '../../libraries/scope/useSlugLevel.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import { useEmailColumns } from './email.columns.js';
import { useEmailSchema } from './email.schema';

export default function Emails() {
  const path = useApiUrl(SlugLevel.Project, 'emails');
  const level = useSlugLevel();
  const schema = useEmailSchema();
  const columns = useEmailColumns();
  const scope = useScopeLevel({
    project: AptlyScopes.ProjectEmails,
    base: AptlyScopes.Admin,
  });

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('emails')}
      level={level}
      scope={scope}
      header={{ title: i18n.t('singles.emails') }}
      post={{ title: i18n.t('singles.emails') }}
      patch={{
        title: (data) => String(data.type),
        fields: ['type', 'subject', 'content', 'actionLabel'],
      }}
      schema={schema}
      fields={['type']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project' } }}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
