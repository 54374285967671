import { AptlyUser } from '@aptly-as/types';
import { user } from './UserContext';

export function handleSessionTimeout(savePath?: boolean) {
  user.value = null;
  if (savePath) {
    localStorage.setItem('redirect', window.location.pathname);
  }
}

export function toUserRefValue(user: AptlyUser): Pick<AptlyUser, '_id' | 'fullName'> {
  return {
    _id: user._id,
    fullName: user.fullName || `${user.firstName} ${user.lastName}`,
  };
}
