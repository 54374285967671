import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { useCallback } from 'react';
import { AptlyProduct } from '@aptly-as/types';
import { OpenInNewIconButton } from '../../components/actions/icons/Icons';
import Avatar from '../../components/Avatar';
import { createModal } from '../../containers/Modal/ModalContext';
import createMediaURL from '../Media/createMediaURL';
import extractFeaturedImage from '../Product/extractFeaturedImage';
import SingleProduct from '../../deprecated/SingleProduct';

interface ProductItemProps {
  product: AptlyProduct;
  variant?: string;
}

// todo: Should be rewritten to not use SingleProduct. Or single product needs to be rewritten to only show product info with no field or logic.
function ProductItem({ product, variant }: ProductItemProps) {
  const productVariant = variant && product.variants.find((x) => x._id === variant);
  const name = productVariant ? productVariant.name : product.name;
  const productNumber = productVariant ? productVariant.productNumber : product.productNumber;
  let image = product.images[0] ? createMediaURL(extractFeaturedImage(product.images)!.image) : '';

  if (productVariant) {
    if (productVariant.images[0]) {
      image = createMediaURL(extractFeaturedImage(productVariant.images)!.image);
    }
  }

  const spawnProductModal = useCallback(() => {
    createModal(<SingleProduct id={product._id} />, { width: 'md' });
  }, [product._id]);

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={image} alt={name} />
        </ListItemAvatar>
        <ListItemText primary={name} secondary={productNumber} />
        <ListItemSecondaryAction>
          <OpenInNewIconButton onClick={spawnProductModal} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export default ProductItem;
