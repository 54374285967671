import { AptlyUpsellTemplate } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import {
  dataGridLevelColumn,
  dataGridSearchImageColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields.js';
import i18n from '../../libraries/i18n.js';

export function useUpsellTemplateColumns(): GridColDef<AptlyUpsellTemplate>[] {
  return [
    dataGridSearchImageColumn('image'),
    dataGridSearchNameColumn(),
    {
      type: 'string',
      minWidth: 250,
      field: 'videoEmbed',
      headerName: i18n.t('paragraphs.videoEmbedCode'),
    },
    {
      field: 'links',
      minWidth: 150,
      headerName: i18n.t('singles.links'),
      renderCell: (params) => params.value.map((x: any) => x.name).join(', '),
    },
    dataGridLevelColumn(),
    dataGridActionProps(
      (params) => (
        <DataGridActionWrapper>
          <DataGridEditAction params={params} checkIfDisabledInProject />
          <DataGridCopyAction<AptlyUpsellTemplate>
            params={params}
            preCopy={(data) => ({
              name: `${data.name} - kopi`,
              image: data.image,
              imageMedia: data.imageMedia,
              links: data.links,
              text: data.text,
              videoEmbed: data.videoEmbed,
            })}
          />
          <DataGridExportAction params={params} />
          <DataGridDeleteAction params={params} checkIfDisabledInProject />
        </DataGridActionWrapper>
      ),
      { width: 235 }
    ),
  ];
}
