import { ReactElement, useCallback, useContext } from 'react';
import { ICreateModelOptions } from './modal.types.js';
import { ModalContext } from './ModalContext';

export const useModalFullScreenOpts: ICreateModelOptions = {
  fullScreen: true,
};

export function useModal<T>(fn: (data: T) => ReactElement, opts?: ICreateModelOptions) {
  const { createModal } = useContext(ModalContext);
  return useCallback(
    (data: T) => {
      createModal(fn(data), opts);
    },
    [fn, opts]
  );
}
