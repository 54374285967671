import { AptlyScopes } from '@aptly-as/types';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import useSlugLevel, { ILevelItem } from './useSlugLevel.js';

export interface IUseScopePropsProps {
  base: AptlyScopes;
  organization?: AptlyScopes;
  project?: AptlyScopes;
  unit?: AptlyScopes;
}

export default function useScopeLevel(
  { base, organization, project, unit }: IUseScopePropsProps,
  item?: ILevelItem
): AptlyScopes {
  const level = useSlugLevel(item);
  if (level === SlugLevel.Unit && unit) return unit;
  if (level >= SlugLevel.Project && project) return project;
  if (level >= SlugLevel.Organization && organization) return organization;
  return base;
}
