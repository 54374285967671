import { AptlyScopes } from '@aptly-as/types';
import i18n from '../../libraries/i18n';
import Search, { SearchFieldText } from '../../components/Search/Search';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { GridColDef } from '../../mui/x-data-grid/DataGrid';
import { supplierSchema } from './supplier.schema';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import {
  dataGridActions,
  dataGridSearchNameColumn,
  dataGridSearchImageColumn,
  dataGridSearchTextColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';

const columns = (): GridColDef[] => [
  dataGridSearchImageColumn('logo'),
  dataGridSearchNameColumn(),
  dataGridSearchTextColumn('email', i18n.t('singles.email'), { type: 'email' }),
  dataGridActions(),
];

function Suppliers() {
  const path = useApiUrl(SlugLevel.Base, 'suppliers');

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('suppliers')}
      scope={AptlyScopes.AdminSuppliers}
      header={{ title: i18n.t('singles.supplier') }}
      patch={{ title: (data) => String(data.name) }}
      post={{ title: i18n.t('singles.supplier') }}
      schema={supplierSchema()}
      fields={['name', 'email', 'logo']}
      options={{ disableBulk: true }}
      reach={{ query: { archived: false, sort: 'name' } }}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}

export default Suppliers;
