import { AptlyCurrency } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef.js';
import { useFormat } from '../../containers/Format/Format.js';
import i18n from '../../libraries/i18n.js';
import { useAlgorithmPrice } from './useAlgorithms.js';

export function useAlgorithmAmountColumn<
  T extends { amount?: number; algorithm?: string | null; currency?: AptlyCurrency },
>(override: Partial<GridColDef<T>> = {}): GridColDef<T> {
  const format = useFormat();
  return {
    type: 'number',
    field: 'algorithm',
    headerName: i18n.t('singles.amount'),
    align: 'right',
    renderCell: (params) => {
      const { row, value } = params;
      const price = useAlgorithmPrice(row.amount || 0, value);
      return format.amount(price.amount, price.currency);
    },
    ...override,
  };
}
