import { useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { DrawerMenu } from '../../containers/Nav/DrawerMenu';
import Header from '../../containers/Nav/Header';
import { Main } from '../../components/Main';
import { ModalProvider } from '../../containers/Modal/ModalProvider';
import { NavBackItem } from '../../containers/Nav/NavBackItem.js';
import { NavDrawer } from '../../containers/Nav/NavDrawer';
import SidebarRoutes from '../../containers/Nav/SidebarRoutes';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n.js';
import { ScopeProvider } from '../../libraries/scope/ScopeProvider';
import OrganizationAppsAccept from '../App/OrganizationAppsAccept';
import Offer from '../Offer/Offer';
import Page from '../Page/Page';
import Project from '../Project/Project';
import UnitTemplateV2 from '../UnitTemplate/UnitTemplate';
import { useOrganizationRoutes } from './organization.routes';
import { OrganizationContext } from './OrganizationContext';
import OrganizationHeader from './OrganizationHeader';
import { OrganizationProvider } from './OrganizationProvider.js';

function Organization() {
  const { slug } = useParams<{ slug: string }>();

  return (
    <OrganizationProvider slug={slug}>
      <ScopeProvider path={`organizations/${slug}/scope`}>
        <OrgRoutes />
      </ScopeProvider>
    </OrganizationProvider>
  );
}

function OrgRoutes() {
  const org = useContext(OrganizationContext);
  const routes = useOrganizationRoutes(org.data.producer);

  return (
    <Routes>
      <Route path="prosjekter/:projectID/*" element={<Project />} />
      <Route path="apps/:app/accept" element={<OrganizationAppsAccept />} />
      <Route
        path="*"
        element={
          <ModalProvider>
            <Header breadcrumbs={[<OrganizationHeader key="org" />]} />
            <NavDrawer>
              <NavBackItem to="/" primary={i18n.t('singles.organizations')} />
              <DrawerMenu level={SlugLevel.Organization} injection="organization" routes={routes} />
            </NavDrawer>
            <Main>
              <ErrorBoundary>
                <SidebarRoutes routes={routes}>
                  <Route path={'tilvalg/:unitTemplateID'} element={<UnitTemplateV2 />} />
                  <Route path={'sider/:pageID'} element={<Page />} />
                  <Route path={'tilbud/:offer'} element={<Offer level={SlugLevel.Organization} />} />
                </SidebarRoutes>
              </ErrorBoundary>
            </Main>
          </ModalProvider>
        }
      />
    </Routes>
  );
}

export default Organization;
