import styled, { css } from 'styled-components';
import { cssSpacing } from '../utils/spacing';
import { MOBILE_BREAK } from '../utils/themes';

const Wrapper = styled.div<{ hugBottom?: boolean }>`
  margin: ${cssSpacing} 0;
  padding: 0 ${cssSpacing};

  > .section {
    padding: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${(props) =>
    props.hugBottom &&
    css`
      margin-bottom: 0;
    `};

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    padding: ${(props) => props.theme.spacing(0, 1)};
    margin: ${(props) => props.theme.spacing(1, 0)};
  }
`;

type Props = {
  children: React.ReactNode;
  hugBottom?: boolean;
  style?: object;
};

function Section(props: Props) {
  const { children, hugBottom, ...rest } = props;

  return (
    <Wrapper className="section" hugBottom={hugBottom} {...rest}>
      {children}
    </Wrapper>
  );
}

export default Section;
