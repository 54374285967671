import { AptlyFieldType, AptlyOffer, AptlyOfferItem, AptlyOfferSendBody, AptlyOption } from '@aptly-as/types';
import { useContext } from 'react';
import { schemaDescription, schemaEmail, schemaName } from '../../components/crud/schema/fields.schema.js';
import { ICrudSchema } from '../../components/crud/utils/crud.utils.js';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n.js';
import { IDateTimePickerProps } from '../../mui/x-date-picker/DatePickerTwo.js';
import { useAddressGeoSchemaField } from '../Address/address.schema.js';
import { useAlgorithmPreviewSchemaField, useAlgorithmSchemaField } from '../Algorithm/algorithm.schema.js';
import { CustomAllowancePrice } from '../Algorithm/AllowanceCharges';
import { useOptionSchemaField } from '../Option/option.schema.js';
import { useOrganizationMemberField } from '../Organization/organization.schema.js';
import { useUnitSchemaField } from '../Unit/unit.schema.js';
import { toUserRefValue } from '../User/user.utils';
import { UserContext } from '../User/UserContext';

export interface OfferSchema extends AptlyOffer {
  __algorithm?: undefined;
  status?: undefined;
}

export const offerSchemaFields: (keyof OfferSchema)[] = [
  'name',
  'amount',
  'algorithm',
  '__algorithm',
  'createdBy',
  'expiresAt',
];
export function useOfferSchema(level: SlugLevel): ICrudSchema<OfferSchema> {
  const user = useContext(UserContext);
  const isOrganization = level === SlugLevel.Organization;
  return {
    name: schemaName({
      autoFocus: true,
    }),
    identification: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.identification'),
      defaultValue: undefined,
    },
    description: schemaDescription({
      label: '',
    }),
    amount: {
      type: AptlyFieldType.Amount,
      label: i18n.t('singles.overrideAmount'),
      defaultValue: undefined,
      gridProps: {
        $gridColumn: 'span 6',
      },
    },
    allowance: {
      type: AptlyFieldType.Custom,
      defaultValue: null,
      label: '',
      CustomComponent: CustomAllowancePrice,
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    algorithm: useAlgorithmSchemaField({
      gridProps: {
        $gridColumn: 'span 6',
      },
    }),
    __algorithm: useAlgorithmPreviewSchemaField(),
    createdBy: useOrganizationMemberField({
      label: i18n.t('statuses.ourContact'),
      defaultValue: toUserRefValue(user.data),
    }),
    expiresAt: {
      type: AptlyFieldType.Date,
      label: i18n.t('statuses.expiresAt'),
      defaultValue: null,
      props: { disablePast: true } satisfies IDateTimePickerProps,
      renderValidate: () => !isOrganization,
    },
  };
}

export interface OfferItemSchema extends AptlyOfferItem {
  __algorithm?: string;
}

export const offerItemSchemaFields: (keyof OfferItemSchema)[] = [
  'option',
  'title',
  'note',
  'quantity',
  'amount',
  'algorithm',
  '__algorithm',
];
export function useOfferItemSchema(): ICrudSchema<OfferItemSchema> {
  return {
    option: useOptionSchemaField({
      required: true,
      preOnChange: (value: AptlyOption, crud) => {
        if (value) {
          crud.setData({
            quantity: value.quantity || 1,
            amount: value.amount || 0,
            algorithm: value.algorithm || undefined,
          });
        }
        return value;
      },
    }),
    title: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.title'),
      defaultValue: '',
      placeholderFn: (crud) => {
        const option = crud.getField('option').value;
        if (option && typeof option === 'object') return option.title;
        return '';
      },
    },
    note: {
      type: AptlyFieldType.TextArea,
      label: i18n.t('singles.comment'),
      defaultValue: '',
      renderValidate: (crud) => !!crud.getField('option').value,
    },
    quantity: {
      type: AptlyFieldType.Number,
      label: i18n.t('singles.quantity'),
      defaultValue: 1,
      required: true,
      renderValidate: (crud) => !!crud.getField('option').value,
    },
    allowance: {
      type: AptlyFieldType.Custom,
      defaultValue: null,
      label: '',
      CustomComponent: CustomAllowancePrice,
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    amount: {
      type: AptlyFieldType.Amount,
      label: i18n.t('singles.amount'),
      defaultValue: 0,
      required: true,
      renderValidate: (crud) => !!crud.getField('option').value,
      gridProps: {
        $gridColumn: 'span 6',
      },
    },
    algorithm: useAlgorithmSchemaField({
      gridProps: {
        $gridColumn: 'span 6',
      },
      renderValidate: (crud) => !!crud.getField('option').value,
    }),
    __algorithm: useAlgorithmPreviewSchemaField({
      renderValidate: (crud) => !!crud.getField('option').value,
    }),
  };
}

export interface AptlyOfferSendBodySchema extends AptlyOfferSendBody {
  _id?: string;
  or?: undefined;
  __info?: undefined;
}

export const userOfferSendFields: (keyof AptlyOfferSendBodySchema)[] = [
  'address',
  'email',
  'firstName',
  'lastName',
  'phone',
];
export function useOfferSendSchema(): ICrudSchema<AptlyOfferSendBodySchema> {
  return {
    unit: useUnitSchemaField(),
    address: useAddressGeoSchemaField({
      required: true,
      label: i18n.t('address.shipping'),
      renderValidate: (crud) => !crud.getField('unit').value,
    }),
    email: schemaEmail({
      renderValidate: (crud) => !crud.getField('unit').value,
    }),
    firstName: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.firstName'),
      defaultValue: '',
      renderValidate: (crud) => !crud.getField('unit').value,
      required: true,
    },
    lastName: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.lastName'),
      defaultValue: '',
      renderValidate: (crud) => !crud.getField('unit').value,
      required: true,
    },
    phone: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.phone'),
      defaultValue: '',
      renderValidate: (crud) => !crud.getField('unit').value,
    },
  };
}
