import { Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { PropsWithChildren, useContext } from 'react';
import { NavContext } from './NavContext';

export const NAV_DRAWER_WIDTH = 240;

export function NavDrawer({ children }: PropsWithChildren<object>) {
  const { state, toggle } = useContext(NavContext);
  return (
    <MuiDrawer
      open={!state.mobile || state.open}
      variant={state.mobile ? 'temporary' : 'permanent'}
      anchor="left"
      sx={{
        width: NAV_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: NAV_DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      onClose={toggle}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>{children}</Box>
    </MuiDrawer>
  );
}
