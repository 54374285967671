import { useCallback } from 'react';
import { AptlyApp, AptlyAppAction, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import SmartButtonIcon from '@mui/icons-material/SmartButtonOutlined';
import ActionButtons from '../../../components/actions/buttons/ActionButtons';
import { EditIconButton } from '../../../components/actions/icons/Icons';
import Search from '../../../components/Search/Search';
import SearchAddPrimaryAction from '../../../components/Search/search-actions/SearchPrimaryAction';
import { IGridActionProps } from '../../../components/Search/search-data-grid/data-grid.actions';
import { dataGridActions } from '../../../components/Search/search-data-grid/data-grid.fields';
import { useDataGridEditModal } from '../../../components/Search/search-data-grid/data-grid.hooks';
import SearchDataGrid from '../../../components/Search/SearchDataGrid';
import { ICreateModelOptions } from '../../../containers/Modal/modal.types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import Dialog, { ModalActions } from '../../../mui/Dialog';
import { useAppActionSchema, appActionSchemaFields } from '../app.schema';

export default function AppsActions({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyApp>(params);
  const handleOnChange = useCallback(
    (actions: AptlyAppAction[]) => {
      onChange((data) => ({ ...(data as AptlyApp), actions }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<SmartButtonIcon />} title={i18n.t('singles.actions')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent app={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  app: AptlyApp;
  onChange: (actions: AptlyAppAction[]) => void;
}

const modalProps: ICreateModelOptions = { width: 'sm' };

const useAppActionsColumns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'model',
    headerName: i18n.t('singles.model'),
    width: 100,
  },
  {
    type: 'string',
    field: 'label',
    headerName: i18n.t('singles.label'),
    flex: 1,
  },
  {
    type: 'string',
    field: 'webhook',
    headerName: i18n.t('singles.webhook'),
    flex: 1,
  },
  {
    type: 'string',
    field: 'scope',
    headerName: i18n.t('singles.scope'),
    width: 150,
  },
  dataGridActions(
    {},
    {
      minWidth: 125,
    }
  ),
];

function ModalContent({ app, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Organization, `apps/${app._id}/actions`);
  const schema = useAppActionSchema();
  const columns = useAppActionsColumns();

  return (
    <Search<AptlyAppAction>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.AdminApps}
      header={{ title: i18n.t('singles.actions') }}
      post={{ title: i18n.t('singles.actions') }}
      patch={{ title: i18n.t('singles.actions') }}
      schema={schema}
      fields={appActionSchemaFields}
      options={{ disableBulk: true }}
      reach={{ limit: 999 }}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      modalProps={modalProps}
      onChange={onChange}
    >
      {() => <SearchDataGrid columns={columns} pageSize={10} />}
    </Search>
  );
}
