import { GridColDef } from '@mui/x-data-grid';
import {
  dataGridActions,
  dataGridSearchDateTimeColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields.js';
import i18n from '../../libraries/i18n.js';

export function usePeriodColumns(): GridColDef[] {
  return [
    dataGridSearchNameColumn(),
    dataGridSearchDateTimeColumn('open', {
      headerName: i18n.t('singles.from'),
    }),
    dataGridSearchDateTimeColumn('close', {
      headerName: i18n.t('singles.to'),
    }),
    dataGridActions({ checkIfDisabledInProject: true }),
  ];
}