import { AptlyOrganization } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { createContext, useContext } from 'react';
import { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { matchId } from '../../libraries/mongoose';
import { UserContext } from '../User/UserContext';
import { IOrganizationSchema } from './organization.schema';

type Context = IUseFieldRet<IOrganizationSchema, IApiError, ICrudFieldData<IOrganizationSchema>> & {
  data: AptlyOrganization;
};
export const OrganizationContext = createContext<Context>({ data: {} } as any);

export function useOrganization() {
  const { data } = useContext(OrganizationContext);
  return data;
}

export function useOrganizationMember() {
  const user = useContext(UserContext);
  const org = useContext(OrganizationContext);
  return org.data.members.find((x) => matchId(x.user, user.data._id));
}
