import { AptlyScopes } from '@aptly-as/types';
import { useCallback } from 'react';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import useSlugLevel from '../../libraries/scope/useSlugLevel.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import {
  ICrudUpsellTemplate,
  useUpsellImportSchema,
  useUpsellTemplateSchema,
} from './upsell-template.schema';
import { useUpsellTemplateColumns } from './upsell-template.columns.js';

export default function UpsellTemplates() {
  const path = useApiUrl(SlugLevel.Project, 'upsell-templates');
  const schema = useUpsellTemplateSchema();
  const importSchema = useUpsellImportSchema();
  const columns = useUpsellTemplateColumns();
  const scope = useScopeLevel({
    project: AptlyScopes.ProjectUpsellTemplates,
    organization: AptlyScopes.OrganizationUpsellTemplates,
    base: AptlyScopes.Admin,
  });

  const buttonActions = useCallback(
    () => [<SearchImportAction key="import" path={`${path}/import`} schema={importSchema} />],
    [path, importSchema]
  );

  return (
    <Search<ICrudUpsellTemplate>
      path={path}
      patchPath={toSearchPatchPath('upsell-templates')}
      level={useSlugLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.upsellTemplate') }}
      post={{ title: i18n.t('singles.upsellTemplate') }}
      patch={{ title: (data) => String(data.name) }}
      schema={schema}
      fields={['name', 'text', 'image', 'videoEmbed', 'links']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name', archived: false, level: 'project', $populate: 'links:_id,name' } }}
      buttonActions={buttonActions}
      primaryAction={<SearchAddPrimaryAction />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
