import Add from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Sync from '@mui/icons-material/Sync';
import Search, { Query } from './Search';
import Divider from '../../mui/Divider';
import EmptyState from '../../containers/Error/EmptyState';
import Button from '../../mui/Button';
import List, { ListItem } from '../../components/List/List';
import ActionBar from '../../components/ActionBar';
import { TextField, TextFieldIcon } from '../../mui/Input';
import Block from '../../components/Block';
import Spacer from '../../components/Spacer';
import Progresser from '../../mui/Progresser';
import i18n from '../../libraries/i18n';
import React from 'react';

export type Item = {
  _id: string;
  label?: string;
};

type Props<T extends Item> = {
  data?: T[];
  endpoint?: string;
  queryKey?: string;
  labelKey?: keyof T;
  selectedItems?: T[];
  singular?: boolean;
  autoFocus?: boolean;
  searchHint?: string;
  onAdd?: (item: T) => any;
  onChange?: (item: T) => any;
  onRemove?: (id: string) => any;
  onCommit?: (item: T[]) => any;
  query?: Query;
};

type State<T extends Item> = {
  selectedItems: T[];
};

/**
 * @deprecated
 */
class SearchAndSelect<T extends Item> extends React.Component<Props<T>, State<T>> {
  state = {
    selectedItems: this.props.selectedItems || [],
  };

  addItem = (item: T) => {
    const { onAdd } = this.props;
    const { selectedItems } = this.state;

    this.setState({
      selectedItems: [...selectedItems, item],
    });

    if (typeof onAdd === 'function') {
      onAdd(item);
    }
  };

  changeItem = (item: T) => {
    const { onChange, singular, onCommit } = this.props;

    this.setState({
      selectedItems: [item],
    });

    if (typeof onChange === 'function') {
      onChange(item);
    }

    if (singular && typeof onCommit === 'function') {
      onCommit([item]);
    }
  };

  removeItem = (id: string) => {
    const { onRemove, singular, onCommit } = this.props;
    const { selectedItems } = this.state;

    const items = selectedItems.filter((i) => i._id !== id);

    this.setState({
      selectedItems: items,
    });

    if (typeof onRemove === 'function') {
      onRemove(id);
    }

    if (singular && typeof onCommit === 'function') {
      onCommit(items);
    }
  };

  commit = () => {
    const { onCommit } = this.props;
    const { selectedItems } = this.state;

    if (typeof onCommit === 'function') {
      onCommit(selectedItems);
    }
  };

  renderItems = (items: T[]) => {
    const { singular } = this.props;
    const { selectedItems } = this.state;

    return (
      <React.Fragment>
        <Divider />
        <List>
          {items.map((i) => {
            return (
              <ListItem
                key={i._id}
                button
                onClick={() => {
                  if (singular) {
                    return this.changeItem(i);
                  }

                  return this.addItem(i);
                }}
                icon={singular && selectedItems.length > 0 ? <Sync /> : <Add />}
              >
                {i.label}
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    );
  };

  renderSearch = () => {
    const { endpoint, queryKey, labelKey, autoFocus, searchHint, query } = this.props;
    const { selectedItems } = this.state;

    if (!endpoint || !queryKey || !labelKey) {
      return null;
    }

    return (
      <Search
        endpoint={endpoint}
        query={query}
        limit={5}
        onQueryComplete={() => window.dispatchEvent(new Event('resize'))}
        initSearch
      >
        {({ busy, results, hasQueried, query }) => {
          const filteredResults = results
            .filter((r) => !selectedItems.some((i) => i._id === r._id))
            .map(
              (f) =>
                ({
                  ...f,
                  _id: f._id,
                  label: f[labelKey],
                }) as T
            );

          return (
            <React.Fragment>
              <Block>
                <TextField
                  label={i18n.t('actions.searchToAdd')}
                  autoFocus={autoFocus}
                  placeholder={searchHint}
                  fullWidth
                  onChange={(e) => query(queryKey, e.target.value)}
                  tail={<TextFieldIcon>{busy && <Progresser size={24} />}</TextFieldIcon>}
                  InputLabelProps={
                    searchHint
                      ? {
                          shrink: true,
                        }
                      : {}
                  }
                />
                {hasQueried && results.length < 1 && (
                  <Spacer $hugBottom>
                    <EmptyState tiny>{i18n.t('statuses.cantFindAnything')}!</EmptyState>
                  </Spacer>
                )}
              </Block>
              {filteredResults.length > 0 && this.renderItems(filteredResults)}
            </React.Fragment>
          );
        }}
      </Search>
    );
  };

  render() {
    const { data, onCommit, singular, labelKey } = this.props;
    const { selectedItems } = this.state;

    return (
      <React.Fragment>
        {selectedItems.length > 0 && (
          <React.Fragment>
            <List>
              {selectedItems.map((i) => (
                <ListItem
                  key={i._id}
                  button
                  icon={<DeleteOutlinedIcon />}
                  onClick={() => this.removeItem(i._id)}
                >
                  {i.label || String(i[labelKey as keyof T])}
                </ListItem>
              ))}
            </List>
            <Divider />
          </React.Fragment>
        )}
        {data ? this.renderItems(data) : this.renderSearch()}
        {onCommit && !singular && (
          <React.Fragment>
            <Divider />
            <Block $compact>
              <ActionBar>
                <Button color="primary" onClick={this.commit}>
                  {i18n.t('actions.complete')}
                </Button>
              </ActionBar>
            </Block>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default SearchAndSelect;
