import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Grid2 from '@mui/material/Grid2';
import { PropsWithChildren, ReactNode, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import { IconButton } from '../../mui/Button';
import Menu from '../../mui/Menu';
import SubMenu, { SubMenuProps } from '../SubMenu';
import { SearchButtonWithIcon } from './buttons/Buttons';
import { SearchIconButton } from './icons/Icons';

interface HeaderActionsProps {
  subMenuOptions?: SubMenuProps['options'];
  primaryAction?: ReactNode;
  onSearch?: () => void;
}

/**
 *
 * @param subMenuOptions Actions show in a three dot expand button.
 * @param children Expected to be a list of button actions
 * @param primaryAction Primary FAB action.
 * @param onSearch To how the search icon in top right and handle click event
 * @constructor
 */
export function HeaderActions({
  subMenuOptions,
  children,
  primaryAction,
  onSearch,
}: PropsWithChildren<HeaderActionsProps>) {
  const isMobile = useMobile();

  return (
    <Grid2 container gap={1} alignItems="center" justifyContent="flex-end">
      {subMenuOptions && subMenuOptions.length > 0 && (
        <Grid2>
          <SubMenu options={subMenuOptions} />
        </Grid2>
      )}
      {isMobile && Array.isArray(children) && children.length > 0 ? (
        <Grid2>
          <ShowMoreActionsMobile>{children}</ShowMoreActionsMobile>
        </Grid2>
      ) : (
        children
      )}
      {primaryAction}
      {onSearch && (
        <Grid2>
          {isMobile ? <SearchIconButton onClick={onSearch} /> : <SearchButtonWithIcon onClick={onSearch} />}
        </Grid2>
      )}
    </Grid2>
  );
}

function ShowMoreActionsMobile({ children }: PropsWithChildren<object>) {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  return (
    <>
      <IconButton
        ref={ref}
        aria-label={i18n.t('actions.showMeMore')}
        aria-controls="long-menu"
        aria-haspopup="true"
        size="large"
        color="secondary"
        onClick={handleClick}
      >
        <ExpandCircleDownIcon
          sx={{ transform: open ? 'rotate(-180deg)' : '', transition: '200ms ease-in-out' }}
        />
      </IconButton>
      <Menu anchorEl={ref.current} open={open} autoFocus onClose={onClose} onClick={onClose}>
        <StyledGrid container flexDirection="column-reverse" spacing={1}>
          {children}
        </StyledGrid>
      </Menu>
    </>
  );
}

const StyledGrid = styled(Grid2)`
  padding: 0 0.5rem;
  button {
    width: 100%;
  }
`;
