import { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { SearchContext } from '../search.utils';

export interface ISearchFieldActionProps {
  field: string;
  /**
   * @deprecated: Should use prop "field".
   */
  queryKey?: string;
}

export function useSearchQueryState<T>(
  field: string,
  queryKey: string = field
): [value: T, (e?: ChangeEvent<HTMLInputElement> | T) => void] {
  const { query, setQuery } = useContext(SearchContext);
  const value = query[queryKey] || '';
  const handleOnEvent = useCallback(
    (e?: ChangeEvent<HTMLInputElement> | T) => {
      if (!e) {
        return setQuery((s) => {
          delete s[queryKey];
          return { ...s };
        });
      }
      const value = typeof e === 'object' && 'target' in e ? e.target.value : e;
      setQuery((s) => {
        return { ...s, [queryKey]: value };
      });
    },
    [queryKey, setQuery]
  );
  return useMemo(() => [value, handleOnEvent], [value, handleOnEvent]);
}
