import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useCallback } from 'react';
import { PrimaryActionFab } from '../../components/actions/Primary';
import { CLIENT_URL } from '../../env';
import { useProject } from '../../models/Project/ProjectContext';
import i18n from '../../libraries/i18n';

interface Props extends UseClientIframeProps {
  label?: string;
}

export default function ClientIFrame({ endpoint, isUnit, label = i18n.t('actions.openPreview') }: Props) {
  const onClick = useClientIframe({ endpoint, isUnit });

  return (
    <PrimaryActionFab onClick={onClick} icon={<VisibilityOutlinedIcon />}>
      {label}
    </PrimaryActionFab>
  );
}

interface UseClientIframeProps {
  endpoint: string;
  isUnit?: boolean;
}

function useClientIframe({ endpoint, isUnit }: UseClientIframeProps) {
  const project = useProject();
  const url = isUnit
    ? `${CLIENT_URL}/${endpoint}`
    : `${CLIENT_URL}/dashboard/${endpoint}${project?._id ? `?projectId=${project?._id}` : ''}`;

  return useCallback(() => {
    if (typeof window !== 'undefined') {
      window.open(url, '_blank');
    }
  }, [url]);
}
