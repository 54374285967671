import {
  AptlyFieldType,
  AptlyItemType,
  AptlyOption,
  AptlyProduct,
  AptlyQuantityUnitCode,
} from '@aptly-as/types';
import { ListItemAvatar } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { useMemo } from 'react';
import styled from 'styled-components';
import { quantityUnitCodeOptions } from '../../aptly/aptly.options.js';
import Avatar from '../../components/Avatar.js';
import { schemaFile, schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema, ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils';
import LinkDocument from '../../components/LinkDocument.js';
import { ListItemText } from '../../components/List/List.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { acceptCsv } from '../../libraries/react-dropzone/drop-zone.utils.js';
import Typography from '../../mui/Typography.js';
import { AlgorithmPreviewCustomComponent, useAlgorithmSchemaField } from '../Algorithm/algorithm.schema.js';
import { CustomAllowancePrice } from '../Algorithm/AllowanceCharges';
import { useCategorySchemaField } from '../Category/categories.schema.js';
import { itemTypeOptions } from '../Item/item.utils.js';
import createMediaURL from '../Media/createMediaURL.js';
import extractFeaturedImage from '../Product/extractFeaturedImage.js';
import { useProductsSchemaField } from '../Product/product.schema.js';
import { productImageToMediaSrc } from '../Product/product.utils.js';
import { ProductImage } from '../Product/ProductImage.js';
import OptionCategoryKey from './OptionRoomKey.js';

export interface IOptionSchema extends AptlyOption {
  __quantity?: boolean;
  __amount?: boolean;
  __algorithm?: undefined;
}

export const optionSchemaFields: (keyof IOptionSchema)[] = [
  'thumbnail',
  'product',
  'name',
  'identification',
  'title',
  'quantityUnitCode',
  'type',
  // 'quantity',
  // 'quantityCategory',
  // 'quantityCategoryKey',
  'amount',
  // '__quantity',
  'algorithm',
  'amountCategory',
  'amountCategoryKey',
  '__algorithm',
  // '__amount',
  // 'description',
];

export function useOptionSchema(level: SlugLevel): ICrudSchema<IOptionSchema> {
  return {
    product: useProductsSchemaField({
      gridProps: {
        $gridColumn: 'span 8',
      },
      preOnChange: (value: AptlyProduct, crud) => {
        if (value) {
          const image = extractFeaturedImage(value.images);
          crud.setData({
            name: value.name || '',
            title: value.title || '',
            identification: value.productNumber || '',
            producer: value.producer,
            description: value.description || '',
            amount: (value.unitCost || 0) * 100,
            type: AptlyItemType.Physical,
            thumbnail: image ? productImageToMediaSrc(image) : undefined,
          });
        }
        return value;
      },
    }),
    thumbnail: {
      type: AptlyFieldType.Custom,
      defaultValue: null,
      label: i18n.t('singles.thumbnail'),
      CustomComponent: CustomOptionImage,
      gridProps: {
        $gridColumn: 'span 4',
        $gridRow: 'span 4',
        $alignSelf: 'center',
      },
    },
    name: schemaName<IOptionSchema>({
      requiredValidate: (crud) => !crud.getField('product').value,
      placeholderFn: (crud) => (crud.getField('product').value as AptlyProduct)?.name,
      gridProps: {
        $gridColumn: 'span 8',
      },
    }),
    identification: {
      type: AptlyFieldType.Text,
      placeholderFn: (crud) => (crud.getField('product').value as AptlyProduct)?.productNumber,
      label: i18n.t('singles.identification'),
      defaultValue: '',
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    title: {
      type: AptlyFieldType.Text,
      label: i18n.t('info.productTitle'),
      defaultValue: '',
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    type: {
      type: AptlyFieldType.Select,
      label: i18n.t('singles.type'),
      defaultValue: AptlyItemType.Service,
      options: itemTypeOptions(),
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    description: {
      type: AptlyFieldType.Markdown,
      label: i18n.t('singles.description'),
      defaultValue: '',
      gridProps: {
        $gridColumn: 'span 12',
      },
    },
    quantity: {
      type: AptlyFieldType.Number,
      label: i18n.t('singles.quantity'),
      defaultValue: undefined,
      gridProps: {
        $gridColumn: 'span 9',
      },
      renderValidate: (crud) => !crud.getField('__quantity').value,
    },
    quantityUnitCode: {
      type: AptlyFieldType.Select,
      label: i18n.t('singles.quantityUnitCode'),
      defaultValue: AptlyQuantityUnitCode.Piece,
      options: quantityUnitCodeOptions(),
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
    quantityCategory: useCategorySchemaField<IOptionSchema>({
      required: true,
      gridProps: {
        $gridColumn: 'span 3',
      },
      renderValidate: (crud) => !!crud.getField('__quantity').value,
    }),
    quantityCategoryKey: {
      type: AptlyFieldType.Custom,
      label: i18n.t('singles.key'),
      defaultValue: undefined,
      CustomComponent: OptionCategoryKey,
      gridProps: {
        $gridColumn: 'span 6',
      },
      renderValidate: (crud) => !!crud.getField('__quantity').value,
    },
    __quantity: {
      type: AptlyFieldType.Checkbox,
      label: i18n.t('paragraphs.autoCalcAmountFromRoom'),
      defaultValue: false,
      preOnChange: (value, crud) => {
        crud.setData({
          quantityCategory: null,
          quantityCategoryKey: value ? 'kvmGulv' : '',
          quantityUnitCode: value ? AptlyQuantityUnitCode.SquareMeter : AptlyQuantityUnitCode.Piece,
        });
        return value;
      },
    },
    amount: {
      type: AptlyFieldType.Amount,
      label: i18n.t('singles.amount'),
      required: true,
      defaultValue: 0,
      gridProps: {
        $gridColumn: 'span 6',
      },
      renderValidate: (crud) => !crud.getField('__amount').value,
    },
    amountCategory: useCategorySchemaField({
      required: true,
      gridProps: {
        $gridColumn: 'span 3',
      },
      renderValidate: (crud) => !!crud.getField('__amount').value,
    }),
    amountCategoryKey: {
      type: AptlyFieldType.Custom,
      label: i18n.t('singles.key'),
      defaultValue: undefined,
      CustomComponent: OptionCategoryKey,
      gridProps: {
        $gridColumn: 'span 6',
      },
      renderValidate: (crud) => !!crud.getField('__amount').value,
    },
    algorithm: useAlgorithmSchemaField(
      {
        gridProps: {
          $gridColumn: 'span 6',
        },
      },
      level
    ),
    __amount: {
      type: AptlyFieldType.Checkbox,
      label: i18n.t('paragraphs.autoCalcCostFromRoom'),
      defaultValue: false,
      gridProps: {
        $gridColumn: 'span 6',
      },
      preOnChange: (value, crud) => {
        crud.setData({
          amountCategory: null,
          amountCategoryKey: value ? 'kvmGulv' : '',
        });
        return value;
      },
    },
    __algorithm: {
      type: AptlyFieldType.Custom,
      defaultValue: undefined,
      label: '',
      CustomComponent: AlgorithmPreviewCustomComponent,
    },
    allowance: {
      type: AptlyFieldType.Custom,
      defaultValue: null,
      label: '',
      CustomComponent: CustomAllowancePrice,
      gridProps: {
        $gridColumn: 'span 4',
      },
    },
  };
}

export interface OptionImportSchema {
  _description: undefined;
  file: File | null;
  action: 'add' | 'edit';
}

export function useOptionImportSchema(): ICrudSchema<OptionImportSchema> {
  return {
    _description: {
      type: AptlyFieldType.Custom,
      defaultValue: undefined,
      label: '',
      customRender: () => (
        <LinkDocument
          label="Tjenestekatalog import.csv"
          href="https://docs.google.com/spreadsheets/d/1g1F7GtbROJaE4GwuoAj4PqdrBYYsHWab1ph85rInaiE/template/preview?usp=sharing"
        />
      ),
    },
    action: {
      type: AptlyFieldType.Select,
      label: i18n.t('singles.action'),
      defaultValue: 'add',
      options: [
        { value: 'add', label: i18n.t('actions.add') },
        { value: 'edit', label: i18n.t('actions.edit') },
      ],
    },
    file: schemaFile(acceptCsv, { required: true }),
  };
}

export function useOptionSchemaField<T extends object>(
  override: Partial<ICrudField<T, any>> = {}
): ICrudField<T, any> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Unit, 'options/search'),
      queryKey: 'q',
      query: { archived: false, select: '_id,name,thumbnail', level: 'project', sort: 'name' },
      multiple: false,
      autocompleteSearchProps: {
        renderOption: (props, option: AptlyOption) => {
          const { key, ...optionProps } = props;
          return (
            <ListItem key={key} {...optionProps}>
              <ListItemAvatar>
                <Avatar
                  src={option.thumbnail ? createMediaURL(option.thumbnail.src, { width: 80 }) : undefined}
                  alt={option.name}
                />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography>{option.name}</Typography>}
                secondary={option.identification}
              />
            </ListItem>
          );
        },
      },
    }),
    label: i18n.t('singles.option'),
    defaultValue: null,
    ...override,
  };
}

function CustomOptionImage({ crud, field }: ICustomRenderComponentProps<AptlyOption, 'thumbnail'>) {
  const image = useMemo(() => {
    if (field.value) {
      return <ProductImage image={field.value} />;
    }
    const product = crud.getField('product').value;
    if (product && typeof product === 'object') {
      const image = extractFeaturedImage(product.images);
      if (image) {
        return <ProductImage image={image} />;
      }
    }
    return null;
  }, [crud, field.value]);

  if (!image) {
    return null;
  }

  return <StyledImageWrapper>{image}</StyledImageWrapper>;
}

const StyledImageWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;
