import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useCallback } from 'react';
import { AptlyDepartment, AptlyScopes, AptlyUser } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { EditIconButton } from '../../components/actions/icons/Icons';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  IGridActionProps,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { useDataGridEditModal } from '../../components/Search/search-data-grid/data-grid.hooks';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import Dialog, { ModalActions } from '../../mui/Dialog';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import Search from '../../components/Search/Search';
import { useDepartmentUserSchema, ICrudDepartmentUser } from './department.schema';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';

export default function OrganizationDepartmentUsers({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyDepartment>(params);
  const handleOnChange = useCallback(
    (users: AptlyDepartment['users']) => {
      return onChange((data) => ({ ...data, users }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<SupervisorAccountIcon />} title={i18n.t('singles.users')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent department={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  department: AptlyDepartment;
  onChange: (users: AptlyDepartment['users']) => void;
}

const modalProps: ICreateModelOptions = { width: 'sm' };

function ModalContent({ department, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Organization, `departments/${department._id}/users`);
  const schema = useDepartmentUserSchema();
  const columns = useColumns();

  return (
    <Search<ICrudDepartmentUser>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationDepartments}
      header={{ title: `${department.name} - ${i18n.t('singles.users')}` }}
      post={{ title: i18n.t('singles.users') }}
      patch={{ title: (data) => (data.user as AptlyUser).fullName || 'N/A' }}
      schema={schema}
      fields={['user']}
      options={{ disableBulk: true }}
      reach={{ limit: 999, query: { $populate: 'user:_id,fullName' } }}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      modalProps={modalProps}
      onChange={onChange}
    >
      {() => {
        return <SearchDataGrid columns={columns} pageSize={10} />;
      }}
    </Search>
  );
}

function useColumns(): GridColDef[] {
  return [
    {
      type: 'string',
      field: 'user',
      headerName: i18n.t('singles.name'),
      valueGetter: (value) => (value as AptlyUser).fullName,
      flex: 1,
    },
    dataGridActionProps((params) => (
      <DataGridActionWrapper>
        <DataGridDeleteAction params={params} />
      </DataGridActionWrapper>
    )),
  ];
}
