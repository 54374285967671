import { FormEvent, useCallback, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { EditorText } from '../../components/EditorText.js';
import i18n from '../../libraries/i18n';
import { SimpleCrudContent, SimpleCrudForm } from '../../components/simple/SimpleCrud';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import { useOrganization } from '../Organization/OrganizationContext';
import { useProject } from '../Project/ProjectContext';
import { useInquirySchema, IInquirySchema } from './inquiry.schema';
import ApiError from '../../components/ApiError';
import { PaperPadded } from '../../mui/Paper';
import Grid from '../../mui/Grid';
import { InquiryContext } from './InquiryContext';
import Send from '@mui/icons-material/Send';

interface Props {
  onClose?: () => void;
}

export default function InquiryRespond({ onClose }: Props) {
  const organization = useOrganization();
  const project = useProject();
  const { data: inquiry, setData } = useContext(InquiryContext);
  const path = `organizations/${organization.slug}/projects/${project._id}/inquiries/${inquiry._id}/messages`;
  const [crud, fields] = useSimpleCrudFields<IInquirySchema, 'message' | 'files'>(
    path,
    {},
    useInquirySchema(),
    ['message', 'files'],
    {
      reachOptions: {
        type: 'multipart/form-data',
        fileKeys: ['files'],
      },
      dontSetStateOnPost: true,
    }
  );

  const handleOnSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const res = await crud.save();
      if (res) {
        setData(res);
        onClose!();
      }
    },
    [crud, setData]
  );

  return (
    <SimpleCrudForm
      title={i18n.t('singles.respond')}
      description={
        <Grid container flexDirection="column" style={{ marginBottom: '1rem' }}>
          <Typography variant="subtitle1">{i18n.t('statuses.lastMessage')}</Typography>
          <PaperPadded>
            <EditorText value={inquiry.lastMessage} />
          </PaperPadded>
        </Grid>
      }
      onClose={onClose}
      actionButtonProps={{
        disabled: crud.state.busy,
        actionLabel: i18n.t('actions.send'),
        actionIcon: <Send />,
      }}
      onSubmit={handleOnSubmit}
    >
      <SimpleCrudContent crud={crud} schema={fields} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}
