import { CLOUDINARY_KEY } from '../../env';

export type IMediaTransformations = {
  width?: number;
  height?: number;
  crop?: 'fill' | 'fit' | 'pad';
};

const validKeys = ['width', 'height', 'crop'];

function createMediaURL(id?: string | null, transformations?: IMediaTransformations): string {
  if (!id) return '';
  if (id.startsWith('http')) return id;
  return createCloudinaryUrl(id, transformations);
}

function createCloudinaryUrl(id: string, transformations?: IMediaTransformations) {
  const stringedTransformations: string[] = ['f_auto'];

  if (transformations) {
    for (const k in transformations) {
      if (!validKeys.includes(k)) continue;
      stringedTransformations.push(`${k[0]}_${transformations[k as keyof IMediaTransformations]}`);
    }
  }

  return `https://res.cloudinary.com/${CLOUDINARY_KEY}/image/upload/${stringedTransformations.join(
    ','
  )}/${id}`;
}

export default createMediaURL;
