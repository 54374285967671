import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseButtonWithIconProps } from '../../actions/buttons/Buttons';
import { PrimaryActionFab } from '../../actions/Primary';
import { SearchCrudContext } from '../search.utils';
import i18n from '../../../libraries/i18n';

export interface ISearchCreateActionProps<T extends object> {
  title?: string;
  keys?: (keyof T)[];
  buttonProps?: Omit<BaseButtonWithIconProps, 'onClick'>;
}

export default function SearchAddPrimaryAction<T extends object>({
  keys,
  title = i18n.t('actions.create'),
  buttonProps,
}: ISearchCreateActionProps<T>) {
  const { actions, scope } = useContext(SearchCrudContext);

  if (!scope.create) return null;

  return (
    <PrimaryActionFab buttonProps={buttonProps} onClick={() => actions.spawnPost(actions.unshift, {}, keys)}>
      {title}
    </PrimaryActionFab>
  );
}

export interface ISearchCreateActionToProps<T extends object> extends ISearchCreateActionProps<T> {
  to: string;
  data?: Partial<T>;
}

export function SearchAddPrimaryActionTo<T extends { _id?: string }>({
  keys,
  title,
  to,
  buttonProps,
  data,
}: ISearchCreateActionToProps<T>) {
  const { actions, scope } = useContext(SearchCrudContext);
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () =>
      actions.spawnPost(
        (item: T) => {
          navigate(`${to}/${item._id}`);
        },
        data,
        keys
      ),
    [actions, navigate, to, data]
  );

  if (!scope.create) return null;

  return (
    <PrimaryActionFab buttonProps={buttonProps} onClick={handleOnClick}>
      {title}
    </PrimaryActionFab>
  );
}
