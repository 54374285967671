import { AptlyScopeCrud, AptlyScopes } from '@aptly-as/types';
import React, { useContext } from 'react';

export interface IScopeContext {
  crud: (scope: AptlyScopes | AptlyScopes[], permission?: AptlyScopeCrud, every?: boolean) => boolean;
}

export const ScopeContext = React.createContext<IScopeContext>({
  crud: () => false,
});

export function useScope(): IScopeContext {
  return useContext(ScopeContext);
}
