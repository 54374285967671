import styled from 'styled-components';

interface AptlyLogoProps {
  $small?: boolean;
  $isMobile?: boolean;
}

const AptlyLogo = styled.img.attrs<AptlyLogoProps>({
  src: '/logo.png',
})`
  max-width: 250px;
  max-height: ${(props) => (props.$small ? '40px' : '150px')};
`;

export default AptlyLogo;
