import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  dataGridDateTimeColumn,
  dataGridImageColumn,
  dataGridMediaColumn,
  dataGridNameCol,
  dataGridTextColumn,
} from '../../../mui/x-data-grid/dataGrid.cols';
import i18n from '../../../libraries/i18n';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
  IGridActionPropsWithLevelScope,
} from './data-grid.actions';
import { useDataGridDateTimeText } from './DataGridDateTimeEdit';
import { DataGridFieldClickEdit } from './DataGridFieldClickEdit';
import { DataGridTextEdit, IDataGridTextEditTextProps, useDataGridEditText } from './DataGridTextEdit';

export const dataGridSearchNameColumn = (): GridColDef =>
  dataGridNameCol({
    minWidth: 250,
    flex: 1,
    editable: true,
    renderEditCell: useDataGridEditText,
  });

export const dataGridSearchTextColumn = <T extends object>(
  field: keyof T,
  headerName: string,
  props?: IDataGridTextEditTextProps
): GridColDef<T> =>
  dataGridTextColumn(field, headerName, {
    editable: true,
    renderEditCell: (params) => <DataGridTextEdit params={params} {...props} />,
  });

export const dataGridSearchDateTimeColumn = (field: string, props: Partial<GridColDef> = {}): GridColDef =>
  dataGridDateTimeColumn(field, {
    editable: true,
    renderEditCell: useDataGridDateTimeText,
    ...props,
  });

export const dataGridSearchImageColumn = <T extends object>(
  field: keyof T,
  props: Partial<GridColDef> = {},
  toSrc?: (params: GridRenderCellParams) => string | undefined
): GridColDef =>
  dataGridImageColumn(
    field,
    {
      ...props,
      editable: true,
      renderEditCell: (params) => <DataGridFieldClickEdit params={params} keys={[field]} />,
    },
    toSrc
  );
export const dataGridSearchMediaColumn = <T extends object>(
  field: keyof T,
  props: Partial<GridColDef> = {},
  toSrc?: (params: GridRenderCellParams) => string | undefined
): GridColDef =>
  dataGridMediaColumn(
    field,
    {
      ...props,
      editable: true,
      renderEditCell: (params) => <DataGridFieldClickEdit params={params} keys={[field]} />,
    },
    toSrc
  );

export const dataGridFieldClickColumn = <T extends object, K extends keyof T>(
  field: K,
  headerName: string,
  renderCell: (params: GridRenderCellParams) => string | undefined,
  props: Partial<GridColDef> = {}
): GridColDef => ({
  width: 70,
  field: String(field),
  headerName,
  align: 'center',
  display: 'flex',
  disableColumnMenu: true,
  sortable: false,
  editable: true,
  renderEditCell: (params) => <DataGridFieldClickEdit<T> params={params} keys={[field]} />,
  renderCell,
  ...props,
});

export const dataGridActions = (
  props: Omit<IGridActionPropsWithLevelScope, 'params'> = {},
  gridProps?: Partial<GridColDef>
) =>
  dataGridActionProps(
    (params) => (
      <DataGridActionWrapper>
        <DataGridEditAction params={params} {...props} />
        <DataGridDeleteAction params={params} {...props} />
      </DataGridActionWrapper>
    ),
    gridProps
  );

export const dataGridLevelColumn = (): GridColDef => ({
  type: 'string',
  field: 'organization',
  headerName: i18n.t('singles.level'),
  width: 150,
  renderCell: (params) => (params.row.project ? i18n.t('singles.project') : i18n.t('singles.organization')),
});
