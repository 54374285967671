import { AptlyInquiry, AptlyScopes, AptlyUnit } from '@aptly-as/types';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useCallback, useContext, useMemo } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackButtonWithIcon, SendButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { PrimaryActionFab } from '../../components/actions/Primary.js';
import Search, { SearchFieldQuery, SearchFieldSelect, SearchFieldText } from '../../components/Search/Search';
import {
  ISearchCrudActions,
  ISearchCrudContext,
  SearchContext,
  SearchCrudContext,
} from '../../components/Search/search.utils';
import { CrudContactNotifyButton } from '../../components/simpleCruds/settings/CrudContactNotifyAdd';
import EmptyState from '../../containers/Error/EmptyState';
import { useModal } from '../../containers/Modal/Modal.js';
import { createModal } from '../../containers/Modal/ModalContext';
import { Query } from '../../deprecated/Search/Search';
import { SlugLevel, useApiUrl, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import { intlDateFormat } from '../../libraries/intl';
import Scope from '../../libraries/scope/Scope';
import Grid from '../../mui/Grid';
import Paper from '../../mui/Paper';
import { TypographyEllipsis } from '../../mui/Typography';
import { info } from '../../utils/themes';
import { useUnitAutocompleteQueryProps } from '../Unit/unit.schema.js';
import Inquiry from './Inquiry';
import { translateInquiryType } from './inquiry.types';
import InquiryBroadcast from './InquiryBroadcast';
import InquiryNew from './InquiryNew';
import { getStatus, inquiryStatusOptions } from './InquiryUtils';

function Inquiries() {
  const { pathname } = useLocation();
  const [, , unitID] = useSlugs();
  const baseUrl = useApiUrl(SlugLevel.Unit);
  const inquiryEndpoint = `${baseUrl}/inquiries`;
  const unitQueryProps = useUnitAutocompleteQueryProps();

  const searchFields = useMemo(
    () => [
      <SearchFieldText
        key="title"
        field="title"
        queryKey="$title"
        label={i18n.t('singles.title')}
        autoFocus
      />,
      <SearchFieldQuery key="unit" field="unit" autocompleteQueryProps={unitQueryProps} />,
      <SearchFieldSelect
        key="status"
        field="status"
        label={i18n.t('singles.status')}
        options={[{ value: '', label: i18n.t('singles.all') }, ...(inquiryStatusOptions() as any[])]}
      />,
      <SearchFieldSelect
        key="read"
        field="read"
        label={i18n.t('singles.read')}
        options={[
          { value: '', label: i18n.t('singles.all') },
          { value: 'read', label: i18n.t('statuses.read') },
          { value: 'unread', label: i18n.t('statuses.unread') },
        ]}
      />,
    ],
    []
  );

  const buttonActions = useCallback(
    (_: any, { refresh }: ISearchCrudActions<AptlyInquiry>) => {
      if (!Scope.crud(AptlyScopes.ProjectInquiry, 'C')) return [];
      return [
        <CrudContactNotifyButton key="0" defaultExpandGroup="support" />,
        ...(unitID
          ? []
          : [
              <SendButtonWithIcon
                key="2"
                title={i18n.t('actions.sendToMultiple')}
                onClick={() => {
                  createModal(<InquiryBroadcast onCreate={refresh} />, {
                    width: 'md',
                  });
                }}
              />,
            ]),
      ];
    },
    [createModal, unitID]
  );

  const activeInquiry = useMemo(() => {
    const match = pathname.match(/kundesupport\/([^?|/]*)/);
    return match ? match[1] : '';
  }, [pathname]);
  const query = useMemo(() => {
    const _query: Query = { archived: 'false' };
    if (unitID) {
      _query.unit = unitID;
    }
    return _query;
  }, [unitID]);

  return (
    <Search<AptlyInquiry>
      header={{
        title: i18n.t('singles.clientSupport'),
        description: i18n.t('paragraphs.searchInqiriesFromClients'),
      }}
      post={{ title: i18n.t('singles.clientSupport') }}
      patch={{ title: i18n.t('singles.clientSupport') }}
      scope={AptlyScopes.ProjectInquiry}
      patchPath={() => inquiryEndpoint}
      path={inquiryEndpoint}
      searchFields={searchFields}
      reach={{ query }}
      buttonActions={buttonActions}
      primaryAction={<PrimaryAction />}
    >
      {() => <InquiriesContent activeInquiry={activeInquiry} />}
    </Search>
  );
}

function PrimaryAction() {
  const { refresh } = useContext(SearchContext);
  const [, , unitID] = useSlugs();
  const onClick = useModal(() => <InquiryNew onCreate={refresh} unitID={unitID} />);
  return (
    <PrimaryActionFab onClick={onClick} icon={<EmailOutlinedIcon />}>
      {i18n.t('actions.newMessage')}
    </PrimaryActionFab>
  );
}

interface InquiriesContentProps {
  activeInquiry: string;
}

export function InquiriesContent({ activeInquiry }: InquiriesContentProps) {
  const { items, actions }: ISearchCrudContext<AptlyInquiry> = useContext(SearchCrudContext);
  const to = useTo(SlugLevel.Unit, '/kundesupport');
  const navigate = useNavigate();
  const isMobile = useMobile();
  const showItems = !activeInquiry || !isMobile;

  if (items.length === 0) {
    return <EmptyState>{i18n.t('statuses.noMessages')}</EmptyState>;
  }

  return (
    <Grid container spacing={1}>
      {showItems ? (
        <Grid item xs={12} lg={6}>
          {items.map((inquiry: AptlyInquiry) => (
            <MessageItem key={inquiry._id} to={`${to}/${inquiry._id}`}>
              <PaperPad elevation={activeInquiry === inquiry._id ? 9 : 1}>
                <Grid container justifyContent="space-between">
                  <StatusWrapperLayout item>{!inquiry.readAdmin ? <ReadIcon /> : null}</StatusWrapperLayout>
                  <Grid container direction="column" item xs={8}>
                    <Grid item>
                      <Typography variant="subtitle1">{inquiry.title}</Typography>
                    </Grid>
                    <TypographyEllipsis variant="caption" style={{ maxWidth: '400px' }}>
                      {inquiry.lastMessage}
                    </TypographyEllipsis>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={getStatus(inquiry).label} arrow placement="top">
                      {getStatus(inquiry).icon}
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <UnitAndDate container direction="column">
                      <Grid item>
                        <Typography variant="subtitle2">{(inquiry.unit as AptlyUnit)?.name}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">{intlDateFormat(inquiry.lastMessageSent)}</Typography>
                      </Grid>
                      {inquiry._type !== 'basic' && (
                        <Grid item>
                          <Typography variant="subtitle2">{translateInquiryType()[inquiry._type]}</Typography>
                        </Grid>
                      )}
                    </UnitAndDate>
                  </Grid>
                </Grid>
              </PaperPad>
            </MessageItem>
          ))}
        </Grid>
      ) : (
        <Grid item>
          <BackButtonWithIcon onClick={() => navigate('./')} title={i18n.t('singles.messages')} />
        </Grid>
      )}

      <Grid item xs={12} lg={6}>
        <Routes>
          <Route element={<EmptyState>{i18n.t('paragraphs.noMessageSelected')}</EmptyState>} />
          <Route
            path=":inquiryID"
            element={
              <Inquiry
                onDelete={(inquiry: AptlyInquiry) => {
                  actions.filter((x: AptlyInquiry) => x._id !== inquiry._id);
                  navigate('../');
                }}
                onSubmit={(inquiry: AptlyInquiry) => {
                  const index = items.findIndex((x) => x._id === inquiry._id);
                  actions.splice(index, 1, inquiry);
                }}
              />
            }
          />
        </Routes>
      </Grid>
    </Grid>
  );
}

const MessageItem = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: inherit;
`;

const PaperPad = styled(Paper)`
  padding: 1rem;
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  margin-bottom: 0.5rem;
  position: relative;
`;

const UnitAndDate = styled(Grid)`
  text-align: right;
`;

const StatusWrapperLayout = styled(Grid)`
  padding-top: 0.5rem;
  position: absolute;
  top: 0;
  left: 4px;
`;

const ReadIcon = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background: ${info};
`;

export default Inquiries;
