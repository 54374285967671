import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces.js';
import { useCallback, useContext, useMemo, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useMobile } from '../../../hooks/useMobile.js';
import { SearchCrudContext } from '../search.utils';
import { ISearchDataGridProps } from '../SearchDataGrid.js';

export function useDataGridVisibilityModel(
  fields: string[],
  columnVisibilityModel: GridColumnVisibilityModel = {}
): ISearchDataGridProps['initialState'] {
  const isMobile = useMobile();
  return useMemo(
    () => ({
      columns: {
        columnVisibilityModel: fields.reduce((obj, field) => {
          if (field in obj && !obj[field]) return obj;
          obj[field] = !isMobile;
          return obj;
        }, columnVisibilityModel),
      },
    }),
    [fields, isMobile, columnVisibilityModel]
  );
}

export interface IUseDataGridEditModalState<T extends object> {
  data: null | T;
  index: number;
}

export function useDataGridEditModal<T extends object>(params: GridRenderCellParams) {
  const {
    items,
    actions: { splice },
  } = useContext(SearchCrudContext);
  const [state, setState] = useState<IUseDataGridEditModalState<T>>({ data: null, index: -1 });
  const { id } = params;

  // todo: create hook for advanced edit modal
  const onClosed = useCallback(
    (product: T, index: number) => {
      splice(index, 1, product);
    },
    [splice]
  );

  const onShow = useCallback(() => {
    setState((s) => {
      if (s.data) {
        onClosed(s.data, s.index);
        return { data: null, index: -1 };
      }
      const index = items.findIndex((x) => x._id === id);
      return { data: items[index], index };
    });
  }, [onClosed, items, id]);

  const onChange = useCallback((data: (d: T) => T) => {
    return setState((s) => ({ ...s, data: s.data ? data(s.data) : s.data }));
  }, []);

  const onDelete = useCallback(() => {
    setState((s) => {
      if (s.index > -1) {
        splice(s.index, 1);
      }
      return { data: null, index: -1 };
    });
  }, [splice]);

  return useMemo(
    () => ({ state, onClosed, onShow, onDelete, onChange }),
    [state, onClosed, onShow, onDelete, onChange]
  );
}
