import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material';
import { AptlyFieldType, AptlyTag } from '@aptly-as/types';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';

export function useTagSchema(): ICrudSchema<AptlyTag> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
  };
}

export function useTagsSchemaField<T extends object, V extends string | AptlyTag>(
  override?: Partial<ICrudField<T, V[]>>
): ICrudField<T, V[]> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useTagsAutocompleteQuery,
    label: i18n.t('singles.tags'),
    defaultValue: [],
    ...override,
  };
}

export function useTagsAutocompleteQuery(): Omit<
  AptlyAutocompleteQueryProps<any, any, any>,
  'onChange' | 'defaultValue' | 'value'
> {
  return {
    path: useApiUrl(SlugLevel.Base, 'tags'),
    query: { archived: false, select: '_id,name' },
    multiple: true,
    queryOnFocus: false,
    autocompleteSearchProps: {
      label: i18n.t('singles.tags'),
      noOptionsText: i18n.t('statuses.noResults'),
    },
  };
}
