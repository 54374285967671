import AddIcon from '@mui/icons-material/Add';
import Button, { ButtonProps } from '@mui/material/Button';
import Fab, { FabProps } from '@mui/material/Fab';
import { MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile';

interface PrimaryActionFabProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  icon?: ReactNode;
  fabProps?: Omit<FabProps, 'onClick'>;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
  children?: string;
}

export function PrimaryActionFab({
  onClick,
  icon = <AddIcon />,
  fabProps,
  buttonProps,
  children,
}: PrimaryActionFabProps) {
  const isMobile = useMobile();
  if (isMobile) {
    return (
      <StyledFab color="primary" aria-label={children} {...fabProps} onClick={onClick}>
        {icon}
      </StyledFab>
    );
  }
  return (
    <Button color="primary" variant="contained" {...buttonProps} endIcon={icon} onClick={onClick}>
      {children}
    </Button>
  );
}

const StyledFab = styled(Fab)`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(3)};
  right: ${({ theme }) => theme.spacing(3)};
`;
