import styled, { css } from 'styled-components';
import { gutter } from '../../utils/spacing';

export const NotificationContent = styled.div`
  padding: ${gutter};
`;

interface NotificationVisualProps {
  $backgroundColor?: string;
  $image?: string;
}

export const NotificationVisual = styled.div<NotificationVisualProps>`
  width: 5rem;
  background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : '#999')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-shrink: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  ${(props) =>
    props.$image &&
    css`
      background-image: url(${props.$image});
      background-size: cover;
      background-position: center;
    `};
`;
