import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material/Autocomplete/AutocompleteQuery';
import {
  AptlyAppImplementation,
  AptlyFieldType,
  AptlyOrganization,
  AptlyOrganizationInvite,
  AptlyOrganizationMember,
  AptlyOrganizationMemberPosition,
  AptlyOrganizationRoles,
  AptlyOrganizationStatus,
  AptlyScopes,
  AptlyUser,
  Populated,
} from '@aptly-as/types';
import { DotObject } from '@ewb/reach-react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import {
  schemaAddressCity,
  schemaAddressCountry,
  schemaAddressLine1,
  schemaAddressLine2,
  schemaAddressZip,
} from '../../components/crud/schema/extends.schemas';
import {
  schemaEmail,
  schemaMediaImage,
  schemaName,
  schemaPhone,
} from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudFieldMedia, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import { useScope } from '../../libraries/scope/ScopeContext.js';
import { SelectOption } from '../../mui/Select';
import { useAppSchemaField } from '../App/app.schema.js';
import { useDepartmentsSchemaField } from '../Department/department.schema.js';
import { producerSchemaField } from '../Producer/producer.schema.js';
import { useProjectsSchemaField } from '../Project/project.schema.js';
import {
  organizationRoleTranslation,
  organizationStatusOptions,
  organizationTypeOptions,
} from './organization.utils';

export interface ICrudOrganizationInvite extends AptlyOrganizationInvite {
  email: string;
  filterProjects?: boolean;
  filterDepartments?: boolean;
}

const address = {
  line1: '',
  line2: '',
  street: '',
  zip: '',
  city: '',
  country: '',
};

export const organizationRolesOptions = (): SelectOption[] => [
  {
    value: AptlyOrganizationRoles.ThirdParty,
    label: organizationRoleTranslation()['thirdParty'],
  },
  {
    value: AptlyOrganizationRoles.Support,
    label: organizationRoleTranslation()['support'],
  },
  {
    value: AptlyOrganizationRoles.Reports,
    label: organizationRoleTranslation()['reports'],
  },
  {
    value: AptlyOrganizationRoles.Orders,
    label: organizationRoleTranslation()['orders'],
  },
  {
    value: AptlyOrganizationRoles.Content,
    label: organizationRoleTranslation()['content'],
  },
  {
    value: AptlyOrganizationRoles.ProjectAdmin,
    label: organizationRoleTranslation()['projectAdmin'],
  },
  {
    value: AptlyOrganizationRoles.OrganizationContent,
    label: organizationRoleTranslation()['organizationContent'],
  },
  {
    value: AptlyOrganizationRoles.Admin,
    label: organizationRoleTranslation()['admin'],
  },
  {
    value: AptlyOrganizationRoles.TestingAdmin,
    label: organizationRoleTranslation()['testingAdmin'],
  },
];

type DotFields = 'address';
export type IOrganizationSchema = DotObject<Pick<AptlyOrganization, DotFields>> &
  Omit<AptlyOrganization, DotFields>;

const fieldAddressStreet = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.street'),
  autoComplete: 'address-line1',
});

const fieldAddressZip = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.zipCode'),
  autoComplete: 'address-line2',
});

const fieldAddressCity = (): ICrudField<IOrganizationSchema, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.place'),
  autoComplete: 'address-line3',
});

const defaultLogoImageOpts = (): ICrudFieldMedia<IOrganizationSchema> => ({
  width: 320,
  accept: acceptImages,
  opts: {
    disableFullWidth: true,
    style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
    transforms: 'c_fit',
  },
});

export function useOrganizationSchema(): ICrudSchema<IOrganizationSchema> {
  return {
    name: schemaName({ autoFocus: true }),
    email: schemaEmail({ required: true }),
    complaintEmail: schemaEmail({ label: i18n.t('singles.complaint'), required: false }),
    invoiceEmail: schemaEmail({ required: false, label: i18n.t('singles.invoiceEmail') }),
    tripletexCustomer: {
      type: AptlyFieldType.AutocompleteQuery,
      defaultValue: undefined,
      required: false,
      label: i18n.t('singles.tripletexCustomer'),
      preOnChange: (value) => ({ id: value.id, name: value.name }),
      useAutocompleteQuery: () => ({
        multiple: false,
        path: useApiUrl(SlugLevel.Base, 'tripletex/customer'),
        queryKey: 'organizationNumber',
        queryOnFocus: false,
        autocompleteSearchProps: {
          textFieldProps: { label: i18n.t('actions.searchByOrganizationNumber') },
        },
      }),
    },
    phone: schemaPhone(),
    number: schemaName({ label: i18n.t('singles.organizationCode'), required: false }),
    vat: schemaName({ label: i18n.t('singles.organizationNumber') }),
    slug: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: 'Slug / url',
      required: true,
      pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$',
    },
    address: {
      type: AptlyFieldType.Object,
      defaultValue: {
        billing: address,
        visit: address,
        post: address,
      },
      label: '',
    },
    logo: {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: i18n.t('singles.logo'),
      image: {
        ...defaultLogoImageOpts(),
        mediaKey: 'logoMedia',
      },
    },
    logoMedia: schemaMediaImage(),
    negativeLogoMedia: schemaMediaImage({
      label: i18n.t('singles.negativeLogo'),
      image: { ...defaultLogoImageOpts(), dark: true },
    }),
    projectLogoMedia: schemaMediaImage({
      label: i18n.t('singles.projectLogo'),
      image: defaultLogoImageOpts(),
    }),
    'address.billing.line1': schemaAddressLine1(),
    'address.billing.line2': schemaAddressLine2(),
    'address.billing.zip': schemaAddressZip(),
    'address.billing.city': schemaAddressCity(),
    'address.billing.country': schemaAddressCountry(),
    'address.visit.street': fieldAddressStreet(),
    'address.visit.zip': fieldAddressZip(),
    'address.visit.city': fieldAddressCity(),
    'address.post.street': fieldAddressStreet(),
    'address.post.zip': fieldAddressZip(),
    'address.post.city': fieldAddressCity(),
    producer: producerSchemaField(),
    status: {
      type: AptlyFieldType.Select,
      label: i18n.t('singles.status'),
      defaultValue: AptlyOrganizationStatus.NewBusiness,
      options: organizationStatusOptions(),
    },
    type: {
      type: AptlyFieldType.Select,
      defaultValue: [],
      label: i18n.t('singles.type'),
      multiple: true,
      options: organizationTypeOptions(),
    },
    paymentApp: useAppSchemaField(
      {
        label: i18n.t('singles.paymentApp'),
      },
      {
        implements: AptlyAppImplementation.Payment,
      }
    ),
    signApp: useAppSchemaField(
      {
        label: i18n.t('singles.signApp'),
      },
      {
        implements: AptlyAppImplementation.Sign,
      }
    ),
    signMembers: useOrganizationMembersField(),
  };
}

export interface IOrganizationMemberSchema extends AptlyOrganizationMember {
  email?: string;
  filterProjects?: boolean;
  filterDepartments?: boolean;
}

export const organizationMemberSchemaFields: (keyof IOrganizationMemberSchema)[] = [
  'user',
  'permissions',
  'position',
  'filterProjects',
  'projects',
];
export function useOrganizationMemberSchema(): ICrudSchema<IOrganizationMemberSchema> {
  const scope = useScope();
  return {
    user: {
      type: AptlyFieldType.AutocompleteQuery,
      defaultValue: null as any,
      label: i18n.t('singles.user'),
      required: true,
      useAutocompleteQuery: () => ({
        path: 'api/v1/users',
        multiple: false,
        queryKey: '$fullName',
        query: { select: '_id,fullName,email', limit: 7 },
        queryOnFocus: false,
        autocompleteSearchProps: {
          getOptionLabel: (user) => user.fullName,
          renderOption: (props, user) => <RenderUserOption props={props} value={user} />,
        },
      }),
    },
    position: {
      type: AptlyFieldType.Select,
      defaultValue: AptlyOrganizationMemberPosition.User,
      label: i18n.t('singles.position'),
      options: [
        {
          value: AptlyOrganizationMemberPosition.Owner,
          label: i18n.t('singles.owner'),
        },
        {
          value: AptlyOrganizationMemberPosition.Admin,
          label: i18n.t('singles.admin'),
        },
        {
          value: AptlyOrganizationMemberPosition.Accounting,
          label: i18n.t('singles.accounting'),
        },
        {
          value: AptlyOrganizationMemberPosition.User,
          label: i18n.t('singles.user'),
        },
      ],
    },
    permissions: {
      type: AptlyFieldType.Select,
      defaultValue: [AptlyOrganizationRoles.Admin],
      label: i18n.t('singles.accessLevel'),
      multiple: true,
      options: organizationRolesOptions,
    },
    filterProjects: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: i18n.t('singles.projects'),
      renderValidate: () => scope.crud(AptlyScopes.OrganizationMembers, 'U'),
      preOnChange: (filterProjects, crud) => {
        if (filterProjects) {
          crud.setField('projects')([]);
        } else {
          crud.setField('projects')(null);
        }
        return filterProjects;
      },
    },
    projects: useProjectsSchemaField({
      renderValidate: (crud) =>
        scope.crud(AptlyScopes.OrganizationMembers, 'U') && Boolean(crud.getField('filterProjects').value),
      defaultValue: null as any,
    }),
    filterDepartments: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: i18n.t('singles.departments'),
      renderValidate: () => scope.crud(AptlyScopes.OrganizationDepartments, 'U'),
      preOnChange: (filterProjects, crud) => {
        if (filterProjects) {
          crud.setField('departments')([]);
        } else {
          crud.setField('departments')(null);
        }
        return filterProjects;
      },
    },
    departments: useDepartmentsSchemaField({
      renderValidate: (crud) =>
        scope.crud(AptlyScopes.OrganizationDepartments, 'U') &&
        Boolean(crud.getField('filterDepartments').value),
    }),
  };
}
export const organizationInviteSchemaFields: (keyof ICrudOrganizationInvite)[] = [
  'email',
  'permissions',
  'position',
  'filterProjects',
  'projects',
  'filterDepartments',
  'departments',
];
export function useOrganizationInviteSchema(): ICrudSchema<ICrudOrganizationInvite> {
  return {
    email: schemaEmail({
      required: true,
    }),
    ...(useOrganizationMemberSchema() as ICrudSchema<ICrudOrganizationInvite>),
  };
}

export function useOrganizationSchemaField<T extends object, V extends string | null>(
  override?: Partial<ICrudField<T, V>>
): ICrudField<T, V> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Base, 'organizations'),
      query: { archived: false, select: '_id,name' },
      multiple: false,
      queryOnFocus: true,
    }),
    label: i18n.t('singles.organizations'),
    defaultValue: null as V,
    ...override,
  };
}

export function useOrganizationMembersField<T extends object, V extends AptlyUser | string>(
  override?: Partial<ICrudField<T, V[]>>
): ICrudField<T, V[]> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useOrganizationMembersAutocompleteQueryProps({
      multiple: true,
    }),
    label: i18n.t('singles.user'),
    defaultValue: [],
    ...override,
  };
}

export function useOrganizationMemberField<T extends object>(
  override?: Partial<ICrudField<T, Populated<AptlyUser> | string | null>>
): ICrudField<T, Populated<AptlyUser> | string | null> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useOrganizationMembersAutocompleteQueryProps(),
    label: i18n.t('singles.user'),
    defaultValue: null,
    ...override,
  };
}

export function useOrganizationMembersAutocompleteQueryProps(
  props?: Omit<
    AptlyAutocompleteQueryProps<AptlyUser, boolean>['autocompleteSearchProps'],
    'getOptionLabel' | 'renderOption'
  >
) {
  return (): Omit<AptlyAutocompleteQueryProps<AptlyUser, boolean>, 'onChange' | 'defaultValue'> => ({
    path: useApiUrl(SlugLevel.Organization, 'users'),
    query: { archived: false, select: '_id,fullName,email', limit: '5' },
    queryKey: '$fullName',
    queryOnFocus: true,
    clearSearchOnChange: true,
    autocompleteSearchProps: {
      label: i18n.t('singles.user'),
      noOptionsText: i18n.t('statuses.noResults'),
      ...props,
      getOptionLabel: (user) => user.fullName,
      renderOption: (props, user) => <RenderUserOption props={props} value={user} />,
    },
    multiple: false,
  });
}

interface RenderUserOptionProps {
  props: React.HTMLAttributes<HTMLLIElement> & { key: any };
  value: AptlyUser;
}

function RenderUserOption({ props, value }: RenderUserOptionProps) {
  return (
    <ListItem {...props} key={value._id} disablePadding disableGutters>
      <ListItemText primary={value.fullName} secondary={value.email} />
    </ListItem>
  );
}
