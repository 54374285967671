import { AptlyScopes, AptlyTag } from '@aptly-as/types';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { useTagSchema } from './tag.schema';
import { useTagColumns } from './tag.columns.js';

function Tags() {
  const path = useApiUrl(SlugLevel.Base, 'tags');
  const schema = useTagSchema();
  const columns = useTagColumns();

  return (
    <Search<AptlyTag>
      path={path}
      patchPath={toSearchPatchPath('tags')}
      scope={AptlyScopes.AdminTags}
      header={{ title: i18n.t('singles.tags') }}
      post={{ title: i18n.t('singles.tags') }}
      patch={{ title: i18n.t('singles.tags') }}
      schema={schema}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      primaryAction={<SearchAddPrimaryAction />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}

export default Tags;
