import { AptlyFieldType, AptlyScopes, AptlyUnitTemplate } from '@aptly-as/types';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import GridOn from '@mui/icons-material/GridOn';
import LocationCity from '@mui/icons-material/LocationCity';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import type { GridRowParams } from '@mui/x-data-grid/models/params';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CopyIconButton, DownloadIconButton } from '../../components/actions/icons/Icons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions';
import { dataGridSearchNameColumn } from '../../components/Search/search-data-grid/data-grid.fields';
import { SearchCrudContext, toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { Post } from '../../components/simple/useSimplePost';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { useSearchActionProps } from '../../hooks/useSearchActionProps';
import filesSaver from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import Grid from '../../mui/Grid';
import { Switch } from '../../mui/Input';
import { useAlgorithmSchemaField } from '../Algorithm/algorithm.schema.js';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import { usePeriodSchemaField } from '../Period/period.schema.js';
import { usePeriods } from '../Period/usePeriods';
import { ProjectContext } from '../Project/ProjectContext';
import { ICrudUnitTemplate, unitTemplateImportSchema, unitTemplateSchema } from './unit-template.schema';
import { UnitTemplate as UnitTemplateType } from './unit-template.types';

export default function UnitTemplates() {
  const navigate = useNavigate();
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'unit-templates');
  const scope = useScopeLevel({
    project: AptlyScopes.ProjectUnitTemplates,
    organization: AptlyScopes.OrganizationUnitTemplates,
    base: AptlyScopes.Admin,
  });
  const algorithms = useAlgorithms();
  const periods = usePeriods();
  const patchPath = useMemo(() => toSearchPatchPath('unit-templates'), []);
  const columns = useUnitTemplateColumns();

  const buttonActions = useCallback(() => {
    return [
      <SearchImportAction
        key="import"
        path={`${path}/import`}
        fields={!project.data ? ['file', 'algorithm'] : undefined}
        schema={unitTemplateImportSchema(algorithms, periods)}
      />,
    ];
  }, [path, project, algorithms, periods]);

  const handleOnRowClick = useCallback(
    (params: GridRowParams) => {
      if (params.row.project || scope === AptlyScopes.OrganizationUnitTemplates) {
        navigate(String(params.id));
      }
    },
    [navigate]
  );

  return (
    <Search<ICrudUnitTemplate>
      path={path}
      patchPath={patchPath}
      scope={scope}
      header={{ title: i18n.t('singles.catalogs') }}
      post={{ title: i18n.t('singles.catalog') }}
      patch={{ title: (data) => String(data.name) }}
      schema={unitTemplateSchema()}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{
        query: {
          sort: '-project,name',
          archived: false,
          select: '_id name organization project',
          level: 'project',
        },
      }}
      buttonActions={buttonActions}
      primaryAction={<SearchAddPrimaryAction />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} onRowClick={handleOnRowClick} />}
    </Search>
  );
}

function useUnitTemplateColumns(): GridColDef[] {
  const project = useContext(ProjectContext);
  return [
    dataGridSearchNameColumn(),
    {
      field: 'project',
      headerName: i18n.t('singles.type'),
      renderCell: (params) => {
        if (params.value) {
          return i18n.t('statuses.selected');
        }
        return i18n.t('singles.template');
      },
    },
    dataGridActionProps(
      (params) =>
        !project.data || params.row.project ? (
          <DataGridActionWrapper>
            <DataGridDeleteAction params={params} />
            <DataGridExportAction
              params={params}
              exportProps={{
                icon: <GridOn />,
                title: i18n.t('actions.downloadCSVTemplate'),
                data: { format: 'csv' },
              }}
            />
            <DownloadIconButton
              onClick={() => createModal(<DownloadUnitCsvTemplate unitTemplate={params.row} />)}
              title={i18n.t('actions.exportUnitTemplate')}
              icon={<LocationCity />}
            />
            <DataGridExportAction params={params} />
            <CopyUnitTemplate params={params} />
            <DataGridEditAction params={params} />
            <DataGridGoToAction params={params} />
          </DataGridActionWrapper>
        ) : (
          <DataGridActionWrapper>
            <CopyUnitTemplate params={params} />
          </DataGridActionWrapper>
        ),
      {
        minWidth: 380,
      }
    ),
  ];
}

interface DownloadUnitCsvTemplateProps {
  unitTemplate: UnitTemplateType;
  onClose?: () => void;
}

function DownloadUnitCsvTemplate({ onClose, unitTemplate }: DownloadUnitCsvTemplateProps) {
  const endpoint = useApiUrl(SlugLevel.Project, 'unit-templates');
  const project = useContext(ProjectContext);
  const projectName = project.data.name;
  const [data, setData] = useState<{ [key: string]: boolean | string }>({
    format: 'unit',
    allFields: false,
    kvmGulv: false,
    kvmVegg: false,
    lmList: false,
  });

  const mySwitch = (key: string, title: string) => (
    <Switch
      checked={Boolean(data[key])}
      onChange={() => setData((s) => ({ ...s, [key]: !data[key] }))}
      label={title}
    />
  );

  const fileName = `${projectName}-${unitTemplate.name}-${i18n.t('singles.units')}-${i18n.t(
    'actions.export'
  )}.csv`;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        filesSaver({
          endpoint: `${endpoint}/${unitTemplate._id}/export`,
          fileName,
          data,
        });
      }}
    >
      <DialogTitle>{i18n.t('paragraphs.selectFieldsToFill')}</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          {mySwitch('allFields', i18n.t('paragraphs.generalFields'))}
          {mySwitch('kvmGulv', i18n.t('paragraphs.squareMetersFloor'))}
          {mySwitch('kvmVegg', i18n.t('paragraphs.squareMetersWall'))}
          {mySwitch('lmList', i18n.t('paragraphs.lengthMetersMoldings'))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button variant="text" onClick={onClose} type="button">
              {i18n.t('actions.close')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" type="submit">
              {i18n.t('actions.download')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  );
}

interface CopyUnitTemplateProps {
  params: GridRenderCellParams;
}
function CopyUnitTemplate({ params }: CopyUnitTemplateProps) {
  const { scope, patchPath, level } = useContext(SearchCrudContext);
  const project = useContext(ProjectContext);
  const schema = useUnitTemplateCopySchema(level);

  const onAction = useCallback(
    ({ unshift }: any, data: AptlyUnitTemplate) => {
      createModal(
        <Post<any>
          path={`${patchPath(data)}/${data._id}/copy`}
          schema={schema}
          data={project.data ? { project: project.data._id } : {}}
          onCreate={unshift}
          props={{ title: `${i18n.t('actions.copy')} ${data.name}` }}
          fields={project.data && !data.project ? ['period', 'algorithm', 'copyAlgorithms', 'project'] : []}
          actionButtonProps={{
            actionLabel: i18n.t('actions.copy'),
            actionIcon: <FileCopyOutlined />,
          }}
        />
      );
    },
    [project, patchPath, schema]
  );

  const onClick = useSearchActionProps(params.id, onAction);
  if (!scope.create) return null;
  return <CopyIconButton onClick={onClick} />;
}

function useUnitTemplateCopySchema(level: SlugLevel): ICrudSchema<CopyUnitTemplateSchemaData> {
  return {
    period: usePeriodSchemaField(),
    algorithm: useAlgorithmSchemaField(
      {
        defaultValue: '',
        required: true,
        renderValidate: (crud) => !crud.getField('copyAlgorithms').value,
      },
      level
    ),
    copyAlgorithms: {
      type: AptlyFieldType.Switch,
      label: i18n.t('actions.useAlgorithmsFromOrganization'),
      defaultValue: false,
      renderValidate: (crud) => !crud.getField('algorithm').value,
    },
    project: {
      type: AptlyFieldType.Hidden,
      defaultValue: undefined,
      label: '',
    },
  };
}

type CopyUnitTemplateSchemaData = {
  period: string;
  project?: string;
  copyAlgorithms?: boolean;
  algorithm?: string | null;
};
