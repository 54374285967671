import { AptlyFieldType, AptlyOrder, AptlyPayment } from '@aptly-as/types';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { Alert, Grid2 } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import ActionButtons from '../../components/actions/buttons/ActionButtons.js';
import { EditIconButton } from '../../components/actions/icons/Icons.js';
import { ICrudSchema } from '../../components/crud/utils/crud.utils.js';
import CrudForm, {
  CrudFormContext,
  CrudFormDateTimeField,
  CrudFormTextField,
} from '../../components/simple/CrudForm.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { ModalButton } from '../../containers/Modal/ModalButton.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { getId } from '../../libraries/mongoose.js';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog.js';
import { OrderContext } from '../Order/OrderContext.js';

interface OrderDeliveryModalProps {
  order: AptlyOrder;
  payment: AptlyPayment;
  onClose?: () => void;
  onSave: (payment: AptlyPayment) => void;
}

export function canSetDelivery(payment?: AptlyPayment, ifNotSet: boolean = false) {
  if (!payment || payment.captured.at) {
    return false;
  }
  if (ifNotSet && payment.shippingDate) {
    return false;
  }
  return true;
}

export function PaymentDeliveryAction({
  order,
  payment,
  onSave,
}: Pick<OrderDeliveryModalProps, 'order' | 'payment' | 'onSave'>) {
  const spawnModal = useModal(() => <OrderDeliveryModal order={order} payment={payment} onSave={onSave} />);
  if (!canSetDelivery(payment, true)) {
    return null;
  }
  return (
    <EditIconButton
      onClick={spawnModal}
      icon={<LocalShippingOutlinedIcon />}
      title={i18n.t('actions.delivery')}
    />
  );
}

export function OrderDeliveryAction() {
  const { order, setState } = useContext(OrderContext);
  const payment = order.payment as AptlyPayment;
  const handleOnSave = useCallback(
    (payment: AptlyPayment) => {
      setState((s) => ({
        ...s,
        data: {
          ...s.data!,
          payment,
        },
      }));
    },
    [setState]
  );

  if (!canSetDelivery(payment)) {
    return null;
  }

  return (
    <ModalButton label={i18n.t('actions.delivery')} buttonProps={{ endIcon: <LocalShippingOutlinedIcon /> }}>
      <OrderDeliveryModal order={order} payment={payment} onSave={handleOnSave} />
    </ModalButton>
  );
}

export function OrderDeliveryModal({ order, payment, onSave, onClose }: OrderDeliveryModalProps) {
  const path = useApiUrl(SlugLevel.Project, `orders/${getId(order)}/payment/delivery`);
  const schema: ICrudSchema<AptlyPayment> = useMemo(
    () => ({
      shippingDate: {
        type: AptlyFieldType.DateTime,
        defaultValue: '',
        label: i18n.t('singles.shippingDate'),
        required: true,
        autoComplete: 'off',
      },
      shippingDescription: {
        type: AptlyFieldType.Text,
        defaultValue: '',
        label: i18n.t('singles.description'),
      },
    }),
    []
  );

  const handleOnSave = useCallback(
    (payment: AptlyPayment) => {
      onSave(payment);
      onClose!();
    },
    [onSave, onClose]
  );

  return (
    <CrudForm<AptlyPayment>
      path={path}
      data={{
        shippingDate: payment?.shippingDate || '',
        shippingDescription: payment?.shippingDescription || '',
      }}
      schema={schema}
      onEdit={handleOnSave}
      crudProps={{ alwaysPost: true }}
    >
      <ModalTitle>{i18n.t('actions.delivery')}</ModalTitle>
      <ModalContent>
        <Grid2 container flexDirection="column" gap={2}>
          <Alert color="info">
            <Trans i18nKey="info.setDelivery">
              Setting a delivery date will send an email to customer now and on date of delivery.
            </Trans>
          </Alert>
          <CrudFormDateTimeField name="shippingDate" />
          <CrudFormTextField name="shippingDescription" />
        </Grid2>
      </ModalContent>
      <ModalActions>
        <CrudFormContext.Consumer>
          {({ state }) => <ActionButtons onClose={onClose} disabled={state.busy} />}
        </CrudFormContext.Consumer>
      </ModalActions>
    </CrudForm>
  );
}
