import { AptlyScopes } from '@aptly-as/types';
import Add from '@mui/icons-material/Add';
import { Alert } from '@mui/material';
import { Dayjs } from 'dayjs';
import { useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ActionHeader } from '../../components/actions/ActionHeader.js';
import { AddButtonWithIcon, EditButtonWithIcon } from '../../components/actions/buttons/Buttons.js';
import { HeaderActions } from '../../components/actions/HeaderActions.js';
import { PrimaryActionFab } from '../../components/actions/Primary.js';
import { PositionParent } from '../../components/Position';
import Section from '../../components/Section.js';
import Spacer from '../../components/Spacer.js';
import { useModal } from '../../containers/Modal/Modal.js';
import i18n from '../../libraries/i18n';
import { intlDateTimeFormat } from '../../libraries/intl';
import { getId } from '../../libraries/mongoose';
import { FragmentScope, LayoutScope } from '../../libraries/scope/ScopeComponets';
import Button from '../../mui/Button';
import Grid from '../../mui/Grid';
import Paper from '../../mui/Paper';
import { LinearProgresser } from '../../mui/Progresser';
import Typography from '../../mui/Typography';
import { rawSpacing, tightSpacing } from '../../utils/spacing';
import { useUser } from '../User/UserContext';
import BookingCalendarDelete from './BookingCalendarDelete';
import {
  AvailabilityContext,
  BookingContext,
  BookingProvider,
  CalendarContext,
  CalendarProvider,
  useAvailability,
  useCalendar,
} from './BookingContext';
import BookingDatePicker, { AvailableDot, BookedDot } from './BookingDatePicker';
import BookingList from './BookingList';
import { BookingTimeAdd } from './BookingTimeAdd';
import BookingTimes from './BookingTimes';
import CalendarAddOrEdit from './CalendarAddOrEdit';

export default function Calendar() {
  const availability = useAvailability();
  const { calendarID } = useParams();
  if (!availability) {
    return null;
  }
  return (
    <CalendarProvider id={calendarID!}>
      <BookingProvider>
        <Content />
      </BookingProvider>
    </CalendarProvider>
  );
}

function Content() {
  const booking = useContext(BookingContext);
  const availability = useContext(AvailabilityContext);
  const calendarCtx = useContext(CalendarContext);
  const calendar = useCalendar();
  const user = useUser();
  const navigate = useNavigate();
  const spawnAdd = useModal(() => <BookingTimeAdd booking={booking} />);
  const spawnEdit = useModal(() => (
    <CalendarAddOrEdit availability={availability} calendar={calendarCtx} onDestroy={handleCalendarDelete} />
  ));
  const { state, setDate, addUserAvailability, deleteUserAvailability } = useContext(BookingContext);

  const handleDateChange = useCallback(
    (date: Dayjs | null) => {
      if (!date) {
        return;
      }
      setDate(date);
    },
    [setDate]
  );

  const handleCalendarDelete = useCallback(() => {
    navigate('../motebooking');
  }, []);

  if (!availability || !calendar) {
    return null;
  }

  const isUserConnected = user ? calendar.userAvailability?.some((x) => getId(x) === user._id) : false;

  return (
    <Section>
      {state.busy && (
        <PositionParent>
          <LinearProgresser offset />
        </PositionParent>
      )}
      <ActionHeader title={calendar.name} description={calendar.description}>
        <FragmentScope scope={AptlyScopes.ProjectBooking} crud="U">
          <HeaderActions
            primaryAction={
              <PrimaryActionFab onClick={spawnAdd} buttonProps={{ variant: 'outlined', endIcon: <Add /> }}>
                {i18n.t('actions.addTime')}
              </PrimaryActionFab>
            }
          >
            <BookingCalendarDelete />
            <EditButtonWithIcon color="primary" variant="outlined" onClick={spawnEdit}>
              {i18n.t('actions.edit')}
            </EditButtonWithIcon>
          </HeaderActions>
        </FragmentScope>
      </ActionHeader>
      <Spacer />
      <Grid container direction="column" spacing={tightSpacing}>
        <Grid container item>
          {isUserConnected ? (
            <Alert severity="info">
              {i18n.t('info.booking.deleteUserFromCalendar')}
              <Button color="warning" onClick={deleteUserAvailability}>
                {i18n.t('actions.removeConnection')}
              </Button>
            </Alert>
          ) : (
            <Alert severity="warning">
              {i18n.t('info.booking.connectUserToCalendar')}
              <Button onClick={addUserAvailability}>{i18n.t('actions.confirm')}</Button>
            </Alert>
          )}
        </Grid>
        <Grid item>
          <Grid container spacing={rawSpacing} justifyContent="space-between">
            <Grid item xs={12} lg={4}>
              <StyledPaper>
                <Grid container direction="column" spacing={tightSpacing}>
                  {calendar.endAvailabilityAt && (
                    <Grid item>
                      <Alert severity="info">
                        {i18n.t('info.booking.endAvailabilityAtActive')}{' '}
                        <strong>{intlDateTimeFormat(calendar.endAvailabilityAt)}</strong>
                      </Alert>
                    </Grid>
                  )}
                  {!calendar.endAvailabilityAt &&
                    calendar.userAvailability &&
                    calendar.userAvailability.length > 0 &&
                    isUserConnected && (
                      <Grid item>
                        <Alert severity="warning">
                          {i18n.t('info.booking.endAvailabilityAtInactive')}{' '}
                          <EditButtonWithIcon onClick={spawnEdit}>
                            {i18n.t('actions.edit')}
                          </EditButtonWithIcon>
                        </Alert>
                      </Grid>
                    )}
                  <Grid item>
                    <Grid container alignItems={'center'}>
                      <BookedDot />
                      <StyledTypography>{i18n.t('paragraphs.meetingBookedThatDay')}</StyledTypography>
                    </Grid>
                    <Grid container alignItems={'center'}>
                      <AvailableDot />
                      <StyledTypography>{i18n.t('paragraphs.availableMeetingSlots')}</StyledTypography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <BookingDatePicker onChange={handleDateChange} onMonthChange={handleDateChange} />
                  </Grid>
                  <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="U">
                    <Grid container direction={'column'} spacing={tightSpacing}>
                      <Grid item>
                        <Typography>{i18n.t('actions.addMeetingSlotsToday')}</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent="center">
                          <AddButtonWithIcon variant="outlined" onClick={spawnAdd}>
                            {i18n.t('actions.addTime')}
                          </AddButtonWithIcon>
                        </Grid>
                      </Grid>
                    </Grid>
                  </LayoutScope>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid container item xs={12} lg={8} spacing={rawSpacing}>
              <Grid item xs={12} xl={6}>
                <CardTitles variant="body2">{i18n.t('paragraphs.todaysMeetings')}</CardTitles>
                <StyledPaper>
                  <BookingList />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} xl={6}>
                <CardTitles variant="body2">{i18n.t('paragraphs.availableSlotsThisDay')}</CardTitles>
                <StyledPaper>
                  <BookingTimes booking={booking} />
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  );
}

const StyledPaper = styled(Paper)`
  min-height: 250px;
  padding: 1rem;
`;

const StyledTypography = styled(Typography)`
  margin-left: 0.5rem !important;
`;

const CardTitles = styled(Typography)`
  margin-bottom: 1rem !important;
`;
