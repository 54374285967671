import { AptlyScopes } from '@aptly-as/types';
import Add from '@mui/icons-material/Add';
import { Grid2 } from '@mui/material';
import { Fragment, useContext } from 'react';
import { ActionHeader } from '../../components/actions/ActionHeader.js';
import { HeaderActions } from '../../components/actions/HeaderActions.js';
import Section from '../../components/Section';
import { CrudContactNotifyButton } from '../../components/simpleCruds/settings/CrudContactNotifyAdd';
import EmptyState from '../../containers/Error/EmptyState';
import { ModalPrimaryAction } from '../../containers/Modal/ModalButton';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import ComponentLink from '../../libraries/router/ComponentLink';
import { FragmentScope } from '../../libraries/scope/ScopeComponets.js';
import Card, { CardContent } from '../../mui/Card';
import Divider from '../../mui/Divider';
import Grid from '../../mui/Grid';
import Typography from '../../mui/Typography';
import AvailabilityAdd, { RemoveAvailability } from './AvailabilityAdd';
import CalendarAddOrEdit from './CalendarAddOrEdit';
import { AvailabilityContext } from './BookingContext';

function BookingCalendarSelect() {
  const availability = useContext(AvailabilityContext);
  const to = useTo(SlugLevel.Unit, '/motebooking');

  if (!availability.data && availability.state.busy) {
    return null;
  }
  if (!availability.data) {
    return (
      <Fragment>
        <Section>
          <EmptyState>{i18n.t('statuses.noBooking')}</EmptyState>
          <Grid container justifyContent="center">
            <AvailabilityAdd />
          </Grid>
        </Section>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Section>
        <ActionHeader title={i18n.t('singles.calendars')}>
          <HeaderActions
            primaryAction={
              availability ? (
                <FragmentScope scope={AptlyScopes.ProjectBooking} crud="C">
                  <ModalPrimaryAction
                    buttonProps={{ variant: 'contained', endIcon: <Add /> }}
                    label={i18n.t('actions.add')}
                  >
                    <CalendarAddOrEdit availability={availability} />
                  </ModalPrimaryAction>
                </FragmentScope>
              ) : null
            }
          >
            <FragmentScope scope={AptlyScopes.ProjectBooking} crud="D">
              <RemoveAvailability />
            </FragmentScope>
            <CrudContactNotifyButton defaultExpandGroup="booking" />
          </HeaderActions>
        </ActionHeader>
      </Section>
      <Section>
        <Grid2 container gap={2} justifyContent="center">
          {availability.data?.calendars.map((calendar) => (
            <Grid2 key={calendar._id} size={{ xs: 12, md: 4 }}>
              <Card component={ComponentLink} to={`${to}/${calendar._id}`}>
                <CardContent>
                  <Typography variant="subtitle1">{calendar.name}</Typography>
                  <Divider />
                  <Typography>{calendar.description}</Typography>
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>
      </Section>
    </Fragment>
  );
}

export default BookingCalendarSelect;
