import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import { ISimpleModalProps, SimpleModal } from '../../components/simple/SimpleCrud.js';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import {
  AptlyOfferSendBodySchema,
  OfferSchema,
  useOfferSendSchema,
  userOfferSendFields,
} from './offer.schema.js';
import { IOfferContext } from './OfferContext.js';

interface OfferSendProps extends Pick<ISimpleModalProps<AptlyOfferSendBodySchema>, 'onClose'> {
  offer: IOfferContext;
  unit?: boolean;
}

export function OfferSend({ offer, unit, onClose }: OfferSendProps): JSX.Element {
  const to = useTo(SlugLevel.Project, '/tilbud');
  const navigate = useNavigate();
  const path = useApiUrl(SlugLevel.Project, `offers/${offer.data._id}/send`);
  const schema = useOfferSendSchema();
  if (unit) {
    schema.unit!.disabled = true;
  }

  return (
    <SimpleModal<AptlyOfferSendBodySchema, OfferSchema>
      title={i18n.t('actions.send')}
      path={path}
      fields={unit ? ['unit', 'description'] : userOfferSendFields}
      schema={schema}
      data={
        unit
          ? { unit: offer.data.unit as string, description: offer.data.description }
          : { description: offer.data.description }
      }
      onClose={onClose}
      onEdit={() => {
        navigate(to);
      }}
      actionButtonProps={{
        actionLabel: i18n.t('actions.send'),
        actionIcon: <SendIcon />,
      }}
    />
  );
}
