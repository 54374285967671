import { AptlyScopes } from '@aptly-as/types';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { GridColDef } from '@mui/x-data-grid';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { dataGridActions } from '../../components/Search/search-data-grid/data-grid.fields';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import Search from '../../components/Search/Search';
import {
  ICrudOrganizationInvite,
  useOrganizationInviteSchema,
  organizationInviteSchemaFields,
  organizationRolesOptions,
} from './organization.schema';
import Scope from '../../libraries/scope/Scope';
import { toPermissionTranslation } from './organization.utils';
import { DataGridSelectMultipleEdit } from '../../components/Search/search-data-grid/DataGridSelectEdit';

const columns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'email',
    headerName: i18n.t('singles.email'),
    valueGetter: (_, row) => row.claim.email,
    flex: 1,
  },
  {
    type: 'string',
    field: 'permissions',
    headerName: i18n.t('singles.permissions'),
    renderCell: (params) => params.value?.map(toPermissionTranslation).join(', ') || 'N/A',
    flex: 1,
    editable: true,
    renderEditCell: (params) => (
      <DataGridSelectMultipleEdit params={params} options={organizationRolesOptions()} />
    ),
  },
  {
    type: 'number',
    field: 'projects',
    headerName: i18n.t('singles.projects'),
    valueGetter: (value: string[]) => value?.length ?? i18n.t('singles.all'),
    flex: 1,
  },
  {
    type: 'boolean',
    field: 'claim',
    headerName: i18n.t('statuses.claimed'),
    renderCell: (params) => (params.value.claimed ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlank />),
    width: 100,
  },
  {
    type: 'number',
    field: 'clickCount',
    headerName: i18n.t('statuses.clicked'),
    valueGetter: (v) => v || 0,
    width: 100,
  },
  dataGridActions(),
];

function OrganizationInvites() {
  const path = useApiUrl(SlugLevel.Organization, 'invites');
  const schema = useOrganizationInviteSchema();

  return (
    <Search<ICrudOrganizationInvite>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationMembers}
      header={{ title: i18n.t('singles.invitations') }}
      post={{ title: i18n.t('singles.invitations') }}
      patch={{
        fields: organizationInviteSchemaFields.slice(1),
        title: (data) => data.claim?.email || data.email || 'N/A',
        preData: (data) => ({ ...data, filterProjects: Boolean(data.projects) }),
      }}
      schema={schema}
      fields={organizationInviteSchemaFields}
      options={{ disableBulk: true }}
      reach={{
        query: { sort: 'name', $populate: 'projects:_id,name' },
      }}
      primaryAction={
        Scope.crud(AptlyScopes.OrganizationMembers, 'C') ? (
          <SearchAddPrimaryAction key="create" title={i18n.t('actions.inviteClient')} />
        ) : null
      }
    >
      {() => <SearchDataGrid columns={columns()} />}
    </Search>
  );
}

export default OrganizationInvites;
