import { AptlyDocument, AptlyUnit } from '@aptly-as/types';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import ReferenceInput from '../../components/ReferenceInput';
import handleError from '../../containers/Error/handleError';
import useGetApiUrl, { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import apiRequest from '../../libraries/fetch/apiRequest';
import i18n from '../../libraries/i18n';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import { Checkbox } from '../../mui/Input';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import { documentTypeTranslations } from './Documents_old';

interface LinkToUnitModalProps {
  name: string;
  documents: AptlyDocument[];
  refresh?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  unit?: AptlyUnit;
  autoClick?: boolean;
  defaultMove?: boolean;
}

export default function LinkDocumentToUnit({
  name,
  documents,
  refresh,
  onClose,
  onCancel,
  autoClick,
  defaultMove = true,
  ...props
}: LinkToUnitModalProps) {
  const projectUrl = useGetApiUrl('project');
  const projectReferenceUrl = useApiUrl(SlugLevel.Project);
  const [active, setActive] = useState(new Array(documents.length).fill(true));
  const [move, setMove] = useState(defaultMove);
  const [busy, setBusy] = useState(false);
  const [unit, setUnit] = useState<null | AptlyUnit>(props.unit || null);

  const handleOnSubmit = useCallback(
    async function handleOnSubmit(e?: FormEvent) {
      if (e) {
        e.preventDefault();
      }
      if (!unit) return;
      try {
        setBusy(true);
        await apiRequest(`${projectUrl}/units/${unit._id}/documents/move`, {
          method: 'POST',
          data: {
            documents: active.reduce((arr, checked, i) => (checked ? [...arr, documents[i]._id] : arr), []),
            type: move ? 'move' : 'copy',
          },
        });
        setBusy(false);
        if (typeof refresh === 'function') {
          refresh();
        }
        if (typeof onClose === 'function') {
          onClose();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [projectUrl, unit, refresh, onClose, active, move, documents]
  );

  useEffect(() => {
    if (autoClick && !busy) {
      handleOnSubmit().then();
    }
  }, [handleOnSubmit, busy, autoClick]);

  return (
    <form onSubmit={handleOnSubmit}>
      <ModalTitle>
        {i18n.t('actions.connectToUnit')} {unit ? unit.name : i18n.t('singles.NA')}
      </ModalTitle>
      <ModalContent>
        <Grid container direction="column">
          <ReferenceInput<AptlyUnit>
            defaultSelected={unit ? [unit] : undefined}
            endpoint={`${projectReferenceUrl}/units`}
            queryKey="name"
            labelKey="name"
            initQuery={!unit ? name : undefined}
            query={{ limit: '5', as: 'array', archived: 'false' }}
            singular={true}
            onSelect={(units) => {
              setUnit(units[0]);
            }}
            autoFocus
          />
          <Checkbox
            checked={move}
            onChange={() => setMove(!move)}
            label={i18n.t('paragraphs.removeFilesFromProject')}
          />
        </Grid>
      </ModalContent>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{i18n.t('singles.name')}</TableCell>
            <TableCell>{i18n.t('singles.type')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map((doc, i) => (
            <TableRow key={i}>
              <TableCell>
                <Checkbox
                  checked={active[i]}
                  onChange={() => {
                    active[i] = !active[i];
                    setActive([...active]);
                  }}
                />
              </TableCell>
              <TableCell>{doc.name}</TableCell>
              <TableCell>{documentTypeTranslations(doc._type)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ModalActions>
        <ActionButtons
          onClose={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
          submitType="submit"
          disabledSubmit={!unit}
          disabled={busy}
        />
      </ModalActions>
    </form>
  );
}
