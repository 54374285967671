import { PropsWithChildren } from 'react';
import Typography, { TypographyProps } from '../../mui/Typography';

export interface SearchHeaderProps {
  title: string;
  variant?: TypographyProps['variant'];
}

export default function SearchHeader({ title, variant, children }: PropsWithChildren<SearchHeaderProps>) {
  return (
    <>
      <Typography variant={variant || 'h1'}>{title}</Typography>
      {children}
    </>
  );
}
