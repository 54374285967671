import { AptlyLanguage } from '@aptly-as/types';
import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Grid2';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSSOLogin } from '@aptly-as/sso-login-react';
import styled from 'styled-components';
import AptlyLogo from '../../components/AptlyLogo';
import { API_URL } from '../../env';
import { UserContext } from '../../models/User/UserContext';
import { MOBILE_BREAK } from '../../utils/themes';

const loginUrl = `${API_URL}/auth/admin`;
const registerUrl = `${API_URL}/auth/admin?${new URLSearchParams({ register: 'true' }).toString()}`;

export default function Login() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { ready, i18n } = useTranslation();
  const onLogin = useCallback(async () => {
    const data = await user.refresh();
    if (data && data.language && Object.values(AptlyLanguage).includes(data.language)) {
      await i18n.changeLanguage(data.language);
    }
    const redirect = window.localStorage.getItem('redirect');
    if (redirect) {
      window.localStorage.removeItem('redirect');
      navigate(redirect);
    } else {
      navigate('/');
    }
  }, [navigate, user, i18n]);

  const login = useSSOLogin(loginUrl, onLogin);
  const register = useSSOLogin(registerUrl, onLogin);

  if (!ready) {
    return null;
  }

  return (
    <StyledContainerGrid2 container>
      <StyledLeftGrid2 container flexDirection="column" gap={2}>
        <Grid2>
          <Grid2 container flexDirection="column" gap={1}>
            <StyledLogoGrid2>
              <AptlyLogo />
            </StyledLogoGrid2>
            <Grid2>
              <Typography variant="h1" color="textPrimary" gutterBottom>
                {i18n.t('paragraphs.welcomeAptlyPortal')}
              </Typography>
            </Grid2>
            <Grid2>
              <Typography></Typography>
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2>
          <Divider />
        </Grid2>
        <Grid2>
          <Button fullWidth variant="contained" color="primary" onClick={login}>
            {i18n.t('actions.login')}
          </Button>
        </Grid2>
        <Grid2>
          <Button fullWidth variant="outlined" color="primary" onClick={register}>
            {i18n.t('actions.register')}
          </Button>
        </Grid2>
      </StyledLeftGrid2>
      <StyledRightGrid flex={1} />
    </StyledContainerGrid2>
  );
}

const StyledContainerGrid2 = styled(Grid2)`
  min-height: 100vh;
  width: 100%;
`;

const StyledLeftGrid2 = styled(Grid2)`
  width: 500px;
  padding: ${(props) => props.theme.spacing(2)};

  @media only screen and (max-width: ${MOBILE_BREAK}) {
    width: 100%;
  }
`;

const StyledRightGrid = styled(Grid2)`
  height: 100vh;
  background: url('https://res.cloudinary.com/rubics/image/upload/w_1376/f_auto/ei1nmbhqetrweabbxcli.png');
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    height: 100%;
  }
`;

const StyledLogoGrid2 = styled(Grid2)`
  margin: -0.25rem;
  img {
    height: 50px;
  }
`;
