import {
  AptlyAlgorithm,
  AptlyAlgorithmPipelineData,
  AptlyAlgorithmPipelineOperation,
  AptlyAllowance,
  AptlyCurrency,
} from '@aptly-as/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Grid2 } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { useFormat } from '../../containers/Format/Format.js';
import i18n from '../../libraries/i18n.js';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table.js';
import Typography from '../../mui/Typography.js';
import { MOBILE_BREAK } from '../../utils/themes.js';
import { Algorithm } from './algorithm.pipeline.js';
import { algorithmOperationLabels } from './algorithm.schema.js';

interface AlgorithmPreviewProps {
  algorithm?: AptlyAlgorithm;
  amount: number;
  allowance?: AptlyAllowance | null;
}

export function AlgorithmPreview({ algorithm, allowance, amount }: AlgorithmPreviewProps) {
  const format = useFormat();
  const pipeline = useMemo(() => {
    const algo = new Algorithm(algorithm).amount(amount);
    if (allowance) {
      algo.priceAllowance(allowance);
    }
    return algo.quantity(1).getPipeline();
  }, [algorithm, amount, allowance]);
  const operationLabels = algorithmOperationLabels();
  const reversed = !algorithm || algorithm.reversed;
  const showWarning = algorithm && !algorithm.reversed && pipeline[0].amount !== amount;

  if (pipeline[pipeline.length - 1].amount === 0 && !allowance) return null;
  const priceLabel = reversed ? i18n.t('singles.customerAmount') : i18n.t('singles.netCost');
  const amountLabel = format.amount(amount);

  return (
    <>
      {showWarning && (
        <Alert color="warning">
          Utregning med valgte pipeline steg er ikke 100% støttet av systemet. Ta kontakt med support om du
          ønsker å ta i bruk denne kombinasjonen.
        </Alert>
      )}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid2 container justifyContent="space-between" sx={{ width: '100%' }}>
            <Grid2>
              <Typography>
                {i18n.t('singles.calculation')} ( {priceLabel} )
              </Typography>
            </Grid2>
            <Grid2>
              <Typography>{pipeline.map((pipe) => format.amount(pipe.amount)).join('  >  ')}</Typography>
            </Grid2>
          </Grid2>
        </AccordionSummary>
        <AccordionDetails>
          <Alert color="info">
            {i18n.t('singles.example')}:{' '}
            {reversed ? (
              <Trans i18nKey="algorithm.exampleInfoReversed">
                Uses published {{ amountLabel }} as gross price and calculates the net with help of pipeline
                and VAT
              </Trans>
            ) : (
              <Trans i18nKey="algorithm.exampleInfoStandard">
                Uses published {{ amountLabel }} as net price and calculates the gross with help of pipeline
                and VAT
              </Trans>
            )}
          </Alert>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <TableCell>
                  <strong>Steg</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Basert på</strong>
                </TableCell>
                <TableCell align="right" width="70">
                  <strong>Verdi</strong>
                </TableCell>
                <TableCell align="right" width="70">
                  <strong>Handling</strong>
                </TableCell>
                <TableCell align="right" width="100">
                  <strong>Diff</strong>
                </TableCell>
                <TableCell align="right" width="100">
                  <strong>Verdi</strong>
                </TableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {pipeline.map((pipe) => (
                <Pipe key={pipe.operation + pipe.label} pipe={pipe} labels={operationLabels} />
              ))}
              <TableRow></TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

interface PipeProps {
  pipe: AptlyAlgorithmPipelineData;
  labels: ReturnType<typeof algorithmOperationLabels>;
}
function Pipe({ pipe, labels }: PipeProps) {
  const format = useFormat();
  const value = useValue(pipe);
  return (
    <StyledTableRow key={pipe.operation + pipe.label}>
      <TableCell>{pipe.label}</TableCell>
      <TableCell align="right">{format.amount(pipe.baseAmount)}</TableCell>
      <TableCell align="right">{value}</TableCell>
      <TableCell align="right">{labels[pipe.operation]}</TableCell>
      <TableCell align="right">{format.amount(pipe.diff)}</TableCell>
      <TableCell align="right">{format.amount(pipe.amount)}</TableCell>
    </StyledTableRow>
  );
}

function useValue(pipe: AptlyAlgorithmPipelineData): string {
  const format = useFormat();
  if (
    [
      AptlyAlgorithmPipelineOperation.Percent,
      AptlyAlgorithmPipelineOperation.Vat,
      AptlyAlgorithmPipelineOperation.Fee,
    ].includes(pipe.operation)
  ) {
    return `${pipe.value}%`;
  }
  if (pipe.operation === AptlyAlgorithmPipelineOperation.Multiply) {
    return `${pipe.value} x`;
  }
  return format.amount(pipe.value || 0, AptlyCurrency.NorwegianKrone);
}

const StyledTableRow = styled(TableRow)`
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    th:nth-child(n + 2):nth-child(-n + 4) {
      display: none;
    }
    td:nth-child(n + 2):nth-child(-n + 4) {
      display: none;
    }
  }
`;
