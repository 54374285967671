import { AptlyProduct, AptlyScopes } from '@aptly-as/types';
import { useMemo } from 'react';
import Search, { SearchFieldText, useSearchButtonActions } from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction.js';
import { ISearchCrudActions, toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { IUseCrudSearchPatchProps } from '../../libraries/reach/useCrudSearch.js';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import { useOptionColumns } from './option.columns.js';
import { OptionsProductAdd } from './option.fetch.js';
import {
  IOptionSchema,
  optionSchemaFields,
  useOptionImportSchema,
  useOptionSchema,
} from './option.schema.js';

interface OptionsProps {
  level: SlugLevel;
}

export default function Options({ level }: OptionsProps) {
  const columns = useOptionColumns();
  const path = useApiUrl(level, 'options');
  const schema = useOptionSchema(level);
  const importSchema = useOptionImportSchema();
  const scope = useScopeLevel({
    unit: AptlyScopes.ProjectUnitOptions,
    project: AptlyScopes.ProjectOptions,
    organization: AptlyScopes.OrganizationOptions,
    base: AptlyScopes.Admin,
  });
  const reach: IUsePaginatedSearchProps<IOptionSchema> = useMemo(
    () => ({
      paginationMode: 'server',
      query: {
        sort: 'name',
        $populate: 'product:_id,name,productNumber,images;quantityCategory:_id,name,amountCategory:_id,name;',
        archived: false,
      },
    }),
    []
  );
  const patch: IUseCrudSearchPatchProps<IOptionSchema> = useMemo(
    () => ({
      title: (v) => (v?.product as AptlyProduct)?.name || i18n.t('singles.options'),
      preData: (data) => ({
        ...data,
        __quantity: !!data.quantityCategory,
        __amount: !!data.amountCategory,
      }),
    }),
    []
  );

  const buttonActions = useSearchButtonActions((_items, actions: ISearchCrudActions<IOptionSchema>) => {
    return [
      <SearchImportAction key="import" path={`${path}/import`} schema={importSchema} disableUnshift />,
      <OptionsProductAdd key="add" actions={actions} />,
    ];
  });

  return (
    <Search<IOptionSchema>
      path={path}
      patchPath={toSearchPatchPath('options')}
      scope={scope}
      header={{ title: i18n.t('singles.serviceCatalog') }}
      post={{ title: i18n.t('singles.option') }}
      patch={patch}
      schema={schema}
      fields={optionSchemaFields}
      options={{ disableBulk: true }}
      reach={reach}
      buttonActions={buttonActions}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
