import React, { PropsWithChildren, useCallback } from 'react';
import { useMobile } from '../../hooks/useMobile.js';
import Dialog from '../../mui/Dialog.js';
import { ICreateModelOptions, IModal as ModalType } from './modal.types.js';
import { deleteModal, modals, newModal } from './modal.utils.js';
import { ModalContext } from './ModalContext.js';

export function ModalProvider({ children }: PropsWithChildren<object>) {
  const createModal = useCallback((element: React.ReactElement, opts: ICreateModelOptions = {}) => {
    newModal(element, opts);
  }, []);
  const deleteModal = useCallback((id: string) => {
    modals.value.splice(
      modals.value.findIndex((x) => x.id === id),
      1
    );
  }, []);

  return (
    <ModalContext.Provider value={{ createModal, deleteModal }}>
      {children}
      <Modals />
    </ModalContext.Provider>
  );
}

function Modals() {
  return (
    <>
      {modals.value.map((m) =>
        !m.naked ? (
          <Modal key={m.id} m={m}>
            {m.element}
          </Modal>
        ) : (
          m.element
        )
      )}
    </>
  );
}

function Modal({ m, children }: React.PropsWithChildren<{ m: ModalType }>) {
  const isMobile = useMobile();
  const handleOnClose = useCallback(() => {
    if (m.disableEscape) return;
    deleteModal(m.id);
  }, [m.disableEscape, m.id]);

  return (
    <Dialog
      key={m.id}
      id={m.id}
      open={true}
      maxWidth={m.width}
      fullScreen={isMobile || m.fullScreen}
      scroll={m.scroll}
      onClose={handleOnClose}
      enableBackdropOnClose={m.enableBackdropOnClose}
    >
      {children}
    </Dialog>
  );
}
