import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useContext } from 'react';
import { AptlyProducer, AptlyProduct, AptlyTag } from '@aptly-as/types';
import ColorPalette from '../../components/ColorPalette.js';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import {
  dataGridSearchNameColumn,
  dataGridSearchTextColumn,
} from '../../components/Search/search-data-grid/data-grid.fields.js';
import { DataGridFieldClickEdit } from '../../components/Search/search-data-grid/DataGridFieldClickEdit.js';
import { useDataGridEditText } from '../../components/Search/search-data-grid/DataGridTextEdit.js';
import i18n from '../../libraries/i18n.js';
import { dataGridColorColumn, dataGridImagesColumn } from '../../mui/x-data-grid/dataGrid.cols.js';
import { OrganizationContext } from '../Organization/OrganizationContext.js';
import { DataGridEditProduct } from './DataGridEditProduct.js';
import DataGridVariantOrder from './DataGridVariantOrder.js';
import { copyProduct, getProductScope } from './product.utils.js';

export function useProductColumns(): GridColDef<AptlyProduct>[] {
  return [
    dataGridImagesColumn('images', {
      editable: true,
      renderEditCell: (params) => <DataGridFieldClickEdit params={params} keys={['images']} />,
    }),
    dataGridSearchNameColumn(),
    dataGridSearchTextColumn('productNumber', i18n.t('singles.productNumber')),
    {
      field: 'producer',
      headerName: i18n.t('singles.producer'),
      width: 150,
      valueGetter: (producer?: AptlyProducer) => producer?.name || '',
    },
    {
      field: 'tags',
      headerName: i18n.t('singles.tags'),
      width: 150,
      valueGetter: (v?: AptlyTag[]) => (v ? v.map((x) => x.name).join(', ') : ''),
    },
    {
      type: 'string',
      field: 'products',
      headerName: i18n.t('singles.variants'),
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        if (params.row.products && params.row.products.length > 0) {
          return `(${params.row.products.length}) ${params.row.products
            .map((x) => (x as AptlyProduct).productNumber)
            .join('|')}`;
        }
        return 'N/A';
      },
      renderEditCell: useDataGridEditText,
    },
    dataGridColorColumn('color', {
      renderCell: (params) => printColorValues(params.row),
    }),
    {
      type: 'string',
      field: 'organization',
      headerName: i18n.t('statuses.active'),
      display: 'flex',
      renderCell: (params) => (params.row.organization ? <CheckBoxOutlineBlank /> : <CheckBoxOutlinedIcon />),
    },
    dataGridActionProps((params) => <ProductActions params={params} />, {
      minWidth: 230,
    }),
  ] satisfies GridColDef<AptlyProduct>[];
}

interface ProductActionsProps {
  params: GridRenderCellParams;
}

function ProductActions({ params }: ProductActionsProps) {
  const org = useContext(OrganizationContext);
  const product = params.row;
  const scope = getProductScope(product, org.data);

  return (
    <DataGridActionWrapper>
      {scope.update && product.variants?.length > 1 && <DataGridVariantOrder params={params} />}
      {scope.update ? (
        <DataGridEditProduct params={params} update />
      ) : (
        <DataGridEditProduct params={params} />
      )}
      {scope.update && <DataGridCopyAction<AptlyProduct> params={params} preCopy={copyProduct} />}
      {scope.delete && <DataGridDeleteAction params={params} />}
    </DataGridActionWrapper>
  );
}

const printColorValues = (product: AptlyProduct) => {
  const values = getVariantValues(product, 'color', (x) => (x ? <ColorPalette key={x} color={x} /> : null));
  return values.length === 0 ? product.colorLabel || '' : values;
};

const renderValue = (value: string): string | JSX.Element | null => `${value} `;
const getVariantValues = <T extends AptlyProduct, K extends keyof (T | AptlyProduct['variants'][0])>(
  product: T,
  key: K,
  render = renderValue
) => {
  const values = new Set(typeof product[key] === 'string' ? [product[key] as string] : []);
  if (product.variants) {
    for (const variant of product.variants) {
      if (key in variant && typeof variant[key] === 'string') {
        values.add(variant[key] as string);
      }
    }
  }
  return [...values].map(render);
};
