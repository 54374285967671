import { AptlyOrder, AptlyOrganization, AptlyPayment, AptlyProject, AptlyUnit } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { useContext } from 'react';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { SearchContext } from '../../components/Search/search.utils.js';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { intlCurrencyDecimal } from '../../libraries/intl.js';
import { getId } from '../../libraries/mongoose.js';
import { DocumentPreviewIcon } from '../Document/DocumentPreview.js';
import { PaymentCaptureAction } from '../Payment/PaymentCapture.js';
import { orderStatusLabel } from './order.utils.js';

export function useOrderColumns(): GridColDef<AptlyOrder>[] {
  return [
    {
      type: 'string',
      field: 'orderNumber',
      headerName: i18n.t('singles.orderNumber'),
      flex: 1,
    },
    {
      type: 'string',
      field: 'organization',
      headerName: i18n.t('singles.organization'),
      width: 200,
      valueGetter: (value: AptlyOrganization) => value?.name || value,
    },
    {
      type: 'string',
      field: 'project',
      headerName: i18n.t('singles.project'),
      width: 150,
      valueGetter: (value: AptlyProject) => value?.name || value,
    },
    {
      type: 'string',
      field: 'unit',
      headerName: i18n.t('singles.unit'),
      width: 150,
      valueGetter: (value: AptlyUnit) => value?.name || value,
    },
    {
      type: 'string',
      field: 'status',
      headerName: i18n.t('singles.status'),
      width: 200,
      valueGetter: (value) => orderStatusLabel(value),
    },
    {
      type: 'date',
      field: 'created',
      headerName: i18n.t('statuses.created'),
      width: 150,
      valueGetter: (value) => new Date(value),
    },
    {
      type: 'number',
      field: 'totalCost',
      headerName: i18n.t('singles.totalPrice'),
      width: 175,
      valueGetter: (value) => intlCurrencyDecimal(value || 0),
    },
    dataGridActionProps(
      (params) => {
        const { refresh } = useContext(SearchContext);
        const order = params.row as AptlyOrder;
        const receipt = order.signedReceipt || order.receipt;
        const orderTo = useTo(SlugLevel.Project, '/ordre', { project: getId(order.project) });

        return (
          <DataGridActionWrapper>
            <DocumentPreviewIcon document={receipt} />
            <PaymentCaptureAction order={order} payment={order.payment as AptlyPayment} onSave={refresh} />
            <DataGridGoToAction params={params} preTo={orderTo} />
          </DataGridActionWrapper>
        );
      },
      { minWidth: 125 }
    ),
  ];
}
