import { Grid2, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { CancelIconButton } from '../components/actions/icons/Icons.js';
import { IModal } from '../containers/Modal/modal.types.js';
import { MOBILE_BREAK } from '../utils/themes.js';
import { Form } from './Input';
import ErrorBoundary from '../components/ErrorBoundary';

const ModalContent = DialogContent;
const ModalTitle = DialogTitle;
const ModalActions = DialogActions;

const ModalForm = styled(Form)<{ fullScreen?: boolean }>`
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    max-height: 100%;
  }

  ${(props) =>
    props.fullScreen &&
    css`
      max-height: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `};
`;

interface Props extends Omit<DialogProps, 'onClose'>, Pick<IModal, 'enableBackdropOnClose'> {
  id?: string;
  onClose: () => void;
}

function Dialog(props: Props) {
  const init = useRef(false);
  const { id, open, fullScreen, maxWidth, onClose, children, scroll, enableBackdropOnClose } = props;
  const [_open, setOpen] = useState(open);
  const [search, setSearch] = useSearchParams();

  const popstate = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    window.addEventListener('popstate', popstate);
    return () => {
      window.removeEventListener('popstate', popstate);
    };
  }, [popstate]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (open && !init.current) {
      init.current = true;
      search.set('modal', id || 'true');
      setSearch(search);
    }
  }, [open, id, search, setSearch]);

  return (
    <MUIDialog
      open={_open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      fullWidth
      scroll={scroll}
      autoFocus={fullScreen}
      onClose={enableBackdropOnClose ? popstate : undefined}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </MUIDialog>
  );
}

interface ModalHeaderProps {
  onClose?: () => void;
  title?: string;
}
const titleSX: SxProps<Theme> = { paddingLeft: (theme) => theme.spacing(1) };
const childrenSX: SxProps<Theme> = { paddingRight: (theme) => theme.spacing(1) };
export function ModalHeader({ onClose, title, children }: PropsWithChildren<ModalHeaderProps>) {
  if (!children) {
    return <ModalTitle>{title}</ModalTitle>;
  }

  return (
    <Grid2 container justifyContent="space-between" alignItems="center">
      <Grid2>
        <CancelIconButton onClick={onClose} />
      </Grid2>
      <Grid2 flex={1}>
        <ModalTitle sx={titleSX}>{title}</ModalTitle>
      </Grid2>
      <Grid2 sx={childrenSX}>{children}</Grid2>
    </Grid2>
  );
}

export default Dialog;
export { ModalContent, ModalTitle, ModalActions, ModalForm };
export type { Props as DialogProps };
