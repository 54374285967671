import './libraries/i18n';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ReachProvider } from '@ewb/reach-react';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { FormatProvider } from './containers/Format/FormatProvider.js';
import { queryClient } from './libraries/react-query.js';
import UserProvider from './models/User/UserContext';
import { unregister } from './serviceWorker';
import { reachService } from './libraries/reach/reach';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReachProvider value={reachService}>
        <Suspense fallback={<div>Loading...</div>}>
          <UserProvider>
            <FormatProvider>
              <App />
            </FormatProvider>
          </UserProvider>
        </Suspense>
      </ReachProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// todo: Temp fix for admins to take them to .no domain
if (window.location.href.includes('aptly.as')) {
  window.location.href = window.location.href.replace('aptly.as', 'aptly.no');
}

unregister();
