import { AptlyInquiry, AptlyInquiryStatus, AptlyFieldType } from '@aptly-as/types';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import { useUnitSchemaField } from '../Unit/unit.schema.js';
import { inquiryStatusOptions } from './InquiryUtils';

export interface IInquirySchema extends AptlyInquiry {
  message?: string;
  files?: File[];
}

export function useInquirySchema(): ICrudSchema<IInquirySchema> {
  return {
    status: {
      type: AptlyFieldType.Select,
      defaultValue: AptlyInquiryStatus.Closed,
      label: i18n.t('singles.status'),
      options: inquiryStatusOptions(),
    },
    unit: useUnitSchemaField<IInquirySchema>({
      required: true,
    }),
    title: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.title'),
      required: true,
      autoFocus: true,
    },
    message: {
      type: AptlyFieldType.Markdown,
      defaultValue: '',
      label: i18n.t('singles.message'),
      required: true,
    },
    files: {
      type: AptlyFieldType.Files,
      defaultValue: [],
      label: i18n.t('singles.files'),
    },
  };
}
