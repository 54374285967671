import ErrorBoundary from '../../components/ErrorBoundary';
import i18n from '../../libraries/i18n';
import { DrawerMenu } from '../Nav/DrawerMenu';
import Header from '../Nav/Header';
import { Main } from '../../components/Main';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import { NavBackItem } from '../Nav/NavBackItem';
import { NavDrawer } from '../Nav/NavDrawer';
import SidebarRoutes from '../Nav/SidebarRoutes';
import { useAdminRoutes } from './admin.routes';

function Admin() {
  const routes = useAdminRoutes();

  return (
    <>
      <Header breadcrumbs={[]} />
      <NavDrawer>
        <NavBackItem to="/" primary={i18n.t('singles.organizations')} />
        <DrawerMenu routes={routes} level={SlugLevel.Admin} injection="organization" />
      </NavDrawer>
      <Main>
        <ErrorBoundary>
          <SidebarRoutes routes={routes}></SidebarRoutes>
        </ErrorBoundary>
      </Main>
    </>
  );
}

export default Admin;
