import React from 'react';
import Chip from '@mui/material/Chip';
import { AptlyProduct } from '@aptly-as/types';
import ReferenceInput from '../../components/ReferenceInput';
import Search from '../../deprecated/Search/Search';
import EmptyState from '../../containers/Error/EmptyState';
import Grid from '../../mui/Grid';
import Block from '../../components/Block';
import Button from '../../mui/Button';
import { ModalActions, ModalContent, ModalForm, ModalTitle } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { rawSpacing } from '../../utils/spacing';
import List, { ListItem } from '../../components/List/List';
import Divider from '../../mui/Divider';

import createMediaURL from '../Media/createMediaURL';
import Avatar from '../../components/Avatar';
import i18n from '../../libraries/i18n';
import { Producer } from '../Producer/producer.types';

type ChooseProductProps = {
  onClose?: () => any;
  onSelect: (arg0: AptlyProduct) => any;
  organizationSlug?: string;
  projectID?: string;
};

export default function ChooseProductOld({
  onClose,
  onSelect,
  organizationSlug,
  projectID,
}: ChooseProductProps) {
  let endpoint = ``;
  if (organizationSlug) {
    endpoint += `organizations/${organizationSlug}/`;
  }
  if (projectID) {
    endpoint += `projects/${projectID}/`;
  }
  const query = React.useMemo(() => ({ sort: '-project,-organization' }), []);

  return (
    <ModalForm fullScreen>
      <ModalTitle>{i18n.t('actions.searchProducts')}</ModalTitle>
      <ModalContent>
        <Search endpoint={`${endpoint}products/search`} query={query}>
          {({ busy, results, hasQueried, query }) => {
            return (
              <React.Fragment>
                <Block $compact>
                  <Grid container spacing={rawSpacing}>
                    <Grid item sm={12} md={4}>
                      <TextField
                        autoFocus
                        label={i18n.t('singles.productName')}
                        fullWidth
                        type="text"
                        onChange={(e: any) => query('name', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        label={i18n.t('singles.productNumber')}
                        fullWidth
                        type="text"
                        onChange={(e: any) => query('productNumber', e.target.value)}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <ReferenceInput<Producer>
                        label={i18n.t('singles.producer')}
                        endpoint="producers/search"
                        labelKey="name"
                        queryKey="name"
                        singular
                        onSelect={(items) => {
                          if (items.length > 0) {
                            query('producer', items[0]._id);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Block>
                {!busy && hasQueried && results.length < 1 ? (
                  <EmptyState tiny>{i18n.t('statuses.cantFindAnything')}!</EmptyState>
                ) : (
                  <List>
                    {results.map((r) => {
                      const hasProducer = Boolean(r.producer);
                      const image = r.images.find((x: any) => x.featured) || r.images[0];
                      let secondaryText = (
                        <React.Fragment>
                          <span>
                            {i18n.t('singles.productNumber')}: {r.productNumber}
                          </span>
                          {hasProducer && (
                            <span>
                              <br />
                              {i18n.t('singles.producer')}: {r.producer!.name}
                            </span>
                          )}
                          {r.variants && r.variants.length > 0 ? (
                            <span>
                              <br />
                              {i18n.t('singles.variants')}: {r.variants.length} (
                              {r.variants.map((x: any) => x.productNumber).join(',')})
                            </span>
                          ) : null}
                          {r.project || r.organization ? <br /> : null}
                          {r.project ? <Chip label={i18n.t('singles.projectProduct')} /> : null}
                          {!r.project && r.organization ? (
                            <Chip label={i18n.t('singles.organizationProduct')} />
                          ) : null}
                        </React.Fragment>
                      );

                      return (
                        <React.Fragment key={r._id}>
                          <ListItem
                            icon={
                              <Avatar
                                src={image && createMediaURL(image.image, { width: 80 })}
                                alt={r.name}
                              />
                            }
                            onClick={() => {
                              onSelect(r as any);

                              if (typeof onClose === 'function') {
                                onClose();
                              }
                            }}
                            button
                            key={r._id}
                            secondaryText={secondaryText}
                          >
                            {r.name}
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </List>
                )}
              </React.Fragment>
            );
          }}
        </Search>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>{i18n.t('actions.cancel')}</Button>
      </ModalActions>
    </ModalForm>
  );
}
