import { AptlyProject } from '@aptly-as/types';
import { IUseCrudActions, IUseFieldRet } from '@ewb/reach-react';
import { createContext, useContext } from 'react';
import { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { ISimpleCrudModalProps } from '../../components/simple/SimpleCrud';
import { IProjectSchema } from './project.schema';

type FieldProps = IUseFieldRet<IProjectSchema, IApiError, ICrudFieldData<IProjectSchema>>;

export interface ProjectContextActions extends IUseCrudActions {
  spawnPatch: (
    fields: (keyof IProjectSchema)[],
    props?: Partial<ISimpleCrudModalProps<IProjectSchema>>
  ) => void;
}

export interface IProjectContext extends Omit<FieldProps, 'actions'> {
  data: AptlyProject;
  actions: ProjectContextActions;
}

export const ProjectContext = createContext<IProjectContext>({} as any);

export function useProject() {
  const { data } = useContext(ProjectContext);
  return data;
}
