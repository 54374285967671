import { Grid2, Grid2Props } from '@mui/material';
import { GridProps } from '@mui/material/Grid';
import { ListProps } from '@mui/material/List';
import { PropsWithChildren } from 'react';
import Grid from '../../mui/Grid';
import List, { ListItem, ListItemProps } from '../../components/List/List';
import { ScopeProps } from './scope.utils';
import { useScope } from './ScopeContext';

export function FragmentScope({ scope, crud = 'R', every, children }: PropsWithChildren<ScopeProps>) {
  const _scope = useScope();
  return _scope.crud(scope, crud, every) ? <>{children}</> : null;
}

export function ListScope({ scope, crud, children, every, ...props }: ScopeProps & ListProps) {
  const _scope = useScope();
  return _scope.crud(scope, crud, every) ? <List {...props}>{children}</List> : null;
}

export function ListItemScope({ scope, crud = 'R', every, children, ...props }: ListItemProps & ScopeProps) {
  const _scope = useScope();
  return _scope.crud(scope, crud, every) ? <ListItem {...props}>{children}</ListItem> : null;
}

export function LayoutScope({ scope, crud, every, ...props }: GridProps & ScopeProps) {
  const _scope = useScope();
  return _scope.crud(scope, crud, every) ? <Grid {...props} /> : null;
}

export function Grid2Scope({ scope, crud, every, ...props }: Grid2Props & ScopeProps) {
  const _scope = useScope();
  return _scope.crud(scope, crud, every) ? <Grid2 {...props} /> : null;
}
