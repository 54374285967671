import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactNode, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useMobile } from '../../hooks/useMobile';
import { NavContext } from './NavContext';

interface Props {
  to: string;
  primary: string;
}

export function NavBackItem({ to, primary }: Props): ReactNode {
  const { toggle } = useContext(NavContext);
  const isMobile = useMobile();
  if (!isMobile) return null;

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to={to} onClick={toggle}>
            <ListItemIcon>
              <ArrowBackIcon />
            </ListItemIcon>
            <ListItemText primary={primary} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </>
  );
}
