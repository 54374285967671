import { AptlyAlgorithm, AptlyFieldType, AptlyMediaSrc, AptlyScopes } from '@aptly-as/types';
import i18n from '../../../libraries/i18n';
import { acceptImages } from '../../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../../libraries/scope/Scope';
import { ICrudField, ICrudFieldFile } from '../utils/crud.utils';
import { preValueToId } from './schema.utils';

export const schemaBulkIds = <T extends object>(): ICrudField<T, string[] | undefined> => ({
  type: AptlyFieldType.BulkIds,
  defaultValue: undefined,
  label: '',
});

export const schemaName = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.name'),
  required: true,
  ...p,
});

export const schemaDescription = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Markdown,
  defaultValue: '',
  label: i18n.t('singles.description'),
  ...p,
});

export const schemaEmail = <T extends object>({
  label = i18n.t('singles.email'),
  required = true,
  ...rest
}: Partial<ICrudField<T, string>> = {}): ICrudField<T, string> => ({
  type: AptlyFieldType.Email,
  defaultValue: '',
  autoComplete: 'email',
  label,
  required,
  ...rest,
});

export const schemaPhone = <T extends object>(
  p: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string> => ({
  type: AptlyFieldType.Text,
  defaultValue: '',
  label: i18n.t('singles.phoneNumber'),
  autoComplete: 'tel',
  ...p,
});

export const schemaMediaImage = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Image,
  defaultValue: null,
  label: i18n.t('singles.image'),
  ...p,
});

export const schemaMediaLogo = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Media,
  defaultValue: null,
  label: i18n.t('singles.logo'),
  image: {
    width: 320,
    accept: acceptImages,
    opts: {
      disableFullWidth: true,
      style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
      transforms: 'c_fit',
    },
  },
  ...p,
});

export const schemaMediaIcon = <T extends object>(
  p: Partial<ICrudField<T, AptlyMediaSrc | null>> = {}
): ICrudField<T, AptlyMediaSrc | null> => ({
  type: AptlyFieldType.Media,
  defaultValue: null,
  label: i18n.t('singles.icon'),
  image: {
    width: 160,
    accept: acceptImages,
    opts: {
      disableFullWidth: true,
      style: { maxHeight: '160px', maxWidth: '160px', width: 'auto' },
      transforms: 'c_fit',
    },
  },
  ...p,
});

export const schemaFile = <T extends object>(
  accept?: ICrudFieldFile['accept'],
  props: Partial<ICrudField<T, File | null>> = {}
): ICrudField<T, File | null> => ({
  type: AptlyFieldType.File,
  defaultValue: null,
  label: i18n.t('singles.file'),
  file: { accept },
  ...props,
});

export const schemaColor = <T extends object>(p: Partial<ICrudField<T, string>>): ICrudField<T, string> => ({
  label: i18n.t('singles.NCSColorCode'),
  defaultValue: '',
  ...p,
  type: AptlyFieldType.Color,
});

export const schemaSelectAlgorithm = <T extends object>(
  algorithms: AptlyAlgorithm[],
  props: Partial<ICrudField<T, string>> = {}
): ICrudField<T, string | null> => ({
  type: AptlyFieldType.Select,
  defaultValue: null,
  options: [
    { value: null, label: i18n.t('statuses.nothing') },
    ...algorithms.map((x) => ({ value: x._id, label: x.name })),
  ],
  label: i18n.t('singles.calculation'),
  preValue: preValueToId,
  renderValidate: () => Scope.crud([AptlyScopes.OrganizationAlgorithms, AptlyScopes.ProjectAlgorithms], 'R'),
  ...props,
});
