import { AptlyModuleItem } from '@aptly-as/types/models/module';
import { IUseSearchProps } from '@ewb/reach-react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useCallback, useContext, useMemo } from 'react';
import { AptlyErrorBody, AptlyModule, AptlyScopes, AptlySearchPaginateResponse } from '@aptly-as/types';
import { ShowMeMoreIconButton } from '../../components/actions/icons/Icons';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import { useModal } from '../../containers/Modal/Modal';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import BusyState from '../../components/BusyState';
import Section from '../../components/Section';
import { matchId } from '../../libraries/mongoose';
import { reach } from '../../libraries/reach/reach';
import i18n from '../../libraries/i18n';
import ApiError from '../../components/ApiError';
import { useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import { OrganizationContext } from '../Organization/OrganizationContext';
import ModuleCard from './ModuleCard';

export interface ModulesProps {}

const reachProps: IUseSearchProps<AptlyModule, AptlyErrorBody, AptlySearchPaginateResponse<AptlyModule>> = {
  limit: 30,
};

export default function Modules({}: ModulesProps) {
  const org = useContext(OrganizationContext);
  const url = useApiUrl(SlugLevel.Organization, 'modules');
  const [busy, modules, error, next, info] = useSimpleSearch(`${url}/search`, reachProps);

  const handleOnInstall = useModal<AptlyModule>(
    useCallback(
      (module) => (
        <ConfirmModal
          title={module.name}
          actionLabel={i18n.t('actions.activate')}
          onConfirm={async () => {
            const newModule = await reach.api<AptlyModuleItem>(`${url}/${module._id}/install`, {
              method: 'POST',
            });
            if (newModule) {
              org.setData(
                {
                  installedModules: [...org.data.installedModules, newModule],
                },
                {},
                false
              );
            }
          }}
        >
          <Typography>Installer?</Typography>
        </ConfirmModal>
      ),
      [url, org]
    )
  );

  const handleOnContact = useModal<AptlyModule>(
    useCallback(
      (module) => (
        <ConfirmModal
          title={module.name}
          actionLabel={i18n.t('actions.contactUs')}
          onConfirm={async () => {
            await reach.api<AptlyModuleItem>(`${url}/${module._id}/contact`, {
              method: 'POST',
            });
          }}
        >
          <Typography>
            Dette vil sende en mail til support@aptly.no om at du er interessert i tillegstjenesten{' '}
            {module.name}. Denne eposten inneholder navn på modul, organisasjon, ditt navn og e-post.
            <br />
            Vi vil kontakte deg så fort som mulig for mer info.
          </Typography>
        </ConfirmModal>
      ),
      [url, org]
    )
  );

  const handleOnUnInstall = useModal<AptlyModule>(
    useCallback(
      (module) => (
        <ConfirmModal
          title={module.name}
          onConfirm={async () => {
            await reach.api(`${url}/${module._id}/uninstall`, {
              method: 'DELETE',
            });
            org.setData(
              {
                installedModules: org.data.installedModules.filter((m) => !matchId(m.module, module)),
              },
              {},
              false
            );
          }}
          actionLabel={i18n.t('actions.deactivate')}
        >
          <Typography>Installer?</Typography>
        </ConfirmModal>
      ),
      [url, org]
    )
  );

  if (error) {
    return <ApiError error={error} />;
  }
  if (busy) {
    return <BusyState title={i18n.t('singles.modules')} />;
  }

  return (
    <>
      <Section>
        <Grid container>
          <Typography variant="h1">{i18n.t('singles.modules')}</Typography>
        </Grid>
      </Section>
      <Section>
        <Grid container spacing={2}>
          {modules.map((module) => (
            <Grid key={module._id} item xl={4} md={6} xs={12}>
              <ModuleItem
                module={module}
                onInstall={handleOnInstall}
                onUnInstall={handleOnUnInstall}
                onContact={handleOnContact}
              />
            </Grid>
          ))}
        </Grid>
        {modules.length < info.count && (
          <Grid container justifyContent="center">
            <ShowMeMoreIconButton onClick={() => next()} disabled={busy} />
          </Grid>
        )}
      </Section>
    </>
  );
}

interface ModuleItemProps {
  module: AptlyModule;
  onInstall: (module: AptlyModule) => void;
  onUnInstall: (module: AptlyModule) => void;
  onContact: (module: AptlyModule) => void;
}

function ModuleItem({ module, onInstall, onUnInstall, onContact }: ModuleItemProps) {
  const org = useContext(OrganizationContext);
  const handleOnInstall = useCallback(() => onInstall(module), [onInstall, module]);
  const handleOnUnInstall = useCallback(() => onUnInstall(module), [onUnInstall, module]);
  const handleOnContact = useCallback(() => onContact(module), [onContact, module]);
  const isInstalled = useMemo(
    () => org.data.installedModules.find((x) => matchId(x.module, module)),
    [org, module]
  );

  return (
    <ModuleCard module={module}>
      {!isInstalled && (
        <Button size="small" onClick={handleOnContact} disabled={!!isInstalled}>
          {i18n.t('actions.contactUs')}
        </Button>
      )}
      <FragmentScope scope={AptlyScopes.Admin}>
        <Button size="small" onClick={handleOnInstall} disabled={!!isInstalled}>
          {isInstalled ? i18n.t('statuses.activated') : i18n.t('actions.activate')}
        </Button>
        {isInstalled && (
          <Button size="small" onClick={handleOnUnInstall}>
            {i18n.t('actions.deactivate')}
          </Button>
        )}
      </FragmentScope>
    </ModuleCard>
  );
}
