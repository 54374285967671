import { AptlyPage, AptlyPageSection, AptlyScopes } from '@aptly-as/types';
import { useGet } from '@ewb/reach-react';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SelectButton from '../../components/actions/buttons/SelectButton';
import { DeleteIconButton, EditIconButton } from '../../components/actions/icons/Icons';
import Section from '../../components/Section';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import ClientIFrame from '../../containers/Client/ClientIFrame';
import handleError from '../../containers/Error/handleError';
import { createModal } from '../../containers/Modal/ModalContext';
import deleteResource from '../../containers/Notification/deleteResource';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import {
  SortableIcon,
  SortableProvider,
  SortableTableBody,
  SortableTableRow,
  useSortableItems,
} from '../../libraries/dnd/Sortable';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import Paper from '../../mui/Paper';
import { HeaderProgresser } from '../../mui/Progresser';
import Table, { TableCell, TableFooter, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { ProjectContext } from '../Project/ProjectContext';
import { getPageSectionSchema } from './page.schema';
import Grid from '../../mui/Grid';
import i18n from '../../libraries/i18n';
import { pageSectionOptionLabel, pageSectionOptions } from './page.utils';

export default function Page() {
  const { pageID } = useParams<{ pageID: string }>();
  const pagesEndpoint = useGetApiUrl('project', '/pages');
  const pageEndpoint = `${pagesEndpoint}/${pageID}`;
  const [state, , pageSetSate] = useGet<AptlyPage>(pageEndpoint);

  const setPageState = useCallback(
    (page: Partial<AptlyPage>, busy = false) => {
      pageSetSate((s) => ({ ...s, busy, data: { ...s.data!, ...page } }));
    },
    [pageSetSate]
  );

  if (!state.data) return null;

  return (
    <>
      {state.busy && <HeaderProgresser />}
      <Content pageEndpoint={pageEndpoint} page={state.data} setPageState={setPageState} />
    </>
  );
}

interface ContentProps {
  pageEndpoint: string;
  page: AptlyPage;
  setPageState: (page: Partial<AptlyPage>, busy?: boolean) => void;
}

function Content({ pageEndpoint, page, setPageState }: ContentProps) {
  const { pageID } = useParams<{ pageID: string }>();
  const org = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const sortableItems = useSortableItems(page.sections || []);
  const scope = useScopeLevel({
    project: AptlyScopes.ProjectPages,
    organization: AptlyScopes.OrganizationPages,
    base: AptlyScopes.Admin,
  });

  let previewEndpoint = `organizations/${org.data.slug}`;
  if (project.data) {
    previewEndpoint += `/projects/${project.data._id}`;
  }

  const onClickCrud = useCallback(
    (data: Partial<AptlyPageSection> & Pick<AptlyPageSection, 'type'>) => {
      createModal(
        <SimpleModal<AptlyPageSection, AptlyPage>
          path={`${pageEndpoint}/sections`}
          data={data}
          useFieldsProps={{ forcePatch: ['type', 'name', 'value'] }}
          schema={getPageSectionSchema(data.type)}
          fields={['type', 'name', 'value']}
          title={data?.name || pageSectionOptionLabel(data.type)}
          onEdit={(d) => setPageState(d)}
        />
      );
    },
    [pageEndpoint, setPageState]
  );

  const deleteSection = useCallback(
    (section: AptlyPageSection) => {
      return deleteResource(`${pageEndpoint}/sections/${section._id}`, () => {
        setPageState({
          sections: page.sections.filter((x) => x._id !== section._id) || [],
        });
      });
    },
    [page, setPageState]
  );

  const handleOnDragEnd = useCallback(
    (sections: AptlyPageSection[]) => {
      const oldSections: AptlyPageSection[] = [...page.sections];
      setPageState({ sections }, true);
      return simpleRequest({
        endpoint: `${pageEndpoint}/order`,
        method: 'POST',
        data: { ids: sections.map((x) => x._id) },
        onRequestDone: () => {
          setPageState({}, false);
        },
        onError: (e) => {
          handleError(e);
          setPageState({ sections: oldSections }, true);
        },
      });
    },
    [page, setPageState]
  );

  return (
    <>
      <Section>
        <Grid container justifyContent="space-between">
          <Typography variant="h1">{page ? page.name : ''}</Typography>
          <ClientIFrame endpoint={`${previewEndpoint}/page/${pageID}/`} />
        </Grid>
      </Section>
      <Section>
        <Paper>
          <SortableProvider items={sortableItems} onDragEnd={handleOnDragEnd}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={50} />
                  <TableCell>{i18n.t('singles.name')}</TableCell>
                  <TableCell width={150}>{i18n.t('singles.type')}</TableCell>
                  <TableCell width={120} align="right">
                    {i18n.t('singles.actions')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <SortableTableBody id="page-sections" items={sortableItems}>
                {page.sections.map((section) => (
                  <PageSectionSortable
                    key={section._id}
                    section={section}
                    onEdit={onClickCrud}
                    onDelete={deleteSection}
                  />
                ))}
              </SortableTableBody>
              <FragmentScope scope={scope} crud="U">
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Grid container justifyContent="flex-end" gap={2}>
                        <Grid item>
                          <SelectButton
                            title={i18n.t('actions.newSection')}
                            options={pageSectionOptions()}
                            onChange={(type) => {
                              onClickCrud({ type });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </FragmentScope>
            </Table>
          </SortableProvider>
        </Paper>
      </Section>
    </>
  );
}

interface PageSectionSortableProps {
  section: AptlyPageSection;
  onEdit?: (section: AptlyPageSection) => void;
  onDelete?: (section: AptlyPageSection) => void;
}

function PageSectionSortable({ section, onEdit, onDelete }: PageSectionSortableProps) {
  const handleOnDelete = useCallback(() => onDelete!(section), [onDelete, section]);
  const handleOnEdit = useCallback(() => onEdit!(section), [onEdit, section]);

  return (
    <SortableTableRow id={section._id}>
      <TableCell>
        <SortableIcon id={section._id} />
      </TableCell>
      <TableCell>{section.name}</TableCell>
      <TableCell>{pageSectionOptionLabel(section.type)}</TableCell>
      <TableCell>
        <Grid container justifyContent="flex-end" gap={2}>
          <Grid item>
            <EditIconButton onClick={handleOnEdit} />
          </Grid>
          <Grid item>
            <DeleteIconButton onClick={handleOnDelete} />
          </Grid>
        </Grid>
      </TableCell>
    </SortableTableRow>
  );
}
