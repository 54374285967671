import { AptlyAlgorithm } from '@aptly-as/types';
import Decimal from 'decimal.js';
import { useFormat } from '../../containers/Format/Format.js';
import { Algorithm } from './algorithm.pipeline.js';

export function useAlgorithmFinalAmount(
  algorithm?: AptlyAlgorithm | string,
  baseCost = 0,
  reverse?: boolean
) {
  if (!algorithm || typeof algorithm === 'string') return baseCost * 100;
  const steps = getAlgorithmSteps(algorithm, baseCost, reverse);
  const cost = steps.length > 0 ? steps[steps.length - 1].amount : baseCost * 100;
  return reverse ? new Decimal(cost).toDP(2).toNumber() : Math.ceil(cost);
}

export function useAlgorithmVisual(algorithm: AptlyAlgorithm, baseCost: number) {
  const format = useFormat();
  const steps = getAlgorithmSteps(algorithm, baseCost);
  return steps
    .map((x) => {
      return `${format.amount(x.amount)} (${x.label})`;
    })
    .join(' > ');
}

export function getAlgorithm(algorithm?: AptlyAlgorithm, baseCost: number = 0, reverse?: boolean) {
  if (!algorithm) {
    return new Algorithm(undefined, 25).amount(baseCost * 100).quantity(1);
  }
  const reversed = reverse ?? algorithm.reversed;
  return new Algorithm({ ...algorithm, reversed }, reversed ? 25 : 0).amount(baseCost * 100).quantity(1);
}

export function getAlgorithmSteps(algorithm?: AptlyAlgorithm, baseCost: number = 0, reverse?: boolean) {
  const algo = getAlgorithm(algorithm, baseCost, reverse);
  return algo.getPipeline();
}
