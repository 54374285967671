import { useFields } from '@ewb/reach-react';
import { PropsWithChildren, useMemo } from 'react';
import ApiError, { IApiError } from '../../components/ApiError.js';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { OfferSchema, useOfferSchema } from './offer.schema.js';
import { getOfferLevelQuery, OfferContext } from './OfferContext.js';

interface IOfferContextProps {
  id: string;
  level: SlugLevel;
}

export function OfferProvider({ children, id, level }: PropsWithChildren<IOfferContextProps>) {
  const path = useApiUrl(SlugLevel.Project, 'offers');
  const schema = useOfferSchema(level);
  const crud = useFields<OfferSchema, IApiError, ICrudFieldData<OfferSchema>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    schema,
    useMemo(
      () => ({
        idKey: '_id',
        initWithGet: true,
        reachOptions: {
          query: {
            $populate: 'order:_id,orderNumber,status,paymentSession;unit:_id,name;createdBy:fullName',
            level: getOfferLevelQuery(level),
          },
        },
      }),
      [level]
    )
  );

  if (crud.state.error) return <ApiError error={crud.state.error} />;
  if (!crud.state.data.name) return null;

  const isTemplate = !crud.state.data.project;
  const canEdit = !crud.state.data.sentAt && (!!crud.state.data.project || level === SlugLevel.Organization);

  return (
    <OfferContext.Provider value={{ data: crud.state.data, crud, canEdit, level, isTemplate }}>
      {children}
    </OfferContext.Provider>
  );
}
