import { AptlyOrder, AptlyOrderSigneeType, AptlyOrderStatus, AptlyUnit } from '@aptly-as/types';
import { Alert, Grid } from '@mui/material';
import type { ChartData } from 'chart.js';
import Decimal from 'decimal.js';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import BusyState from '../../components/BusyState';
import BusyWrapper from '../../components/BusyWrapper';
import EmptyState from '../../containers/Error/EmptyState';
import ErrorState from '../../containers/Error/ErrorState';
import useApiRequest from '../../hooks/useApiRequest';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import { HideMobileFragment } from '../../hooks/useMobile';
import { ChartLine } from '../../libraries/chart-js';
import i18n from '../../libraries/i18n';
import { intlCurrency } from '../../libraries/intl';
import Button from '../../mui/Button';
import Card, { CardContent, CardHeader } from '../../mui/Card';
import { PaperPadded } from '../../mui/Paper';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';

import { primary } from '../../utils/themes';
import { UserContext } from '../User/UserContext';
import OrdersTableRow, { OrdersTableHead } from './OrdersTable';

const SIGNED_STATUS = [AptlyOrderStatus.Signed, AptlyOrderStatus.FileReceived, AptlyOrderStatus.Completed];

export default function ProjectDashboardOrders() {
  const apiUrl = useApiUrl(SlugLevel.Project);
  const { data } = useContext(UserContext);
  const notSignedTo = useTo(SlugLevel.Project, `/ordre?status=${AptlyOrderStatus.ReadyToSign}`);
  const [busy, orders, error, refresh] = useApiRequest<AptlyOrder[]>(
    `${apiUrl}/orders`,
    useMemo(
      () => ({
        data: {
          archived: false,
          sort: 'createdAt',
          as: 'array',
          limit: 999,
          $populate: 'unit:_id,name',
          select:
            '_id orderNumber unit status totalCost signees created createdAt signage receipt signedReceipt',
          $nin_status: AptlyOrderStatus.Invalid,
        },
      }),
      []
    )
  );
  const ordersToHandle = useMemo(() => {
    if (orders) {
      return orders.filter((x) => {
        if ([AptlyOrderStatus.New, AptlyOrderStatus.SignedByCustomer].includes(x.status)) return true;
        if ([AptlyOrderStatus.NotSigned, AptlyOrderStatus.ReadyToSign].includes(x.status)) {
          return x.signees?.some((x) => !x.signedAt && x.type === AptlyOrderSigneeType.Admin);
        }
        return false;
      });
    }
    return [];
  }, [orders, data]);

  const waitForCustomerSign = useMemo(() => {
    if (!orders) return [];
    return orders.filter(
      (x) =>
        x.status === AptlyOrderStatus.ReadyToSign &&
        x.signees?.some((x) => !x.signedAt && x.type === AptlyOrderSigneeType.Unit)
    );
  }, [orders]);

  const chartData = useMemo((): {
    average: number;
    completed: number;
    confirmed: number;
    lineData: ChartData<'line', { x: string; y: number }[], string>;
    total: number;
  } => {
    let labels: string[] = [];
    if (!orders || orders.length === 0) {
      return { average: 0, completed: 0, confirmed: 0, lineData: { labels, datasets: [] }, total: 0 };
    }
    const data: { x: string; y: number }[] = [];
    let confirmedCost = 0;
    let completedCost = 0;
    let totalCost = 0;
    labels = [orders[0].createdAt, orders[orders.length - 1].createdAt];
    for (const order of orders) {
      const isCompleted = SIGNED_STATUS.includes(order.status);
      totalCost += order.totalCost;
      data.push({ x: order.createdAt, y: totalCost });
      if (isCompleted) {
        completedCost += order.totalCost;
      } else {
        confirmedCost += order.totalCost;
      }
    }
    const total = completedCost + confirmedCost;
    return {
      average: new Decimal(total).dividedBy(orders.length).toDP(2).toNumber(),
      completed: completedCost,
      confirmed: confirmedCost,
      total,
      lineData: {
        labels,
        datasets: [
          {
            label: `${data.length} ${i18n.t('statuses.completed')}`,
            data,
            borderColor: primary,
            backgroundColor: primary,
          },
        ],
      },
    };
  }, [orders]);

  if (error) {
    return <ErrorState error={error} />;
  }

  if (!orders && busy) {
    return <BusyState title={i18n.t('singles.orders')} />;
  }

  if (!orders || (orders && orders.length === 0)) {
    return (
      <PaperPadded>
        <EmptyState tiny>{i18n.t('statuses.noOrders')}</EmptyState>
      </PaperPadded>
    );
  }

  return (
    <BusyWrapper $busy={busy}>
      <Card>
        <CardHeader title={<Typography variant="h2">{i18n.t('singles.orders')}</Typography>} />
        <CardContent>
          <Grid container spacing={2}>
            <HideMobileFragment>
              <Grid item xl={10} lg={8} md={7} xs={12}>
                <PaperPadded>
                  <ChartLine
                    height={220}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          time: {
                            minUnit: 'month',
                          },
                          type: 'time',
                        },
                        y: {
                          position: 'right',
                        },
                      },
                    }}
                    data={chartData.lineData}
                  />
                </PaperPadded>
              </Grid>
            </HideMobileFragment>
            <Grid item xl={2} lg={4} md={5} xs={12}>
              <PaperPadded>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{i18n.t('singles.field')}</TableCell>
                      <TableCell align="right">{i18n.t('singles.value')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{i18n.t('singles.orders')}</TableCell>
                      <TableCell align="right">{orders.length}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t('statuses.confirmed')}</TableCell>
                      <TableCell align="right">{intlCurrency(chartData.confirmed)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t('statuses.completed')}</TableCell>
                      <TableCell align="right">{intlCurrency(chartData.completed)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t('singles.total')}</TableCell>
                      <TableCell align="right">{intlCurrency(chartData.total)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{i18n.t('singles.average')}</TableCell>
                      <TableCell align="right">{intlCurrency(chartData.average)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </PaperPadded>
            </Grid>
          </Grid>
          <HideMobileFragment>
            <Grid container flexDirection="column" gap={2} sx={{ marginTop: (theme) => theme.spacing(2) }}>
              {waitForCustomerSign.length > 0 && (
                <Grid item xs={12} md={4}>
                  <Alert
                    severity={getSeverityByLength(waitForCustomerSign.length)}
                    action={
                      <Button color="inherit" size="small" component={Link} to={notSignedTo}>
                        {i18n.t('actions.goToOrder')}
                      </Button>
                    }
                  >
                    {waitForCustomerSign.length
                      ? `${waitForCustomerSign.length} ${i18n.t('paragraphs.ordersReadyToSignToCustomer')}`
                      : i18n.t('paragraphs.ordersIsSigned')}
                  </Alert>
                </Grid>
              )}
              {ordersToHandle.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h3">{i18n.t('statuses.ordersReady')}</Typography>
                  <Alert severity={getSeverityByLength(ordersToHandle.length)}>
                    {i18n.t('order.handleWarning', { count: ordersToHandle.length })}
                  </Alert>
                  <Table>
                    <OrdersTableHead />
                    <TableBody>
                      {ordersToHandle.map((order) => (
                        <OrdersTableRow
                          key={order._id}
                          order={order}
                          unit={order.unit as AptlyUnit}
                          refresh={refresh}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </HideMobileFragment>
        </CardContent>
      </Card>
    </BusyWrapper>
  );
}

const getSeverityByLength = (length: number) => {
  if (length > 3) return 'error';
  if (length > 1) return 'warning';
  if (length > 0) return 'info';
  return 'success';
};
