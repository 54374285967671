import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Theme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import { SxProps } from '@mui/system';
import { Fragment, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile';
import { useScope } from '../../libraries/scope/ScopeContext';
import { NavContext } from './NavContext';
import {
  ISidebarListItem,
  ISidebarProviderProps,
  SidebarContext,
  SidebarInjection,
  SidebarList,
  SidebarProvider,
} from './sidebar.utils';

interface DrawerMenuProps extends ISidebarProviderProps {
  routes: SidebarList<SidebarInjection>[];
}

export function DrawerMenu({ level, injection, routes }: DrawerMenuProps): ReactNode {
  const filter = useRouteFilter();

  const filteredRoutes = useMemo(() => {
    return routes
      .map((route) => ({
        ...route,
        items: route.items.map(toFilteredItems(filter)).filter(filter),
      }))
      .filter(filter);
  }, [routes, filter]);

  return (
    <SidebarProvider level={level} injection={injection}>
      {filteredRoutes.map((route, i) => (
        <Fragment key={getKey(route, i)}>
          <MenuList route={route} />
          <Divider />
        </Fragment>
      ))}
    </SidebarProvider>
  );
}

interface RouteListProps {
  route: SidebarList<SidebarInjection>;
}

function MenuList({ route }: RouteListProps): ReactNode {
  const injectedItems = useRouteInjections(route.injection);
  const items = [...route.items, ...injectedItems];

  return (
    <List
      subheader={
        route.subheader ? <ListSubheader color="primary">{route.subheader}</ListSubheader> : undefined
      }
    >
      {items.map((item, i) => {
        if ('items' in item) return <MenuExpand key={getKey(item, i)} route={item} />;
        return <MenuListItem key={getKey(item, i)} route={item} />;
      })}
    </List>
  );
}

function MenuExpand({ route }: RouteListProps) {
  const [show, setShow] = useState(false);
  const toggle = useCallback(() => setShow((s) => !s), []);
  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={show ? <ExpandLess onClick={toggle} /> : <ExpandMore onClick={toggle} />}
      >
        <ListItemButton onClick={toggle}>
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.label} />
        </ListItemButton>
      </ListItem>
      <Collapse in={show} timeout="auto" unmountOnExit sx={collapseSX}>
        <MenuList route={route} />
      </Collapse>
    </>
  );
}

const collapseSX: SxProps<Theme> = {
  borderLeft: (theme) => `${theme.spacing(1)} solid ${theme.palette.primary.main}`,
};

function MenuListItem({ route }: { route: ISidebarListItem }): ReactNode {
  const { toggle } = useContext(NavContext);
  const { level } = useContext(SidebarContext);
  const location = useLocation();
  const to = useTo(level, `/${route.to || route.path}`);
  return (
    <ListItem disablePadding>
      <ListItemButton component={NavLink} to={to} selected={location.pathname === to} onClick={toggle}>
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText primary={route.label} />
      </ListItemButton>
    </ListItem>
  );
}

function getKey(route: ISidebarListItem | SidebarList<SidebarInjection>, i: number): string {
  if ('items' in route) {
    return `${route.injection}-${i}`;
  }
  return `${route.label}-${i}`;
}

function useRouteInjections(injectionMenu: SidebarInjection): ISidebarListItem[] {
  const { menus } = useContext(SidebarContext);
  const scope = useScope();
  return useMemo(() => {
    const injections: ISidebarListItem[] = [];
    for (const injection of menus) {
      if (injectionMenu === injection.menu && scope.crud(injection.model)) {
        injections.push({
          scope: injection.model,
          label: injection.label,
          path: `apps/${injection.app}/${injection._id}`,
          element: <></>,
          icon: injection.icon ? (
            <Avatar
              sx={{ marginLeft: '2px', height: 30, width: 30 }}
              variant="square"
              src={injection.icon?.url || injection.icon?.src}
              alt={injection.label}
              imgProps={{ style: { objectFit: 'contain' } }}
            >
              {injection.label[0]}
            </Avatar>
          ) : undefined,
        });
      }
    }
    return injections;
  }, [menus, injectionMenu, scope]);
}

function useRouteFilter() {
  const mobile = useMobile();
  const scope = useScope();
  return useCallback(
    (item: ISidebarListItem | SidebarList<SidebarInjection>) => {
      if ('items' in item && item.items.length === 0) return false;
      if (mobile && !item.mobile) return false;
      if (item.hide) return false;
      return scope.crud(item.scope, item.crud, item.every);
    },
    [mobile, scope]
  );
}

function toFilteredItems(filter: (item: ISidebarListItem | SidebarList<SidebarInjection>) => boolean) {
  return (
    item: ISidebarListItem | SidebarList<SidebarInjection>
  ): ISidebarListItem | SidebarList<SidebarInjection> => {
    if ('items' in item) {
      return {
        ...item,
        items: item.items.map(toFilteredItems(filter)).filter(filter),
      };
    }
    return item;
  };
}
