import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material';
import { AptlyFieldType, AptlyRecommendation } from '@aptly-as/types';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaColor, schemaName } from '../../components/crud/schema/fields.schema';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';

export const recommendationSchema = (): ICrudSchema<AptlyRecommendation> => ({
  name: schemaName({ autoFocus: true }),
  color: schemaColor({ required: true }),
  title: {
    type: AptlyFieldType.Text,
    defaultValue: '',
    label: i18n.t('singles.title'),
    required: true,
  },
  text: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.description'),
  },
  image: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    image: { mediaKey: 'imageMedia', width: 720, height: 350, accept: acceptImages },
  },
});

export function useRecommendationSchemaField<T extends object, V extends string | null>(
  override?: Partial<ICrudField<T, V>>
): ICrudField<T, V> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useRecommendationAutocompleteQueryProps,
    label: i18n.t('singles.recommendation'),
    defaultValue: null as V,
    ...override,
  };
}

export function useRecommendationAutocompleteQueryProps(): Omit<
  AptlyAutocompleteQueryProps<any, any, any>,
  'onChange' | 'defaultValue' | 'value'
> {
  return {
    path: useApiUrl(SlugLevel.Project, 'recommendations'),
    query: { archived: false, select: '_id,name' },
    multiple: false,
    queryOnFocus: true,
  };
}
