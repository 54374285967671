import { AptlyFieldType } from '@aptly-as/types';
import { Grid2 } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons.js';
import { ICrudSchema } from '../../components/crud/utils/crud.utils.js';
import CrudForm, { CrudFormSelect, CrudFormTimeField } from '../../components/simple/CrudForm.js';
import { HideMobileFragment, useMobile } from '../../hooks/useMobile.js';
import i18n from '../../libraries/i18n';
import { intlDateFormat } from '../../libraries/intl';
import Button from '../../mui/Button.js';
import { ModalActions, ModalContent, ModalHeader } from '../../mui/Dialog';
import Grid from '../../mui/Grid.js';
import Typography from '../../mui/Typography';
import { IBookingContext } from './BookingContext';
import BookingTimes from './BookingTimes';

interface BookingTimeAddProps {
  booking: IBookingContext;
  onClose?: () => void;
}

export function BookingTimeAdd({ booking, onClose }: BookingTimeAddProps) {
  const mobile = useMobile();
  const {
    calendarPath,
    getTimes,
    state: { date },
  } = booking;
  const activeDate = date ? date.toDate() : new Date();
  const handleTimeCreate = useCallback(() => {
    getTimes(date.toISOString());
    if (onClose) {
      onClose();
    }
  }, [getTimes, date, onClose]);
  const schema = useSchema(activeDate);

  return (
    <CrudForm path={`${calendarPath}/times`} data={{}} onEdit={handleTimeCreate} schema={schema}>
      <ModalHeader onClose={onClose} title={intlDateFormat(activeDate)}>
        {mobile ? (
          <Button variant="contained" type="submit">
            {i18n.t('actions.addTime')}
          </Button>
        ) : null}
      </ModalHeader>
      <ModalContent>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Grid container spacing={2} direction="column" justifyContent="space-between">
              <Grid item>
                <CrudFormTimeField name="from" />
              </Grid>
              <Grid item>
                <CrudFormTimeField name="to" />
              </Grid>
              <Grid item style={{ maxWidth: '100%' }}>
                <CrudFormSelect name="days" />
              </Grid>
              <Grid item>
                <CrudFormSelect name="repeat" />
              </Grid>
            </Grid>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Typography variant="subtitle1">{i18n.t('paragraphs.availableSlotsThisDay')}</Typography>
            <BookingTimes booking={booking} hideDate />
          </Grid2>
        </Grid2>
      </ModalContent>
      <HideMobileFragment>
        <ModalActions>
          <ActionButtons onClose={onClose} actionLabel={i18n.t('actions.addTime')} />
        </ModalActions>
      </HideMobileFragment>
    </CrudForm>
  );
}

function useSchema(activeDate: Date): ICrudSchema<{
  from: Dayjs;
  to: Dayjs;
  days: string[];
  repeat: number;
}> {
  return {
    from: {
      type: AptlyFieldType.Time,
      defaultValue: dayjs(activeDate).hour(8).minute(0).second(0),
      label: i18n.t('singles.from'),
      preOnChange: (value, crud) => {
        const from = dayjs(value);
        const to = crud.getField('to').value;
        if (from.isSame(to) || from.isAfter(to)) {
          crud.setField('to')(from.add(1, 'hour'));
        }
        return value;
      },
    },
    to: {
      type: AptlyFieldType.Time,
      defaultValue: dayjs(activeDate).hour(9).minute(0).second(0),
      label: i18n.t('singles.to'),
      preOnChange: (value, crud) => {
        const from = dayjs(crud.getField('from').value);
        if (from.isAfter(value)) {
          crud.setField('from')(dayjs(value).subtract(1, 'hour'));
        }
        return value;
      },
    },
    days: {
      type: AptlyFieldType.Select,
      defaultValue: [
        dayjs(activeDate || new Date())
          .day()
          .toString(),
      ],
      label: i18n.t('singles.days'),
      options: useDayOptions(),
      multiple: true,
    },
    repeat: {
      type: AptlyFieldType.Select,
      defaultValue: 0,
      label: i18n.t('singles.repeat'),
      options: [0, 1, 2, 3, 4].map((count) => ({
        value: count,
        label: `${count} ${i18n.t('singles.weeks', { count })}`,
      })),
    },
  };
}

function useDayOptions() {
  return [
    {
      value: '1',
      label: i18n.t('singles.monday'),
    },
    {
      value: '2',
      label: i18n.t('singles.tuesday'),
    },
    {
      value: '3',
      label: i18n.t('singles.wednesday'),
    },
    {
      value: '4',
      label: i18n.t('singles.thursday'),
    },
    {
      value: '5',
      label: i18n.t('singles.friday'),
    },
    {
      value: '6',
      label: i18n.t('singles.saturday'),
    },
    {
      value: '0',
      label: i18n.t('singles.sunday'),
    },
  ];
}
