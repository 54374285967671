import styled, { css } from 'styled-components';
import { gutter } from '../utils/spacing';

const Spacer = styled.div<{ $hugBottom?: boolean; $hugTop?: boolean }>`
  margin: ${gutter} 0;

  ${(props) =>
    props.$hugBottom &&
    css`
      margin-bottom: 0;
    `};

  ${(props) =>
    props.$hugTop &&
    css`
      margin-top: 0;
    `};
`;

export default Spacer;
