import { useCallback, useMemo } from 'react';
import { AptlyOrganizationMember, AptlyScopes, AptlyUser } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import {
  dataGridActions,
  dataGridSearchImageColumn,
} from '../../components/Search/search-data-grid/data-grid.fields';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import Search from '../../components/Search/Search';
import { useScope } from '../../libraries/scope/ScopeContext';
import {
  IOrganizationMemberSchema,
  useOrganizationInviteSchema,
  organizationInviteSchemaFields,
  useOrganizationMemberSchema,
  organizationMemberSchemaFields,
  organizationRolesOptions,
} from './organization.schema';
import createMediaURL from '../Media/createMediaURL';
import { toOrganizationMemberPosition, toPermissionTranslation } from './organization.utils';
import { DataGridSelectMultipleEdit } from '../../components/Search/search-data-grid/DataGridSelectEdit';
import useSimplePost from '../../components/simple/useSimplePost';

function useOrganizationMembersColumns(): GridColDef[] {
  return [
    {
      ...dataGridSearchImageColumn('image', {}, (params) => {
        const member = params.row as AptlyOrganizationMember;
        const url = (member.user as AptlyUser).profileImage;
        return url ? createMediaURL(url) : undefined;
      }),
      editable: false,
    },
    {
      type: 'string',
      field: 'user',
      headerName: i18n.t('singles.name'),
      valueGetter: (value: AptlyUser) => value.fullName,
      flex: 1,
    },
    {
      type: 'string',
      field: 'email',
      headerName: i18n.t('singles.email'),
      valueGetter: (_, row) => (row.user as AptlyUser).email,
      flex: 1,
    },
    {
      type: 'string',
      field: 'position',
      headerName: i18n.t('singles.position'),
      valueGetter: (value) => toOrganizationMemberPosition(value),
      flex: 1,
    },
    {
      type: 'string',
      field: 'phone',
      headerName: i18n.t('singles.phone'),
      valueGetter: (_, row) => (row.user as AptlyUser).phone,
      flex: 1,
    },
    {
      type: 'string',
      field: 'permissions',
      headerName: i18n.t('singles.permissions'),
      renderCell: (params) => params.value.map(toPermissionTranslation).join(', '),
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <DataGridSelectMultipleEdit params={params} options={organizationRolesOptions()} />
      ),
    },
    {
      type: 'number',
      field: 'projects',
      headerName: i18n.t('singles.projects'),
      valueGetter: (value: string[]) => value?.length ?? i18n.t('singles.all'),
      width: 150,
    },
    dataGridActions(),
  ];
}

function OrganizationMembers() {
  const scope = useScope();
  const path = useApiUrl(SlugLevel.Organization, 'members');
  const invitePath = useApiUrl(SlugLevel.Organization, 'invites');
  const gridColumns = useOrganizationMembersColumns();
  const schema = useOrganizationMemberSchema();
  const inviteSchema = useOrganizationInviteSchema();
  const inviteUser = useSimplePost(invitePath, inviteSchema, {
    fields: organizationInviteSchemaFields,
    title: i18n.t('actions.addUser'),
    useFieldsProps: {
      dontSetStateOnPost: true,
    },
  });
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          projects: scope.crud(AptlyScopes.OrganizationMembers, 'U'),
        },
      },
    }),
    []
  );

  const buttonActions = useCallback(() => {
    const ret = [];

    if (scope.crud(AptlyScopes.OrganizationMembers, 'C')) {
      ret.push(
        <AddButtonWithIcon onClick={() => inviteUser()}>{i18n.t('actions.inviteClient')}</AddButtonWithIcon>
      );
      if (scope.crud(AptlyScopes.AdminUsers, 'R')) {
        ret.push();
      }
    }

    return ret;
  }, [inviteUser]);

  return (
    <Search<IOrganizationMemberSchema>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationMembers}
      header={{ title: i18n.t('singles.members') }}
      post={{ title: i18n.t('singles.members') }}
      patch={{
        fields: organizationMemberSchemaFields.slice(1),
        title: (data) => (data.user as AptlyUser).fullName,
        preData: (data) => ({ ...data, filterProjects: Boolean(data.projects) }),
      }}
      schema={schema}
      fields={organizationMemberSchemaFields}
      options={{ disableBulk: true }}
      reach={{
        query: { sort: 'name', $populate: 'projects:_id,name;user:_id,fullName,email,phone,profileImage' },
      }}
      primaryAction={
        scope.crud(AptlyScopes.AdminUsers, 'R') ? (
          <SearchAddPrimaryAction title={i18n.t('actions.addUser')} />
        ) : null
      }
      buttonActions={buttonActions}
    >
      {() => <SearchDataGrid columns={gridColumns} initialState={initialState} />}
    </Search>
  );
}

export default OrganizationMembers;
