import { AptlyAutocompleteQuery } from '@aptly-as/sdk-react/material';
import {
  AptlyAlgorithm,
  AptlyProducer,
  AptlyProduct,
  AptlyTag,
  AptlyUnitTemplateCategorySectionAssortment,
  AptlyUnitTemplateCategorySectionPackage,
} from '@aptly-as/types';
import { AppBar, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import React, { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import ActionButtons, { IActionButtonProps } from '../../components/actions/buttons/ActionButtons';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { ShowMeMoreIconButton } from '../../components/actions/icons/Icons';
import ApiError from '../../components/ApiError';
import handleError from '../../containers/Error/handleError.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { useStateThrottle } from '../../hooks/useStateThrottle';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import { IUseSimpleSearchProps, useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import useSlugLevel from '../../libraries/scope/useSlugLevel.js';
import { ModalActions } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { LinearProgresser } from '../../mui/Progresser';
import Select, { SelectOption } from '../../mui/Select';
import Typography from '../../mui/Typography';
import DataGrid, { dataGridNoRowsOverlay } from '../../mui/x-data-grid/DataGrid';
import {
  dataGridColorColumn,
  dataGridImagesColumn,
  dataGridLevelColumn,
  dataGridNameCol,
  dataGridReferenceColumn,
  dataGridSizeColumn,
  dataGridTextColumn,
} from '../../mui/x-data-grid/dataGrid.cols';
import { useOrganization } from '../Organization/OrganizationContext';

export interface IProductSearchAddAsProps {
  as: ProductsAddAs;
  package?: AptlyUnitTemplateCategorySectionPackage;
  assortment?: AptlyUnitTemplateCategorySectionAssortment;
}

export interface IProductSearchProps extends Pick<IActionButtonProps, 'onClose'> {
  onSave: (selected: AptlyProduct[], algorithm?: string, addAs?: IProductSearchAddAsProps) => Promise<void>;
  algorithms?: AptlyAlgorithm[];
  products?: AptlyProduct['products'];
  defaultSelected?: AptlyProduct[];
  level?: SlugLevel;
  fullScreen?: boolean;
  addAs?: Omit<IProductSearchAddAsProps, 'as'>;
  single?: boolean;
  showAddAs?: boolean;
}

interface ProductAtlasSearch {
  q?: string;
  producer?: string;
  tags?: string;
  level?: 'aptly' | 'organization' | 'project';
  products?: string;
}

export enum ProductsAddAs {
  Products = 'products',
  Assortment = 'assortment',
  Package = 'package',
}
const addOptions = (): SelectOption[] => [
  { value: 'products', label: i18n.t('singles.products') },
  { value: 'assortment', label: i18n.t('singles.assortment') },
  { value: 'package', label: i18n.t('singles.package') },
];

const slugLevelToAtlasLevel = (level?: SlugLevel): ProductAtlasSearch['level'] => {
  switch (level) {
    case SlugLevel.Organization:
    case SlugLevel.Project:
    case SlugLevel.Unit:
      return 'organization';
    default:
      return 'aptly';
  }
};

export function ProductSearch({
  products: propsProducts,
  level: propsLevel,
  onSave,
  onClose,
  algorithms,
  fullScreen,
  single,
  defaultSelected = [],
  showAddAs,
  ...props
}: IProductSearchProps) {
  const org = useOrganization();
  const [selected, setSelected] = React.useState<AptlyProduct[]>(defaultSelected);
  const scopeLevel = useSlugLevel();
  const isBaseLevel = propsLevel === SlugLevel.Base;
  const level = propsLevel || scopeLevel;
  const url = useApiUrl(level, 'products/search');
  const producerEndpoint = useApiUrl(SlugLevel.Base, 'producers');
  const tagsEndpoint = useApiUrl(SlugLevel.Base, 'tags');

  const algorithmOptions = useMemo(
    () =>
      !algorithms
        ? null
        : [
            { value: null, label: i18n.t('statuses.nothing') },
            ...algorithms.map((x) => ({ value: x._id, label: x.name })),
          ],
    [algorithms]
  );
  const addAsOptions = useMemo((): SelectOption[] => {
    const _options = addOptions();
    if (!props.addAs) return _options;
    if (props.addAs.package) return _options.filter((o) => o.value !== 'package');
    if (props.addAs.assortment) return [_options[0]];
    return _options;
  }, [props.addAs]);

  const defaultQuery: ProductAtlasSearch = useMemo(
    () => ({
      level: slugLevelToAtlasLevel(level),
      $nin__id: (propsProducts || []).map(getId).join(','),
    }),
    [propsProducts, level]
  );
  const searchProps: IUseSimpleSearchProps<AptlyProduct> = useMemo(
    () => ({
      limit: 20,
      query: defaultQuery,
    }),
    [propsProducts]
  );
  const [busy, products, error, next, info, actions] = useSimpleSearch<AptlyProduct>(url, searchProps);
  const [activeProduct, setActiveProduct] = useState<AptlyProduct | null>(null);
  const [algorithm, setAlgorithm] = useState<string | undefined>(algorithms?.[0]?._id || undefined);
  const [addAs, setAddAs] = useState<ProductsAddAs | null>(algorithms ? ProductsAddAs.Products : null);
  const [search, setSearch] = useStateThrottle<ProductAtlasSearch>(defaultQuery, 350, actions.search);

  const handleOnNext = useCallback(() => next(search), [next, search]);
  const handleOnTextSearch = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    await setSearch((s) => ({ ...s, q: e.target.value }));
  }, []);
  const handleOnProducerSearch = useCallback(async (item: AptlyProducer | null) => {
    await setSearch(
      (s) => ({
        ...s,
        producer: item ? item._id : '',
      }),
      0
    );
  }, []);
  const handleOnTagsSearch = useCallback(async (items: AptlyTag[]) => {
    await setSearch((s) => ({ ...s, $in_tags: items.map((i) => i._id).join(',') }), 0);
  }, []);
  const handleOnLevelSearch = useCallback(
    (level: ProductAtlasSearch['level']) => async () => {
      await setSearch((s) => ({ ...s, level }), 0);
    },
    []
  );

  const handleOnSelectionModelChange = useCallback(
    (gsm: GridRowSelectionModel) => {
      setSelected(
        (s) =>
          gsm
            .map((x) => {
              if (activeProduct?._id === x) return activeProduct;
              return products.find((p) => p._id === x) || s.find((p) => p._id === x);
            })
            .filter(Boolean) as AptlyProduct[]
      );
    },
    [products, activeProduct]
  );

  const handleOnProductsFilter = useCallback((product: AptlyProduct) => {
    setSearch((s) => ({ ...s, q: '', products: product._id }));
    setActiveProduct(product);
  }, []);
  const handleOnDeleteProductsFilter = useCallback(() => {
    setSearch((s) => ({ ...s, products: '' }));
    setActiveProduct(null);
  }, []);

  const handleOnAlgorithmSelect = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAlgorithm(e.target.value);
  }, []);
  const handleOnAddOption = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddAs(e.target.value as ProductsAddAs);
  }, []);

  const handleOnSave = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      try {
        const name = e.currentTarget.name;
        if (selected.length > 0) {
          await onSave(
            selected,
            algorithm,
            addAs
              ? {
                  as: addAs,
                  ...props.addAs,
                }
              : undefined
          );
        }
        if (name === 'continue') {
          setSelected([]);
        } else if (typeof onClose === 'function') {
          onClose();
        }
      } catch (e: unknown) {
        handleError(e);
      }
    },
    [selected, onSave, onClose, algorithm, addAs]
  );

  const rows = useMemo(() => {
    const newSelected = activeProduct
      ? [activeProduct, ...selected.filter((x) => activeProduct._id !== x._id)]
      : selected;
    return [
      ...newSelected,
      ...products.filter((x) => {
        if (activeProduct && activeProduct._id === x._id) return false;
        return !selected.some((y) => x._id === y._id);
      }),
    ];
  }, [activeProduct, selected, products]);

  const columns = useMemo(
    () =>
      [
        { ...dataGridImagesColumn('images'), editable: false },
        dataGridNameCol({ minWidth: 200, flex: 1, resizable: true }),
        dataGridTextColumn('productNumber', i18n.t('singles.productNumber')),
        dataGridColorColumn(),
        dataGridSizeColumn(),
        dataGridReferenceColumn('producer', i18n.t('singles.producer')),
        dataGridReferenceColumn('tags', i18n.t('singles.tags'), {
          renderCell: (params) =>
            Array.isArray(params.value) ? params.value.map((x) => x.name).join(', ') : 'N/A',
        }),
        dataGridLevelColumn(),
        {
          field: 'products',
          headerName: 'Handling',
          minWidth: 200,
          renderCell: (params) => <FilterButton params={params} setProductsFilter={handleOnProductsFilter} />,
        },
      ] as GridColDef<AptlyProduct>[],
    [handleOnProductsFilter]
  );

  return (
    <Wrapper fullScreen={fullScreen}>
      <StyledAppBar enableColorOnDark position="relative" color="inherit">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography variant="h1">
              {i18n.t('singles.products')} {products.length}/{info.count}
            </Typography>
          </Grid>
          <Grid container flex={1} item alignItems="center" spacing={1}>
            {!isBaseLevel && (
              <Chip
                color="secondary"
                variant={search.level === 'aptly' ? 'filled' : 'outlined'}
                label="Aptly"
                onClick={handleOnLevelSearch('aptly')}
              />
            )}
            {!isBaseLevel && level >= SlugLevel.Organization && (
              <Chip
                color="secondary"
                variant={search.level === 'organization' ? 'filled' : 'outlined'}
                label={i18n.t('singles.organization')}
                onClick={handleOnLevelSearch('organization')}
              />
            )}
            {!isBaseLevel && level === SlugLevel.Project && (
              <Chip
                color="secondary"
                variant={search.level === 'project' ? 'filled' : 'outlined'}
                label={i18n.t('singles.project')}
                onClick={handleOnLevelSearch('project')}
              />
            )}
            {activeProduct && (
              <Chip
                color="primary"
                variant="filled"
                label={`${i18n.t('singles.filter')}: ${activeProduct.name}`}
                onDelete={handleOnDeleteProductsFilter}
              />
            )}
          </Grid>
          <Grid container justifyContent="flex-end" spacing={2} xs={3}>
            {!org?.producer && (
              <Grid item xs={6}>
                <AptlyAutocompleteQuery<AptlyProducer, false, false>
                  path={producerEndpoint}
                  defaultValue={null}
                  queryOnFocus
                  autocompleteSearchProps={{
                    label: i18n.t('singles.producer'),
                    noOptionsText: i18n.t('statuses.noResults'),
                    textFieldProps: {
                      variant: 'standard',
                      fullWidth: true,
                    },
                  }}
                  multiple={false}
                  onChange={handleOnProducerSearch}
                  query={{ select: '_id,name', limit: 7 }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <AptlyAutocompleteQuery<AptlyTag, true, false>
                path={tagsEndpoint}
                defaultValue={[]}
                queryOnFocus
                autocompleteSearchProps={{
                  label: i18n.t('singles.tags'),
                  noOptionsText: i18n.t('statuses.noResults'),
                  textFieldProps: {
                    variant: 'standard',
                    fullWidth: true,
                  },
                }}
                multiple
                onChange={handleOnTagsSearch}
                query={{ select: '_id,name', limit: 7 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledAppBar>
      {busy && <LinearProgresser offset />}
      {error && <ApiError error={error} />}
      <GridWrapper>
        <DataGrid
          rowSelectionModel={selected.map((x) => x._id)}
          getRowId={(row) => row._id}
          columns={columns}
          rows={rows}
          onRowSelectionModelChange={handleOnSelectionModelChange}
          hideFooter
          checkboxSelection
          disableMultipleRowSelection={!!single}
          slots={
            search.q
              ? {
                  noRowsOverlay: dataGridNoRowsOverlay(
                    i18n.t('statuses.noResultsWithSearch', { search: search.q })
                  ),
                }
              : undefined
          }
        />
      </GridWrapper>
      <ModalActions>
        <Grid container item xs={4}>
          <Grid item flex={1}>
            <TextField
              autoFocus
              key={search.q}
              defaultValue={search.q}
              size="small"
              placeholder={i18n.t('actions.searchProducts')}
              onChange={handleOnTextSearch}
              fullWidth
            />
          </Grid>
          {info.hasFetched && products.length < 100 && products.length < info.count && (
            <Grid container xs={2} item justifyContent="center">
              <ShowMeMoreIconButton onClick={handleOnNext} size="medium" />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={8} justifyContent="flex-end" alignItems="center" gap={2}>
          {algorithmOptions && (
            <Grid container item xs={4} spacing={2}>
              <Grid container item xs={showAddAs ? 7 : 12}>
                <Select
                  fullWidth
                  label={i18n.t('singles.calculation')}
                  size="small"
                  defaultValue={algorithm}
                  options={algorithmOptions}
                  onChange={handleOnAlgorithmSelect}
                />
              </Grid>
              {showAddAs && (
                <Grid container item xs={5} justifyContent="flex-end">
                  <Select
                    fullWidth
                    label={i18n.t('actions.addAs')}
                    size="small"
                    defaultValue={addAsOptions[0].value}
                    options={addAsOptions}
                    onChange={handleOnAddOption}
                  />
                </Grid>
              )}
            </Grid>
          )}
          <Grid item>
            <ActionButtons onClose={onClose} onClick={handleOnSave} disabledSubmit={selected.length === 0}>
              {!single && (
                <Grid item>
                  <AddButtonWithIcon
                    name="continue"
                    disabled={selected.length === 0}
                    onClick={handleOnSave}
                  />
                </Grid>
              )}
            </ActionButtons>
          </Grid>
        </Grid>
      </ModalActions>
    </Wrapper>
  );
}

const StyledAppBar = styled(AppBar)`
  padding: ${(props) => props.theme.spacing(1)};
`;

const Wrapper = styled.div<Pick<IProductSearchProps, 'fullScreen'>>`
  position: relative;
  height: ${(props) => (props.fullScreen ? '100%' : '80vh')};
`;

const GridWrapper = styled.div`
  height: calc(100% - 120px);
`;

interface IFilterButtonProps {
  params: GridRenderCellParams<any, AptlyProduct>;
  setProductsFilter: (product: AptlyProduct) => void;
}

function FilterButton({ params, setProductsFilter }: IFilterButtonProps) {
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (params.row) {
        setProductsFilter(params.row);
      }
    },
    [params.row]
  );
  if (!params.value || (params.value as any).length === 0) return null;
  return (
    <Button onClick={handleOnClick}>
      {i18n.t('singles.show')} {params.value.length} {i18n.t('singles.variants')}
    </Button>
  );
}
