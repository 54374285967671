import { Grid2 } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import { useCallback, useMemo, useState } from 'react';
import { ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils.js';
import i18n from '../../libraries/i18n.js';
import { Select, TextField } from '../../mui/Input.js';
import { sizeParamOptions } from '../UnitTemplate/sizeParams.js';

export default function OptionRoomKey<T extends object, K extends keyof T>({
  crud,
  field,
}: ICustomRenderComponentProps<T, K>) {
  const [option, setOption] = useState<string>(field.value as string);
  const options = useMemo(() => sizeParamOptions(), []);
  const onSelectChange = useCallback(
    (e: SelectChangeEvent) => {
      const v = e.target.value;
      setOption(v);
      if (v !== '__custom') {
        crud.setField(field.key)(v as T[K]);
      } else {
        crud.setField(field.key)('' as T[K]);
      }
    },
    [crud, field]
  );
  return (
    <Grid2 container spacing={1}>
      <Grid2 size={{ xs: option === '__custom' ? 6 : 12 }}>
        <Select
          fullWidth
          name={String(field.key)}
          value={option}
          onChange={onSelectChange}
          options={options}
        />
      </Grid2>
      {option === '__custom' && (
        <Grid2 size={{ xs: 6 }}>
          <TextField
            fullWidth
            label={i18n.t('singles.value')}
            autoFocus
            onChange={crud.setField(field.key)}
            required
          />
        </Grid2>
      )}
    </Grid2>
  );
}
