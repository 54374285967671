import LinkIcon from '@mui/icons-material/Link';
import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';

const ComponentLink = styled(NavLink)`
  display: block;
  text-decoration: none;
`;

interface TextLinkWithIconProps extends NavLinkProps {
  icon?: ReactNode;
}

export function TextLinkWithIcon({
  children,
  icon = <LinkIcon />,
  ...props
}: PropsWithChildren<TextLinkWithIconProps>) {
  return (
    <StyledNavLink {...props}>
      {icon}
      {children}
    </StyledNavLink>
  );
}

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(0.5)};
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export const TextLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

export default ComponentLink;
