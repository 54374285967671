import { useCallback } from 'react';
import { ICreateModelOptions } from '../../containers/Modal/modal.types';
import { createModal } from '../../containers/Modal/ModalContext';
import { ICrudSchema, ISimpleCrudObject } from '../crud/utils/crud.utils';
import { ISimpleModalProps, SimpleModal } from './SimpleCrud';
import { IToSearchPathPathFn } from '../Search/search.utils';
import { IUseSimpleDeleteFn } from './useSimpleDelete.js';

export type SimpleModalProps<T extends ISimpleCrudObject> = Omit<
  IUseSimplePatchProps<T>,
  'title' | 'path' | 'schema' | 'data' | 'fields' | 'onEdit'
>;

export interface IUseSimplePatchProps<T extends ISimpleCrudObject>
  extends Omit<ISimpleModalProps<T>, 'path' | 'schema' | 'data' | 'onEdit' | 'title' | 'onDelete'> {
  title: string | ((item: Partial<T>) => string);
  modalProps?: ICreateModelOptions;
  path?: (path: string, data: Partial<T>) => string;
  preData?: (data: Partial<T>) => Partial<T> & object;
  onDelete?: IUseSimpleDeleteFn<any>;
}
export type IUseSimplePatchFn<T extends ISimpleCrudObject> = (
  data: Partial<T>,
  index: number,
  keys?: (keyof T)[],
  overrideProps?: SimpleModalProps<T>
) => void;

export default function useSimplePatch<T extends ISimpleCrudObject>(
  path: IToSearchPathPathFn,
  schema: ICrudSchema<T>,
  splice: (index: number, end: number, data: T) => void,
  props: IUseSimplePatchProps<T>
): IUseSimplePatchFn<T> {
  return useCallback(
    (
      data: Partial<T>,
      index: number,
      fields: (keyof T)[] = props.fields,
      overrideProps?: SimpleModalProps<T>
    ) => {
      const handleOnEdit = (newData: T) => {
        if (props.disableFields) {
          for (const key of props.disableFields) {
            delete newData[key];
          }
        }
        splice(index, 1, { ...data, ...newData });
      };
      const onDelete = props.onDelete
        ? (onClose?: () => void) => {
            props.onDelete!(data, index, onClose);
          }
        : undefined;

      return createModal(
        <SimpleModal<T>
          {...props}
          {...overrideProps}
          title={typeof props.title === 'function' ? props.title(data) : props.title}
          path={path(data)}
          schema={schema}
          data={props.preData ? props.preData(data) : data}
          fields={fields}
          onEdit={handleOnEdit}
          onDelete={onDelete}
        />,
        props.modalProps || { width: 'md' }
      );
    },
    [path, schema, props, splice]
  );
}
