import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Delete from '@mui/icons-material/Delete';
import { ISimpleComponentProps } from './simple-components.utils';
import { FileDropzone } from '../../../libraries/react-dropzone/DropZone';
import { IconButton } from '../../../mui/Button';
import List, { ListItem, ListItemText } from '../../List/List';

interface SimpleFileUploadProps<T extends object> extends ISimpleComponentProps<T, any> {
  simple?: boolean;
  multiple?: boolean;
}

export default function SimpleFiles<T extends object>({
  field,
  onChange,
  simple,
  multiple,
}: SimpleFileUploadProps<T>) {
  const { value, label, required, file } = field;

  const handleOnDrop = useCallback(
    (files: File[]) => {
      if (multiple) {
        onChange([...value, ...files]);
      } else {
        onChange(files[0]);
      }
    },
    [onChange, value, multiple]
  );
  const handleOnDelete = useCallback(
    (i: number) => () => {
      if (multiple) {
        value.splice(i, 1);
        onChange([...value]);
      } else {
        onChange(null);
      }
    },
    [onChange, value, multiple]
  );

  const files = (Array.isArray(value) ? value : value ? [value] : []) as File[];

  return (
    <>
      {simple && <Typography variant="subtitle1">{label}</Typography>}
      <List dense>
        {files?.map((file: File, i: number) => (
          <ListItem
            divider
            key={`${file.name}-${i}`}
            secondaryAction={
              <IconButton edge="end" onClick={handleOnDelete(i)}>
                <Delete />
              </IconButton>
            }
          >
            <ListItemText
              primary={file.name}
              secondary={`${Number(file.size / 1000000).toFixed(2)}MB - ${file.type}`}
            />
          </ListItem>
        ))}
      </List>
      {(multiple || !files[0]) && (
        <FileDropzone multiple onDrop={handleOnDrop} required={required} {...file} />
      )}
    </>
  );
}
