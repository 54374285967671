import {
  AptlyAlgorithm,
  AptlyAlgorithmPipelineApplyTo,
  AptlyAlgorithmPipelineOperation,
  AptlyAllowance,
  AptlyFieldType,
  AptlyProject,
  AptlyScopes,
} from '@aptly-as/types';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema, ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils';
import DraggableCrudList, { Field, ItemsToSelect } from '../../components/DraggableCrudList';
import { useFormat } from '../../containers/Format/Format.js';
import { SlugLevel } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose.js';
import Scope from '../../libraries/scope/Scope';
import { AlgorithmPreview } from './AlgorithmPreview.js';
import { useAlgorithms } from './useAlgorithms.js';

export type ICrudAlgorithm = AptlyProject['algorithms'][0] & { __example: undefined; warning?: undefined };
export const algorithmOperationLabels = (): { [key in AptlyAlgorithmPipelineOperation]: string } => ({
  [AptlyAlgorithmPipelineOperation.Base]: i18n.t('singles.netCost'),
  [AptlyAlgorithmPipelineOperation.Add]: i18n.t('actions.addition'),
  [AptlyAlgorithmPipelineOperation.Subtract]: i18n.t('actions.subtract'),
  [AptlyAlgorithmPipelineOperation.Multiply]: i18n.t('actions.multiply'),
  [AptlyAlgorithmPipelineOperation.Percent]: i18n.t('singles.percent'),
  [AptlyAlgorithmPipelineOperation.Fee]: i18n.t('singles.fee'),
  [AptlyAlgorithmPipelineOperation.Vat]: i18n.t('singles.vat'),
  [AptlyAlgorithmPipelineOperation.Allowance]: i18n.t('singles.allowance'),
});

const label = (): Field => ({
  key: 'label',
  label: i18n.t('singles.name'),
  type: 'text',
});

const value = (label = i18n.t('singles.value')): Field => ({
  key: 'value',
  label,
  type: 'number',
});

const applyTo = (): Field => {
  return {
    key: 'applyTo',
    label: i18n.t('paragraphs.appliesTo'),
    type: 'select',
    options: [
      {
        value: 'base',
        label: i18n.t('singles.publishedAmount'),
      },
      {
        value: 'previousStep',
        label: i18n.t('actions.previousStep'),
      },
    ],
  };
};

const algorithmPipelineItems = (): ItemsToSelect => {
  const labels = algorithmOperationLabels();
  return {
    [AptlyAlgorithmPipelineOperation.Base]: {
      label: labels[AptlyAlgorithmPipelineOperation.Base],
      fields: [label()],
      hide: true,
    },
    [AptlyAlgorithmPipelineOperation.Add]: {
      label: labels[AptlyAlgorithmPipelineOperation.Add],
      fields: [label(), value()],
    },
    [AptlyAlgorithmPipelineOperation.Subtract]: {
      label: labels[AptlyAlgorithmPipelineOperation.Subtract],
      fields: [label(), value()],
    },
    [AptlyAlgorithmPipelineOperation.Multiply]: {
      label: labels[AptlyAlgorithmPipelineOperation.Multiply],
      fields: [label(), value(i18n.t('singles.value') + ' ( eks. 1.1 )'), applyTo()],
    },
    [AptlyAlgorithmPipelineOperation.Percent]: {
      label: labels[AptlyAlgorithmPipelineOperation.Percent],
      fields: [label(), value(i18n.t('singles.percent')), applyTo()],
    },
  };
};

export function useAlgorithmSchemaField<T extends object>(
  override: Partial<ICrudField<T, string | null>> = {},
  level?: SlugLevel
): ICrudField<T, string | null> {
  const algorithms = useAlgorithms(level);
  const options = algorithms.map((x) => ({
    value: x._id,
    label: x.name,
  }));
  options.unshift({ value: '', label: i18n.t('statuses.nothing') });

  return {
    type: AptlyFieldType.Select,
    label: i18n.t('singles.calculation'),
    defaultValue: algorithms[0] ? algorithms[0]._id : null,
    options,
    ...override,
  };
}

export const algorithmEditFields: (keyof ICrudAlgorithm)[] = [
  'name',
  'pipeline',
  'fee',
  'vat',
  'reversed',
  '__example',
];

export function useAlgorithmSchema(): ICrudSchema<ICrudAlgorithm> {
  const format = useFormat();

  return {
    name: schemaName({
      autoFocus: true,
    }),
    pipeline: {
      type: AptlyFieldType.Custom,
      defaultValue: [
        {
          _id: '',
          operation: AptlyAlgorithmPipelineOperation.Base,
          label: i18n.t('singles.netCost'),
          value: 0,
          applyTo: AptlyAlgorithmPipelineApplyTo.Root,
        },
      ],
      label: '',
      customRender: (crud, field) => {
        const pipeline = field.value as any[];
        return (
          <>
            <DraggableCrudList
              label={i18n.t('singles.pipeline')}
              addActionText={i18n.t('actions.add')}
              valueAsLabel="label"
              onCommit={(items) => {
                crud.setField('pipeline')(
                  items.map((i) => {
                    const operation = i.key;
                    const label = i.values.find((i) => i.key === 'label');
                    const value = i.values.find((i) => i.key === 'value');
                    const applyTo = i.values.find((i) => i.key === 'applyTo');
                    const defaultApplyTo =
                      operation === AptlyAlgorithmPipelineOperation.Multiply
                        ? AptlyAlgorithmPipelineApplyTo.PreviousStep
                        : undefined;

                    return {
                      operation,
                      label: label ? label.value : undefined,
                      value: value ? value.value : undefined,
                      applyTo: applyTo ? applyTo.value : defaultApplyTo,
                    };
                  }) as any[]
                );
              }}
              items={pipeline.map((p) => ({
                key: p.operation,
                disabled: p.operation === AptlyAlgorithmPipelineOperation.Base,
                values: [
                  {
                    key: 'label',
                    value: p.label,
                  },
                  {
                    key: 'value',
                    value: p.value,
                  },
                  {
                    key: 'applyTo',
                    value: p.applyTo,
                  },
                ],
              }))}
              itemsToSelect={algorithmPipelineItems() as any}
            />
          </>
        );
      },
    },
    vat: {
      type: AptlyFieldType.Select,
      label: i18n.t('singles.vat'),
      defaultValue: 25,
      options: [
        { value: 25, label: '25%' },
        { value: 15, label: '15%' },
        { value: 12, label: '12%' },
        { value: '0', label: '0%' },
      ],
    },
    fee: {
      type: AptlyFieldType.Number,
      label: `Aptly sin ${i18n.t('singles.fee')} ${i18n.t('singles.percent').toLocaleLowerCase()} ( ${i18n.t(
        'paragraphs.aptlyFee'
      )} )`,
      defaultValue: 0,
      requiredValidate: () => Scope.crud(AptlyScopes.Admin),
    },
    reversed: {
      type: AptlyFieldType.Switch,
      label: i18n.t('paragraphs.useNetAsGross'),
      defaultValue: false,
    },
    __example: {
      disabled: true,
      type: AptlyFieldType.Custom,
      label: '',
      defaultValue: undefined,
      customRender: (crud) => {
        const algorithm: AptlyAlgorithm = crud.state.data;
        delete algorithm.feeBasePercent;
        delete algorithm.feePercent;
        for (const pipe of algorithm.pipeline) {
          delete pipe.valuePercent;
        }
        const amount = 50000;
        return (
          <>
            {i18n.t('singles.example')} ( {format.amount(amount)} ):{' '}
            <AlgorithmPreview algorithm={algorithm} amount={amount} />
          </>
        );
      },
    },
  };
}

export function useAlgorithmPreviewSchemaField<T extends { amount?: number; algorithm?: string | null }>(
  override: Partial<ICrudField<T, undefined>> = {}
): ICrudField<T, undefined> {
  return {
    type: AptlyFieldType.Custom,
    defaultValue: undefined,
    label: '',
    ...override,
    CustomComponent: AlgorithmPreviewCustomComponent,
  };
}

export function AlgorithmPreviewCustomComponent<
  T extends { amount?: number; algorithm?: string | null; allowance?: AptlyAllowance | null },
  K extends keyof T,
>({ crud }: ICustomRenderComponentProps<T, K>) {
  const algorithms = useAlgorithms();
  const value = getId(crud.getField('algorithm').value);
  const algorithm = algorithms.find((a) => a._id === value);
  const allowance = crud.getField('allowance').value;
  const amount = crud.getField('amount').value || 0;
  return <AlgorithmPreview algorithm={algorithm} amount={amount} allowance={allowance} />;
}
