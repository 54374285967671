import { useContext, useEffect, useRef } from 'react';
import { GridRenderEditCellParams } from '@mui/x-data-grid';
import { productGroupProps } from '../../../models/Product/product.utils';
import { SearchCrudContext } from '../search.utils';

interface Props<T extends object> {
  params: GridRenderEditCellParams;
  keys: (keyof T)[];
}

export function DataGridFieldClickEdit<T extends object>({ params, keys }: Props<T>) {
  const active = useRef(false);
  const { id } = params;
  const {
    items,
    actions: { spawnPatch },
  } = useContext(SearchCrudContext);

  useEffect(() => {
    if (active.current) return;
    active.current = true;
    const index = items.findIndex((x) => x._id === id);
    spawnPatch(items[index], index, keys, {
      onClose: () => {
        active.current = false;
      },
      contentProps: {
        accordionProps: { expanded: true },
        groupProps: productGroupProps(),
      },
    });
  }, []);

  return <></>;
}
