import { AptlyFieldType, AptlyOptionLabel } from '@aptly-as/types';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';

export const optionLabelSchema = (): ICrudSchema<AptlyOptionLabel> => ({
  name: schemaName({
    autoFocus: true,
  }),
});

export function useOptionLabelsSchemaField<T extends object, V extends string | AptlyOptionLabel>(
  override?: Partial<ICrudField<T, V[]>>
): ICrudField<T, V[]> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'option-labels'),
      query: { archived: false, select: '_id,name' },
      multiple: true,
      queryOnFocus: true,
    }),
    label: i18n.t('singles.labels'),
    defaultValue: [],
    ...override,
  };
}
