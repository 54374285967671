import Paper from '../../mui/Paper';
import Typography from '../../mui/Typography';
import Card from '../../mui/Card';
import List from '../../components/List/List';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface SettingsSectionProps {
  title?: string;
}

export default function SettingsSection({ title, children }: PropsWithChildren<SettingsSectionProps>) {
  return (
    <>
      {title && (
        <StyledPaper elevation={0}>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
        </StyledPaper>
      )}
      <Card>
        <List disablePadding>{children}</List>
      </Card>
    </>
  );
}

const StyledPaper = styled(Paper)`
  padding-left: ${(props) => props.theme.spacing(1)};
  background: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.secondary.contrastText};
`;
