import { AptlyScopes } from '@aptly-as/types';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import type { GridRowParams } from '@mui/x-data-grid/models/params';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SendButtonWithIcon } from '../../components/actions/buttons/Buttons';
import Search, {
  ISearchSubActions,
  SearchFieldSelect,
  SearchFieldText,
} from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { useDataGridVisibilityModel } from '../../components/Search/search-data-grid/data-grid.hooks.js';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import SimpleCrud from '../../components/SimpleCrud';
import { unitStatusOptions } from '../../components/simpleCruds/unit/CrudUnitEdit';
import CrudUnitInvitations from '../../components/simpleCruds/unit/CrudUnitInvitations';
import { createModal } from '../../containers/Modal/ModalContext';
import { Query } from '../../deprecated/Search/Search';
import { SlugLevel, useApiUrl, useSlugs, useTo } from '../../hooks/useGetApiUrl';
import { useMobile } from '../../hooks/useMobile.js';
import filesSaver from '../../libraries/files-saver';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import { useScope } from '../../libraries/scope/ScopeContext';
import Button, { ButtonWithIcon } from '../../mui/Button';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { ProjectContext } from '../Project/ProjectContext';
import { unitDesktopColumns, useUnitColumns } from './unit.columns.js';
import {
  ICrudUnit,
  ICrudUnitImportSchema,
  useUnitImportSchema,
  unitSchemaFields,
  useUnitSchema,
} from './unit.schema';

function Units() {
  const scope = useScope();
  const { data: project } = useContext(ProjectContext);
  const to = useTo(SlugLevel.Project, '/enheter');
  const navigate = useNavigate();
  const [organizationSlug, projectID] = useSlugs();
  const path = useApiUrl(SlugLevel.Project, 'units');
  const schema = useUnitSchema();
  const unitImportSchema = useUnitImportSchema();
  const isMobile = useMobile();
  const columns = useUnitColumns();
  const initialState = useDataGridVisibilityModel(unitDesktopColumns, {
    status: scope.crud(AptlyScopes.ProjectUnitTemplates),
    unitTemplate: scope.crud(AptlyScopes.ProjectUnitTemplates),
  });

  const projectEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const baseEndpoint = `${projectEndpoint}/units`;

  const menuActions: ISearchSubActions<ICrudUnit> = useCallback(
    (_, { refresh }) => {
      const actions = [];

      if (Scope.crud(AptlyScopes.ProjectUnits, 'C')) {
        actions.push({
          label: i18n.t('actions.export'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
            });
          },
        });
        actions.push({
          label: i18n.t('actions.import'),
          icon: <CloudUploadOutlinedIcon />,
          onClick: () => {
            createModal(
              <SimpleModal<ICrudUnitImportSchema>
                title={i18n.t('actions.import')}
                path={`${baseEndpoint}/import`}
                schema={unitImportSchema}
                fields={['_description', 'type', 'unitTemplate', 'csv']}
                useFieldsProps={{ reachOptions: { type: 'multipart/form-data', fileKeys: ['csv'] } }}
                data={{}}
                onEdit={refresh}
              />
            );
          },
        });
        actions.push({
          label: i18n.t('actions.exportForIntegrations'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
              data: { type: '3rdParty' },
            });
          },
        });
        actions.push({
          label: i18n.t('actions.exportProtocolTransfer'),
          icon: <CloudDownloadOutlinedIcon />,
          onClick: async () => {
            await filesSaver({
              endpoint: `${baseEndpoint}/csv/export`,
              fileName: `${project.name}-unit-export-${Date.now()}.csv`,
              data: { type: 'protocol-transfer' },
            });
          },
        });
      }

      if (Scope.crud(AptlyScopes.ProjectThirdParty, 'U')) {
        actions.push({
          label: i18n.t('paragraphs.linkForThirdPartyUploads'),
          icon: <ArrowForwardIosIcon />,
          onClick: () => {
            createModal(
              <SimpleCrud<{ url: string }>
                title={i18n.t('paragraphs.linkForThirdPartyUploads')}
                description={
                  <div>
                    {i18n.t('info.thirdPartyUnit')}
                    <br />
                    <br />
                    <strong>{i18n.t('paragraphs.linkExpiresOneMonth')}</strong>
                  </div>
                }
                actionLabel={i18n.t('actions.create')}
                endpoint={`${projectEndpoint}/create-third-party-cart-link`}
                onCreate={(data) => {
                  createModal(<ShareLinkModal url={data.url} />);
                }}
                fields={[
                  {
                    helpField: i18n.t('paragraphs.supplierName'),
                    name: 'supplierName',
                    type: 'text',
                    value: null,
                    label: '',
                    required: true,
                    autoFocus: true,
                  },
                ]}
              />
            );
          },
        });
      }

      return actions;
    },
    [baseEndpoint, projectEndpoint, project.name, unitImportSchema]
  );

  const handleOnRowClick: ISearchDataGridProps['onRowClick'] = useCallback(
    (params: GridRowParams) => {
      navigate(`${to}/${params.id}`);
    },
    [navigate, to]
  );

  return (
    <Search<ICrudUnit>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.ProjectUnits}
      header={{ title: i18n.t('singles.customers') }}
      post={{ title: i18n.t('singles.customer') }}
      bulk={{ title: i18n.t('singles.customers'), fields: ['unitTemplate'] }}
      patch={{ title: (data) => String(data.name) }}
      schema={schema}
      fields={unitSchemaFields}
      reach={{
        paginationMode: 'server',
        limit: isMobile ? 10 : 100,
        query: {
          sort: 'name',
          select:
            '_id,name,status,users,unitTemplate,unitTemplateEjected,queueInvites,invites,invitesQueue,organizations,projects',
          $populate: 'unitTemplate:_id,name;users:_id,fullName',
          archived: false,
        },
      }}
      buttonActions={() => [
        <SendButtonWithIcon
          key="invites"
          title={i18n.t('actions.bulkInvites')}
          onClick={() => {
            createModal(<CrudUnitInvitations />, { width: 'md' });
          }}
        />,
      ]}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      options={{ disableBulk: isMobile }}
      subActions={menuActions}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
        <SearchFieldSelect
          key="status"
          field="status"
          label={i18n.t('singles.status')}
          options={[{ value: '', label: i18n.t('singles.all') }, ...(unitStatusOptions() as any[])]}
        />,
      ]}
    >
      {() => (
        <SearchDataGrid
          columns={columns}
          onRowClick={handleOnRowClick}
          pageSize={isMobile ? 10 : 100}
          initialState={initialState}
        />
      )}
    </Search>
  );
}

type ExportType = {
  baseEndpoint: string;
  label?: string;
  overrideQuery?: Query;

  // Overridden with SearchViewComposer. Use overrideQuery if not working
  query?: Query;
};

/** @deprecated Use SearchExportButton or ExportButtonV2 **/
function Export(props: ExportType) {
  const { baseEndpoint, label = i18n.t('actions.export'), query = {}, overrideQuery = {} } = props;

  const data: Query = { ...query, ...overrideQuery };
  delete data.limit;

  return (
    <ButtonWithIcon
      variant="outlined"
      color="primary"
      onClick={() =>
        filesSaver({
          endpoint: `${baseEndpoint}/report/export`,
          fileName: 'rapport.csv',
          data,
        })
      }
    >
      {label}.csv
      <CloudDownloadOutlinedIcon />
    </ButtonWithIcon>
  );
}

type ShareLinkModalProps = {
  url: string;
  onClose?: () => void;
};

function ShareLinkModal(props: ShareLinkModalProps) {
  const { url, onClose } = props;

  return (
    <React.Fragment>
      <ModalTitle>{i18n.t('paragraphs.linkForThirdPartyUploads')}</ModalTitle>
      <ModalContent>
        <p>{i18n.t('info.thirdPartyUnitShare')}</p>
        <TextField value={url} fullWidth onFocus={(e) => e.target.select()} />
      </ModalContent>
      <ModalActions>
        <Button color="primary" onClick={onClose}>
          {i18n.t('actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  );
}

export default Units;
export { Export };
