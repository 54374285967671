import {
  AptlyAutocompleteQuery,
  AptlyAutocompleteSelect,
  AptlyAutocompleteSelectOption,
} from '@aptly-as/sdk-react/material';
import { AptlyFieldType, AptlySearchAddress, AptlySearchPaginateResponse } from '@aptly-as/types';
import { Grid2 } from '@mui/material';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { MyLocationIconButton, SearchIconButton } from '../../components/actions/icons/Icons.js';
import ApiError, { IApiError } from '../../components/ApiError.js';
import { ICrudField, ICustomRenderComponentProps } from '../../components/crud/utils/crud.utils.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import simpleRequest from '../../libraries/fetch/simpleRequest.js';
import i18n from '../../libraries/i18n.js';

type AddressItem = { address?: AptlySearchAddress | null };
export function useAddressGeoSchemaField<T extends AddressItem>(
  override: Partial<ICrudField<T, undefined>>
): ICrudField<T, undefined> {
  return {
    type: AptlyFieldType.Custom,
    label: '',
    defaultValue: undefined,
    CustomComponent: AddressSearch,
    ...override,
  };
}

function AddressSearch<T extends AddressItem>({ crud, field }: ICustomRenderComponentProps<T, 'address'>) {
  const [geo, setGeo] = useState<boolean>(false);
  const [address, setAddress] = useState<AptlySearchAddress | null>(null);
  const showGeo = window.navigator && 'geolocation' in window.navigator;

  const toggle = useCallback(() => {
    setGeo((s) => !s);
    crud.setField('address')(null);
    setAddress(null);
  }, []);
  const onChange = useCallback(
    (address: AptlySearchAddress | null) => {
      setAddress(address);
      crud.setField('address')(address);
    },
    [field]
  );
  const onUnitNumberChange = useCallback(
    (option: AptlyAutocompleteSelectOption | null) => {
      crud.setField('address')({
        ...address,
        unitNumber: option?.value || '',
      } as T['address']);
    },
    [crud, address]
  );

  return (
    <Grid2 container flexDirection="column" spacing={1}>
      <Grid2 container>
        <Grid2 flex={1}>
          {geo ? (
            <AddressGeo onChange={onChange} required={field.required} />
          ) : (
            <AddressQuery
              address={address}
              onChange={onChange}
              label={String(field.label)}
              required={field.required}
            />
          )}
        </Grid2>
        {showGeo && (
          <Grid2 container justifyContent="center" alignItems="center" width="50px">
            {geo ? (
              <SearchIconButton size="small" onClick={toggle} />
            ) : (
              <MyLocationIconButton size="small" onClick={toggle} />
            )}
          </Grid2>
        )}
      </Grid2>
      {address?.unitNumbers && (
        <Grid2 size={{ xs: 12 }}>
          <AptlyAutocompleteSelect
            fullWidth
            noOptionsText={i18n.t('statuses.noResults')}
            options={address.unitNumbers.map((x) => ({
              value: x,
              label: x,
            }))}
            textFieldProps={{
              label: i18n.t('singles.unitNumber'),
              required: true,
            }}
            onChange={onUnitNumberChange}
          />
        </Grid2>
      )}
    </Grid2>
  );
}

interface AddressProps {
  label: string;
  address: AptlySearchAddress | null;
  required?: boolean;
  onChange: (value: AptlySearchAddress | null) => void;
}

function AddressQuery({ address, required, onChange, label }: AddressProps) {
  const path = useApiUrl(SlugLevel.Base, 'address');

  return (
    <AptlyAutocompleteQuery<AptlySearchAddress, false, false>
      path={path}
      defaultValue={address}
      multiple={false}
      queryKey="q"
      onChange={onChange}
      autocompleteSearchProps={{
        noOptionsText: i18n.t('actions.searchInKartverket'),
        throttleMs: 1000,
        textFieldProps: {
          required,
        },
        label: label || i18n.t('actions.searchAddress'),
      }}
    />
  );
}

function AddressGeo({ required, onChange }: Pick<AddressProps, 'required' | 'onChange'>): ReactNode {
  const url = useApiUrl(SlugLevel.Base, 'address');
  const [approved, setApproved] = useState(false);
  const [value, setValue] = useState<AptlyAutocompleteSelectOption | null>(null);
  const [list, setList] = useState<AptlySearchAddress[]>([]);
  const [error, setError] = useState<IApiError | null>(null);
  useEffect(() => {
    if ('geolocation' in window.navigator) {
      window.navigator.geolocation.getCurrentPosition(
        (pos) => {
          setApproved(true);
          simpleRequest<AptlySearchPaginateResponse<AptlySearchAddress>>({
            endpoint: url,
            query: { lat: pos.coords.latitude, lng: pos.coords.longitude, limit: '20' },
            onRequestDone: (response) => setList(response.data),
            onError: setError,
          });
        },
        () => {
          setError(new Error('Kunne ikke finne lokasjon'));
        }
      );
    }
  }, [url]);

  const handleOnChange = useCallback(
    (option: AptlyAutocompleteSelectOption | null) => {
      setValue(option);
      if (option) {
        const address = list.find((x) => x._id === option.value);
        onChange(address || null);
      } else {
        onChange(null);
      }
    },
    [onChange, list]
  );
  if (error) {
    return <ApiError error={error} />;
  }
  return (
    <AptlyAutocompleteSelect
      value={value}
      onChange={handleOnChange}
      blurOnSelect
      multiple={false}
      noOptionsText={!approved ? i18n.t('actions.approveGeoLocation') : undefined}
      textFieldProps={{
        required,
        label: approved ? i18n.t('actions.selectGeoAddress') : i18n.t('actions.approveGeoLocation'),
      }}
      options={list.map((x) => ({
        value: x._id,
        label: x.name,
      }))}
    />
  );
}
