import { AptlyFieldType, AptlySupplier } from '@aptly-as/types';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaEmail, schemaName } from '../../components/crud/schema/fields.schema';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';

export const supplierSchema = (): ICrudSchema<AptlySupplier> => ({
  name: schemaName({
    autoFocus: true,
  }),
  email: schemaEmail({
    required: false,
  }),
  logo: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.logo'),
    image: {
      accept: acceptImages,
      mediaKey: 'logoMedia',
      width: 320,
      opts: {
        disableFullWidth: true,
        style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
        transforms: 'c_fit',
      },
    },
  },
});

export function useSupplierSchemaField<T extends object, V extends string | null>(
  override?: Partial<ICrudField<T, V>>
): ICrudField<T, V> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'suppliers'),
      query: { archived: false, select: '_id,name' },
      multiple: false,
      queryOnFocus: true,
    }),
    label: i18n.t('singles.supplier'),
    defaultValue: null as V,
    ...override,
  };
}
