import { AptlyMediaSrc } from '@aptly-as/types';
import AptlyMedia from '../../components/AptlyMedia.js';

interface ProductImageProps {
  image: AptlyMediaSrc;
}

export function ProductImage({ image }: ProductImageProps) {
  return (
    <AptlyMedia
      src={image.image || image.src}
      media={image}
      width={320}
      crop="fit"
      style={{ height: 'auto', width: '100%', maxHeight: '250px', objectFit: 'contain' }}
      opts={{ transforms: 'c_pad' }}
    />
  );
}
