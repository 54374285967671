import { useContext } from 'react';
import { Route } from 'react-router-dom';
import { DrawerMenu } from '../../containers/Nav/DrawerMenu';
import Header from '../../containers/Nav/Header';
import { Main } from '../../components/Main';
import { NavBackItem } from '../../containers/Nav/NavBackItem';
import { NavDrawer } from '../../containers/Nav/NavDrawer';
import SidebarRoutes from '../../containers/Nav/SidebarRoutes';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { useUserRoutes } from './user.routes';
import UserAPIClient from './UserAPIClient';
import { UserContext } from './UserContext';

function User() {
  const user = useContext(UserContext);
  const routes = useUserRoutes();

  return (
    <>
      <Header breadcrumbs={[<div key="fn">{user.data.fullName}</div>]} />
      <NavDrawer>
        <NavBackItem to="/" primary={i18n.t('singles.organizations')} />
        <DrawerMenu routes={routes} level={SlugLevel.User} injection="user" />
      </NavDrawer>
      <Main>
        <SidebarRoutes routes={routes}>
          <Route path="clients/new" element={<UserAPIClient />} />
          <Route path="clients/:clientID" element={<UserAPIClient />} />
        </SidebarRoutes>
      </Main>
    </>
  );
}

export default User;
