import { AptlyScopes, AptlyUserInjection } from '@aptly-as/types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { SidebarList } from '../../containers/Nav/sidebar.utils';
import i18n from '../../libraries/i18n';
import UserAPIClients from './UserAPIClients';
import UserSettings from './UserSettings';

export function useUserRoutes(): SidebarList<AptlyUserInjection>[] {
  return [
    {
      injection: AptlyUserInjection.Base,
      scope: AptlyScopes.Unit,
      items: [
        {
          scope: AptlyScopes.Unit,
          path: 'instillinger',
          icon: <SettingsOutlinedIcon />,
          label: i18n.t('singles.settings'),
          element: <UserSettings />,
        },
        {
          scope: AptlyScopes.Admin,
          path: 'clients',
          icon: <VpnKeyOutlinedIcon />,
          label: i18n.t('singles.oauth2'),
          element: <UserAPIClients />,
        },
      ],
    },
  ];
}
