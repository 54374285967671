import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { MOBILE_BREAK } from '../../utils/themes';

interface INavigationContextState {
  open: boolean;
  mobile: boolean;
}

interface INavigationContext {
  state: INavigationContextState;
  toggle: () => void;
}

const isDefaultMobile = window.innerWidth <= MOBILE_BREAK;
export const NavContext = createContext<INavigationContext>({
  state: {
    open: false,
    mobile: isDefaultMobile,
  },
  toggle: () => {},
});

export function NavProvider({ children }: PropsWithChildren<object>) {
  const mobile = useRef(false);
  const [state, setState] = useState<INavigationContextState>({
    open: false,
    mobile: isDefaultMobile,
  });

  useEffect(() => {
    function onResize() {
      const isMobile = window.innerWidth <= MOBILE_BREAK;
      if (mobile.current !== isMobile) {
        mobile.current = isMobile;
        setState((s) => ({ ...s, mobile: isMobile }));
      }
    }
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const toggle = useCallback(() => {
    setState((s) => ({ ...s, open: !s.open }));
  }, []);

  return <NavContext.Provider value={{ state, toggle }}>{children}</NavContext.Provider>;
}
