import { AptlyScopes } from '@aptly-as/types';
import { useMemo } from 'react';
import { useScope } from './ScopeContext.js';
import { ILevelItem } from './useSlugLevel.js';
import useScopeLevel, { IUseScopePropsProps } from './useScopeLevel.js';

export interface IUseScope {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export type IUseScopeProps = AptlyScopes | IUseScope;

export default function useScopeModel(props: IUseScopeProps): IUseScope {
  const scope = useScope();
  return useMemo(
    () =>
      typeof props === 'string'
        ? {
            create: scope.crud(props, 'C'),
            read: scope.crud(props, 'R'),
            update: scope.crud(props, 'U'),
            delete: scope.crud(props, 'D'),
          }
        : props,
    [props, scope]
  );
}

export const useScopePropsModel = (props: IUseScopePropsProps, item?: ILevelItem) => {
  const scope = useScopeLevel(props, item);
  return useScopeModel(scope);
};
