import { IUseGetRet } from '@ewb/reach-react/core/useGet';
import dayjs, { Dayjs } from 'dayjs';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AptlyAvailability,
  AptlyAvailabilityCalendar,
  AptlyAvailabilityCalendarAvailableTime,
  AptlyBooking,
} from '@aptly-as/types';
import { IUseFieldRet, useFields, useGet } from '@ewb/reach-react';
import handleError from '../../containers/Error/handleError';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import apiRequest from '../../libraries/fetch/apiRequest';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import i18n from '../../libraries/i18n';
import { matchId } from '../../libraries/mongoose';
import { useUser } from '../User/UserContext';
import { useCalendarSchema } from './booking.schema';
import { BookingReport, GetBookingReportParams } from './booking.types';

export type AptlyAvailabilityContext = {
  state: IUseGetRet<AptlyAvailability, IApiError>[0];
  get: IUseGetRet<AptlyAvailability, IApiError>[1];
  setState: IUseGetRet<AptlyAvailability, IApiError>[2];
  data?: AptlyAvailability;
  loaded: boolean;
};
export const AvailabilityContext = createContext<AptlyAvailabilityContext>({
  state: {
    busy: false,
  },
  data: undefined,
  get: () => Promise.resolve(null),
  setState: () => {},
  loaded: false,
});

export function useAvailability() {
  const { data } = useContext(AvailabilityContext);
  return data!;
}

export function AvailabilityProvider({ children }: PropsWithChildren<{ id?: string }>) {
  const path = useGetApiUrl('project', '/bookings');
  const [state, get, setState] = useGet<AptlyAvailability, IApiError>(path);

  return (
    <AvailabilityContext.Provider value={{ state, get, setState, data: state.data, loaded: true }}>
      {children}
    </AvailabilityContext.Provider>
  );
}

export type AptlyCalendarContext = IUseFieldRet<
  AptlyAvailabilityCalendar,
  IApiError,
  ICrudFieldData<AptlyAvailabilityCalendar>
> & {
  data: AptlyAvailabilityCalendar;
  loaded: boolean;
};

export const CalendarContext = createContext<AptlyCalendarContext>({ data: {}, loaded: false } as any);

export function useCalendar() {
  const { data } = useContext(CalendarContext);
  return data;
}

export function CalendarProvider({ id, children }: PropsWithChildren<{ id: string }>) {
  const availability = useAvailability();
  const path = useGetApiUrl('project', `/bookings/${availability._id}/calendars`);
  const crud = useFields<AptlyAvailabilityCalendar, IApiError, ICrudFieldData<AptlyAvailabilityCalendar>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useCalendarSchema(),
    useMemo(() => ({ idKey: '_id', initWithGet: true }), [])
  );

  const value = useMemo(() => ({ data: crud.state.data, loaded: true, ...crud }), [crud]);

  if (crud.state.error) return <ApiError error={crud.state.error} />;
  if (!crud.state.data.name) return null;

  return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
}

export interface IBookingContext {
  state: BookingState;
  setDate: (date: Dayjs) => void;
  setBusy: (busy: boolean) => void;
  getTimes: (date: string) => void;
  getReport: () => void;
  getBookings: (from?: string, to?: string) => void;
  addUserAvailability: () => void;
  deleteUserAvailability: () => void;
  calendarPath: string;
}

export interface BookingState {
  busy: boolean;
  date: Dayjs;
  times: AptlyAvailabilityCalendarAvailableTime[];
  bookings: AptlyBooking[];
  reportParams?: GetBookingReportParams;
  report: BookingReport;
}

const initialState: BookingState = {
  busy: false,
  date: dayjs(),
  times: [],
  bookings: [],
  reportParams: {},
  report: {
    available: [],
    booked: [],
  },
};
export const BookingContext = createContext<IBookingContext>({
  state: initialState,
  setDate: () => {},
  setBusy: () => {},
  getTimes: () => {},
  getBookings: () => {},
  getReport: () => {},
  addUserAvailability: () => {},
  deleteUserAvailability: () => {},
  calendarPath: '',
});

export function BookingProvider({ children }: PropsWithChildren<object>) {
  const [state, setState] = useState<BookingState>(initialState);
  const availability = useAvailability();
  const user = useUser();
  const calendar = useContext(CalendarContext);
  const availabilityPath = useGetApiUrl('project', `/bookings/${availability._id}`);
  const calendarPath = `${availabilityPath}/${calendar.data._id}`;

  const setBusy = useCallback((busy: boolean) => {
    setState((s) => ({ ...s, busy }));
  }, []);

  const setDate = useCallback(
    async (date: Dayjs) => {
      setState((s) => ({ ...s, date, busy: true }));

      const params = {
        month: date.month(),
        year: date.year(),
        calendar: calendar.data._id,
      };
      const requests: [
        Promise<AptlyAvailabilityCalendarAvailableTime[]>,
        Promise<AptlyBooking[]>,
        Promise<BookingReport>,
      ] = [
        fetchTimes(calendarPath, date.format()),
        fetchBookings(calendarPath, {
          from: dayjs(date).startOf('day').format(),
          to: dayjs(date).endOf('day').format(),
        }),
        fetchAvailabilityReport(availabilityPath, params),
      ];

      try {
        const [times, bookings, report] = await Promise.all(requests);
        setState((s) => ({ ...s, times, bookings, report, busy: false }));
      } catch (e) {
        handleError(e);
      }
    },
    [state, calendar.data._id]
  );

  const addUserAvailability = useCallback(async () => {
    try {
      setState((s) => ({ ...s, busy: true }));
      await simpleRequest({
        endpoint: `${calendarPath}/userAvailability`,
        method: 'POST',
        onRequestDoneText: i18n.t('statuses.saved'),
        onRequestDone: () => {
          calendar.setData({
            userAvailability: [...(calendar.getField('userAvailability').value || []), user as any],
          });
          setDate(dayjs());
          setState((s) => ({ ...s, busy: false }));
        },
      });
    } catch (error) {
      handleError(error);
    }
  }, [calendarPath, user]);

  const deleteUserAvailability = useCallback(async () => {
    try {
      setState((s) => ({ ...s, busy: true }));
      await simpleRequest({
        endpoint: `${calendarPath}/userAvailability`,
        method: 'DELETE',
        onRequestDoneText: i18n.t('statuses.saved'),
        confirmMessage: i18n.t('paragraphs.areYouSureToDelete'),
        onRequestDone: () => {
          calendar.setData({
            userAvailability:
              calendar.getField('userAvailability').value?.filter((x) => matchId(x, user)) || [],
          });
          setDate(dayjs());
          setState((s) => ({ ...s, busy: false }));
        },
      });
    } catch (error) {
      handleError(error);
    }
  }, []);

  const getTimes = useCallback(
    async (date: string) => {
      setState((s) => ({ ...s, busy: true }));
      const times = await fetchTimes(calendarPath, date);
      setState((s) => ({ ...s, times, busy: false }));
    },
    [calendarPath]
  );

  const getBookings = useCallback(
    async (from?: string, to?: string) => {
      setState((s) => ({ ...s, busy: true }));
      const bookings = await fetchBookings(calendarPath, {
        from: from || dayjs().format('YYYY-MM-DD'),
        ...(to ? { to } : {}),
      });
      setState((s) => ({ ...s, bookings, busy: false }));
    },
    [calendarPath]
  );

  const getReport = useCallback(async () => {
    setState((s) => ({ ...s, busy: true }));
    const report = await fetchAvailabilityReport(availabilityPath, {
      month: state.date.month(),
      year: state.date.year(),
      calendar: calendar.data._id,
    });
    setState((s) => ({ ...s, report, busy: true }));
  }, [state.date, calendar]);

  useEffect(() => {
    setDate(dayjs());
  }, []);

  return (
    <BookingContext.Provider
      value={{
        state,
        setDate,
        setBusy,
        getTimes,
        getBookings,
        getReport,
        addUserAvailability,
        deleteUserAvailability,
        calendarPath,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
}

async function fetchTimes(path: string, date: string) {
  return await apiRequest<AptlyAvailabilityCalendarAvailableTime[]>(path, {
    data: {
      date,
    },
  });
}

async function fetchBookings(path: string, data: { from: string; to?: string }) {
  return await apiRequest<AptlyBooking[]>(`${path}/booked`, {
    data,
  });
}

async function fetchAvailabilityReport(path: string, params?: GetBookingReportParams) {
  return await apiRequest<BookingReport>(`${path}/report`, {
    data: params,
  });
}
