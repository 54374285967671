import { AptlyOption } from '@aptly-as/types';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AppBar from '@mui/material/AppBar';
import Grid2 from '@mui/material/Grid2';
import DialogContent from '@mui/material/DialogContent';
import { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { AddButtonWithIcon, CancelButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { CancelIconButton, TooltipIconButton } from '../../components/actions/icons/Icons';
import { PrimaryActionFab } from '../../components/actions/Primary.js';
import ApiError from '../../components/ApiError';
import { useDataGridVisibilityModel } from '../../components/Search/search-data-grid/data-grid.hooks';
import { useModal } from '../../containers/Modal/Modal';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { HideDesktopFragment, HideMobileFragment } from '../../hooks/useMobile.js';
import { useStateThrottle } from '../../hooks/useStateThrottle';
import i18n from '../../libraries/i18n';
import { IUseSimpleSearchProps, useSimpleSearch } from '../../libraries/reach/useSimpleSearch';
import { ModalActions } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import { LinearProgresser } from '../../mui/Progresser';
import DataGrid, { dataGridNoRowsOverlay } from '../../mui/x-data-grid/DataGrid';
import { useOptionSearchColumns } from './option.columns';

export interface OptionsSearchProps {
  onSelect: (options: AptlyOption[]) => void;
  $fullScreen?: boolean;
  onClose?: () => void;
}

export function OptionsSearchButton({ onSelect }: Pick<OptionsSearchProps, 'onSelect'>) {
  const onClick = useModal(() => <OptionsSearch $fullScreen onSelect={onSelect} />, {
    fullScreen: true,
  });
  return <PrimaryActionFab onClick={onClick}>{i18n.t('actions.add')}</PrimaryActionFab>;
}

export function OptionsSearch({ $fullScreen, onClose, onSelect }: OptionsSearchProps) {
  const url = useApiUrl(SlugLevel.Organization, 'options/search');
  const [selected, setSelected] = React.useState<AptlyOption[]>([]);
  const columns = useOptionSearchColumns();
  const initialState = useDataGridVisibilityModel(['identification']);
  const searchProps: IUseSimpleSearchProps<AptlyOption> = useMemo(
    () => ({
      limit: 20,
      query: { archived: false },
    }),
    []
  );
  const [busy, options, error, , , actions] = useSimpleSearch<AptlyOption>(url, searchProps);
  const handleOnSave = useCallback(() => {
    onSelect(selected);
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onSelect, onClose, selected]);

  const defaultSearch = useMemo(() => ({}), []);
  const [search, setSearch] = useStateThrottle<OptionAtlasSearch>(defaultSearch, 350, actions.search);
  const handleOnTextSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearch((s) => ({ ...s, q: e.target.value })),
    []
  );

  const rows = useMemo(() => {
    return [
      ...selected,
      ...options.filter((x) => {
        return !selected.some((y) => x._id === y._id);
      }),
    ];
  }, [selected, options]);

  const handleOnSelectionModelChange = useCallback(
    (gsm: GridRowSelectionModel) => {
      setSelected(
        (s) =>
          gsm
            .map((x) => {
              return options.find((p) => p._id === x) || s.find((p) => p._id === x);
            })
            .filter(Boolean) as AptlyOption[]
      );
    },
    [options]
  );

  return (
    <>
      <StyledAppBar enableColorOnDark position="relative" color="inherit">
        <Grid2 container justifyContent="space-between" alignItems="center" spacing={1}>
          <HideDesktopFragment>
            <Grid2>
              <CancelIconButton size="small" onClick={onClose} />
            </Grid2>
          </HideDesktopFragment>
          <Grid2 flex={1}>
            <TextField
              autoComplete="off"
              defaultValue={search.q}
              placeholder={i18n.t('actions.searchInOptions')}
              onChange={handleOnTextSearch}
              fullWidth
            />
          </Grid2>
          <Grid2>
            <TooltipIconButton
              onClick={handleOnSave}
              color="primary"
              size="medium"
              title={i18n.t('actions.add')}
              icon={<AddCircleRoundedIcon />}
            />
          </Grid2>
        </Grid2>
      </StyledAppBar>
      <DialogContent className="no-padding">
        <Grid2 flex={1}></Grid2>
        <Wrapper $fullScreen={$fullScreen}>
          {busy && <LinearProgresser offset />}
          {error && <ApiError error={error} />}

          <DataGrid
            initialState={initialState}
            rowSelectionModel={selected.map((x) => x._id)}
            getRowId={(row) => row._id}
            columns={columns}
            rows={rows}
            onRowSelectionModelChange={handleOnSelectionModelChange}
            hideFooter
            checkboxSelection
            slots={
              search.q
                ? {
                    noRowsOverlay: dataGridNoRowsOverlay(
                      i18n.t('statuses.noResultsWithSearch', { search: search.q })
                    ),
                  }
                : undefined
            }
          />
        </Wrapper>
      </DialogContent>
      <HideMobileFragment>
        <ModalActions>
          <Grid2 container size={{ xs: 6 }} gap={2} justifyContent="flex-end">
            <CancelButtonWithIcon onClick={onClose} />
            <AddButtonWithIcon onClick={handleOnSave} />
          </Grid2>
        </ModalActions>
      </HideMobileFragment>
    </>
  );
}

interface OptionAtlasSearch {
  q?: string;
}

const Wrapper = styled.div<Pick<OptionsSearchProps, '$fullScreen'>>`
  position: relative;
  height: ${(props) => (props.$fullScreen ? '100%' : '80vh')};
`;

const StyledAppBar = styled(AppBar)`
  padding: ${(props) => props.theme.spacing(1)};
`;
