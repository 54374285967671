import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { AptlyError, AptlyScopes, AptlyScopeSchema } from '@aptly-as/types';
import ApiError from '../../components/ApiError';
import handleError from '../../containers/Error/handleError';
import apiRequest from '../fetch/apiRequest';
import Scope, { ScopeModel } from './Scope';
import { IScopeContext, ScopeContext } from './ScopeContext';

interface ScopeProviderProps {
  path: string;
}

interface State<M extends object> {
  busy: boolean;
  error: AptlyError | null;
  models: M;
}

export function ScopeProvider({ path, children }: PropsWithChildren<ScopeProviderProps>) {
  const init = useRef(false);
  const [state, setState] = useState<State<Partial<AptlyScopeSchema>>>({
    busy: true,
    error: null,
    models: {},
  });

  const fetchPermissions = useCallback(async () => {
    try {
      const models = await apiRequest<ScopeModel>(path);
      Scope.scopes = models;
      setState((s) => ({ ...s, busy: false, models, error: null }));
    } catch (error) {
      setState((s) => ({ ...s, busy: false, error: error as AptlyError }));
      handleError(error);
    }
  }, [path]);

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      fetchPermissions().then();
    }
  }, [fetchPermissions]);

  const crud: IScopeContext['crud'] = useCallback(
    (scope, permission = 'R', every = false) => {
      const scopes: AptlyScopes[] = Array.isArray(scope) ? scope : [scope];
      if (every) {
        return scopes.every((x) => state.models[x] && state.models[x]!.includes(permission));
      }
      return scopes.some((x) => state.models[x] && state.models[x]!.includes(permission));
    },
    [state.models]
  );

  if (state.busy) {
    return null;
  }

  if (state.error) {
    return <ApiError error={state.error} />;
  }

  return <ScopeContext.Provider value={{ crud }}>{children}</ScopeContext.Provider>;
}
