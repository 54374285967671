import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Grid2';
import { useContext, useMemo } from 'react';
import createMediaURL from '../Media/createMediaURL';
import Logo from '../../components/Logo';
import { TextLink } from '../../libraries/router/ComponentLink';
import i18n from '../../libraries/i18n';
import StatusText from '../../components/StatusText';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { ProjectContext } from './ProjectContext';

function ProjectHeader() {
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const slug = useMemo(() => organization?.data?.slug || '', [organization]);
  const projectID = useMemo(() => project?.data?._id || '', [project]);
  const logo = useMemo(
    () => (project.data?.theme.images?.logo ? createMediaURL(project.data.theme.images.logo) : ''),
    [project.data]
  );
  const to = useMemo(() => `/organisasjoner/${slug}/prosjekter/${projectID}`, [slug, projectID]);

  if (!project?.data) return <StatusText>{i18n.t('statuses.loading')}…</StatusText>;

  return logo ? (
    <TextLink to={to}>
      <Grid2 container alignItems="center">
        <Logo key="logo" gfx={logo} />
        <Typography variant="body1">{project.data.name}</Typography>
      </Grid2>
    </TextLink>
  ) : (
    <TextLink to={to}>{project.data.name}</TextLink>
  );
}

export default ProjectHeader;
