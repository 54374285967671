import { PropsWithChildren, useCallback } from 'react';
import { AptlyCurrency, AptlyQuantity, AptlyQuantityUnitCode } from '@aptly-as/types';
import i18n from '../../libraries/i18n.js';
import { FormatContext, useIntlLocale } from './Format.js';

export function FormatProvider({ children }: PropsWithChildren) {
  const locale = useIntlLocale();
  const amount = useCallback(
    (amount: number, currency?: AptlyCurrency) => formatAmount(locale, amount, currency),
    [locale]
  );
  const date = useCallback((date: string) => formatDate(locale, date), [locale]);
  const dateTime = useCallback((date: string) => formatDateTime(locale, date), [locale]);

  return (
    <FormatContext.Provider value={{ amount, quantity: formatQuantity, date, dateTime }}>
      {children}
    </FormatContext.Provider>
  );
}

function formatQuantity(item: Partial<AptlyQuantity>, quantity = item.quantity) {
  const label = i18n.t(
    'types:standards.quantityUnitCode.' + item.quantityUnitCode || AptlyQuantityUnitCode.Piece,
    {
      count: quantity || 0,
    }
  );
  if (!quantity) return label;
  return `${quantity} ${label}`;
}

function formatAmount(
  locale: string,
  amount: number,
  currency: AptlyCurrency = AptlyCurrency.NorwegianKrone
) {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(amount / 100);
}

function formatDate(locale: string, date: string) {
  return Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(date));
}

function formatDateTime(locale: string, date: string) {
  return Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
}
