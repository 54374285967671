import { Container } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import {
  AptlyDocumentType,
  AptlyFieldType,
  AptlyUnit,
  AptlyUnitTemplateCategory,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import {
  DownloadIconButton,
  SetPriceIconButton,
  UploadIconButton,
} from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import apiRequest from '../../libraries/fetch/apiRequest';
import Resource from '../../components/Resource';
import Accordion from '../../components/Accordion';
import BusyWrapper from '../../components/BusyWrapper';
import ActionBar from '../../components/ActionBar';
import { acceptPdf } from '../../libraries/react-dropzone/drop-zone.utils';
import { LinearProgresser } from '../../mui/Progresser';
import Centered from '../../components/Centered';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import Section from '../../components/Section';
import { AccordionBody, AccordionDetails } from '../../mui/Accordion';
import Grid from '../../mui/Grid';
import { TextLink } from '../../libraries/router/ComponentLink';
import { TextField } from '../../mui/Input';
import SimpleCrud from '../../components/SimpleCrud';
import { Category } from '../UnitTemplate/unit-template.types';
import i18n from '../../libraries/i18n';
import { minSpacing } from '../../utils/spacing';
import ThirdPartyAddExtraProduct from '../../containers/ThirdParty/ThirdPartyAddExtraProduct';
import ThirdPartyExtraProducts from '../../containers/ThirdParty/ThirdPartyExtraProducts';
import Spacer from '../../components/Spacer';
import CrudUploadDocument from '../../components/simpleCruds/document/CrudUploadDocument';
import EmptyState from '../../containers/Error/EmptyState';
import { downloadDocument } from '../../libraries/files-saver';
import { IDocumentSchema } from '../Document/document.schema';

type ListUnit = {
  _id: string;
  name: string;
};

type State = {
  units: ListUnit[];
  name: string;
};

function OrdersThirdParty() {
  return (
    <Routes>
      <Route path="" element={<SelectUnit />} />
      <Route path=":unitID" element={<HandleUnit />} />
    </Routes>
  );
}

function SelectUnit() {
  const code = useRef('');
  const location = useLocation();
  const [state, setState] = useState<State>({
    units: [],
    name: '',
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    code.current = params.get('kode') || '';

    try {
      apiRequest<State['units']>('third-party/units', {
        customAuth: code.current,
      }).then((units) => {
        setState((s) => ({
          ...s,
          units,
        }));
      });
    } catch (error) {
      alert(i18n.t('statuses.linkNotActive'));
    }
  }, []);

  const { units, name } = state;
  return (
    <Container maxWidth="md">
      <Section>
        <Typography variant="h1">Vennligst velg enhet</Typography>
      </Section>
      <Section>
        <TextField
          value={name}
          onChange={(e) => setState((s) => ({ ...s, name: e.target.value }))}
          label={i18n.t('actions.searchUnit')}
        />
      </Section>
      <Table>
        <TableHead>
          <TableCell>{i18n.t('singles.unit')}</TableCell>
        </TableHead>
        <TableBody>
          {units &&
            units
              .filter((u) => u.name.match(new RegExp(name, 'i')))
              .map((u) => (
                <TableRow key={u._id}>
                  <TableCell>
                    <TextLink to={`/varelinjer/${u._id}?kode=${code.current}`}>{u.name}</TextLink>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Container>
  );
}

type HandleUnitState = {
  unit: AptlyUnit | null;
  categories: Category[];
  code: string;
};

function HandleUnit() {
  const { unitID } = useParams<{ unitID: string }>();
  const [state, setState] = useState<HandleUnitState>({
    unit: null,
    categories: [],
    code: '',
  });

  useEffect(() => {
    async function refresh(code: string) {
      try {
        const [unit, categories] = await Promise.all([getUnit(code), getCategories(code)]);
        setState({ unit, categories, code });
      } catch (error) {
        alert(i18n.t('statuses.linkNotActive'));
      }
    }
    const params = new URLSearchParams(location.search);
    const code = params.get('kode') || '';
    refresh(code || state.code);
  }, [state.code]);

  const getUnit = useCallback(
    (code: string) => {
      return apiRequest<AptlyUnit>(`third-party/units/${unitID}`, {
        customAuth: code,
      });
    },
    [unitID]
  );

  const getCategories = useCallback(
    (code: string) => {
      return apiRequest<Category[]>(`third-party/units/${unitID}/categories`, {
        customAuth: code,
      });
    },
    [unitID]
  );

  const refreshUnit = useCallback(async () => {
    try {
      const unit = await getUnit(state.code);
      setState((s) => ({ ...s, unit }));
    } catch (error) {
      alert(i18n.t('statuses.linkNotActive'));
    }
  }, [getUnit, state.code]);

  const { unit, categories, code } = state;

  if (!unit) return null;

  const unitEndpoint = `third-party/units/${unit._id}`;

  return (
    <Container maxWidth="md">
      <Section style={{ width: '100%' }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">{unit.name}</Typography>
          </Grid>
          <Grid item>
            <ThirdPartyAddExtraProduct code={code} unitID={unit._id} refresh={refreshUnit} />
          </Grid>
        </Grid>
      </Section>
      <Grid container>
        <Typography variant="h2">{i18n.t('singles.extraOptions')}</Typography>
        {unit && <ThirdPartyExtraProducts unit={unit} categories={categories} />}
      </Grid>
      <Spacer />
      <Typography variant="h2">{i18n.t('singles.optionList')}</Typography>
      <Grid container direction="row" spacing={minSpacing}>
        {categories.map((c) => (
          <Grid item sm={12} key={c._id}>
            <Accordion title={c.name}>
              {() => {
                const categoryEndpoint = `${unitEndpoint}/categories/${c._id}`;
                return (
                  <Resource<AptlyUnitTemplateCategory> endpoint={categoryEndpoint} customAuth={code}>
                    {({ busy, data, refresh }) => {
                      if (!data || Array.isArray(data)) {
                        return <LinearProgresser />;
                      }

                      const hasPickTool = (p: AptlyUnitTemplateCategorySectionProduct) =>
                        p.params?.some((x) => x.key === 'pickTool' && x.value === 'thirdParty');
                      const category = data;
                      const hasProducts = category.sections.some(
                        (s) => s.products.filter(hasPickTool).length > 0
                      );

                      if (!hasProducts) {
                        return <EmptyState>{i18n.t('statuses.nothing')}</EmptyState>;
                      }

                      return (
                        <BusyWrapper $busy={busy} key={data._id}>
                          <AccordionDetails>
                            <AccordionBody>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{i18n.t('singles.product')}</TableCell>
                                    <TableCell align="right">{i18n.t('singles.customerAmount')}</TableCell>
                                    <TableCell>
                                      <Centered>{i18n.t('singles.clientChoice')}</Centered>
                                    </TableCell>
                                    <TableCell align="right">{i18n.t('singles.actions')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {category.sections.map((s) => {
                                    const sectionEndpoint = `${categoryEndpoint}/sections/${s._id}`;
                                    const products = s.products.filter(hasPickTool);

                                    return products.map((p) => {
                                      const productEndpoint = `${sectionEndpoint}/products/${
                                        p._id ? p._id : ''
                                      }`;
                                      const selectedOption = unit.selectedOptions?.find(
                                        (o) => o.sectionProductRef === p._id
                                      );
                                      const selected = Boolean(s.standard || selectedOption);
                                      const document = p.pick?.params?.find((x: any) => x.key === 'document');
                                      const showDocumentTool = selected && !s.standard;

                                      return (
                                        <TableRow key={p._id}>
                                          <TableCell>{p.product ? p.product.name : p.text}</TableCell>
                                          <TableCell align="right">
                                            {!p.partOfPackage && p.unitCost}
                                          </TableCell>
                                          <TableCell>
                                            <Centered>
                                              {selected ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlank />}
                                            </Centered>
                                          </TableCell>
                                          <TableCell>
                                            {!p.partOfPackage && (
                                              <ActionBar>
                                                {document && document.downloadToken && (
                                                  <DownloadIconButton
                                                    onClick={() => {
                                                      downloadDocument(document);
                                                    }}
                                                  />
                                                )}
                                                {showDocumentTool && (
                                                  <UploadAction
                                                    showPrice={!document}
                                                    endpoint={productEndpoint}
                                                    onCreate={() => {
                                                      refreshUnit();
                                                      refresh();
                                                    }}
                                                    code={code}
                                                  />
                                                )}
                                                {document && selected && (
                                                  <PriceAction
                                                    endpoint={productEndpoint}
                                                    onCreate={refresh}
                                                    product={p}
                                                    code={code}
                                                  />
                                                )}
                                              </ActionBar>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    });
                                  })}
                                </TableBody>
                              </Table>
                            </AccordionBody>
                          </AccordionDetails>
                        </BusyWrapper>
                      );
                    }}
                  </Resource>
                );
              }}
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

interface PriceActionProps {
  product: AptlyUnitTemplateCategorySectionProduct;
  endpoint: string;
  code: string;
  onCreate: () => void;
}

function PriceAction({ product, endpoint, code, onCreate }: PriceActionProps) {
  const handleOnClick = useCallback(
    () =>
      createModal(
        <SimpleCrud
          title={i18n.t('actions.applyCustomerAmount')}
          endpoint={`${endpoint}/set-price`}
          customAuth={code}
          actionLabel={i18n.t('actions.save')}
          onCreate={onCreate}
          fields={[
            {
              type: 'number',
              name: 'price',
              value: product.unitCost ? product.unitCost : null,
              label: product.unitCost ? product.unitCost.toString() : '',
              helpField: i18n.t('singles.price'),
            },
          ]}
        />
      ),
    [endpoint, code, onCreate, product]
  );
  return <SetPriceIconButton onClick={handleOnClick} />;
}

interface UploadActionProps {
  endpoint: string;
  code: string;
  onCreate: (doc: any) => void;
  showPrice?: boolean;
}

function UploadAction({ endpoint, code, onCreate, showPrice }: UploadActionProps) {
  const uploadDocument = useCallback(() => {
    createModal(
      <CrudUploadDocument<{ price: number } & IDocumentSchema>
        onCreate={onCreate}
        endpoint={`${endpoint}/set-document`}
        options={[
          {
            label: i18n.t('singles.document'),
            value: AptlyDocumentType.ProductAttachment,
          },
        ]}
        customAuth={code}
        accept={acceptPdf}
        fields={
          showPrice
            ? {
                price: {
                  label: i18n.t('singles.price'),
                  type: AptlyFieldType.Number,
                  defaultValue: 0,
                  required: true,
                },
              }
            : {}
        }
      />
    );
  }, [endpoint, onCreate, code, showPrice]);

  return <UploadIconButton onClick={uploadDocument}>{i18n.t('actions.uploadDocument')}</UploadIconButton>;
}

export default OrdersThirdParty;
