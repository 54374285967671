import { AptlyOrganization, AptlyOrganizationInjection, AptlyScopes } from '@aptly-as/types';
import AppsIcon from '@mui/icons-material/Apps';
import AttachMoney from '@mui/icons-material/AttachMoney';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import Cloud from '@mui/icons-material/CloudDownloadOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import Functions from '@mui/icons-material/Functions';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Label from '@mui/icons-material/Label';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Star from '@mui/icons-material/Star';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import { SidebarList } from '../../containers/Nav/sidebar.utils';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Typography from '../../mui/Typography';
import Algorithms from '../Algorithm/Algorithms';
import OrganizationApps from '../App/OrganizationApps';
import OrganizationDepartments from '../Department/OrganizationDepartments';
import Modules from '../Module/Modules';
import Options from '../Option/Options.js';
import OptionLabels from '../OptionLabel/OptionLabels';
import Orders from '../Order/Orders';
import OrderItems from '../Order/OrdersItems';
import Pages from '../Page/Pages';
import Payments from '../Payment/Payments';
import PaymentSettlements from '../PaymentSettlement/PaymentSettlements.js';
import ProductsV2 from '../Product/Products';
import Projects from '../Project/Projects';
import QueueDownloads from '../QueueDownloads/QueueDownloads';
import Recommendations from '../Recommendation/Recommendations';
import UnitTemplates from '../UnitTemplate/UnitTemplates';
import UpsellTemplates from '../UpsellTemplates/UpsellTemplates';
import OrganizationDashboard from './OrganizationDashboard';
import OrganizationInvites from './OrganizationInvites';
import OrganizationMembers from './OrganizationMembers';
import OrganizationProducerDashboard from './OrganizationProducerDashboard';
import OrganizationSettings from './OrganizationSettings';

export function useOrganizationRoutes(
  orgProducer: AptlyOrganization['producer']
): SidebarList<AptlyOrganizationInjection>[] {
  return [
    {
      injection: AptlyOrganizationInjection.Base,
      scope: AptlyScopes.Organization,
      mobile: true,
      items: [
        {
          label: i18n.t('singles.dashboard'),
          scope: AptlyScopes.Organization,
          path: '',
          icon: <DashboardIcon />,
          element: <OrganizationDashboard />,
          mobile: true,
        },
        ...(orgProducer
          ? [
              {
                label: i18n.t('singles.producer'),
                scope: AptlyScopes.Admin,
                path: 'org-produsent',
                icon: <BuildOutlinedIcon />,
                element: <OrganizationProducerDashboard />,
              },
            ]
          : []),
      ],
    },
    {
      injection: AptlyOrganizationInjection.Project,
      scope: [AptlyScopes.OrganizationMembers, AptlyScopes.Project],
      subheader: i18n.t('singles.project'),
      mobile: true,
      items: [
        {
          scope: AptlyScopes.Project,
          label: i18n.t('singles.projects'),
          path: 'prosjekter',
          icon: <HomeWorkIcon />,
          element: <Projects />,
          mobile: true,
        },
        {
          scope: AptlyScopes.OrganizationOrders,
          label: i18n.t('singles.orders'),
          path: 'ordre',
          icon: <ShoppingCartOutlinedIcon />,
          element: <Orders />,
        },
        {
          scope: AptlyScopes.OrganizationPayment,
          crud: 'U',
          path: 'betaling',
          label: i18n.t('singles.payment'),
          icon: <PaymentIcon />,
          element: <Payments />,
        },
        {
          scope: [AptlyScopes.OrganizationUnitTemplates, AptlyScopes.OrganizationOrders],
          every: true,
          label: i18n.t('singles.products'),
          path: 'ordre-produkter',
          icon: <ShoppingCartOutlinedIcon />,
          element: <OrderItems />,
        },
      ],
    },
    {
      injection: AptlyOrganizationInjection.Options,
      scope: [AptlyScopes.OrganizationProducts, AptlyScopes.OrganizationOptionLabels],
      subheader: i18n.t('singles.content'),
      items: [
        {
          injection: AptlyOrganizationInjection.Products,
          scope: AptlyScopes.OrganizationProducts,
          icon: <CategoryIcon />,
          label: i18n.t('singles.products'),
          items: orgProducer
            ? [
                {
                  scope: AptlyScopes.OrganizationProducts,
                  path: 'organisasjonsprodukter',
                  icon: <CategoryIcon />,
                  label: i18n.t('singles.producerProducts'),
                  element: <ProductsV2 key="organisasjonsprodukter" level={SlugLevel.Organization} />,
                },
              ]
            : [
                {
                  scope: AptlyScopes.OrganizationProducts,
                  path: 'organisasjonsprodukter',
                  icon: <CategoryIcon />,
                  label: i18n.t('singles.organizationProducts'),
                  element: <ProductsV2 key="organisasjonsprodukter" level={SlugLevel.Organization} />,
                },
                {
                  scope: AptlyScopes.AdminProducts,
                  path: 'produkter',
                  icon: <CategoryIcon />,
                  label: i18n.t('singles.aptlyProducts'),
                  element: <ProductsV2 />,
                },
              ],
        },
        {
          injection: AptlyOrganizationInjection.Content,
          scope: [
            AptlyScopes.OrganizationRecommendations,
            AptlyScopes.OrganizationOptionLabels,
            AptlyScopes.OrganizationPages,
          ],
          icon: <InsertDriveFileOutlinedIcon />,
          label: i18n.t('singles.content'),
          items: [
            {
              scope: AptlyScopes.OrganizationRecommendations,
              path: 'maler/anbefalinger',
              icon: <Star />,
              label: i18n.t('singles.recommendations'),
              element: <Recommendations />,
            },
            {
              scope: AptlyScopes.OrganizationOptionLabels,
              every: true,
              path: 'merkelapper',
              icon: <Label />,
              label: i18n.t('singles.labels'),
              element: <OptionLabels />,
            },
            {
              scope: AptlyScopes.OrganizationPages,
              every: true,
              path: 'sider',
              icon: <WebOutlinedIcon />,
              label: i18n.t('singles.pages'),
              element: <Pages />,
            },
          ],
        },
        {
          scope: [AptlyScopes.OrganizationUpsellTemplates],
          every: true,
          path: 'oppsalg',
          icon: <AttachMoney />,
          label: i18n.t('singles.upsell'),
          element: <UpsellTemplates />,
        },
        {
          scope: [AptlyScopes.OrganizationUnitTemplates],
          every: true,
          path: 'tilvalg',
          label: (
            <Typography variant="body2">
              {i18n.t('singles.catalogTemplates')}
              <br />
              <i>{i18n.t('singles.optionListsTemplates')}</i>
            </Typography>
          ),
          icon: <StyleOutlinedIcon />,
          element: <UnitTemplates />,
        },
      ],
    },
    {
      injection: AptlyOrganizationInjection.Service,
      scope: [AptlyScopes.OrganizationOptions],
      subheader: i18n.t('singles.service'),
      mobile: true,
      items: [
        {
          scope: AptlyScopes.OrganizationOptions,
          crud: 'U',
          path: 'options',
          label: i18n.t('singles.serviceCatalog'),
          icon: <MenuBookIcon />,
          element: <Options level={SlugLevel.Organization} />,
          mobile: false,
        },
        // {
        //   scope: AptlyScopes.OrganizationOffers,
        //   path: 'tilbud',
        //   label: i18n.t('singles.offersTemplates'),
        //   icon: <LocalOfferOutlinedIcon />,
        //   element: <Offers level={SlugLevel.Organization} />,
        //   mobile: true,
        // },
      ],
    },
    {
      injection: AptlyOrganizationInjection.Admin,
      scope: [
        AptlyScopes.Admin,
        AptlyScopes.OrganizationAlgorithms,
        AptlyScopes.OrganizationMembers,
        AptlyScopes.OrganizationDepartments,
      ],
      subheader: i18n.t('singles.admin'),
      items: [
        {
          scope: AptlyScopes.OrganizationAlgorithms,
          every: true,
          path: 'kalkyler',
          icon: <Functions />,
          label: i18n.t('singles.calculations'),
          element: <Algorithms />,
        },
        {
          scope: AptlyScopes.OrganizationMembers,
          crud: 'U',
          path: 'brukere',
          label: i18n.t('singles.users'),
          icon: <SupervisorAccountIcon />,
          element: <OrganizationMembers />,
        },
        {
          scope: AptlyScopes.Admin,
          path: 'invitasjoner',
          label: i18n.t('singles.invitations'),
          icon: <SendIcon />,
          element: <OrganizationInvites />,
        },
        {
          scope: AptlyScopes.OrganizationDepartments,
          path: 'avdelinger',
          label: i18n.t('singles.departments'),
          icon: <GroupsIcon />,
          element: <OrganizationDepartments />,
        },
        {
          scope: AptlyScopes.Admin,
          path: 'queue-downloads',
          label: i18n.t('singles.downloadQueue'),
          icon: <Cloud />,
          element: <QueueDownloads />,
        },
        {
          scope: AptlyScopes.Admin,
          crud: 'U',
          path: 'modules',
          label: i18n.t('singles.modules'),
          icon: <ViewModuleIcon />,
          element: <Modules />,
          menuExact: true,
        },
        {
          scope: AptlyScopes.Organization,
          crud: 'U',
          to: 'apps',
          path: 'apps/*',
          label: i18n.t('singles.apps'),
          icon: <AppsIcon />,
          element: <OrganizationApps />,
          menuExact: true,
        },
        {
          scope: AptlyScopes.OrganizationPayment,
          path: 'payment-settlements',
          label: i18n.t('singles.paymentSettlements'),
          icon: <ReceiptOutlinedIcon />,
          element: <PaymentSettlements />,
        },
        {
          scope: AptlyScopes.Organization,
          crud: 'U',
          path: 'instillinger',
          label: i18n.t('singles.settings'),
          icon: <SettingsOutlinedIcon />,
          element: <OrganizationSettings />,
        },
      ],
    },
  ];
}
