import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material';
import {
  AptlyAppImplementation,
  AptlyFieldType,
  AptlyProject,
  AptlyProjectStatus,
  AptlyScopes,
} from '@aptly-as/types';
import { DotObject } from '@ewb/reach-react';
import { useContext } from 'react';
import {
  schemaEmail,
  schemaFile,
  schemaMediaImage,
  schemaName,
  schemaPhone,
} from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { acceptImages, acceptJson } from '../../libraries/react-dropzone/drop-zone.utils';
import { useScope } from '../../libraries/scope/ScopeContext.js';
import { SelectOption } from '../../mui/Select';
import { useAppSchemaField } from '../App/app.schema.js';
import { useOrganizationMembersField } from '../Organization/organization.schema.js';
import { usePagesSchemaField } from '../Page/page.schema.js';
import { UserContext } from '../User/UserContext.js';

export const projectStatusOptions = (withNone?: boolean) => {
  const opts: SelectOption[] = [
    {
      value: AptlyProjectStatus.Template,
      label: i18n.t('singles.template'),
    },
    {
      value: AptlyProjectStatus.NotStarted,
      label: i18n.t('statuses.notStarted'),
    },
    {
      value: AptlyProjectStatus.Started,
      label: i18n.t('statuses.started'),
    },
    {
      value: AptlyProjectStatus.Completed,
      label: i18n.t('statuses.completed'),
    },
  ];
  if (withNone) {
    opts.unshift({
      value: '',
      label: i18n.t('singles.all'),
    });
  }
  return opts;
};

type DotFields = 'theme';
export type IProjectSchema = DotObject<Pick<AptlyProject, DotFields>> &
  Omit<AptlyProject, DotFields> & {
    template?: string | null;
    users?: string[];
    'address.street'?: string;
    'address.zip'?: string;
    'address.city'?: string;
  };

export function useProjectSchema(): ICrudSchema<IProjectSchema> {
  const scope = useScope();
  const { data: user } = useContext(UserContext);

  return {
    name: schemaName({ autoFocus: true }),
    status: {
      type: AptlyFieldType.Select,
      defaultValue: AptlyProjectStatus.NotStarted,
      label: i18n.t('singles.status'),
      options: projectStatusOptions(),
    },
    myPageText: {
      type: AptlyFieldType.Markdown,
      defaultValue: '',
      label: i18n.t('paragraphs.myPageText'),
    },
    inviteText: {
      type: AptlyFieldType.TextArea,
      defaultValue: '',
      label: i18n.t('paragraphs.inviteText'),
    },
    theme: {
      type: AptlyFieldType.Object,
      defaultValue: {
        contact: user
          ? {
              name: user.fullName,
              phone: user.phone,
              mail: user.email,
            }
          : {},
        fonts: {},
        images: {},
        palette: {},
      },
      label: '',
    },
    gdprActive: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: 'GDPR',
    },
    pages: usePagesSchemaField({
      label: i18n.t('singles.informationPages'),
    }),
    'theme.contact.name': schemaName({ defaultValue: user?.fullName || '', required: false }),
    'theme.contact.mail': schemaEmail({ defaultValue: user?.email || '', required: false }),
    'theme.contact.phone': schemaPhone({ defaultValue: user?.phone || '' }),
    'theme.contact.complaintEmail': schemaEmail({
      label: i18n.t('singles.complaint'),
      required: false,
    }),
    'theme.images.logo': {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: `${i18n.t('paragraphs.projectLogo')} (2:1)`,
      image: { mediaKey: 'theme.images.logoMedia', width: 160, height: 100, accept: acceptImages },
    },
    'theme.images.logoMedia': schemaMediaImage(),
    'theme.images.hero': {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: i18n.t('singles.backgroundImage') + ' (3:1)',
      image: { mediaKey: 'theme.images.heroMedia', width: 540, height: 320, accept: acceptImages },
    },
    'theme.images.heroMedia': schemaMediaImage(),
    'theme.images.fallback': {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: `${i18n.t('paragraphs.productsWithoutImage')} (1:2)`,
      image: { mediaKey: 'theme.images.fallbackMedia', width: 320, accept: acceptImages },
    },
    'theme.images.fallbackMedia': schemaMediaImage(),
    template: useProjectSchemaField({
      label: i18n.t('singles.template'),
    }),
    users: useOrganizationMembersField({
      renderValidate: () => scope.crud(AptlyScopes.OrganizationMembers, 'R'),
    }),
    number: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.projectNumber'),
      defaultValue: '',
    },
    developer: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.housingDeveloper'),
      defaultValue: '',
    },
    field: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.constructionField'),
      defaultValue: '',
    },
    address: {
      type: AptlyFieldType.Object,
      label: i18n.t('singles.address'),
      defaultValue: {},
    },
    'address.street': {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.street'),
      defaultValue: '',
    },
    'address.zip': {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.zipCode'),
      defaultValue: '',
    },
    'address.city': {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.city'),
      defaultValue: '',
    },
    invoiceEmail: schemaEmail({ required: false, label: i18n.t('singles.invoiceEmail') }),
    paymentApp: useAppSchemaField(
      {
        label: i18n.t('singles.paymentApp'),
      },
      {
        implements: AptlyAppImplementation.Payment,
      }
    ),
    signApp: useAppSchemaField(
      {
        label: i18n.t('singles.signApp'),
      },
      {
        implements: AptlyAppImplementation.Sign,
      }
    ),
    signMembers: useOrganizationMembersField(),
  };
}

export const projectImportSchema = (): ICrudSchema<IProjectSchema & { file: File | null }> => ({
  name: schemaName(),
  file: schemaFile(acceptJson),
});

export function useProjectSchemaField<T extends object>(
  override?: Partial<ICrudField<T, AptlyProject | null | any>>
): ICrudField<T, AptlyProject | null | any> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () =>
      useProjectAutocompleteQueryProps<T, false>({
        multiple: false,
      }),
    label: i18n.t('singles.project'),
    defaultValue: null,
    ...override,
  };
}

export function useProjectsSchemaField<T extends object, V extends AptlyProject | string>(
  override?: Partial<ICrudField<T, V[]>>
): ICrudField<T, V[]> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () =>
      useProjectAutocompleteQueryProps<T, true>({
        multiple: true,
      }),
    label: i18n.t('singles.projects'),
    defaultValue: [],
    ...override,
  };
}

export function useProjectAutocompleteQueryProps<T extends object, M extends boolean>(
  props?: Omit<
    AptlyAutocompleteQueryProps<T, M>,
    'defaultValue' | 'onChange' | 'path' | 'query' | 'queryOnFocus' | 'queryKey'
  >
): Omit<AptlyAutocompleteQueryProps<T, M>, 'defaultValue' | 'onChange' | 'value'> {
  return {
    path: useApiUrl(SlugLevel.Organization, 'projects/search'),
    query: { archived: false, limit: 5 },
    queryOnFocus: true,
    queryKey: 'name',
    multiple: false as M,
    filter: undefined,
    ...props,
  };
}
