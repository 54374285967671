import { AptlyFieldType, AptlyProject } from '@aptly-as/types';
import dayjs from 'dayjs';
import { preValueToId } from '../../components/crud/schema/schema.utils.js';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';
import { usePeriods } from './usePeriods.js';

export type ICrudPeriod = AptlyProject['periods'][0];

export function usePeriodSchema(): ICrudSchema<ICrudPeriod> {
  const today = dayjs().startOf('day');
  return {
    name: schemaName({
      autoFocus: true,
    }),
    open: {
      type: AptlyFieldType.DateTime,
      defaultValue: today.toISOString(),
      label: i18n.t('singles.from'),
      required: true,
      autoComplete: 'off',
    },
    close: {
      type: AptlyFieldType.DateTime,
      defaultValue: today.add(3, 'months').toISOString(),
      label: i18n.t('singles.to'),
      required: true,
      autoComplete: 'off',
    },
  };
}

export function usePeriodSchemaField<T extends object>(): ICrudField<T, string> {
  const periods = usePeriods();
  return {
    type: AptlyFieldType.Select,
    defaultValue: periods[0]?._id,
    options: periods.map((x) => ({ value: x._id, label: x.name })),
    label: i18n.t('singles.deadlines'),
    preValue: preValueToId,
    required: true,
  };
}
