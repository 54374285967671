import { useFields } from '@ewb/reach-react';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import ApiError, { IApiError } from '../../components/ApiError.js';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils.js';
import { ISimpleCrudModalProps, SimpleCrudModal } from '../../components/simple/SimpleCrud.js';
import { createModal } from '../../containers/Modal/ModalContext.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { useProjectSchema, IProjectSchema } from './project.schema.js';
import { ProjectContext, ProjectContextActions } from './ProjectContext.js';

export function ProjectProvider({
  id,
  children,
}: PropsWithChildren<{
  id: string;
}>) {
  const path = useApiUrl(SlugLevel.Organization, 'projects');
  const crud = useFields<IProjectSchema, IApiError, ICrudFieldData<IProjectSchema>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useProjectSchema(),
    useMemo(() => ({ idKey: '_id', initWithGet: true }), [])
  );

  const spawnPatch = useCallback(
    (fields: (keyof IProjectSchema)[], props: Partial<ISimpleCrudModalProps<IProjectSchema>> = {}) => {
      return createModal(
        <SimpleCrudModal
          crud={crud}
          fields={fields}
          onCreate={crud.setData}
          data={crud.state.data}
          {...props}
        />
      );
    },
    [crud]
  );

  const actions: ProjectContextActions = useMemo(
    () => ({
      ...crud.actions,
      spawnPatch,
    }),
    [crud, spawnPatch]
  );

  if (crud.state.error) return <ApiError error={crud.state.error} />;
  if (!crud.state.data.name) return null;

  return (
    <ProjectContext.Provider value={{ data: crud.state.data, ...crud, actions }}>
      {children as any}
    </ProjectContext.Provider>
  );
}
