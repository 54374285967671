import { AptlyAlgorithm } from '@aptly-as/types';
import { useAlgorithmVisual } from '../../../../models/Algorithm/algorithm.utils';
import { SectionProduct } from '../../../../models/UnitTemplate/unit-template.types';
import Typography from '../../../../mui/Typography';

interface Props {
  baseCost: number;
  algorithm: AptlyAlgorithm;
}

export function getAlgorithm(
  algorithms: AptlyAlgorithm[],
  product?: SectionProduct,
  setDefault = true
): { value: string | null; label: string } {
  if (product && product.algorithm) {
    const alg = product.algorithm as string | AptlyAlgorithm;
    if (typeof alg === 'string') {
      const found = algorithms.find((x) => x._id === alg);
      if (found) {
        return { value: found._id, label: found.name };
      }
    }
    return {
      value: product.algorithm._id,
      label: product.algorithm.name,
    };
  } else if (setDefault && algorithms.length > 0) {
    return {
      value: algorithms[0]._id,
      label: algorithms[0].name,
    };
  }

  return {
    value: null,
    label: '',
  };
}

export default function CrudProductAlgorithm({ baseCost, algorithm }: Props) {
  const visual = useAlgorithmVisual(algorithm, baseCost);
  return <Typography>{visual}</Typography>;
}
