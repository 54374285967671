import { AptlyProduct, AptlyScopes } from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import { useCallback, useContext, useMemo } from 'react';
import { SearchExportButton } from '../../components/actions/buttons/ExportButton';
import Search, {
  ISearchSwitchProps,
  SearchFieldQuery,
  SearchFieldText,
  SearchSwitch,
} from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import {
  ISearchHeaderProps,
  ISearchOptions,
  SearchCrudContext,
  toSearchPatchPath,
} from '../../components/Search/search.utils';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import {
  IUseCrudSearchBulkProps,
  IUseCrudSearchPatchProps,
  IUseCrudSearchPostProps,
} from '../../libraries/reach/useCrudSearch';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { useProducerQueryProps } from '../Producer/producer.schema.js';
import { useTagsAutocompleteQuery } from '../Tag/tag.schema.js';
import { useProductColumns } from './product.columns.js';
import {
  IProductSchema,
  productBulkKeys,
  productEditKeys,
  productNewKeys,
  useProductSchema,
  useProductImportSchemaV2,
} from './product.schema';
import { getProductScope, productGroupProps } from './product.utils';
import { ProductToolbar } from './ProductsToolbar';

interface Props {
  level?: SlugLevel;
}

export default function Products({ level = SlugLevel.Base }: Props) {
  const org = useContext(OrganizationContext);
  const path = useApiUrl(level, 'products');
  const schema = useProductSchema(org.data.producer, true);
  const patchPath = useMemo(() => toSearchPatchPath('products', org.data), [org.data]);
  const importV2 = useProductImportSchemaV2();
  const producerQueryProps = useProducerQueryProps();
  const tagsQueryProps = useTagsAutocompleteQuery();

  const [scopeModel, searchSwitchProps] = useMemo<
    [AptlyScopes, Omit<ISearchSwitchProps, 'field'> | null]
  >(() => {
    if (org.data.producer) {
      return [AptlyScopes.OrganizationProducts, null];
    }
    if (level === SlugLevel.Project) {
      return [
        AptlyScopes.ProjectProducts,
        { offValue: 'project', label: i18n.t('paragraphs.showMeMoreProjectProducts') },
      ];
    }
    if (level === SlugLevel.Organization) {
      return [
        AptlyScopes.OrganizationProducts,
        { offValue: 'organization', label: i18n.t('paragraphs.showMeMoreOrganizationProducts') },
      ];
    }
    return [AptlyScopes.AdminProducts, null];
  }, [level, org]);

  const reachProps: IUsePaginatedSearchProps<AptlyProduct> = useMemo(
    () => ({
      paginationMode: 'server',
      query: {
        select:
          '_id,name,title,productNumber,description,images,length,width,height,organization,project,variants,series,style,material,color,colorLabel',
        sort: 'name',
        $populate: 'producer:_id,name;tags:_id,name;products:_id,name,productNumber',
        archived: false,
        level: '',
      },
    }),
    []
  );

  const searchFields = useMemo(
    () => [
      <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      <SearchFieldText
        key="productNumber"
        field="productNumber"
        queryKey="$productNumber"
        label={i18n.t('singles.productNumber')}
      />,
      ...(org.data.producer
        ? []
        : [<SearchFieldQuery key="producer" field="producer" autocompleteQueryProps={producerQueryProps} />]),
      <SearchFieldQuery key="tags" field="tags" autocompleteQueryProps={tagsQueryProps} />,
      searchSwitchProps ? (
        [
          { xs: 12 },
          <Grid key="level" container justifyContent="center">
            <SearchSwitch field="level" onValue="" {...searchSwitchProps} />
          </Grid>,
        ]
      ) : (
        <></>
      ),
    ],
    [scopeModel, searchSwitchProps, org.data.producer]
  );
  const buttonActions = useCallback(
    () => [
      <SearchExportButton key="export" endpoint={`${path}/export`} />,
      <SearchImportAction key="import" path={`${path}/import-v2`} schema={importV2} disableUnshift />,
    ],
    [path, importV2]
  );

  const header: ISearchHeaderProps = useMemo(
    () => ({
      title: i18n.t('singles.products'),
    }),
    []
  );
  const options: ISearchOptions = useMemo(
    () => ({
      defaultShow: true,
    }),
    []
  );

  const post: IUseCrudSearchPostProps<AptlyProduct> = useMemo(
    () => ({
      title: i18n.t('actions.newProduct'),
    }),
    []
  );
  const patch: IUseCrudSearchPatchProps<AptlyProduct> = useMemo(
    () => ({
      title: (item: any) => String(item.name),
      contentProps: { groupProps: productGroupProps() },
    }),
    []
  );
  const bulk: IUseCrudSearchBulkProps<AptlyProduct> = useMemo(
    () => ({
      title: i18n.t('actions.edit'),
      fields: productBulkKeys,
    }),
    []
  );

  return (
    <Search<IProductSchema>
      path={path}
      patchPath={patchPath}
      scope={scopeModel}
      fields={productEditKeys}
      header={header}
      options={options}
      post={post}
      patch={patch}
      bulk={bulk}
      schema={schema}
      reach={reachProps}
      searchFields={searchFields}
      buttonActions={buttonActions}
      primaryAction={<SearchAddPrimaryAction key="create" keys={productNewKeys} />}
      level={level}
    >
      {() => <ProductDataGrid />}
    </Search>
  );
}

function ProductDataGrid() {
  const org = useContext(OrganizationContext);
  const { query, level } = useContext(SearchCrudContext);
  const columns = useProductColumns();
  const isCellEditable = useCallback((cell: any) => getProductScope(cell.row, org.data).update, [org.data]);
  const initialState: ISearchDataGridProps['initialState'] = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          organization: !!org.data?.producer && level === SlugLevel.Organization,
        },
      },
    }),
    [level, org]
  );
  const props: Partial<ISearchDataGridProps> = useMemo(
    () =>
      !query.level
        ? {
            isCellEditable,
            isRowSelectable: (cell) => getProductScope(cell.row as any, org.data).update,
            Toolbar: ProductToolbar,
          }
        : { isCellEditable, checkboxSelection: false, Toolbar: () => null },
    [query.level, isCellEditable]
  );

  return <SearchDataGrid columns={columns} initialState={initialState} {...props} />;
}
