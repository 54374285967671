import {
  AptlyApp,
  AptlyAppActionModel,
  AptlyOrderPaymentStatus,
  AptlyOrderSigneeType,
  AptlyOrderStatus,
  AptlyPayment,
  AptlyUnit,
  AptlyUser,
} from '@aptly-as/types';
import { usePost } from '@ewb/reach-react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert } from '@mui/material';
import dayjs from 'dayjs';
import React, { Fragment, useCallback, useContext, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  BackButtonWithIcon,
  DeleteButtonWithIcon,
  DoneButtonWithIcon,
  PreviewButtonWithIcon,
  SignButtonWithIcon,
} from '../../components/actions/buttons/Buttons.js';
import ApiError from '../../components/ApiError.js';
import Section from '../../components/Section.js';
import Spacer from '../../components/Spacer.js';
import { useConfirmModal } from '../../containers/Modal/ConfirmModal.js';
import { createModal } from '../../containers/Modal/ModalContext.js';
import sign from '../../containers/Sign/sign.js';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { intlCurrencyDecimal, intlDateTimeFormat } from '../../libraries/intl.js';
import { getId } from '../../libraries/mongoose.js';
import Card, { CardContent } from '../../mui/Card.js';
import Grid from '../../mui/Grid.js';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table.js';
import Typography from '../../mui/Typography.js';
import { useDocumentPreview } from '../Document/DocumentPreview.js';
import { OrderPaymentAction } from '../Payment/PaymentCapture.js';
import { OrderDeliveryAction } from '../Payment/PaymentDelivery.js';
import { WebhookAppActions } from '../Webhook/WebhookAppActions.js';
import HandleOrder, { handleOrderModelOptions } from './HandleOrder/HandleOrder.js';
import { orderSignedAt, orderStatusLabel, orderStatusPaymentLabel } from './order.utils.js';
import { OrderContext, OrderProvider } from './OrderContext.js';

const COLUMN_SPACING = 3;

export function Order() {
  return (
    <OrderProvider>
      <Section>
        <OrderContent />
      </Section>
    </OrderProvider>
  );
}

interface OrderDates {
  created: string;
  signed: string;
  cart: string;
  paid: string;
  captured: string;
  installed: string;
}

function OrderContent() {
  const { order } = useContext(OrderContext);
  const signedDocument = order.signedReceipt || order.signage.document;
  const receipt = (order.payment as AptlyPayment)?.document;
  const report = (order.payment as AptlyPayment)?.reportDocument;
  const previewDocument = useDocumentPreview({ document: order.receipt });
  const previewSignedDocument = useDocumentPreview({ document: signedDocument });
  const previewReceipt = useDocumentPreview({ document: receipt });
  const previewReport = useDocumentPreview({ document: report });
  const hasSigning = !!order.signage.createdAt || (order.signees?.length || 0) > 0;
  const hasPayment = order.paymentSession?.at;

  return (
    <Grid container flexDirection="column" spacing={COLUMN_SPACING}>
      <Grid container item justifyContent="space-between" spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h1">{order.orderNumber}</Typography>
        </Grid>
        <Grid container xs={12} lg={6} item spacing={2} justifyContent="flex-end">
          {order.receipt && (
            <Grid item>
              <PreviewButtonWithIcon onClick={previewDocument}>
                {i18n.t('singles.document')}
              </PreviewButtonWithIcon>
            </Grid>
          )}
          {signedDocument && (
            <Grid item>
              <PreviewButtonWithIcon onClick={previewSignedDocument}>
                {i18n.t('singles.signed')}
              </PreviewButtonWithIcon>
            </Grid>
          )}
          {report && (
            <Grid item>
              <PreviewButtonWithIcon onClick={previewReport}>
                {i18n.t('singles.report')}
              </PreviewButtonWithIcon>
            </Grid>
          )}{' '}
          {receipt && (
            <Grid item>
              <PreviewButtonWithIcon onClick={previewReceipt}>
                {i18n.t('singles.receipt')}
              </PreviewButtonWithIcon>
            </Grid>
          )}
        </Grid>
      </Grid>
      <OrderAlertInfo />
      <Grid item>
        <OrderActions />
      </Grid>
      <Grid container spacing={2} item flexWrap="wrap">
        <Grid item xs={12} lg={6} xl={4}>
          <OrderInfo />
        </Grid>
        {hasSigning && (
          <Grid item xs={12} lg={6} xl={5}>
            <OrderSigning />
          </Grid>
        )}
        {hasPayment && (
          <Grid item xs={12} lg={6} xl={5}>
            <OrderPayment />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function OrderAlertInfo() {
  const { order } = useContext(OrderContext);
  const alert = useMemo(() => {
    if (
      order.status === AptlyOrderStatus.Payment &&
      order.paymentSession?.status === AptlyOrderPaymentStatus.Pending
    ) {
      return i18n.t('paragraphs.unitNotPayed');
    } else if ([AptlyOrderStatus.NotSigned, AptlyOrderStatus.ReadyToSign].includes(order.status)) {
      const usersNotSigned = order.signees?.some((x) => !x.signedAt && x.type === AptlyOrderSigneeType.Unit);
      if (usersNotSigned) {
        return i18n.t('paragraphs.unitNotSigned');
      }
      const adminNotSigned = order.signees?.some((x) => !x.signedAt && x.type === AptlyOrderSigneeType.Admin);
      if (adminNotSigned) {
        return i18n.t('paragraphs.adminNotSigned');
      }
    }
    return '';
  }, [order]);

  if (!alert) return null;
  return (
    <Grid item>
      <Alert severity="warning">{alert}</Alert>
    </Grid>
  );
}

function OrderActions() {
  const { order, get } = useContext(OrderContext);
  const to = useTo(SlugLevel.Project, '/ordre');
  const navigate = useNavigate();

  const canConfirm = order.status === AptlyOrderStatus.New;
  const canDeliver =
    order.status === AptlyOrderStatus.Payment &&
    order.paymentSession?.status !== AptlyOrderPaymentStatus.Pending;
  const canAnnul = order.status !== AptlyOrderStatus.Invalid && !order.paymentSession?.capturedAt;

  return (
    <>
      <Grid container gap={2}>
        <BackButtonWithIcon title={i18n.t('singles.orders')} onClick={() => navigate(to)} />
        {canDeliver && <OrderDeliveryAction />}
        {canConfirm && <ConfirmOrderAction />}
        <OrderPaymentAction order={order} payment={order.payment as AptlyPayment} onSave={get} />
        {canAnnul && <AnnullOrderAction />}
        <WebhookAppActions model={AptlyAppActionModel.Order} document={order} />
      </Grid>
    </>
  );
}

function OrderInfo() {
  const { order } = useContext(OrderContext);
  const toUnit = useTo(SlugLevel.Project, `/enheter/${getId(order.unit)}`);

  const dates: OrderDates = useMemo(() => {
    const signedAt = orderSignedAt(order);
    const session = order.paymentSession || {};
    const payment = order.payment ? (order.payment as AptlyPayment) : null;
    return {
      created: intlDateTimeFormat(order.created || order.createdAt),
      signed: signedAt ? intlDateTimeFormat(signedAt) : '',
      cart: session.at ? intlDateTimeFormat(session.at) : '',
      paid: session.paidAt ? intlDateTimeFormat(session.paidAt) : '',
      installed: payment?.shippingDate ? intlDateTimeFormat(payment.shippingDate) : '',
      captured: payment?.captured.at ? intlDateTimeFormat(payment.captured.at) : '',
    };
  }, [order]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h2" gutterBottom>
          {i18n.t('singles.info')}
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{i18n.t('singles.unit')}</TableCell>
              <TableCell align="right">
                <NavLink to={toUnit}>{(order.unit as AptlyUnit).name}</NavLink>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{i18n.t('singles.status')}</TableCell>
              <TableCell align="right">{orderStatusLabel(order.status)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{i18n.t('singles.total')}</TableCell>
              <TableCell align="right">{intlCurrencyDecimal(order.totalCost)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{i18n.t('singles.vat')}</TableCell>
              <TableCell align="right">{intlCurrencyDecimal(order.totalVat || 0)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{i18n.t('statuses.created')}</TableCell>
              <TableCell align="right">
                {dates.created}
                <br />
                {(order.createdBy as AptlyUser)?.fullName || ''}
              </TableCell>
            </TableRow>
            {order.approved?.at && (
              <TableRow>
                <TableCell>{i18n.t('statuses.approved')}</TableCell>
                <TableCell align="right">
                  {intlDateTimeFormat(order.approved.at)}
                  <br />
                  {(order.approved.user as AptlyUser).fullName}
                </TableCell>
              </TableRow>
            )}
            {dates.signed && (
              <TableRow>
                <TableCell>{i18n.t('statuses.signed')}</TableCell>
                <TableCell align="right">{dates.signed}</TableCell>
              </TableRow>
            )}
            {dates.cart && (
              <TableRow>
                <TableCell>{i18n.t('statuses.openedCart')}</TableCell>
                <TableCell align="right">{dates.cart}</TableCell>
              </TableRow>
            )}
            {dates.paid && (
              <TableRow>
                <TableCell>{i18n.t('statuses.reserved')}</TableCell>
                <TableCell align="right">{dates.paid}</TableCell>
              </TableRow>
            )}
            {dates.installed && (
              <TableRow>
                <TableCell>
                  {dayjs(dates.installed).isAfter(dayjs())
                    ? i18n.t('statuses.installed')
                    : i18n.t('statuses.delivery')}
                </TableCell>
                <TableCell align="right">{dates.installed}</TableCell>
              </TableRow>
            )}
            {dates.captured && (
              <TableRow>
                <TableCell>{i18n.t('statuses.captured')}</TableCell>
                <TableCell align="right">{dates.captured}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function OrderSigning() {
  const { order, get } = useContext(OrderContext);
  const endpoint = useApiUrl(SlugLevel.Project, 'orders');
  const handleSign = useCallback(
    (user: AptlyUser) =>
      sign({
        orderEndpoint: `${endpoint}/${order._id}`,
        initializeSigning: order.status === AptlyOrderStatus.NotSigned,
        user,
        unit: order.unit as AptlyUnit,
        refresh: () => get(),
      }),
    [endpoint, order._id, order.status, order.signees, get]
  );

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h2">Signering</Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t('singles.user')}</TableCell>
              <TableCell align="right">{i18n.t('statuses.signed')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.signees?.map((signee) => (
              <TableRow key={signee._id}>
                <TableCell>{(signee.user as AptlyUser)?.fullName}</TableCell>
                <TableCell align="right">
                  {signee.signedAt ? (
                    intlDateTimeFormat(signee.signedAt)
                  ) : (
                    <SignButtonWithIcon onClick={() => handleSign(signee.user as AptlyUser)} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function OrderPayment() {
  const { order } = useContext(OrderContext);
  const session = order.paymentSession!;
  const payment = order.payment as AptlyPayment | null;

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="h2" gutterBottom>
              {i18n.t('singles.payment')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{(session.app as AptlyApp)?.name}</Typography>
          </Grid>
        </Grid>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>{i18n.t('singles.status')}</TableCell>
              <TableCell align="right">{orderStatusPaymentLabel(session.status)}</TableCell>
            </TableRow>
            {payment && (
              <>
                <TableRow>
                  <TableCell>{i18n.t('singles.name')}</TableCell>
                  <TableCell align="right">
                    {payment.customer.firstName} {payment.customer.lastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{i18n.t('singles.email')}</TableCell>
                  <TableCell align="right">{payment.customer.email}</TableCell>
                </TableRow>
                {payment.customer.phone && (
                  <TableRow>
                    <TableCell>{i18n.t('singles.phone')}</TableCell>
                    <TableCell align="right">{payment.customer.phone}</TableCell>
                  </TableRow>
                )}
              </>
            )}
            {payment?.transaction?.ref && (
              <TableRow>
                <TableCell>{i18n.t('statuses.paymentRef')}</TableCell>
                <TableCell align="right">{payment.transaction.ref}</TableCell>
              </TableRow>
            )}
            {payment?.card && (
              <TableRow>
                <TableCell>{payment.card.brand}</TableCell>
                <TableCell align="right">**** **** **** {payment.card.last4}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Spacer />
        {payment?.captures && payment?.captures.length > 0 && (
          <>
            <Typography variant="h3" gutterBottom>
              {i18n.t('singles.captures')}
            </Typography>
            {payment?.captures?.map((capture) => (
              <Fragment key={capture._id}>
                <Typography variant="h4">
                  {intlDateTimeFormat(capture.at)} ( {intlCurrencyDecimal(capture.amount)} ){' '}
                </Typography>
                {capture.description && <Typography>{capture.description}</Typography>}
              </Fragment>
            ))}
            <Spacer />
          </>
        )}
        {payment && (
          <>
            <Typography variant="h3" gutterBottom>
              {i18n.t('singles.address')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant="h4">{i18n.t('address.shipping')}</Typography>
                <Typography variant="body1">
                  {payment.shipping.line1}
                  <br />
                  {payment.shipping.line2 && (
                    <>
                      {payment.shipping.line2}
                      <br />
                    </>
                  )}
                  {payment.shipping.zip}
                  <br />
                  {payment.shipping.city}
                  <br />
                  {payment.shipping.country}
                </Typography>
              </Grid>
              {payment.billing && (
                <Grid item xs={12} lg={6}>
                  <Typography variant="h4">{i18n.t('address.billing')}</Typography>
                  <Typography variant="body1">
                    {payment.billing.line1}
                    <br />
                    {payment.billing.line2 && (
                      <>
                        {payment.billing.line2}
                        <br />
                      </>
                    )}
                    {payment.billing.zip}
                    <br />
                    {payment.billing.city}
                    <br />
                    {payment.billing.country}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}

function ConfirmOrderAction() {
  const { order, get } = useContext(OrderContext);

  const handleOnNewOrder = React.useCallback(() => {
    createModal(<HandleOrder order={order} onChange={() => get()} />, handleOrderModelOptions);
  }, [order, get]);

  return <DoneButtonWithIcon title={i18n.t('actions.complete')} onClick={handleOnNewOrder} />;
}

function AnnullOrderAction() {
  const { order, get } = useContext(OrderContext);
  const unitsPath = useApiUrl(SlugLevel.Project, `units/${getId(order.unit)}/orders/${order._id}`);
  const [revertState, revert] = usePost(
    `${unitsPath}/revert`,
    useMemo(
      () => ({
        onPost: () => get(),
      }),
      [get]
    )
  );
  const handleOnRevert = useConfirmModal(
    useMemo(
      () => ({
        onConfirm: () => revert({}),
        title: i18n.t('actions.reset'),
        children: <Typography>{i18n.t('paragraphs.areYouSureToDelete')}</Typography>,
        actionLabel: i18n.t('actions.annul'),
        actionIcon: <DeleteIcon />,
      }),
      [revert]
    )
  );

  if (revertState.error) return <ApiError error={revertState.error} />;

  return (
    <DeleteButtonWithIcon variant="outlined" onClick={handleOnRevert}>
      {i18n.t('actions.annul')}
    </DeleteButtonWithIcon>
  );
}
