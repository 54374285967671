import { ChangeEvent, useCallback } from 'react';
import { aptlyModules, aptlyModulesLabels, IAptlyModules } from '../../../models/Module/module.schema';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../../mui/Table';
import i18n from '../../../libraries/i18n';
import { Checkbox } from '../../../mui/Input';
import { Field } from '../../../deprecated/inputs';
import SimpleCrud from '../../SimpleCrud';

interface Props<T> {
  endpoint: string;
  id: string;
  modulesList?: IAptlyModules[];
  modules: IAptlyModules[];
  onSave: (data?: T) => void;
  reverse?: boolean;
  onClose?: () => void;
  name?: 'modules' | 'disabledModules';
}

export default function CrudModules<T>({
  endpoint,
  id,
  modulesList = aptlyModules(),
  modules,
  onSave,
  reverse,
  onClose,
  name = 'modules',
}: Props<T>) {
  const renderComponent = useCallback(
    (set: any, get: any) => {
      const modules = new Set(get(name).value || []);
      const addModules = (key: IAptlyModules) => (_e: ChangeEvent<{}>, checked: boolean) => {
        if (reverse ? !checked : checked) {
          modules.add(key);
        } else {
          modules.delete(key);
        }
        set(name, [...modules]);
      };

      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t('singles.name')}</TableCell>
              <TableCell align="right">{i18n.t('statuses.active')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {modulesList.map((module, i) => (
              <TableRow key={i}>
                <TableCell>{aptlyModulesLabels()[module]}</TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={reverse ? !modules.has(module) : modules.has(module)}
                    onChange={addModules(module)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    },
    [modulesList, name, reverse]
  );

  const fields: Field[] = [
    {
      type: 'customComponent',
      name: name,
      value: modules || [],
      label: '',
      renderComponent,
    },
  ];

  return <SimpleCrud endpoint={endpoint} id={id} fields={fields} onCreate={onSave} onClose={onClose} />;
}
