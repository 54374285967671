import { useField } from '@ewb/reach-react';
import { useCallback, useRef } from 'react';
import { ListItemProps } from '../List/List';
import { CrudFieldComponent, ICrudTextFieldProps } from '../crud/components/CrudTextField';
import SimpleAutocompleteQuery from '../simple/simple-components/SimpleAutocompleteQuery.js';
import SimpleImageMediaUpload from '../simple/simple-components/SimpleImageMediaUpload';
import { SimpleMarkdownField } from '../simple/simple-components/SimpleMarkdown';
import SimpleSelect from '../simple/simple-components/SimpleSelect';
import SimpleSwitch from '../simple/simple-components/SimpleSwitch';
import ApiError, { IApiError } from '../ApiError';
import { ICrudFieldData, ICrudFieldProps, ISimpleCrudObject } from '../crud/utils/crud.utils';
import { SettingsListItem } from '../Settings';
import { CrudTextAreaComponent } from '../crud/components/CrudTextArea';

interface Props<T extends object, K extends keyof T>
  extends Omit<ICrudTextFieldProps<T, K>, 'showLabel'>,
    ListItemProps {
  singleField?: boolean;
}

export default function CrudSettingsListField<T extends object, K extends keyof T>({
  crud,
  field,
  ...rest
}: Props<T, K>) {
  const crudField = crud.getField(field);
  return (
    <SettingsListItem label={crudField.label} required={crudField.required} {...rest}>
      <CrudFieldComponent field={crudField} onChange={crud.setField(field)} />
    </SettingsListItem>
  );
}

export function SingleSettingsListField<T extends object, K extends keyof T & string>({
  crud,
  field,
  ...rest
}: Props<T, K>) {
  const [busy, crudField, err, setValue] = useField<T, K, IApiError, ICrudFieldData<T>>(crud, field);
  if (err) return <ApiError error={err} />;
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <CrudFieldComponent field={crudField} onChange={setValue} disabled={busy} />
    </SettingsListItem>
  );
}

export function SingleSettingsListMedia<T extends object, K extends keyof T & string>({
  crud,
  field,
  ...rest
}: Props<T, K>) {
  const [, crudField, err, setValue] = useField<T, K, IApiError, ICrudFieldData<T>>(crud, field);
  if (err) return <ApiError error={err} />;
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <SimpleImageMediaUpload crud={crud} field={crudField} onChange={setValue} />
    </SettingsListItem>
  );
}

export function CrudSettingsListMarkdown<T extends ISimpleCrudObject, K extends keyof T>({
  crud,
  field,
  ...rest
}: ICrudFieldProps<T, K> & ListItemProps) {
  const crudField = crud.getField(field);
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <SimpleMarkdownField field={crudField} onChange={crud.setField(field)} />
    </SettingsListItem>
  );
}

export function CrudSettingsListTextArea<T extends ISimpleCrudObject, K extends keyof T>({
  crud,
  field,
  ...rest
}: ICrudFieldProps<T, K> & ListItemProps) {
  const crudField = crud.getField(field);
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <CrudTextAreaComponent field={crudField} onChange={crud.setField(field)} />
    </SettingsListItem>
  );
}

export function CrudSettingsListSwitch<T extends ISimpleCrudObject, K extends keyof T>({
  crud,
  field,
  ...rest
}: ICrudFieldProps<T, K> & ListItemProps) {
  const crudField = crud.getField(field);
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <SimpleSwitch field={crudField} onChange={crud.setField(field)} />
    </SettingsListItem>
  );
}

export function CrudSettingsSelect<T extends ISimpleCrudObject, K extends keyof T>({
  crud,
  field,
  ...rest
}: ICrudFieldProps<T, K> & ListItemProps) {
  const crudField = crud.getField(field);
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <SimpleSelect crud={crud} field={crudField} onChange={crud.setField(field)} />
    </SettingsListItem>
  );
}

export function CrudSettingsAutocompleteQuery<T extends ISimpleCrudObject, K extends keyof T>({
  crud,
  field,
  ...rest
}: ICrudFieldProps<T, K> & ListItemProps) {
  const crudField = crud.getField(field);
  const value = useRef<T[K]>(crudField.value);
  const handleOnBlur = useCallback(() => {
    if (!value.current) {
      return crud.setField(field)(value.current);
    }
    if (crudField.preOnChange) {
      return crud.setField(field)(crudField.preOnChange(value.current, crud));
    }
    return crud.setField(field)(value.current);
  }, [field, value]);
  const handleOnChange = useCallback(
    (v: T[K]) => {
      if (Array.isArray(value.current) && Array.isArray(v)) {
        if (value.current.length > v.length) {
          value.current = v;
          return handleOnBlur();
        }
      }
      value.current = v;
    },
    [handleOnBlur]
  );
  return (
    <SettingsListItem label={crudField.label} {...rest}>
      <SimpleAutocompleteQuery
        crud={crud}
        field={crudField}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
    </SettingsListItem>
  );
}
