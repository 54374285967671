import { useCallback, useMemo } from 'react';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import CrudForm, { CrudFormField } from '../../components/simple/CrudForm';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { useOrganizationSchemaField } from '../../models/Organization/organization.schema.js';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import Grid from '../../mui/Grid';

interface Props {
  onClose?: () => void;
}

interface Body {
  from: string;
  to: string;
}

export default function AdminCopyProducts({ onClose }: Props) {
  const path = useApiUrl(SlugLevel.Base, 'admin/products/copy');
  const data = useMemo(() => ({}), []);
  const schema = useMemo(() => {
    const _schema: ICrudSchema<Body> = {
      from: useOrganizationSchemaField({
        label: 'Fra organisasjon',
      }),
      to: useOrganizationSchemaField({
        label: 'Til organisasjon',
      }),
    };
    return _schema;
  }, []);
  const handleOnEdit = useCallback(
    (data?: any) => {
      if (data && onClose) {
        onClose();
      }
    },
    [onClose]
  );
  return (
    <CrudForm path={path} data={data} schema={schema} onEdit={handleOnEdit}>
      <ModalTitle>Kopier produkter</ModalTitle>
      <ModalContent>
        <Grid container flexDirection="column" gap={2}>
          <CrudFormField<Body> name="from" />
          <CrudFormField<Body> name="to" />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} />
      </ModalActions>
    </CrudForm>
  );
}
