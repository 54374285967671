import { AptlyAutocompleteQuery } from '@aptly-as/sdk-react/material';
import { Container } from '@mui/material';
import { useState } from 'react';
import {} from '@aptly-as/sdk-react/material/Autocomplete/Autocomplete.js';
import Typography from '../../mui/Typography.js';

export default function TripletexPlayground() {
  const [account, setAccount] = useState<any>(null);

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        Tripletex funksjoner
      </Typography>
      <AptlyAutocompleteQuery
        path="/tripletex/ledger/account"
        defaultValue={null}
        query={{}}
        multiple={false}
        onChange={setAccount}
      />
      {account && (
        <Typography>
          {account.id}: {account.name}
        </Typography>
      )}
    </Container>
  );
}
