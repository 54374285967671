import { AptlyProductImage } from '@aptly-as/types';

function extractFeaturedImage(images: AptlyProductImage[]): AptlyProductImage | null {
  const featuredIndex = images.findIndex((i) => i.featured);

  if (featuredIndex !== -1) {
    return images[featuredIndex];
  }

  return images[0];
}

export default extractFeaturedImage;
