import { Suspense, useCallback, useMemo, useState } from 'react';
import { draftToHtml, isStringDraftJS } from '../../../libraries/draft-js';
import { parseMarkdown } from '../../../libraries/markdown';
import MarkdownEditor, { IMarkdownEditorProps, IMarkdownProps } from '../../MarkdownEditor';
import { ISimpleComponentProps } from './simple-components.utils';

interface SimpleMarkdownProps<T extends object>
  extends ISimpleComponentProps<T, any>,
    Omit<IMarkdownProps, 'value' | 'onChange'> {}

export default function SimpleMarkdown<T extends object>({
  field,
  onChange,
  ...props
}: SimpleMarkdownProps<T>) {
  const { id, defaultValue, value, type, key, props: fieldProps, ...rest } = field;
  const _value = useMemo(() => {
    if (isStringDraftJS(value)) {
      return parseMarkdown(draftToHtml(value));
    }
    return value;
  }, [value]);

  const handleOnChange: IMarkdownEditorProps['onChange'] = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Suspense fallback={<>...</>}>
      <MarkdownEditor value={_value} onChange={handleOnChange} {...rest} {...fieldProps} {...props} />
    </Suspense>
  );
}

export function SimpleMarkdownField<T extends object>(props: SimpleMarkdownProps<T>) {
  const [value, setValue] = useState(props.field.value || '');

  const onBlur = useCallback(() => {
    props.onChange(value);
  }, [value, props.onChange]);

  return <SimpleMarkdown field={props.field} onChange={setValue} onBlur={onBlur} height={props.height} />;
}
