import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useCallback } from 'react';
import { AptlyApp, AptlyAppSetting, AptlyScopes } from '@aptly-as/types';
import { GridColDef } from '@mui/x-data-grid';
import ActionButtons from '../../../components/actions/buttons/ActionButtons';
import { EditIconButton } from '../../../components/actions/icons/Icons';
import Search from '../../../components/Search/Search';
import SearchAddPrimaryAction from '../../../components/Search/search-actions/SearchPrimaryAction';
import { IGridActionProps } from '../../../components/Search/search-data-grid/data-grid.actions';
import { dataGridActions } from '../../../components/Search/search-data-grid/data-grid.fields';
import { useDataGridEditModal } from '../../../components/Search/search-data-grid/data-grid.hooks';
import SearchDataGrid from '../../../components/Search/SearchDataGrid';
import { ICreateModelOptions } from '../../../containers/Modal/modal.types';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import Dialog, { ModalActions } from '../../../mui/Dialog';
import { useAppSettingsSchema, appSettingsSchemaFields } from '../app.schema';

export default function AppsSettings({ params }: IGridActionProps) {
  const { state, onShow, onChange } = useDataGridEditModal<AptlyApp>(params);
  const handleOnChange = useCallback(
    (settings: AptlyAppSetting[]) => {
      onChange((data) => ({ ...(data as AptlyApp), settings }));
    },
    [onChange]
  );

  return (
    <>
      <EditIconButton onClick={onShow} icon={<SettingsOutlinedIcon />} title={i18n.t('singles.settings')} />
      <Dialog open={Boolean(state.data)} maxWidth="md" onClose={onShow}>
        {state.data && <ModalContent app={state.data} onChange={handleOnChange} />}
        <ModalActions>
          <ActionButtons onClose={onShow} submitType="button" cancelLabel={i18n.t('actions.close')} />
        </ModalActions>
      </Dialog>
    </>
  );
}

interface IModalContentProps {
  app: AptlyApp;
  onChange: (settings: AptlyAppSetting[]) => void;
}

const modalProps: ICreateModelOptions = { width: 'sm' };

const useAppSettingsColumns = (): GridColDef[] => [
  {
    type: 'string',
    field: 'label',
    headerName: i18n.t('singles.label'),
    flex: 1,
  },
  {
    type: 'string',
    field: 'key',
    headerName: i18n.t('singles.key'),
    width: 200,
  },
  {
    type: 'string',
    field: 'type',
    headerName: i18n.t('singles.type'),
    width: 150,
  },
  {
    type: 'string',
    field: 'defaultValue',
    headerName: i18n.t('singles.defaultValue'),
    width: 150,
  },
  dataGridActions(),
];

function ModalContent({ app, onChange }: IModalContentProps) {
  const path = useApiUrl(SlugLevel.Organization, `apps/${app._id}/settings`);
  const schema = useAppSettingsSchema();
  const columns = useAppSettingsColumns();

  return (
    <Search<AptlyAppSetting>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.AdminApps}
      header={{ title: i18n.t('singles.settings') }}
      post={{ title: i18n.t('singles.settings') }}
      patch={{ title: i18n.t('singles.settings') }}
      schema={schema}
      fields={appSettingsSchemaFields}
      options={{ disableBulk: true }}
      reach={{ limit: 999 }}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      modalProps={modalProps}
      onChange={onChange}
    >
      {() => <SearchDataGrid columns={columns} pageSize={10} />}
    </Search>
  );
}
