import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material';
import { AptlyFieldType, AptlyProducer, AptlyProducerTrademark, AptlyScopes } from '@aptly-as/types';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { IBulkEdit, ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaEmail, schemaBulkIds, schemaName } from '../../components/crud/schema/fields.schema';
import i18n from '../../libraries/i18n';
import { useTagsSchemaField } from '../Tag/tag.schema.js';

export interface ICrudProducer extends AptlyProducer, IBulkEdit {}

export function useProducerSchema(): ICrudSchema<ICrudProducer> {
  return {
    ids: schemaBulkIds(),
    name: schemaName({
      autoFocus: true,
    }),
    email: schemaEmail({
      required: false,
    }),
    url: {
      type: AptlyFieldType.Text,
      label: i18n.t('singles.url'),
      defaultValue: '',
    },
    tags: useTagsSchemaField(),
    logo: {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: i18n.t('singles.logo'),
      image: {
        accept: acceptImages,
        mediaKey: 'logoMedia',
        width: 160,
        opts: {
          disableFullWidth: true,
          style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
          transforms: 'c_fit',
        },
      },
    },
    featured: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: i18n.t('singles.featured'),
      renderValidate: () => Scope.crud(AptlyScopes.Admin),
    },
  };
}

export function producerSchemaField<T extends object, V extends AptlyProducer | string | undefined | null>(
  override: Partial<ICrudField<T, V>> = {}
): ICrudField<T, V> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useProducerQueryProps,
    label: i18n.t('singles.producer'),
    defaultValue: null as V,
    ...override,
  };
}

export function useProducerQueryProps(): Omit<
  AptlyAutocompleteQueryProps<any, any, any>,
  'onChange' | 'defaultValue' | 'value'
> {
  return {
    path: useApiUrl(SlugLevel.Base, 'producers'),
    query: { archived: false, select: '_id,name' },
    multiple: false,
    queryOnFocus: true,
    autocompleteSearchProps: {
      label: i18n.t('singles.producer'),
      noOptionsText: i18n.t('statuses.noResults'),
    },
  };
}

export function useProducerTrademarkSchema(): ICrudSchema<AptlyProducerTrademark> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
    logo: {
      type: AptlyFieldType.Media,
      defaultValue: null,
      label: i18n.t('singles.logo'),
      image: {
        accept: acceptImages,
        width: 160,
        opts: {
          disableFullWidth: true,
          style: { maxHeight: '50px', maxWidth: '250px', width: 'auto' },
          transforms: 'c_fit',
        },
      },
    },
  };
}
