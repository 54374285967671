import { ReachError, IReachOptions } from '@ewb/reach-react';
import { busyNotification, successNotification } from '../../containers/Notification/notification.utils';
import handleError from '../../containers/Error/handleError';
import { reach } from '../reach/reach';

type Options<T> = {
  endpoint: string;
  confirmMessage?: string;
  method?: string;
  data?: { [key: string]: any };
  query?: { [key: string]: any };
  onLoadText?: string;
  disabledLoadText?: boolean;
  onRequestDone?: (data: T) => void;
  onRequestDoneText?: string;
  onError?: (error: ReachError) => void;
} & Pick<IReachOptions, 'type' | 'usePathAsUrl'>;

async function simpleRequest<T>(opts: Options<T>) {
  let confirmed = !opts.confirmMessage;
  let busy: null | (() => void) = null;

  if (opts.confirmMessage && window.confirm(opts.confirmMessage)) {
    confirmed = true;
  }

  if (confirmed) {
    if (opts.onLoadText) {
      busy = busyNotification(opts.onLoadText);
    }
    try {
      const data = await reach.api<T>(opts.endpoint, {
        method: opts.method || 'GET',
        body: opts.data,
        query: opts.query,
        usePathAsUrl: opts.usePathAsUrl,
        type: opts.type,
      });

      if (opts.onRequestDoneText) {
        successNotification(opts.onRequestDoneText);
      }

      if (typeof opts.onRequestDone === 'function') {
        opts.onRequestDone(data);
      }
      return data;
    } catch (e) {
      if (typeof opts.onError === 'function' && e instanceof ReachError) {
        opts.onError(e);
      } else {
        handleError(e);
      }
    } finally {
      if (busy) {
        busy();
      }
    }
  }
}

export default simpleRequest;
