import { useGet } from '@ewb/reach-react';
import { useCallback, useMemo, useState } from 'react';
import { AptlyProduct, AptlyUnitTemplate, AptlyUnitTemplateCategorySectionProduct } from '@aptly-as/types';
import ApiError, { IApiError } from '../../../components/ApiError.js';
import { createModal } from '../../../containers/Modal/ModalContext.js';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl.js';
import { reach } from '../../../libraries/reach/reach.js';
import ProductEdit from '../../Product/ProductEdit.js';
import { onUnitTemplateOrder, onUnitTemplateOver, onUnitTemplatePatch } from './unit-template.state.js';
import { IUnitTemplateContext, IUseSortableData } from './unit-template.utils.js';
import { UnitTemplateContext } from './UnitTemplateContext.js';
import { UnitTemplateSortable } from './UnitTemplateSortable.js';

interface Props extends JSX.ElementChildrenAttribute {
  id: string;
}

export function UnitTemplateProvider({ id, children }: Props) {
  const [activePackage, setActivePackage] = useState<string | null>(null);
  const unitTemplatePath = useApiUrl(SlugLevel.Unit, 'unit-templates');
  const queryProps = useMemo(() => ({ query: { select: '_id,name,categories,_order_categories' } }), []);
  const path = useMemo(() => `${unitTemplatePath}/${id}`, [unitTemplatePath, id]);
  const [{ data: unitTemplate, error }, read, setState] = useGet<AptlyUnitTemplate, IApiError>(
    path,
    useMemo(() => ({}), []),
    queryProps
  );

  const onOver = useCallback(
    (from: IUseSortableData, to: IUseSortableData) => {
      setState(onUnitTemplateOver(from, to));
    },
    [setState, path]
  );

  const onOrder = useCallback(
    (from: IUseSortableData, to: IUseSortableData, origin?: IUseSortableData) => {
      setState(onUnitTemplateOrder(path, from, to, origin));
    },
    [setState, path]
  );

  const undo = useCallback(
    async (_id?: string) => {
      if (window.confirm('Er du sikker?')) {
        try {
          const url = `${path}/history${_id ? `/${_id}` : ''}/undo`;
          const unitTemplate = await reach.api<AptlyUnitTemplate>(url, { method: 'POST' });
          setState((s) => ({ ...s, data: unitTemplate }));
          return unitTemplate;
        } catch (error: any) {
          setState((s) => ({ ...s, error }));
        }
      }
    },
    [path, setState]
  );

  const editProduct = useCallback(
    (sectionProduct: AptlyUnitTemplateCategorySectionProduct) => {
      if (!sectionProduct.product) return;
      const handleOnChange = (product: AptlyProduct) => {
        setState(onUnitTemplatePatch({ product: sectionProduct, updateProduct: product }));
      };

      createModal(
        <ProductEdit product={sectionProduct.product} onChange={handleOnChange} update disableIfBase />,
        {
          width: 'md',
        }
      );
    },
    [setState]
  );

  if (error) return <ApiError error={error} />;
  if (!unitTemplate || !unitTemplate._id) return null;

  const value: IUnitTemplateContext = {
    unitTemplate: unitTemplate!,
    setState,
    onOver,
    onOrder,
    activePackage,
    setActivePackage,
    read,
    undo,
    path,
    editProduct,
  };

  return (
    <UnitTemplateContext.Provider value={value}>
      <UnitTemplateSortable {...value}>{children}</UnitTemplateSortable>
    </UnitTemplateContext.Provider>
  );
}
