import { useContext } from 'react';
import { AptlyDepartment, AptlyFieldType } from '@aptly-as/types';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { getId } from '../../libraries/mongoose';
import i18n from '../../libraries/i18n';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { SimpleContext } from '../../components/simple/SimpleContext';

export type ICrudDepartmentProject = AptlyDepartment['projects'][0];
export type ICrudDepartmentUser = AptlyDepartment['users'][0];

export function useDepartmentSchema(): ICrudSchema<AptlyDepartment> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
  };
}

export function useDepartmentProjectSchema(): ICrudSchema<ICrudDepartmentProject> {
  return {
    project: {
      type: AptlyFieldType.AutocompleteQuery,
      defaultValue: null as any,
      label: i18n.t('singles.projects'),
      useAutocompleteQuery: () => {
        const { meta } = useContext(SimpleContext);
        const items = meta.data as ICrudDepartmentProject[];
        const _ids = items?.map((x) => getId(x.project)).join(',') || '';
        return {
          path: useApiUrl(SlugLevel.Organization, 'projects'),
          query: { select: '_id,name', limit: 7, archived: false, sort: 'name', $nin__id: _ids },
          multiple: false,
        };
      },
    },
  };
}

export function useDepartmentUserSchema(): ICrudSchema<ICrudDepartmentUser> {
  return {
    user: {
      type: AptlyFieldType.AutocompleteQuery,
      defaultValue: null as any,
      label: i18n.t('singles.user'),
      useAutocompleteQuery: () => {
        const { meta } = useContext(SimpleContext);
        const items = meta.data as ICrudDepartmentUser[];
        const _ids = items?.map((x) => getId(x.user)).join(',') || '';
        return {
          path: useApiUrl(SlugLevel.Organization, 'users'),
          queryKey: '$fullName',
          query: { select: '_id fullName', limit: 5, sort: 'fullName', $nin__id: _ids },
          multiple: false,
          autocompleteSearchProps: {
            getOptionLabel: (user) => user.fullName,
          },
        };
      },
    },
  };
}

export function useDepartmentsSchemaField<T extends object, V extends string | AptlyDepartment>(
  override?: Partial<ICrudField<T, V[]>>
): ICrudField<T, V[]> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Organization, 'departments'),
      query: { archived: false, select: '_id,name' },
      multiple: true,
      queryOnFocus: false,
    }),
    label: i18n.t('singles.departments'),
    defaultValue: [],
    ...override,
  };
}
