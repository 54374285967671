import { AptlyError } from '@aptly-as/types';
import { errorNotification } from '../Notification/notification.utils';
import { ApiError } from './createError';

function handleError(error: Error | ApiError | AptlyError | any) {
  if (error && 'body' in error && error.body && 'name' in error.body && error.body.name === 'AptlyError') {
    const err = new AptlyError(error.body);
    errorNotification(err.toString());
    return;
  }
  console.error(error);
  let message = error.message || error.error || '';
  if (error.details) {
    message += `: ${error.details}`;
  }
  errorNotification(message);
}

export default handleError;
