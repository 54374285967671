import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import { ReactNode } from 'react';
import { MOBILE_BREAK } from '../utils/themes';

const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(1, 0)};
  flex: 1;
`;

const Gfx = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.palette.primary.contrastText};
  }

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    width: 80px;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: -16px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 16px solid ${(props) => props.theme.palette.primary.main};
  right: 38px;

  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    right: 27px;
  }
`;

type Props = {
  open?: boolean;
  icon?: ReactNode;
  children: ReactNode;
};

function PageOverlay(props: Props) {
  const { children, open } = props;

  return (
    <Slide in={open} mountOnEnter unmountOnExit direction="down">
      <Wrapper>
        <Content>{children}</Content>
        <Gfx>
          <SearchIcon />
        </Gfx>
        <Icon />
      </Wrapper>
    </Slide>
  );
}

export default PageOverlay;
