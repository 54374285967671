import { AptlyAutocompleteQuery } from '@aptly-as/sdk-react/material';
import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material/Autocomplete/AutocompleteQuery.js';
import { IUseFieldRet } from '@ewb/reach-react';
import { useMemo } from 'react';
import i18n from '../../../libraries/i18n.js';
import { getId } from '../../../libraries/mongoose.js';
import { IApiError } from '../../ApiError.js';
import { ICrudFieldData } from '../../crud/utils/crud.utils.js';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudSimpleAutocompleteQueryProps<T extends object> extends ISimpleComponentProps<T, any> {
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>;
  onBlur?: () => void;
}

export default function SimpleAutocompleteQuery<T extends object>({
  crud,
  field,
  onChange,
  onBlur,
}: ICrudSimpleAutocompleteQueryProps<T>) {
  const { value, id, disabled, label, defaultValue, useAutocompleteQuery, required } = field;
  if (!useAutocompleteQuery) {
    throw new Error(`Field "${field}" is missing useAutocompleteQuery object`);
  }
  const { autocompleteSearchProps, ...autocompleteProps } = useAutocompleteQuery();
  const filter = useMemo(
    () => ({
      key: '$nin__id',
      fn: (x: any) => getId(x),
    }),
    []
  );
  const _autocompleteSearchProps: AptlyAutocompleteQueryProps<
    T,
    boolean,
    boolean
  >['autocompleteSearchProps'] = useMemo(
    () => ({
      noSearchText: i18n.t('actions.searchToAdd'),
      noOptionsText: i18n.t('statuses.noResults'),
      ...autocompleteSearchProps,
      textFieldProps: {
        ...(autocompleteSearchProps?.textFieldProps || {}),
        required,
      },
      label: label as string,
      onBlur,
      disabled,
      id,
    }),
    [label, disabled, id, required, autocompleteSearchProps, onBlur]
  );

  if (
    (!crud.state.edited && crud.state.busy && typeof value === 'string') ||
    (Array.isArray(value) && typeof value[0] === 'string')
  ) {
    return null;
  }

  return (
    <AptlyAutocompleteQuery<T, boolean, boolean>
      defaultValue={value || defaultValue}
      onChange={onChange}
      filter={filter}
      autocompleteSearchProps={_autocompleteSearchProps}
      queryOnFocus
      {...autocompleteProps}
    />
  );
}
