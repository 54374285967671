import { AptlyDepartment, AptlyScopes } from '@aptly-as/types';
import Search from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { useDepartmentSchema } from './department.schema';
import { useDepartmentColumns } from './department.columns.js';

function OrganizationDepartments() {
  const path = useApiUrl(SlugLevel.Organization, 'departments');
  const schema = useDepartmentSchema();

  return (
    <Search<AptlyDepartment>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.OrganizationDepartments}
      header={{ title: i18n.t('singles.departments') }}
      post={{ title: i18n.t('singles.departments') }}
      patch={{
        title: (data) => data.name || '',
        preData: (data) => ({ ...data, filterProjects: Boolean(data.projects) }),
      }}
      delete={{ confirmInput: (data) => data.name }}
      schema={schema}
      fields={['name']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      primaryAction={<SearchAddPrimaryAction key="create" title={i18n.t('actions.add')} />}
    >
      {() => <SearchDataGrid columns={useDepartmentColumns()} />}
    </Search>
  );
}

export default OrganizationDepartments;
