import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import Resource from '../../components/Resource';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import ActionBar from '../../components/ActionBar';
import Block from '../../components/Block';
import { intlCurrencyDecimal, intlQuantity } from '../../libraries/intl';
import { ButtonWithIcon } from '../../mui/Button';
import Grid from '../../mui/Grid';
import { PaperPadded } from '../../mui/Paper';
import { LinearProgresser } from '../../mui/Progresser';
import { rawSpacing, tightSpacing } from '../../utils/spacing';
import StatusText from '../../components/StatusText';
import Table, { TableBody, TableCell, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import { UnitWithTree } from './unit.types';
import filesSaver from '../../libraries/files-saver';
import renderParams from '../UnitTemplate/renderParams';
import i18n from '../../libraries/i18n';

export default function UnitSelections() {
  const v1Base = useApiUrl(SlugLevel.Unit);
  const [organizationSlug, projectID, unitID] = useSlugs();
  const baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}/units/${unitID}/report`;

  return (
    <Resource<UnitWithTree> endpoint={baseEndpoint}>
      {({ data }) => {
        if (!data || Array.isArray(data)) {
          return <LinearProgresser />;
        }

        return (
          <Block>
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {i18n.t('singles.options')}
                </Typography>
              </Grid>
              <Grid item>
                <ActionBar style={{ gap: '1rem' }}>
                  <ButtonWithIcon
                    color="primary"
                    variant="outlined"
                    onClick={() => filesSaver({ endpoint: `${v1Base}/report.pdf` })}
                  >
                    {i18n.t('singles.unitReport')}.pdf
                    <CloudDownloadOutlinedIcon />
                  </ButtonWithIcon>
                </ActionBar>
              </Grid>
            </Grid>
            <Grid container flexDirection="column" gap={rawSpacing}>
              {data.tree.map((t) => (
                <Grid key={t._id} item>
                  <Typography variant="h2" gutterBottom>
                    {t.name}
                  </Typography>
                  <Grid container flexDirection="column" gap={tightSpacing}>
                    {t.sections.map((s) => (
                      <Grid item key={s._id}>
                        <PaperPadded>
                          <Typography variant="h3" gutterBottom>
                            {s.name}
                          </Typography>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{i18n.t('singles.productName')}</TableCell>
                                <TableCell>{i18n.t('singles.productNumber')}</TableCell>
                                <TableCell>{i18n.t('singles.producer')}</TableCell>
                                <TableCell>{i18n.t('singles.details')}</TableCell>
                                <TableCell align="right">{i18n.t('singles.quantity')}</TableCell>
                                <TableCell align="right">{i18n.t('singles.netCost')}</TableCell>
                                <TableCell align="right">{i18n.t('singles.totalPrice')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {s.picks.map((p) => {
                                let selectedProduct =
                                  p.product && p.variant
                                    ? p.product.variants.find((v: any) => v._id === p.variant)
                                    : p.product;
                                if (p.product?._id === p.variant) {
                                  selectedProduct = p.product;
                                }

                                return (
                                  <TableRow key={p._id}>
                                    <TableCell>
                                      {selectedProduct
                                        ? selectedProduct.name
                                        : p.text || <StatusText>{i18n.t('singles.NA')}</StatusText>}
                                    </TableCell>
                                    <TableCell>
                                      {selectedProduct && selectedProduct.productNumber ? (
                                        selectedProduct.productNumber
                                      ) : (
                                        <StatusText>{i18n.t('statuses.nothing')}</StatusText>
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      {p.product && p.product.producer ? (
                                        p.product.producer.name
                                      ) : (
                                        <StatusText>{i18n.t('statuses.nothing')}</StatusText>
                                      )}
                                    </TableCell>
                                    <TableCell>{renderParams(p.params || [])}</TableCell>
                                    <TableCell align="right">{intlQuantity(p.quantity)}</TableCell>
                                    <TableCell align="right">{intlCurrencyDecimal(p.unitCost)}</TableCell>
                                    <TableCell align="right">
                                      {intlCurrencyDecimal(p.unitCost * p.quantity)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </PaperPadded>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Block>
        );
      }}
    </Resource>
  );
}
