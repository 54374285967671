import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { AptlyProductImage } from '@aptly-as/types';
import type { GridValidRowModel } from '@mui/x-data-grid/models/gridRows.js';
import dayjs from 'dayjs';
import { useFormat } from '../../containers/Format/Format.js';
import { FORMAT_DATE_TIME } from '../../env';
import createMediaURL from '../../models/Media/createMediaURL';
import extractFeaturedImage from '../../models/Product/extractFeaturedImage';
import i18n from '../../libraries/i18n';
import { ChipLevel } from '../Chip';
import ColorPalette from '../../components/ColorPalette';
import Avatar from '../../components/Avatar';

export const dataGridNameCol = (override: Partial<GridColDef> = {}): GridColDef => ({
  type: 'string',
  field: 'name',
  headerName: i18n.t('singles.name'),
  sortable: false,
  ...override,
});

export function useColumnCreatedAt<T extends GridValidRowModel>(
  override: Partial<GridColDef<T>> = {}
): GridColDef<T> {
  const format = useFormat();
  return {
    type: 'string',
    field: 'createdAt',
    headerName: i18n.t('statuses.created'),
    valueGetter: (v) => format.date(v),
    ...override,
  };
}

export const dataGridTextColumn = <T extends object>(
  field: keyof T,
  headerName: string,
  props: Partial<GridColDef<T>> = {}
): GridColDef<T> => ({
  type: 'string',
  field: String(field),
  headerName: headerName,
  minWidth: 200,
  sortable: false,
  ...props,
});

export const dataGridColorColumn = <T extends object>(
  field = 'color',
  props: Partial<GridColDef<T>> = {}
): GridColDef<T> => ({
  type: 'string',
  field,
  headerName: i18n.t('singles.color'),
  minWidth: 50,
  display: 'flex',
  sortable: false,
  renderCell: (params) => {
    if (params.value) {
      return <ColorPalette color={params.value} />;
    } else if ('colorLabel' in params.row) {
      return params.row.colorLabel as string;
    }
    return '';
  },
  ...props,
});

export const dataGridLevelColumn = <T extends object>(props: Partial<GridColDef<T>> = {}): GridColDef<T> => ({
  field: 'level',
  headerName: i18n.t('singles.level'),
  minWidth: 135,
  renderCell: (params) => <ChipLevel data={params.row} />,
  sortable: false,
  ...props,
});

interface SizeObject {
  length?: string;
  width?: string;
  height?: string;
  circumference?: string;
}

export const dataGridSizeColumn = <T extends SizeObject>(
  props: Partial<GridColDef<T>> = {}
): GridColDef<T> => ({
  type: 'string',
  field: 'length',
  headerName: 'Sizes',
  width: 200,
  renderCell: (params) =>
    [params.row.length, params.row.width, params.row.height, params.row.circumference]
      .filter(Boolean)
      .join('/') || 'N/A',
  sortable: false,
  ...props,
});

export const dataGridDateTimeColumn = (
  field: string,
  props: Partial<GridColDef> = {},
  format = FORMAT_DATE_TIME
): GridColDef => ({
  type: 'date',
  field,
  minWidth: 250,
  headerName: i18n.t('singles.date'),
  valueGetter: (value) => (value ? new Date(value) : null),
  renderCell: (params) => (params.value ? dayjs(params.value).format(format) : null),
  ...props,
});

export const dataGridReferenceColumn = <T extends object>(
  field: string,
  headerName: string,
  props: Partial<GridColDef<T>> = {}
): GridColDef<T> => ({
  type: 'string',
  field,
  headerName,
  renderCell: (params) => params.value?.name || 'N/A',
  width: 150,
  sortable: false,
  ...props,
});

const dataGridImageToSrc = (params: GridRenderCellParams) =>
  params.value ? createMediaURL(params.value) : undefined;
export const dataGridImageColumn = <T extends object>(
  field: keyof T,
  props: Partial<GridColDef> = {},
  toSrc: (params: GridRenderCellParams) => string | undefined = dataGridImageToSrc
): GridColDef => ({
  width: 50,
  field: String(field),
  headerName: '',
  align: 'center',
  display: 'flex',
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params: GridRenderCellParams<AptlyProductImage>) => {
    const src = toSrc(params);
    if (!src) return null;
    return (
      <Avatar
        src={toSrc(params)}
        imgProps={{ loading: 'lazy' }}
        alt={params.row.name}
        variant="rounded"
        contain
      />
    );
  },
  ...props,
});
const dataGridMediaToSrc = (params: GridRenderCellParams) =>
  params.value ? createMediaURL(params.value.src) : undefined;
export const dataGridMediaColumn = <T extends object>(
  field: keyof T,
  props: Partial<GridColDef> = {},
  toSrc: (params: GridRenderCellParams) => string | undefined = dataGridMediaToSrc
): GridColDef => ({
  width: 70,
  field: String(field),
  headerName: i18n.t('singles.image'),
  align: 'center',
  display: 'flex',
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params: GridRenderCellParams<AptlyProductImage>) => (
    <Avatar
      src={toSrc(params)}
      imgProps={{ loading: 'lazy' }}
      alt={params.row.name}
      variant="rounded"
      contain
    />
  ),
  ...props,
});

export const dataGridImagesColumn = (field: string, props: Partial<GridColDef> = {}) =>
  dataGridImageColumn(field, props, (params: GridRenderCellParams<AptlyProductImage[]>) => {
    const featuredImage = extractFeaturedImage(params.value || []);
    return featuredImage
      ? createMediaURL(featuredImage.src || featuredImage.image, { width: 80 })
      : undefined;
  });
