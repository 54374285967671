import dayjs from 'dayjs';
import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { AptlyAvailabilityCalendarAvailableTime, AptlyScopes, AptlyUser } from '@aptly-as/types';
import { DeleteIconButton, PersonIconButton } from '../../components/actions/icons/Icons';
import { ICrudSchema } from '../../components/crud/utils/crud.utils.js';
import { SimpleModal } from '../../components/simple/SimpleCrud.js';
import { useModal } from '../../containers/Modal/Modal.js';
import deleteResource from '../../containers/Notification/deleteResource';
import i18n from '../../libraries/i18n';
import { LayoutScope } from '../../libraries/scope/ScopeComponets';
import Grid from '../../mui/Grid';
import { useUnitSchemaField } from '../Unit/unit.schema.js';
import { BookingContext, IBookingContext } from './BookingContext';
import { tightSpacing } from '../../utils/spacing';
import Typography from '../../mui/Typography';
import { FORMAT_DATE, FORMAT_TIME } from '../../env';

type Props = {
  booking: IBookingContext;
  hideDate?: boolean;
};

export default function BookingTimes({ booking, hideDate }: Props) {
  const {
    state: { date, times },
  } = booking;
  return (
    <Grid container direction={'column'} spacing={tightSpacing}>
      {!hideDate && (
        <Grid item>
          <Header variant={'body2'}>{date.format(FORMAT_DATE)}</Header>
        </Grid>
      )}
      <Grid item>
        <Grid container direction={'column'}>
          {times.map((time) => (
            <BookingTime key={time._id} time={time} />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

const Header = styled(Typography)`
  font-weight: bold !important;
  text-decoration: underline;
`;

interface BookingTimeProps {
  time: AptlyAvailabilityCalendarAvailableTime;
}

function BookingTime({ time }: BookingTimeProps) {
  const { calendarPath, getBookings, getTimes } = useContext(BookingContext);

  const handleOnDelete = useCallback(() => {
    deleteResource(`${calendarPath}/${time._id}`, () => {
      getTimes(dayjs(time.from).toISOString());
    });
  }, [time, getTimes]);

  const handleOnSave = useCallback(() => {
    const date = dayjs(time.from).toISOString();
    getTimes(date);
    getBookings(date, date);
  }, [time]);

  return (
    <Grid container justifyContent="space-between">
      <LayoutItem item>
        {dayjs(time.from).format(FORMAT_TIME)}-{dayjs(time.to).format(FORMAT_TIME)}
        {time.user && (
          <>
            <br />
            {(time.user as AptlyUser).fullName}
          </>
        )}
      </LayoutItem>
      <LayoutScope item scope={AptlyScopes.ProjectBooking} crud="U">
        {!time.booked && <FindUnitAndBook time={time} onEdit={handleOnSave} />}
        <DeleteIconButton onClick={handleOnDelete} />
      </LayoutScope>
    </Grid>
  );
}

function FindUnitAndBook({
  time,
  onEdit,
}: {
  time: AptlyAvailabilityCalendarAvailableTime;
  onEdit: () => void;
}) {
  const { calendarPath } = useContext(BookingContext);
  const schema = useTimeBookSchema();
  const spawnModal = useModal(() => (
    <SimpleModal<TimeBook>
      path={calendarPath}
      data={time}
      schema={schema}
      fields={['unit']}
      title={i18n.t('actions.bookMeetingWithUnit')}
      onEdit={onEdit}
    />
  ));

  return <PersonIconButton size="large" title={i18n.t('actions.bookMeetingWithUnit')} onClick={spawnModal} />;
}

interface TimeBook {
  _id: string;
  unit: string | AptlyUser | null;
}
function useTimeBookSchema(): ICrudSchema<TimeBook> {
  return {
    unit: useUnitSchemaField({
      required: true,
    }),
  };
}

const LayoutItem = styled(Grid)`
  padding-top: 0.85rem;
`;
