import { AptlyFieldOption, AptlyItemType } from '@aptly-as/types';
import i18n from '../../libraries/i18n.js';

export function itemTypeOptions(): AptlyFieldOption[] {
  return [
    {
      value: AptlyItemType.Physical,
      label: i18n.t('singles.product'),
    },
    // {
    //   value: AptlyItemType.Digital,
    //   label: i18n.t('singles.digital'),
    // },
    {
      value: AptlyItemType.Service,
      label: i18n.t('singles.service'),
    },
  ];
}
export function itemTypeLabel(type: AptlyItemType): string {
  const option = itemTypeOptions().find((x) => x.value === type);
  return option?.label || type;
}
