import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FormEvent, PropsWithChildren, useCallback } from 'react';
import { useModal } from '../../containers/Modal/Modal';
import i18n from '../../libraries/i18n';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import Button from '../../mui/Button';
import { useOrganization } from '../Organization/OrganizationContext';
import { useProject } from '../Project/ProjectContext';
import { useInquirySchema, IInquirySchema } from './inquiry.schema';
import { SimpleCrudContent, SimpleCrudForm } from '../../components/simple/SimpleCrud';
import ApiError from '../../components/ApiError';

type InquiryNewLinkProps = Omit<Props, 'onClose'>;

export function InquiryNewLink({ children, ...props }: PropsWithChildren<InquiryNewLinkProps>) {
  const spawn = useNewInquiry(props);
  return (
    <Button variant="text" color="secondary" onClick={spawn} startIcon={<EmailOutlinedIcon />}>
      {children}
    </Button>
  );
}

export function useNewInquiry(props: Omit<Props, 'onClose'>) {
  return useModal(() => <InquiryNew {...props} />);
}

interface Props {
  onCreate: (res: any) => void;
  unitID?: string;
  onClose?: () => void;
}
export default function InquiryNew(props: Props) {
  const { unitID, onClose, onCreate } = props;
  const organization = useOrganization();
  const project = useProject();
  const schema = useInquirySchema();
  const path = `organizations/${organization.slug}/projects/${project._id}/inquiries`;

  const crudFields: (keyof IInquirySchema)[] = ['title', 'message', 'files'];
  if (!unitID) {
    crudFields.unshift('unit');
  }

  const [crud, fields] = useSimpleCrudFields<IInquirySchema, keyof IInquirySchema>(
    path,
    unitID ? { unit: unitID } : {},
    schema,
    crudFields,
    {
      reachOptions: {
        type: 'multipart/form-data',
        fileKeys: ['files'],
      },
      dontSetStateOnPost: true,
    }
  );

  const handleOnSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const res = await crud.save();
      if (res) {
        onCreate(res);
        if (onClose) {
          onClose();
        }
      }
    },
    [crud, onCreate, onClose]
  );

  return (
    <SimpleCrudForm
      title={i18n.t('actions.newMessage')}
      onClose={onClose}
      actionButtonProps={{ disabled: crud.state.busy }}
      onSubmit={handleOnSubmit}
    >
      <SimpleCrudContent crud={crud} schema={fields} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}
