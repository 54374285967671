import { AptlyAutocompleteQuery } from '@aptly-as/sdk-react/material';
import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material/Autocomplete/AutocompleteQuery';
import { AptlyAutocompleteSearchItem } from '@aptly-as/sdk-react/material/Autocomplete/AutocompleteSearch.utils';
import { AutocompleteValue } from '@mui/material/Autocomplete';
import { useCallback } from 'react';
import { ISearchFieldActionProps, useSearchQueryState } from './search-field.utils';

type SearchFieldQueryProps<T extends AptlyAutocompleteSearchItem> = ISearchFieldActionProps & {
  autocompleteQueryProps: Omit<AptlyAutocompleteQueryProps<T, boolean>, 'onChange' | 'defaultValue'>;
};

export function SearchFieldQuery<T extends AptlyAutocompleteSearchItem>({
  field,
  queryKey,
  autocompleteQueryProps,
}: SearchFieldQueryProps<T>) {
  const [, setValue] = useSearchQueryState(field, queryKey);
  const onChange = useCallback(
    (item: AutocompleteValue<T, boolean, false, false>) => {
      if (!item) {
        return setValue(undefined);
      }
      if (Array.isArray(item)) {
        return setValue(item.map((x) => x._id).join(','));
      }
      setValue(item._id);
    },
    [setValue]
  );
  return (
    <AptlyAutocompleteQuery
      defaultValue={autocompleteQueryProps.multiple ? [] : null}
      {...autocompleteQueryProps}
      onChange={onChange}
    />
  );
}
