import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { NAV_DRAWER_WIDTH } from '../containers/Nav/NavDrawer';
import { MOBILE_BREAK } from '../utils/themes';

interface MainProps {
  noMenu?: boolean;
}

export function Main({ children, noMenu }: PropsWithChildren<MainProps>) {
  return (
    <StyledBox as="main" $noMenu={noMenu}>
      <Toolbar />
      {children}
    </StyledBox>
  );
}

const StyledBox = styled(Box)<{ $noMenu?: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.$noMenu ? '100%' : `calc(100vw - ${NAV_DRAWER_WIDTH}px)`)};
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    max-width: 100%;
  }
`;
