import { AptlyScopes, AptlyUnit, AptlyUser } from '@aptly-as/types';
import { Grid2 } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import DeleteIconEndpoint from '../../../components/actions/icons/DeleteIconEndpoint';
import { AddIconButton, SendIconButton } from '../../../components/actions/icons/Icons';
import Section from '../../../components/Section';
import CrudUnitEdit from '../../../components/simpleCruds/unit/CrudUnitEdit';
import CrudUnitInvite from '../../../components/simpleCruds/unit/CrudUnitInvite';
import { createModal } from '../../../containers/Modal/ModalContext';
import useGetApiUrl, { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import { intlDateTimeFormat } from '../../../libraries/intl';
import { FragmentScope } from '../../../libraries/scope/ScopeComponets';
import Table, { TableBody, TableCell, TableFooter, TableHead, TableRow } from '../../../mui/Table';
import { MOBILE_BREAK } from '../../../utils/themes.js';
import styled from 'styled-components';

interface Props {
  unit: AptlyUnit;
  refresh: () => void;
}

export default function UnitDashboardUsers({ unit, refresh }: Props) {
  const usersEndpoint = useApiUrl(SlugLevel.Organization, 'users');
  const unitsEndpoint = useGetApiUrl('project') + '/units';
  const unitEndpoint = unitsEndpoint + `/${unit._id}`;
  const users = (unit.users as AptlyUser[]) || [];
  const invites: Partial<AptlyUnit['invites'][0]>[] = useMemo(
    () => [
      ...unit.invites,
      ...unit.queueInvites.map((email) => ({
        _id: 'queue',
        claim: { claimed: false, email },
      })),
      ...unit.invitesQueue.map((queue) => ({
        _id: 'queue',
        claim: { claimed: false, ...queue },
      })),
    ],
    [unit]
  );

  return (
    <Section>
      <Typography variant="subtitle1">{i18n.t('singles.users')}</Typography>
      <Paper>
        <Table>
          <TableHead>
            <StyledTableRow>
              <TableCell flex={1} width="">
                {i18n.t('singles.name')}
              </TableCell>
              <TableCell flex={1}>{i18n.t('singles.email')}</TableCell>
              <TableCell flex={1}>{i18n.t('singles.phone')}</TableCell>
              <TableCell width={175}>{i18n.t('paragraphs.loggedIn')}</TableCell>
              <TableCell width={150}>{i18n.t('singles.registered')}</TableCell>
              <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                <TableCell align="right">{i18n.t('singles.action')}</TableCell>
              </FragmentScope>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user._id}>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>
                  <a href={`mailto:${user.email}`} target="_blank" rel="noopener noreferrer">
                    {user.email}
                  </a>
                </TableCell>
                <TableCell>
                  {user.phone && (
                    <a href={`tel:${user.phone.replace(' ', '')}`} target="_blank" rel="noopener noreferrer">
                      {user.phone}
                    </a>
                  )}
                </TableCell>
                <TableCell>{intlDateTimeFormat(user.synced)}</TableCell>
                <TableCell>{intlDateTimeFormat(user.created)}</TableCell>
                <TableCell align="right">
                  <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
                    <DeleteIconEndpoint
                      endpoint={`${unitEndpoint}/users`}
                      _id={user._id as string}
                      onClick={refresh}
                    />
                  </FragmentScope>
                </TableCell>
              </StyledTableRow>
            ))}
            {invites
              .filter((x) => !x.claim!.claimed)
              .map((invite, index) => {
                const count = invite.sendCount || 1;
                return (
                  <StyledTableRow key={index}>
                    <TableCell>
                      {invite.claim!.fullName}&nbsp;(
                      {invite._id === 'queue' ? (
                        <i>{i18n.t('statuses.inviteNotSent')}</i>
                      ) : (
                        <i>
                          <Trans i18nKey="statuses.invitationSent" count={count}>
                            Invitation sent {{ count }} time
                          </Trans>
                          ...&nbsp;
                          {invite.newOwner ? `(${i18n.t('statuses.newOwner')})` : ''}
                          {!invite.created ? `(${i18n.t('statuses.pending')})` : ''}
                        </i>
                      )}
                      )
                    </TableCell>
                    <TableCell>{invite.claim!.email}</TableCell>
                    <TableCell>{invite.claim!.phone}</TableCell>
                    <TableCell>{i18n.t('statuses.notLoggedInn')}</TableCell>
                    <TableCell>{invite.created ? intlDateTimeFormat(invite.created) : '-'}</TableCell>
                    <TableCell align="right">
                      {invite._id === 'queue' ? (
                        <DeleteIconEndpoint
                          endpoint={`${unitEndpoint}/invites`}
                          _id={invite.claim!.email!}
                          onClick={refresh}
                        />
                      ) : (
                        <DeleteIconEndpoint
                          endpoint={`${unitEndpoint}/invites`}
                          _id={invite._id!}
                          onClick={refresh}
                        />
                      )}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
          <TableFooter>
            <StyledTableRow>
              <TableCell align="right" colSpan="6" sx={{ width: '100%' }}>
                <FragmentScope scope={AptlyScopes.ProjectUnitInvites} crud="C">
                  <Grid2 container justifyContent="flex-end">
                    <Grid2>
                      <AddIconButton
                        title={i18n.t('actions.addAdmin')}
                        onClick={() => {
                          createModal(
                            <CrudUnitEdit
                              unit={unit}
                              onCreate={() => refresh()}
                              fields={['users']}
                              usersEndpoint={usersEndpoint}
                            />
                          );
                        }}
                      />
                    </Grid2>
                    <Grid2>
                      <SendIconButton
                        title={i18n.t('actions.sendInvitation')}
                        onClick={() =>
                          createModal(
                            <CrudUnitInvite unit={unit} endpoint={unitsEndpoint} onCreate={refresh} />
                          )
                        }
                      />
                    </Grid2>
                  </Grid2>
                </FragmentScope>
              </TableCell>
            </StyledTableRow>
          </TableFooter>
        </Table>
      </Paper>
    </Section>
  );
}

const StyledTableRow = styled(TableRow)<{ $row?: number }>`
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    th:nth-child(n + 2):nth-child(-n + ${(props) => props.$row || 5}) {
      display: none;
    }
    td:nth-child(n + 2):nth-child(-n + ${(props) => props.$row || 5}) {
      display: none;
    }
  }
`;
