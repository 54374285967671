import { DateTimePicker as MUIDateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { TextFieldProps } from '@mui/material/TextField';
import { Dayjs } from 'dayjs';

export interface IDateTimePickerProps extends Omit<DateTimePickerProps<Dayjs>, 'renderInput'> {
  textFieldProps?: TextFieldProps;
}

const DateTimePicker = ({ textFieldProps, ...props }: IDateTimePickerProps) => {
  return <MUIDateTimePicker {...props} slotProps={{ textField: { fullWidth: true, ...textFieldProps } }} />;
};

export default DateTimePicker;
