import { AptlyScopes } from '@aptly-as/types';
import { useCallback, useContext } from 'react';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { ProjectContext } from '../Project/ProjectContext';
import { ICrudPeriod, usePeriodSchema } from './period.schema';
import { usePeriodColumns } from './period.columns.js';

export default function Periods() {
  const { setData } = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'periods');
  const schema = usePeriodSchema();
  const columns = usePeriodColumns();

  const handleOnChange = useCallback(
    (periods: ICrudPeriod[]) => {
      if (periods) {
        setData({ periods }, {}, false);
      }
    },
    [setData]
  );

  return (
    <Search<ICrudPeriod>
      path={path}
      patchPath={() => path}
      scope={AptlyScopes.ProjectPeriods}
      header={{ title: i18n.t('singles.deadlines') }}
      post={{ title: i18n.t('singles.deadlines') }}
      patch={{ title: (data) => String(data.name) }}
      schema={schema}
      fields={['name', 'open', 'close']}
      options={{ disableBulk: true }}
      reach={{ query: { sort: 'name' } }}
      onChange={handleOnChange}
      primaryAction={<SearchAddPrimaryAction />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
