import { AptlyProjectInjection, AptlyScopes } from '@aptly-as/types';
import AccessTime from '@mui/icons-material/AccessTime';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Functions from '@mui/icons-material/Functions';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Label from '@mui/icons-material/Label';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LocationCity from '@mui/icons-material/LocationCity';
import PaymentIcon from '@mui/icons-material/Payment';
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Star from '@mui/icons-material/Star';
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import { useContext } from 'react';
import Report from '../../containers/Admin/Report';
import { SidebarList } from '../../containers/Nav/sidebar.utils';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import Scope from '../../libraries/scope/Scope';
import { useScope } from '../../libraries/scope/ScopeContext';
import Typography from '../../mui/Typography';
import Algorithms from '../Algorithm/Algorithms';
import { Booking } from '../Booking/Booking';
import { Documents } from '../Document/Documents';
import Emails from '../Email/Emails';
import Inquiries from '../Inquiry/Inquiries';
import Offers from '../Offer/Offers.js';
import OptionLabels from '../OptionLabel/OptionLabels';
import OrdersV1 from '../Order/OrdersV1';
import { OrganizationContext } from '../Organization/OrganizationContext';
import Pages from '../Page/Pages';
import Payments from '../Payment/Payments.js';
import Periods from '../Period/Periods';
import ProductsV2 from '../Product/Products';
import Recommendations from '../Recommendation/Recommendations';
import Units from '../Unit/Units';
import UnitTemplates from '../UnitTemplate/UnitTemplates';
import UpsellTemplates from '../UpsellTemplates/UpsellTemplates';
import { ProjectContext } from './ProjectContext';
import ProjectDashboard from './ProjectDashboard';
import ProjectEmailsStatus from './ProjectEmailsStatus';
import ProjectExtraOptions from './projectExtraOptions/ProjectExtraOptions';
import { toProjectIntegrationItems } from './ProjectIntegrations';
import ProjectInvites from './ProjectInvites/ProjectInvites';
import ProjectSettings from './ProjectSettings';

export function useProjectRoutes(): SidebarList<AptlyProjectInjection>[] {
  const org = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const scope = useScope();

  return [
    {
      injection: AptlyProjectInjection.Base,
      scope: AptlyScopes.Project,
      mobile: true,
      items: [
        {
          scope: AptlyScopes.Project,
          path: '',
          icon: <DashboardIcon />,
          label: i18n.t('singles.dashboard'),
          element: <ProjectDashboard />,
          mobile: true,
        },
      ],
    },
    {
      injection: AptlyProjectInjection.Options,
      scope: [AptlyScopes.OrganizationProducts],
      subheader: i18n.t('paragraphs.setupOfOptions'),
      items: [
        {
          injection: AptlyProjectInjection.Products,
          scope: org.data.producer
            ? [AptlyScopes.OrganizationProducts]
            : [AptlyScopes.ProjectUnitTemplates, AptlyScopes.OrganizationProducts],
          every: true,
          label: i18n.t('singles.products'),
          icon: <CategoryIcon />,
          items: org.data.producer
            ? []
            : [
                {
                  path: 'prosjektprodukter',
                  scope: AptlyScopes.ProjectProducts,
                  label: i18n.t('singles.projectProducts'),
                  icon: <CategoryIcon />,
                  element: <ProductsV2 key={SlugLevel.Project} level={SlugLevel.Project} />,
                },
                {
                  path: 'organisasjonsprodukter',
                  scope: AptlyScopes.OrganizationProducts,
                  label: i18n.t('singles.organizationProducts'),
                  icon: <CategoryIcon />,
                  element: <ProductsV2 key={SlugLevel.Organization} level={SlugLevel.Organization} />,
                },
                {
                  path: 'produkter',
                  scope: AptlyScopes.AdminProducts,
                  label: i18n.t('singles.aptlyProducts'),
                  icon: <CategoryIcon />,
                  element: <ProductsV2 key={SlugLevel.Base} level={SlugLevel.Base} />,
                },
              ],
        },
        {
          injection: AptlyProjectInjection.Content,
          scope: [
            AptlyScopes.ProjectRecommendations,
            AptlyScopes.OrganizationOptionLabels,
            AptlyScopes.ProjectPages,
            AptlyScopes.ProjectEmails,
          ],
          label: i18n.t('singles.content'),
          icon: <InsertDriveFileOutlinedIcon />,
          items: [
            {
              scope: AptlyScopes.ProjectRecommendations,
              path: 'maler/anbefalinger',
              icon: <Star />,
              label: i18n.t('singles.recommendations'),
              element: <Recommendations />,
            },
            {
              scope: AptlyScopes.OrganizationOptionLabels,
              path: 'maler/merkelapper',
              icon: <Label />,
              label: i18n.t('singles.labels'),
              element: <OptionLabels />,
            },
            {
              scope: AptlyScopes.ProjectPages,
              path: 'sider',
              icon: <WebOutlinedIcon />,
              label: i18n.t('singles.pages'),
              element: <Pages />,
            },
            {
              scope: AptlyScopes.ProjectEmails,
              path: 'emails',
              icon: <EmailOutlinedIcon />,
              label: i18n.t('singles.emailtemplates'),
              element: <Emails />,
            },
          ],
        },
        {
          scope: AptlyScopes.ProjectUpsellTemplates,
          path: 'maler/oppsalg',
          icon: <AttachMoney />,
          label: i18n.t('singles.upsell'),
          element: <UpsellTemplates />,
        },
        {
          scope: AptlyScopes.ProjectPeriods,
          path: 'perioder',
          label: (
            <Typography variant="body2">
              {i18n.t('singles.deadlines')}
              <br />
              <i>{i18n.t('singles.optionPeriods')}</i>
            </Typography>
          ),
          icon: <AccessTime />,
          element: <Periods />,
        },
        {
          scope: AptlyScopes.ProjectUnitTemplates,
          crud: 'U',
          path: 'tilvalg',
          label: (
            <Typography variant="body2">
              {i18n.t('singles.catalogs')}
              <br />
              <i>{i18n.t('singles.optionLists')}</i>
            </Typography>
          ),
          icon: <StyleOutlinedIcon />,
          element: <UnitTemplates />,
        },
        {
          scope: [AptlyScopes.ProjectUnitTemplates, AptlyScopes.ProjectUnitExtraOptions],
          every: true,
          path: 'prosjekt-tilvalg',
          label: i18n.t('singles.fees'),
          icon: <AddShoppingCartIcon />,
          element: <ProjectExtraOptions />,
        },
      ],
    },
    {
      injection: AptlyProjectInjection.Service,
      scope: [AptlyScopes.ProjectOffers],
      subheader: i18n.t('singles.service'),
      mobile: true,
      items: [
        {
          scope: AptlyScopes.ProjectOffers,
          path: 'tilbud',
          label: i18n.t('singles.offers'),
          icon: <LocalOfferOutlinedIcon />,
          element: <Offers level={SlugLevel.Project} />,
          mobile: true,
        },
      ],
    },
    {
      injection: AptlyProjectInjection.Project,
      scope: AptlyScopes.Project,
      subheader: i18n.t('singles.project'),
      mobile: true,
      items: [
        {
          scope: AptlyScopes.ProjectUnits,
          path: 'enheter',
          icon: <LocationCity />,
          label: scope.crud(AptlyScopes.ProjectUnitTemplates) ? (
            <Typography variant="body2">
              {i18n.t('singles.customers')}
              <br />
              <i>{i18n.t('singles.units')}</i>
            </Typography>
          ) : (
            i18n.t('singles.customers')
          ),
          element: <Units />,
          mobile: true,
        },
        {
          scope: [AptlyScopes.ProjectUnitTemplates, AptlyScopes.ProjectDocuments],
          every: true,
          path: 'dokumenter',
          icon: <FileCopyOutlinedIcon />,
          label: i18n.t('singles.documents'),
          element: <Documents level={SlugLevel.Project} scope={AptlyScopes.ProjectDocuments} />,
          mobile: true,
        },
        ...toProjectIntegrationItems(project.data),
        {
          scope: AptlyScopes.ProjectOrders,
          path: 'ordre',
          icon: <ShoppingCartOutlinedIcon />,
          label: i18n.t('singles.order'),
          element: <OrdersV1 />,
        },
        {
          scope: [AptlyScopes.ProjectOrders, AptlyScopes.ProjectPayment],
          every: true,
          crud: 'U',
          path: 'betaling',
          label: i18n.t('singles.payment'),
          icon: <PaymentIcon />,
          element: <Payments />,
        },
        {
          scope: AptlyScopes.ProjectReport,
          path: 'rapporter',
          icon: <InsertChartOutlinedIcon />,
          label: i18n.t('singles.reports'),
          element: <Report showPrice={Scope.crud(AptlyScopes.ProjectReportPrice, 'R')} />,
        },
        {
          scope: AptlyScopes.AdminTesting,
          path: 'email-status',
          icon: <EmailOutlinedIcon />,
          label: i18n.t('singles.emails'),
          element: <ProjectEmailsStatus />,
        },
      ],
    },
    {
      injection: AptlyProjectInjection.Support,
      scope: [AptlyScopes.ProjectBooking, AptlyScopes.ProjectInquiry],
      subheader: i18n.t('singles.clientCommmunication'),
      mobile: true,
      items: [
        {
          scope: AptlyScopes.ProjectBooking,
          to: 'motebooking',
          path: 'motebooking/*',
          icon: <CalendarMonthOutlinedIcon />,
          label: i18n.t('singles.bookAMeeting'),
          element: <Booking />,
          mobile: true,
        },
        {
          scope: AptlyScopes.ProjectInquiry,
          to: 'kundesupport',
          path: 'kundesupport/*',
          icon: <ChatOutlinedIcon />,
          label: i18n.t('singles.support'),
          element: <Inquiries />,
          mobile: true,
        },
      ],
    },
    {
      injection: AptlyProjectInjection.Admin,
      scope: [AptlyScopes.Project, AptlyScopes.ProjectAlgorithms],
      crud: 'U',
      subheader: i18n.t('singles.admin'),
      items: [
        {
          scope: [AptlyScopes.ProjectUnitTemplates, AptlyScopes.ProjectAlgorithms],
          every: true,
          path: 'kalkyler',
          icon: <Functions />,
          label: i18n.t('singles.calculations'),
          element: <Algorithms />,
        },
        {
          scope: AptlyScopes.Admin,
          path: 'invitasjoner',
          label: i18n.t('singles.invites'),
          icon: <SendIcon />,
          element: <ProjectInvites />,
        },
        {
          scope: AptlyScopes.Project,
          crud: 'U',
          path: 'instillinger',
          label: i18n.t('singles.settings'),
          icon: <SettingsOutlinedIcon />,
          element: <ProjectSettings />,
        },
      ],
    },
  ];
}

export default useProjectRoutes;
