import { PropsWithChildren, ReactNode, useContext } from 'react';
import { NavContext } from '../containers/Nav/NavContext';

export function HideMobileFragment({ children }: PropsWithChildren<object>): ReactNode {
  const mobile = useMobile();
  if (mobile) return null;
  return children;
}
export function HideDesktopFragment({ children }: PropsWithChildren<object>) {
  const mobile = useMobile();
  if (!mobile) return null;
  return children;
}

export function useMobile(): boolean {
  const { state } = useContext(NavContext);
  return state.mobile;
}
