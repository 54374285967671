import { Alert } from '@mui/material';
import * as React from 'react';
import { useCallback, useContext } from 'react';
import { AptlyScopes, AptlyUser } from '@aptly-as/types';
import { useNavigate } from 'react-router-dom';
import DeleteResourceButton from '../../components/actions/buttons/DeleteResourceButton';
import { EditIconButton } from '../../components/actions/icons/Icons';
import { createModal } from '../../containers/Modal/ModalContext';
import { LayoutScope, FragmentScope } from '../../libraries/scope/ScopeComponets';
import Section from '../../components/Section';
import Typography from '../../mui/Typography';
import Card from '../../mui/Card';
import MaxWidth from '../../components/MaxWidth';
import { containerWidth } from '../../utils/sizes';
import List, { ListItem, ListItemText } from '../../components/List/List';
import { rawSpacing } from '../../utils/spacing';
import Grid from '../../mui/Grid';
import { OrganizationContext } from '../Organization/OrganizationContext';
import SettingsSection from '../../containers/Settings/SettingsSection';
import CrudContactNotify from '../../components/simpleCruds/settings/CrudContactNotify';
import i18n from '../../libraries/i18n';
import ProjectSettingsIntegration from './projectSettings/ProjectSettingsIntegrations';
import ProjectSettingsModules from './projectSettings/ProjectSettingsModules';
import { ProjectContext } from './ProjectContext';
import CrudSettingsListField, {
  CrudSettingsAutocompleteQuery,
  CrudSettingsListMarkdown,
  CrudSettingsListSwitch,
  CrudSettingsSelect,
  SingleSettingsListField,
  SingleSettingsListMedia,
} from '../../components/Settings/CrudSettingsListField';
import ErrorBoundary from '../../components/ErrorBoundary';

function ProjectSettings() {
  const navigate = useNavigate();
  const org = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const data = project.data;
  const onDelete = useCallback(() => navigate(`/organisasjoner/${org.data.slug}`), []);

  if (!data) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Section>
        <Typography variant="h1">{i18n.t('singles.settings')}</Typography>
      </Section>
      <Section>
        <React.Fragment>
          <MaxWidth width={containerWidth}>
            <Grid container spacing={rawSpacing} direction="column" wrap="nowrap">
              <Grid item>
                <SettingsSection title={i18n.t('singles.general')}>
                  <CrudSettingsSelect crud={project} field="status" divider />
                  <CrudSettingsListField crud={project} field="name" divider />
                  <FragmentScope scope={AptlyScopes.ProjectUnitTemplates}>
                    <CrudSettingsListField crud={project} field="number" divider />
                    <CrudSettingsListField crud={project} field="developer" divider />
                    <CrudSettingsListField crud={project} field="field" divider />
                  </FragmentScope>

                  <FragmentScope scope={AptlyScopes.Admin}>
                    <CrudSettingsListSwitch crud={project} field="gdprActive" divider />
                  </FragmentScope>
                </SettingsSection>
              </Grid>
              <FragmentScope scope={AptlyScopes.ProjectSigning}>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.signing')}>
                    <Alert color="info">
                      Sett nye ordre automatisk til signering ved å sette singeringsapp og administratorer som
                      skal signere.
                    </Alert>
                    <CrudSettingsAutocompleteQuery crud={project} field="signApp" divider />
                    <CrudSettingsAutocompleteQuery crud={project} field="signMembers" divider />
                  </SettingsSection>
                </Grid>
              </FragmentScope>
              <FragmentScope scope={AptlyScopes.OrganizationPayment}>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.payment')}>
                    <Alert color="info">
                      Sett nye ordre automatisk til betaling ved å sette betalingsapp
                    </Alert>
                    <CrudSettingsAutocompleteQuery crud={project} field="paymentApp" divider />
                    <CrudSettingsListField crud={project} field="invoiceEmail" />
                  </SettingsSection>
                </Grid>
              </FragmentScope>
              <FragmentScope scope={AptlyScopes.ProjectUnitTemplates}>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.text')}>
                    <CrudSettingsListMarkdown crud={project} field="myPageText" divider />
                    <CrudSettingsListMarkdown crud={project} field="inviteText" divider />
                    <Alert color="info">
                      Visste du at du kan overstyre alle eposter under Innhold og E-postmaler?
                    </Alert>
                  </SettingsSection>
                </Grid>
              </FragmentScope>
              <Grid item>
                <SettingsSection title={i18n.t('singles.contact')}>
                  <Alert color="info">{i18n.t('project.contact')}</Alert>
                  <SingleSettingsListField crud={project} field={'theme.contact.name'} divider />
                  <SingleSettingsListField crud={project} field={'theme.contact.mail'} divider />
                  <SingleSettingsListField crud={project} field={'theme.contact.phone'} divider />
                  <FragmentScope scope={AptlyScopes.ProjectInquiryComplaint}>
                    <SingleSettingsListField crud={project} field={'theme.contact.complaintEmail'} />
                  </FragmentScope>
                </SettingsSection>
              </Grid>
              <FragmentScope scope={AptlyScopes.ProjectUnitTemplates}>
                <Grid item>
                  <SettingsSection title={i18n.t('singles.address')}>
                    <SingleSettingsListField crud={project} field={'address.street'} divider />
                    <SingleSettingsListField crud={project} field={'address.zip'} divider />
                    <SingleSettingsListField crud={project} field={'address.city'} />
                  </SettingsSection>
                </Grid>
              </FragmentScope>
              <Grid item>
                <SettingsSection title={i18n.t('singles.look')}>
                  <SingleSettingsListMedia crud={project} field="theme.images.logo" divider />
                  <FragmentScope scope={AptlyScopes.ProjectUnitTemplates}>
                    <SingleSettingsListMedia crud={project} field="theme.images.hero" divider />
                    <SingleSettingsListMedia crud={project} field="theme.images.fallback" />
                  </FragmentScope>
                </SettingsSection>
              </Grid>
              <LayoutScope item scope={AptlyScopes.Project} crud="D">
                <SettingsSection title={i18n.t('singles.other')}>
                  <ListItem
                    secondaryAction={
                      <EditIconButton
                        onClick={() => {
                          createModal(
                            <CrudContactNotify
                              endpoint={`organizations/${org.data.slug}/projects/${data._id}/notify`}
                              data={data ? data.notify : []}
                              actionLabel={i18n.t('actions.close')}
                            />
                          );
                        }}
                      />
                    }
                  >
                    <ListItemText
                      primary={i18n.t('singles.notifications')}
                      secondary={data?.notify.map((x) => (x.user as AptlyUser).fullName).join(', ')}
                    />
                  </ListItem>
                  <FragmentScope scope={AptlyScopes.Admin}>
                    <ListItem
                      secondaryAction={
                        <DeleteResourceButton
                          endpoint={`api/v1/organizations/${org.data.slug}/projects/${data._id}`}
                          onClick={onDelete}
                        >
                          {i18n.t('actions.archive')}
                        </DeleteResourceButton>
                      }
                    >
                      {i18n.t('actions.archiveProject')}
                    </ListItem>
                  </FragmentScope>
                </SettingsSection>
              </LayoutScope>
              <LayoutScope item scope={AptlyScopes.Project} crud="U">
                <Card>
                  <List disablePadding>
                    <ProjectSettingsModules project={data} onSave={project.actions.read} />
                  </List>
                </Card>
              </LayoutScope>
              <LayoutScope item scope={AptlyScopes.Admin} crud="U">
                <ProjectSettingsIntegration project={data} />
              </LayoutScope>
            </Grid>
          </MaxWidth>
        </React.Fragment>
      </Section>
    </ErrorBoundary>
  );
}

export default ProjectSettings;
