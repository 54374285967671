import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { IUseFieldRet, useFields } from '@ewb/reach-react';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { IInquirySchema, useInquirySchema } from './inquiry.schema';

type Context = IUseFieldRet<IInquirySchema, IApiError, ICrudFieldData<IInquirySchema>> & {
  data: IInquirySchema;
};
export const InquiryContext = createContext<Context>({ data: {} } as any);

export function useInquiry() {
  const { data } = useContext(InquiryContext);
  return data;
}

export function InquiryProvider({ id, children }: PropsWithChildren<{ id?: string }>) {
  const path = useApiUrl(SlugLevel.Unit, 'inquiries');
  const crud = useFields<IInquirySchema, IApiError, ICrudFieldData<IInquirySchema>>(
    path,
    useMemo(() => ({ _id: id }), [id]),
    useInquirySchema(),
    useMemo(
      () => ({
        idKey: '_id',
        initWithGet: true,
        reachOptions: {
          query: {
            $populate: 'unit:_id,name;product:_id,name,images',
          },
        },
      }),
      []
    )
  );

  useEffect(() => {}, []);

  if (crud.state.error) return <ApiError error={crud.state.error} />;
  if (!crud.state.data.messages) return null;

  return (
    <InquiryContext.Provider value={{ data: crud.state.data, ...crud }}>{children}</InquiryContext.Provider>
  );
}
