import { AptlyOffer, AptlyScopes, AptlyUser } from '@aptly-as/types';
import type { GridRowParams } from '@mui/x-data-grid/models/params/index.js';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Search, { SearchFieldQuery, SearchFieldText } from '../../components/Search/Search';
import { SearchAddPrimaryActionTo } from '../../components/Search/search-actions/SearchPrimaryAction';
import { useDataGridVisibilityModel } from '../../components/Search/search-data-grid/data-grid.hooks.js';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid, { ISearchDataGridProps } from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { IUsePaginatedSearchProps } from '../../libraries/reach/usePaginatedSearch.js';
import { useOrganizationMembersAutocompleteQueryProps } from '../Organization/organization.schema';
import { useUnitAutocompleteQueryProps } from '../Unit/unit.schema';
import { offerDesktopsColumns, useOfferColumns } from './offer.columns.js';
import { OfferSchema, useOfferSchema } from './offer.schema.js';
import { getOfferLevelQuery } from './OfferContext';

interface OffersProps {
  level: SlugLevel;
}

export function Offers({ level }: OffersProps) {
  const isOrganization = level === SlugLevel.Organization;
  const path = useApiUrl(level, 'offers');
  const membersQueryProps = useOrganizationMembersAutocompleteQueryProps({
    label: i18n.t('statuses.ourContact'),
  });
  const unitQueryProps = useUnitAutocompleteQueryProps();
  const to = useTo(SlugLevel.Unit, '/tilbud');
  const navigate = useNavigate();
  const columns = useOfferColumns(level);
  const schema = useOfferSchema(level);
  const fields = useMemo((): (keyof OfferSchema)[] => {
    if (level === SlugLevel.Organization) {
      return ['name'];
    }
    return ['name', 'createdBy', 'expiresAt'];
  }, [level]);
  const initialState = useDataGridVisibilityModel(
    offerDesktopsColumns,
    isOrganization
      ? {
          number: false,
          customer: false,
          unit: false,
          status: false,
        }
      : undefined
  );
  const reach: IUsePaginatedSearchProps<OfferSchema> = useMemo(
    () => ({
      paginationMode: 'server',
      query: {
        $populate: 'unit:name;order:status,paymentSession;createdBy:fullName',
        sort: '-createdAt',
        archived: false,
        level: getOfferLevelQuery(level),
      },
    }),
    [level]
  );
  const handleOnRowClick: ISearchDataGridProps['onRowClick'] = useCallback(
    (params: GridRowParams) => {
      navigate(`${to}/${params.id}`);
    },
    [navigate, to]
  );

  return (
    <Search<OfferSchema>
      path={path}
      patchPath={toSearchPatchPath('offers')}
      scope={AptlyScopes.ProjectOffers}
      header={{ title: i18n.t('singles.offers') }}
      post={{ title: i18n.t('singles.offers') }}
      patch={{ title: i18n.t('singles.offers') }}
      schema={schema}
      fields={fields}
      options={{ disableBulk: true }}
      reach={reach}
      primaryAction={
        <SearchAddPrimaryActionTo<AptlyOffer>
          key="create"
          to={to}
          title={i18n.t('actions.newOffer')}
          data={{ expiresAt: isOrganization ? null : dayjs().add(30, 'days').toISOString() }}
        />
      }
      searchFields={[
        <SearchFieldText key="number" field="number" label={i18n.t('singles.number')} />,
        <SearchFieldText key="customer" field="$customer.fullName" label={i18n.t('singles.name')} />,
        <SearchFieldQuery key="unit" field="unit" autocompleteQueryProps={unitQueryProps} />,
        <SearchFieldText key="name" field="$name" label={i18n.t('singles.offers')} />,
        <SearchFieldQuery<AptlyUser>
          key="createdBy"
          field="createdBy"
          autocompleteQueryProps={membersQueryProps()}
        />,
      ]}
    >
      {() => <SearchDataGrid initialState={initialState} columns={columns} onRowClick={handleOnRowClick} />}
    </Search>
  );
}

export default Offers;
