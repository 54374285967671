import dayjs from 'dayjs';
import DateTimePicker from '../../../mui/x-date-picker/DateTimePicker';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudDateProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleDateTime<T extends object>({ field, onChange }: ICrudDateProps<T>) {
  const { value, label, required, autoComplete, props } = field;
  return (
    <DateTimePicker
      label={label}
      value={dayjs(value)}
      onChange={(date: any) => onChange(date)}
      textFieldProps={{
        required,
        autoComplete,
      }}
      {...props}
    />
  );
}
