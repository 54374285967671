import React, { createContext } from 'react';
import { ICreateModelOptions } from './modal.types';
import { newModal } from './modal.utils';

type ContextProps = {
  createModal: (element: React.ReactElement, opts?: ICreateModelOptions) => void;
  deleteModal: (id: string) => void;
};

export const ModalContext = createContext<ContextProps>({
  createModal: () => {},
  deleteModal: () => {},
});

// @deprecated. Use ModalButton or dialog directly.
function createModal(content: React.ReactElement, opts: ICreateModelOptions = {}): string {
  const modal = newModal(content, opts);
  return modal.id;
}

export { createModal };
