import { AptlyInquiry, AptlyInquiryStatus, AptlyProduct, AptlyScopes, AptlyUnit } from '@aptly-as/types';
import Reply from '@mui/icons-material/Reply';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DeleteIconButton, OpenIconButton } from '../../components/actions/icons/Icons';
import EmptyState from '../../containers/Error/EmptyState';
import ConfirmModal from '../../containers/Modal/ConfirmModal';
import { ModalButton } from '../../containers/Modal/ModalButton';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { TextLinkWithIcon } from '../../libraries/router/ComponentLink';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import Grid from '../../mui/Grid';
import Menu, { MenuItem } from '../../mui/Menu';
import Paper from '../../mui/Paper';
import Typography from '../../mui/Typography';
import { translateInquiryType } from './inquiry.types';
import { InquiryContext, InquiryProvider } from './InquiryContext';
import InquiryMessage from './InquiryMessage';
import InquiryRespond from './InquiryRespond';
import { getStatus, inquiryStatusOptions } from './InquiryUtils';
import ProductItem from './ProductItem';

type Props = {
  onSubmit: (inquiry: AptlyInquiry) => void;
  onDelete: (inquiry: AptlyInquiry) => void;
};

export default function Inquiry({ onSubmit, onDelete }: Props) {
  const { inquiryID } = useParams();
  return (
    <InquiryProvider key={inquiryID} id={inquiryID!}>
      <Content onSubmit={onSubmit} onDelete={onDelete} />
    </InquiryProvider>
  );
}

type State = {
  menuEl: HTMLButtonElement | null;
  busy: boolean;
  inquiryID: string;
};

function Content({ onSubmit, onDelete }: Props) {
  const toUnit = useTo(SlugLevel.Project, '/enheter');
  const { data: inquiry, setField, actions } = useContext(InquiryContext);

  const [state, setState] = useState<State>({
    menuEl: null,
    busy: false,
    inquiryID: '',
  });

  const { menuEl, busy } = state;

  const openMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setState((s) => ({ ...s, menuEl: event.currentTarget }));
  }, []);

  const closeMenu = useCallback(() => {
    setState((s) => ({ ...s, menuEl: null }));
  }, []);

  const statusChange = useCallback(
    (status: AptlyInquiryStatus) => async () => {
      setField('status')(status);
      inquiry.status = status;
      onSubmit(inquiry);
    },
    [inquiry, onSubmit, setField]
  );

  const handleOnDelete = useCallback(() => {
    createModal(
      <ConfirmModal
        actionLabel={i18n.t('actions.delete')}
        onConfirm={async () => {
          await actions.delete();
          onDelete(inquiry);
        }}
      >
        {i18n.t('paragraphs.areYouSureToDelete')}
      </ConfirmModal>
    );
  }, [inquiry, actions]);

  if (!inquiry) {
    return (
      <EmptyState>
        {busy ? `${i18n.t('statuses.loadingMessage')}...` : i18n.t('statuses.couldNotFindMessage')}
      </EmptyState>
    );
  }

  const unit = inquiry.unit as AptlyUnit;

  return (
    <Grid container wrap="wrap" spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Grid item>
            <CenteredLayout item xs={12}>
              <TitleHeader variant="subtitle1">
                <TextLinkWithIcon to={`${unit ? toUnit + '/' + unit._id : '#'}`}>
                  {unit ? unit.name + ': ' : ''}
                  {inquiry.title}
                  <br />
                  {inquiry.caseNumber ? `${i18n.t('singles.caseNumber')}: ${inquiry.caseNumber}` : ''}
                </TextLinkWithIcon>
              </TitleHeader>
            </CenteredLayout>
          </Grid>
          <Grid container justifyContent="space-between">
            <FragmentScope scope={AptlyScopes.ProjectInquiry} crud="U">
              <Grid item xs={6}>
                <ModalButton buttonProps={{ startIcon: <Reply /> }} label={i18n.t('actions.answer')}>
                  <InquiryRespond />
                </ModalButton>
              </Grid>
            </FragmentScope>
            <FragmentScope scope={AptlyScopes.ProjectInquiry} crud="U">
              <Grid container justifyContent="flex-end" gap={1} item xs={6}>
                <OpenIconButton onClick={openMenu} size="medium" icon={getStatus(inquiry).icon}>
                  {getStatus(inquiry).label}
                </OpenIconButton>
                <DeleteIconButton onClick={handleOnDelete} size="medium" />
                <Menu
                  id="message-menu"
                  anchorEl={menuEl || undefined}
                  open={Boolean(menuEl)}
                  onClick={closeMenu}
                >
                  {inquiryStatusOptions().map((status) => (
                    <MenuItem key={status.value} onClick={statusChange(status.value)}>
                      <Grid container>
                        <Grid item>{status.icon}</Grid>
                        <Grid item>{status.label}</Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </FragmentScope>
          </Grid>
        </Paper>
      </Grid>
      {inquiry.product && typeof inquiry.product === 'object' && (
        <Grid item xs={12}>
          <Typography variant="subtitle1">{translateInquiryType()[inquiry._type]}</Typography>
          <Paper>
            <ProductItem product={inquiry.product as AptlyProduct} variant={inquiry.variant} />
          </Paper>
        </Grid>
      )}

      <Grid item xs={12}>
        {inquiry.messages.map((message) => (
          <InquiryMessage key={message._id} message={message} />
        ))}
      </Grid>
    </Grid>
  );
}

const CenteredLayout = styled((props: any) => <Grid {...props} />)`
  text-align: center;
  align-self: center;
`;

const TitleHeader = styled(Typography)`
  display: flex;
  justify-content: center;
  padding-top: 0.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
