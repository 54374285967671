import { FormEvent, useCallback, useContext, useMemo } from 'react';
import { AptlyAppSetting } from '@aptly-as/types';
import { IUseCrudProps } from '@ewb/reach-react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppContext } from './AppContext';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { SimpleCrudContent } from '../../components/simple/SimpleCrud';
import handleError from '../../containers/Error/handleError';
import ApiError from '../../components/ApiError';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import Section from '../../components/Section';
import i18n from '../../libraries/i18n';
import { successNotification } from '../../containers/Notification/notification.utils';
import { OrganizationContext } from '../Organization/OrganizationContext';

export default function AppSettings() {
  const org = useContext(OrganizationContext);
  const { app, config } = useContext(AppContext);

  const url = useApiUrl(SlugLevel.Organization, 'apps');
  const schema = useMemo(() => app.settings.reduce(toFieldFromKey, {}), [app.settings]);
  const props: IUseCrudProps<any> = useMemo(
    () => ({
      idKey: '_id',
      disableAutoSave: true,
      initWithGet: false,
      subPath: 'organization-settings',
    }),
    []
  );
  const data = useMemo(() => ({ ...config.settings, _id: app._id }), [config, app._id]);
  const [crud, crudFields] = useSimpleCrudFields<any, keyof any>(
    url,
    data,
    schema,
    Object.keys(schema),
    props
  );
  const { save, state } = crud;

  const handleOnSubmit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault();
        const settings = await save();
        const apps = org.data.apps || [];
        const index = apps.findIndex((x) => (x._id = config._id));
        if (index > -1) {
          apps[index] = { ...apps[index], settings };
          org.setData({ apps }, {}, false);
        }
        successNotification(i18n.t('statuses.saved'));
      } catch (e) {
        handleError(e);
      }
    },
    [config._id, save, org]
  );

  if (state.error) return <ApiError error={state.error} />;

  return (
    <Section>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          md={6}
          direction="column"
          gap={2}
          component="form"
          onSubmit={handleOnSubmit}
        >
          <Typography variant="h2" gutterBottom>
            {app.name} - {i18n.t('singles.settings')}
          </Typography>
          <SimpleCrudContent crud={crud} schema={crudFields} />
          <ActionButtons disabled={state.busy} />
        </Grid>
      </Grid>
    </Section>
  );
}

function toFieldFromKey<T extends object>(obj: ICrudSchema<T>, field: AptlyAppSetting) {
  obj[field.key as keyof ICrudSchema<T>] = field;
  return obj;
}
