import { createContext } from 'react';
import { IUnitTemplateContext } from './unit-template.utils';

export const UnitTemplateContext = createContext<IUnitTemplateContext>({
  activePackage: null,
  setActivePackage: () => {
    // empty
  },
  unitTemplate: {} as any,
  setState: () => {
    // empty
  },
  onOver: () => {
    // empty
  },
  onOrder: () => {
    // empty
  },
  read: () => {
    // empty
  },
  undo: () => Promise.resolve(),
  path: '',
  editProduct: () => {
    // empty
  },
});
