import { AptlyMediaSrc } from '@aptly-as/types';
import { ImgHTMLAttributes, useCallback } from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import Avatar, { AvatarProps } from '../../components/Avatar';
import { createModal } from '../../containers/Modal/ModalContext';
import createMediaURL, { IMediaTransformations } from './createMediaURL';
import i18n from '../../libraries/i18n';

export const Img = styled.img`
  display: block;
  object-fit: contain;
  height: 100%;
  width: auto;
`;

type Props = {
  src: string;
  alt?: string;
  width?: number;
};

type ImageCloudProps = Props &
  IMediaTransformations & {
    onClose?: () => {};
  } & ImgHTMLAttributes<HTMLImageElement>;

interface ImageMediaCloudProps extends ImageCloudProps {
  mediaProps?: ImageCloudProps;
}

export function ImageMediaCloud({ src, alt, ...rest }: ImageMediaCloudProps) {
  const url = createMediaURL(src, rest);
  return <Img src={url} alt={alt} {...rest} />;
}

export function ImageMediaAvatar({
  media,
  ...rest
}: { media: AptlyMediaSrc } & Omit<AvatarProps, 'src' | 'slotProps' | 'alt' | 'variant' | 'contain'>) {
  return (
    <Avatar
      src={createMediaURL(media.src)}
      slotProps={{
        img: { loading: 'lazy' },
      }}
      alt={media.alt || media.name}
      variant="rounded"
      contain
      {...rest}
    />
  );
}

function ImageWithModal({ src, crop = 'fit', ...rest }: ImageCloudProps) {
  const url = createMediaURL(src, { ...rest });
  const handleOnOpen = useCallback(() => {
    const url = createMediaURL(src, { height: 1000, width: 1000, crop });
    createModal(<Img src={url} {...rest} />, {
      enableBackdropOnClose: true,
    });
  }, [src, rest, crop]);

  return (
    <div onClick={handleOnOpen}>
      <Tooltip title={i18n.t('actions.open')} arrow>
        <Img src={url} {...rest} />
      </Tooltip>
    </div>
  );
}

export default Img;
export { ImageWithModal };
