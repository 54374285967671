import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useContext } from 'react';
import * as React from 'react';
import { useSSOUpdate } from '@aptly-as/sso-login-react';
import { EditButtonWithIcon } from '../../components/actions/buttons/Buttons';
import Section from '../../components/Section';
import { IconButton } from '../../mui/Button';
import Typography from '../../mui/Typography';
import Card from '../../mui/Card';
import MaxWidth from '../../components/MaxWidth';
import { containerWidth } from '../../utils/sizes';
import List, { ListItem } from '../../components/List/List';
import { rawSpacing } from '../../utils/spacing';
import Grid from '../../mui/Grid';
import StatusText from '../../components/StatusText';
import i18n from '../../libraries/i18n';
import { API_URL } from '../../env';
import Avatar from '../../components/Avatar';
import createMediaURL from '../Media/createMediaURL';
import { UserContext } from './UserContext';

const InfoItem = ({ label, value }: { label: string; value: JSX.Element | string }) => (
  <ListItem divider>
    <Grid container spacing={rawSpacing}>
      <Grid item sm={3}>
        {label}
      </Grid>
      <Grid item sm={9}>
        <StatusText>{value}</StatusText>
      </Grid>
    </Grid>
  </ListItem>
);

function UserSettings() {
  const { data, toggleMode, mode, refresh } = useContext(UserContext);
  const edit = useSSOUpdate(`${API_URL}/auth/admin/update`, refresh);

  return (
    <React.Fragment>
      <Section>
        <Typography variant="h1">{i18n.t('singles.settings')}</Typography>
      </Section>
      <Section>
        <React.Fragment>
          <MaxWidth width={containerWidth}>
            <Grid container spacing={rawSpacing} direction="column" wrap="nowrap">
              <Grid item>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" gutterBottom>
                    {i18n.t('singles.general')}
                  </Typography>
                  <EditButtonWithIcon variant="contained" onClick={edit} />
                </Grid>
                <Card>
                  <List disablePadding>
                    <InfoItem label={i18n.t('singles.name')} value={data.fullName} />
                    <InfoItem label={i18n.t('singles.email')} value={data.email} />
                    <InfoItem label={i18n.t('singles.phone')} value={data.phone || '-'} />
                    {data.profileImage && (
                      <InfoItem
                        label={i18n.t('singles.profileImage')}
                        value={
                          <Avatar
                            src={createMediaURL(data.profileImage, { height: 200 })}
                            alt={i18n.t('singles.profileImage')}
                            size="100px"
                          />
                        }
                      />
                    )}
                    <InfoItem
                      label={i18n.t('singles.darkmode')}
                      value={
                        <IconButton onClick={toggleMode}>
                          {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                        </IconButton>
                      }
                    />
                  </List>
                </Card>
              </Grid>
            </Grid>
          </MaxWidth>
        </React.Fragment>
      </Section>
    </React.Fragment>
  );
}

export default UserSettings;
