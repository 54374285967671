import { AptlyAddress } from '@aptly-as/types';
import NavigationIcon from '@mui/icons-material/Navigation';
import CallIcon from '@mui/icons-material/Call';
import { TextLinkWithIcon } from '../../libraries/router/ComponentLink';

interface PhoneLinkProps {
  tel: string;
}

export function PhoneLink({ tel }: PhoneLinkProps) {
  return (
    <TextLinkWithIcon to={`tel:${tel}`} icon={<CallIcon />} target="_blank">
      {tel}
    </TextLinkWithIcon>
  );
}

interface AddressLinkProps {
  address: AptlyAddress;
}

export function AddressLink({ address }: AddressLinkProps): JSX.Element {
  const label = useAddressLabel(address);
  const to = useGoogleNavLink(address);
  return (
    <TextLinkWithIcon to={to} icon={<NavigationIcon />} target="_blank">
      {label}
    </TextLinkWithIcon>
  );
}

export function useAddressLabel(address: AptlyAddress): string {
  const label: string[] = [];
  if (address.line1) label.push(address.line1);
  if (address.line2) label.push(`(${address.line2})`);
  if (address.zip) label.push(address.zip);
  if (address.city) label.push(address.city);
  return label.join(' ');
}

export function useGoogleNavLink(address: AptlyAddress): string {
  const googleLink = 'https://maps.google.com/maps';
  const search = new URLSearchParams({});
  let location: [number, number] | null = null;
  const label = useAddressLabel(address);
  if (address.location?.coordinates) {
    if (address.location.coordinates[0] && address.location.coordinates[1]) {
      location = address.location.coordinates;
    }
  }
  if (location) {
    search.set('q', `${location[1]},${location[0]}`);
  } else {
    search.set('q', label);
  }
  return `${googleLink}?${search}`;
}
