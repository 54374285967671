import { AptlyAvailability, AptlyAvailabilityCalendar, AptlyFieldType, AptlyScopes } from '@aptly-as/types';
import { schemaDescription, schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudSchema } from '../../components/crud/utils/crud.utils';
import i18n from '../../libraries/i18n';
import { useScope } from '../../libraries/scope/ScopeContext.js';
import { useOrganizationMembersField } from '../Organization/organization.schema.js';

export function useAvailabilitySchema(): ICrudSchema<AptlyAvailability> {
  return {
    organization: {
      type: AptlyFieldType.Hidden,
      defaultValue: '',
      label: '',
    },
    project: {
      type: AptlyFieldType.Hidden,
      defaultValue: '',
      label: '',
    },
  };
}

export function useCalendarSchema(): ICrudSchema<AptlyAvailabilityCalendar> {
  const scope = useScope();
  return {
    name: schemaName(),
    description: schemaDescription(),
    location: {
      type: AptlyFieldType.GooglePlace,
      defaultValue: { formatted_address: '', name: '', geometry: { location: { lat: 0, lng: 0 } } },
      label: i18n.t('singles.address'),
    },
    attendees: useOrganizationMembersField({
      label: i18n.t('singles.attendees'),
      renderValidate: () => scope.crud(AptlyScopes.OrganizationMembers, 'R'),
    }),
    userAvailability: useOrganizationMembersField({
      label: i18n.t('info.booking.userAvailability'),
    }),
    endAvailabilityAt: {
      type: AptlyFieldType.DateTime,
      defaultValue: null,
      label: i18n.t('info.booking.endAvailabilityAt'),
    },
    fee: {
      type: AptlyFieldType.Number,
      defaultValue: 0,
      label: i18n.t('paragraphs.userFee'),
    },
  };
}
