import * as React from 'react';
import { AptlyAlgorithm, AptlyUnitTemplateCategory } from '@aptly-as/types';
import Resource from '../../../components/Resource';
import Accordion from '../../../components/Accordion';
import { createModal } from '../../../containers/Modal/ModalContext';
import Grid from '../../../mui/Grid';
import { tightSpacing } from '../../../utils/spacing';
import Button from '../../../mui/Button';
import ActionBar from '../../../components/ActionBar';
import Sections, { SectionsProps } from './Sections';
import { AccordionActions, AccordionBody, AccordionDetails } from '../../../mui/Accordion';
import BusyWrapper from '../../../components/BusyWrapper';
import { LinearProgresser } from '../../../mui/Progresser';
import Section from '../../../components/Section';
import StatusText from '../../../components/StatusText';
import { Category } from '../../UnitTemplate/unit-template.types';
import { CategoryOverride, Room } from '../unit.types';
import i18n from '../../../libraries/i18n';
import CrudOverwriteCategory from '../../../components/simpleCruds/options/CrudOverwriteCategory';

interface Props extends Omit<SectionsProps, 'category' | 'sections'> {
  endpoint: string;
  algorithms: AptlyAlgorithm[];
  categoryOverrides?: CategoryOverride[];
  dataPath?: string;
  organizationSlug: string;
  projectID: string;
  rooms?: Room[];
}

function Categories(props: Props) {
  const {
    endpoint,
    dataPath,
    selectedOptions,
    unitEndpoint,
    onSave,
    categoryOverrides,
    productOverrides,
    rooms,
    canUpdate,
  } = props;

  return (
    <Resource<AptlyUnitTemplateCategory[]> endpoint={endpoint}>
      {({ busy, data }) => {
        let constructedEndpoint = endpoint;
        let categories: AptlyUnitTemplateCategory[] = [] as AptlyUnitTemplateCategory[];

        if (Array.isArray(data)) {
          categories = data;
        } else if (data && dataPath) {
          categories = data[dataPath];
          constructedEndpoint = `${endpoint}/categories`;
        } else {
          return <LinearProgresser />;
        }

        return (
          <Section>
            <BusyWrapper $busy={busy}>
              <Grid container spacing={tightSpacing} direction="column" wrap="nowrap">
                <Grid item>
                  {categories.map((c) => {
                    const override = categoryOverrides
                      ? categoryOverrides.find((o) => o.category === c._id)
                      : undefined;
                    const isHiddenAsRoom =
                      Array.isArray(rooms) && c.isRoom && !rooms.some((x) => x.name === c.name);
                    const subtitle = c.isRoom ? i18n.t('singles.room') : '';

                    return (
                      <Accordion
                        key={c._id}
                        title={
                          <React.Fragment>
                            {c.name}&nbsp;
                            {isHiddenAsRoom && (
                              <StatusText>
                                {i18n.t('statuses.optionsHidden')} ({i18n.t('paragraphs.roomMissing')})
                              </StatusText>
                            )}
                            {override && override.hide && (
                              <StatusText>
                                {i18n.t('statuses.optionsHidden')} ({i18n.t('singles.overwritten')})
                              </StatusText>
                            )}
                          </React.Fragment>
                        }
                        subtitle={subtitle}
                      >
                        {() => (
                          <Resource endpoint={`${constructedEndpoint}/${c._id}`}>
                            {({ busy, data, refresh }) => {
                              if (!data || Array.isArray(data)) {
                                return <LinearProgresser />;
                              }

                              const singleCategoryRefresh = refresh;
                              const category: Category = data as Category;

                              return (
                                <BusyWrapper $busy={busy}>
                                  <AccordionDetails>
                                    <AccordionBody>
                                      <Sections
                                        sections={category.sections}
                                        category={category}
                                        selectedOptions={selectedOptions}
                                        unitEndpoint={unitEndpoint}
                                        productOverrides={productOverrides}
                                        onSave={() => {
                                          singleCategoryRefresh();
                                          if (typeof onSave === 'function') {
                                            onSave();
                                          }
                                        }}
                                        canUpdate={canUpdate}
                                      />
                                    </AccordionBody>
                                  </AccordionDetails>
                                  {canUpdate && (
                                    <AccordionActions>
                                      <ActionBar>
                                        <Button
                                          color="primary"
                                          onClick={() =>
                                            createModal(
                                              <CrudOverwriteCategory
                                                endpoint={unitEndpoint || ''}
                                                category={category}
                                                override={override}
                                                onCreate={() => onSave && onSave()}
                                              />
                                            )
                                          }
                                        >
                                          {i18n.t('actions.overwriteCategory')}
                                        </Button>
                                      </ActionBar>
                                    </AccordionActions>
                                  )}
                                </BusyWrapper>
                              );
                            }}
                          </Resource>
                        )}
                      </Accordion>
                    );
                  })}
                </Grid>
              </Grid>
            </BusyWrapper>
          </Section>
        );
      }}
    </Resource>
  );
}

export default Categories;
