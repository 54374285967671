import { useCallback, useContext } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { AptlyProjectInvite, AptlyScopes } from '@aptly-as/types';
import SearchAddPrimaryAction from '../../../components/Search/search-actions/SearchPrimaryAction';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../../components/Search/search-data-grid/data-grid.actions';
import Search, { SearchFieldText } from '../../../components/Search/Search';
import SearchDataGrid from '../../../components/Search/SearchDataGrid';
import { API_URL } from '../../../env';
import { SlugLevel, useApiUrl } from '../../../hooks/useGetApiUrl';
import i18n from '../../../libraries/i18n';
import useScopeLevel from '../../../libraries/scope/useScopeLevel.js';
import { ProjectContext } from '../ProjectContext';
import { useProjectInvitesSchema } from './project-invites.schema';

function useProjectInviteColumns(): GridColDef[] {
  return [
    {
      field: 'code',
      flex: 1,
      headerName: i18n.t('singles.invitation'),
      renderCell: (params) => {
        return `${API_URL}/api/invite/accept/${params.value}`;
      },
    },
    {
      field: 'unit',
      headerName: i18n.t('singles.unit'),
      renderCell: (params) => params.value?.name || params.value,
      width: 200,
    },
    dataGridActionProps((params) => (
      <DataGridActionWrapper>
        <DataGridEditAction params={params} />
        <DataGridDeleteAction params={params} />
      </DataGridActionWrapper>
    )),
  ];
}

export default function ProjectInvites() {
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'invites');
  const scope = useScopeLevel({
    base: AptlyScopes.Admin,
  });

  const schema = useProjectInvitesSchema();
  const columns = useProjectInviteColumns();
  const { setData } = project;

  const handleOnChange = useCallback(
    (invites: AptlyProjectInvite[]) => {
      if (project && invites) {
        if (setData) {
          setData({ invites }, {}, false);
        }
      }
    },
    [setData]
  );

  return (
    <Search<AptlyProjectInvite>
      path={path}
      patchPath={() => path}
      scope={scope}
      header={{ title: i18n.t('singles.invites') }}
      post={{ title: i18n.t('singles.invites') }}
      patch={{ title: (data) => String(data.code) }}
      schema={schema}
      fields={['unit']}
      options={{ disableBulk: true }}
      reach={{ query: { $populate: 'unit:name' } }}
      onChange={handleOnChange}
      primaryAction={<SearchAddPrimaryAction key="create" />}
      searchFields={[
        <SearchFieldText key={0} field="name" queryKey="name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
