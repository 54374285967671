import { useCallback, useContext, useMemo } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AptlyUnitTemplateCategory } from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import { CreateButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { UploadIconButton } from '../../components/actions/icons/Icons';
import { byOrderIds } from '../../utils/array';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import { usePeriods } from '../Period/usePeriods';
import { UnitTemplateContext } from './utils/UnitTemplateContext';
import { UnitTemplateCategory } from './Category';
import useSimplePost from '../../components/simple/useSimplePost';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { unitTemplateImportSchema, unitTemplateCategorySchema } from './unit-template.schema';
import i18n from '../../libraries/i18n';
import useSimpleDelete from '../../components/simple/useSimpleDelete';
import useSimplePatch from '../../components/simple/useSimplePatch';
import ActionBar from '../../components/ActionBar';
import { onUnitTemplatePush, onUnitTemplateSlice } from './utils/unit-template.state';
import { ProjectContext } from '../Project/ProjectContext';

const confirmInput = (data: AptlyUnitTemplateCategory) => (data.sections.length > 0 ? data.name : undefined);

export function UnitTemplateCategories() {
  const project = useContext(ProjectContext);
  const algorithms = useAlgorithms();
  const periods = usePeriods();
  const { unitTemplate, setState } = useContext(UnitTemplateContext);
  const { _id, categories, _order_categories } = unitTemplate;

  const unitTemplatePath = useApiUrl(SlugLevel.Project, 'unit-templates');
  const path = `${unitTemplatePath}/${_id}/categories`;
  const newCategory = useSimplePost(path, unitTemplateCategorySchema(), {
    fields: ['name', 'isRoom', 'upsellTemplate'],
    title: i18n.t('actions.newCategory'),
  });
  const importCategory = useSimplePost<any>(`${path}/import`, unitTemplateImportSchema(algorithms, periods), {
    fields: project.data ? ['file', 'period', 'algorithm'] : ['file', 'algorithm'],
    title: i18n.t('actions.newCategory'),
    useFieldsProps: { reachOptions: { fileKeys: ['file'], type: 'multipart/form-data' } },
  });

  const handleOnSpliceCategory = useCallback(
    (from: number, end: number, category?: AptlyUnitTemplateCategory) => {
      setState(onUnitTemplateSlice({ level: 'category', from, end, category }));
    },
    [setState]
  );
  const handleOnDelete = useSimpleDelete<AptlyUnitTemplateCategory>(() => path, handleOnSpliceCategory, {
    confirmInput,
  });
  const editCategory = useSimplePatch(() => path, unitTemplateCategorySchema(), handleOnSpliceCategory, {
    fields: ['name', 'isRoom', 'upsellTemplate'],
    title: (data) => String(data.name),
  });

  const pushCategory = useCallback(
    (category: AptlyUnitTemplateCategory) => {
      setState(onUnitTemplatePush({ category }));
    },
    [setState]
  );

  const items = useMemo(() => {
    const cs = categories?.map((x) => ({ ...x, id: `category-${x._id}` })) || [];
    return _order_categories ? cs.sort(byOrderIds(_order_categories)) : cs;
  }, [categories, _order_categories]);

  return (
    <Grid container flexDirection="column">
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((category) => (
          <UnitTemplateCategory
            key={category._id}
            index={categories.findIndex((x) => x._id === category._id)}
            category={category}
            rootPath={`${path}/${category._id}`}
            onDelete={handleOnDelete}
            onEdit={editCategory}
            onPush={pushCategory}
          />
        ))}
      </SortableContext>
      <ActionBar $left>
        <CreateButtonWithIcon onClick={() => newCategory(pushCategory)}>
          {i18n.t('actions.newCategory')}
        </CreateButtonWithIcon>
        <UploadIconButton onClick={() => importCategory(pushCategory)}>
          {i18n.t('actions.uploadCategory')}
        </UploadIconButton>
      </ActionBar>
    </Grid>
  );
}
