import { AptlyAvailability, AptlyAvailabilityCalendar } from '@aptly-as/types';
import { useCallback } from 'react';
import { SimpleModal } from '../../components/simple/SimpleCrud';
import { useCalendarSchema } from './booking.schema';
import i18n from '../../libraries/i18n';
import useGetApiUrl from '../../hooks/useGetApiUrl';
import { AptlyAvailabilityContext, AptlyCalendarContext } from './BookingContext';

type Props = {
  availability: AptlyAvailabilityContext;
  calendar?: AptlyCalendarContext;
  onClose?: () => void;
  onDestroy?: (availability: AptlyAvailability) => void;
};

export default function CalendarAddOrEdit(props: Props) {
  const { availability, calendar, onClose } = props;
  const endpoint = useGetApiUrl('project') + `/bookings/${availability.data!._id}`;
  const schema = useCalendarSchema();

  const handleOnEdit = useCallback(
    (newCalendar: AptlyAvailabilityCalendar) => {
      if (calendar?.loaded) {
        calendar.setData(newCalendar);
      } else {
        availability.get();
      }
    },
    [calendar, availability]
  );

  return (
    <SimpleModal
      path={endpoint}
      data={calendar?.data || {}}
      schema={schema}
      fields={[
        'name',
        'description',
        'location',
        'attendees',
        'userAvailability',
        'endAvailabilityAt',
        'fee',
      ]}
      title={calendar?.data?._id ? calendar.data.name : i18n.t('actions.newCalendar')}
      onEdit={handleOnEdit}
      onClose={onClose}
    />
  );
}
