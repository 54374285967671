import { GridColDef } from '@mui/x-data-grid';
import { dataGridActions } from '../../components/Search/search-data-grid/data-grid.fields.js';
import { useFormat } from '../../containers/Format/Format.js';
import i18n from '../../libraries/i18n.js';
import { dataGridImageColumn } from '../../mui/x-data-grid/dataGrid.cols.js';
import { useAlgorithmAmountColumn } from '../Algorithm/algorithm.columns.js';
import { itemTypeLabel } from '../Item/item.utils.js';
import createMediaURL from '../Media/createMediaURL.js';
import extractFeaturedImage from '../Product/extractFeaturedImage.js';
import { IOptionSchema } from './option.schema.js';

export function useOptionColumns(): GridColDef<IOptionSchema>[] {
  const format = useFormat();
  const defaultColumns = useDefaultOptionColumns();

  return [
    ...defaultColumns,
    {
      type: 'string',
      field: 'type',
      headerName: i18n.t('singles.type'),
      valueGetter: itemTypeLabel,
    },
    useAlgorithmAmountColumn({
      headerName: i18n.t('singles.customerAmount'),
    }),
    {
      type: 'number',
      field: 'quantity',
      width: 100,
      headerName: i18n.t('singles.quantity'),
      align: 'right',
      valueGetter: (quantity, row) => format.quantity(row, quantity),
    },
    dataGridActions(
      { checkIfDisabledInProject: true },
      {
        minWidth: 150,
      }
    ),
  ];
}

export function useOptionSearchColumns(): GridColDef<IOptionSchema>[] {
  return useDefaultOptionColumns();
}

function useDefaultOptionColumns(): GridColDef<IOptionSchema>[] {
  return [
    dataGridImageColumn<IOptionSchema>('product', {}, (params) => {
      if (params.row.thumbnail) {
        return createMediaURL(params.row.thumbnail.src, { width: 80 });
      }
      if (!params.row.product?.images) return undefined;
      const featuredImage = extractFeaturedImage(params.row.product.images);
      return featuredImage
        ? createMediaURL(featuredImage.src || featuredImage.image, { width: 80 })
        : undefined;
    }),
    {
      type: 'string',
      field: 'identification',
      headerName: i18n.t('singles.identification'),
      width: 110,
    },
    {
      type: 'string',
      field: 'name',
      headerName: i18n.t('singles.name'),
      flex: 1,
      renderCell: ({ row, value }) => {
        if (row.title) {
          return (
            <>
              {row.title} ( <i>{value}</i> )
            </>
          );
        }
        return value;
      },
    },
  ];
}
