import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { MouseEvent, useCallback, useContext, useMemo } from 'react';
import { Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { DrawerMenu } from '../../containers/Nav/DrawerMenu';
import Header from '../../containers/Nav/Header';
import { Main } from '../../components/Main';
import StatusText from '../../components/StatusText';
import { ModalProvider } from '../../containers/Modal/ModalProvider.js';
import { NavBackItem } from '../../containers/Nav/NavBackItem';
import { NavDrawer } from '../../containers/Nav/NavDrawer';
import SidebarRoutes from '../../containers/Nav/SidebarRoutes';
import useGetApiUrl, { SlugLevel } from '../../hooks/useGetApiUrl';
import apiRequest from '../../libraries/fetch/apiRequest';
import i18n from '../../libraries/i18n';
import { ScopeProvider } from '../../libraries/scope/ScopeProvider';
import Divider from '../../mui/Divider';
import Typography from '../../mui/Typography.js';
import AppProject from '../App/AppProject';
import { UnitNotes } from '../Note/UnitNotes';
import Offer from '../Offer/Offer.js';
import { OrganizationContext } from '../Organization/OrganizationContext';
import OrganizationHeader from '../Organization/OrganizationHeader';
import { ProjectContext } from '../Project/ProjectContext';
import ProjectHeader from '../Project/ProjectHeader';
import { useUnitRoutes } from './unit.routes';
import { UnitContext } from './UnitContext';
import { UnitProvider } from './UnitProvider.js';

interface Params {
  slug: string;
  projectID: string;
  unitID: string;
  [key: string]: string;
}

export default function Unit() {
  const org = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const { unitID } = useParams<Params>();
  return (
    <UnitProvider key={unitID} id={unitID!}>
      <ScopeProvider path={`organizations/${org.data.slug}/projects/${project.data._id}/scope`}>
        <ModalProvider>
          <Content />
        </ModalProvider>
      </ScopeProvider>
    </UnitProvider>
  );
}

function Content() {
  const { unitID } = useParams<Params>();
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const unit = useContext(UnitContext);
  const routes = useUnitRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const endpoint = useGetApiUrl('unit');
  const pathname = location.pathname;
  const slug = organization.data.slug;
  const projectID = project.data._id;

  const projectUrl = useMemo(() => `/organisasjoner/${slug}/prosjekter/${projectID}`, [slug, projectID]);
  const unitUrl = useMemo(() => `${projectUrl}/enheter`, [slug, projectID, unitID]);

  const handleOnNextUnit = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      try {
        const params = new URLSearchParams();

        if (e.currentTarget.name) params.set('back', 'true');
        const nextUnit = await apiRequest<{ _id: string }>(`${endpoint}/next?${params}`);

        let path = pathname;
        const endsWithUnitId = new RegExp('enheter/[a-z0-9]{24}/?$');
        const endsWithId = new RegExp('[a-z0-9]{24}/?$');
        if (!pathname.match(endsWithUnitId)) {
          path = path.replace(endsWithId, '');
        }
        navigate(path.replace(unitID!, nextUnit._id));
      } catch (_e: unknown) {
        navigate(unitUrl);
      }
    },
    [endpoint, unitUrl, unitID, pathname, navigate]
  );

  return (
    <>
      <Header
        breadcrumbs={[
          <OrganizationHeader key="org" />,
          <ProjectHeader key="project" />,
          <Typography key="unit" variant="body1">
            {unit.data ? unit.data.name : <StatusText>{i18n.t('statuses.loading')}…</StatusText>}
          </Typography>,
        ]}
      />
      <NavDrawer>
        <NavBackItem to={`${projectUrl}/enheter`} primary={project.data.name} />
        <Divider />
        <DrawerMenu level={SlugLevel.Unit} routes={routes} injection="unit" />
        <Divider />
        <List>
          <ListItem disablePadding>
            <Button name="back" onClick={handleOnNextUnit} startIcon={<ArrowBackIcon />} size="large" />
            <Button onClick={handleOnNextUnit} endIcon={<ArrowForwardIcon />} fullWidth size="medium">
              {i18n.t('actions.next')}
            </Button>
          </ListItem>
        </List>
      </NavDrawer>
      <Main>
        <ErrorBoundary>
          <SidebarRoutes routes={routes}>
            <Route path="notater/:note" element={<UnitNotes />} />
            <Route path="apps/:app/*" element={<AppProject />} />
            <Route path="tilbud/:offer/*" element={<Offer level={SlugLevel.Unit} />} />
          </SidebarRoutes>
        </ErrorBoundary>
      </Main>
    </>
  );
}
