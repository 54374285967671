import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import {
  AptlyProduct,
  AptlyUnitTemplateCategorySection,
  AptlyUnitTemplateCategorySectionPackage,
  AptlyUnitTemplateCategorySectionProduct,
} from '@aptly-as/types';
import TableBody from '@mui/material/TableBody';
import Add from '@mui/icons-material/Add';
import {
  AddIconButton,
  CopyIconButton,
  DeleteIconButton,
  EditIconButton,
} from '../../components/actions/icons/Icons';
import { byOrderIds } from '../../utils/array';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import { APTLY_PRIMARY_HEX_COLOR } from '../../env';
import { IProductSearchAddAsProps, ProductsAddAs } from '../Product/ProductSearch';
import { AddProducts } from './AddProducts';
import { IUnitTemplateProductProps, UnitTemplateProduct } from './Product';
import { ProductHead, ProductTable } from './product/ProductRow';
import useSimplePost from '../../components/simple/useSimplePost';
import { getRoomParamOption, productCrudFields, unitTemplateProductSchema } from './unit-template.schema';
import i18n from '../../libraries/i18n';
import { ProjectContext } from '../Project/ProjectContext';
import { DownloadOnClickIcon } from '../../components/actions/icons/ExportIcon';
import { HorizontalDivider } from '../../mui/Divider';
import { UnitTemplateContext } from './utils/UnitTemplateContext';
import ActionBar from '../../components/ActionBar';
import List, { ListItem } from '../../components/List/List';
import useSimpleDelete, { IUseSimpleDeleteFn } from '../../components/simple/useSimpleDelete';
import useSimplePatch, { IUseSimplePatchFn } from '../../components/simple/useSimplePatch';
import { OrganizationContext } from '../Organization/OrganizationContext';
import UnitTemplatePackage from './Package';
import { onUnitTemplatePatch, onUnitTemplatePush, onUnitTemplateSlice } from './utils/unit-template.state';
import {
  packageCrudFields,
  unitTemplateImportPackageSchema,
  unitTemplatePackageSchema,
} from './unit-template-package.schema';
import ErrorBoundary from '../../components/ErrorBoundary';
import ExpandableButton from '../../components/ExpandableButton';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { AssortmentToggleButton, usePartOfAssortment } from './Assortments';

export interface IUnitTemplateProductsProps
  extends Omit<IUnitTemplateProductProps, 'product' | 'onEdit' | 'onDelete' | 'onCopy' | 'onPatch'> {
  onDelete: IUseSimpleDeleteFn<AptlyUnitTemplateCategorySection>;
  onEdit: IUseSimplePatchFn<AptlyUnitTemplateCategorySection>;
  onPush: (category: AptlyUnitTemplateCategorySection) => void;
  rootPath: string;
}

export function UnitTemplateProducts({
  rootPath,
  category,
  section,
  onDelete,
  onEdit,
  index,
  onPush,
}: IUnitTemplateProductsProps) {
  const productPath = `${rootPath}/products`;
  const packagePath = `${rootPath}/packages`;
  const { setState, setActivePackage, unitTemplate } = useContext(UnitTemplateContext);
  const algorithms = useAlgorithms();
  const organization = useContext(OrganizationContext);
  const project = useContext(ProjectContext);
  const assortment = usePartOfAssortment(rootPath, section);
  const { products, _order_products } = section;
  const productSchema = useMemo(
    () =>
      unitTemplateProductSchema(
        organization.data,
        algorithms,
        unitTemplate,
        unitTemplate.categories,
        category,
        section,
        project.data ? project.data._id : undefined
      ),
    [organization.data, project, algorithms, unitTemplate, category, section]
  );
  const toProductData = useCallback(
    (data: Partial<AptlyUnitTemplateCategorySectionProduct>) => ({
      ...data,
      __productFromDB: !data.text,
      __unitSizeRoom: Boolean(data.unitSizeRoom),
      __unitSizeParam: getRoomParamOption(data.unitSizeParam),
      __unitCostRoom: Boolean(data.unitCostRoom),
      __unitCostParam: getRoomParamOption(data.unitCostParam),
      __consequenceRoom: Boolean(data.consequenceRoom),
      __consequenceParam: getRoomParamOption(data.consequenceParam),
    }),
    []
  );
  const items = useMemo(() => {
    const foundPackages: string[] = [];
    const ps = products
      .map((x) => {
        if (x.partOfPackage) {
          if (foundPackages.includes(x.partOfPackage)) return null as any;
          foundPackages.push(x.partOfPackage);
          return { ...x, _id: x.partOfPackage, id: `package-${x.partOfPackage}` };
        }
        return { ...x, id: `product-${x._id}` };
      })
      .filter(Boolean);
    return _order_products ? ps.sort(byOrderIds(_order_products)) : ps;
  }, [products, _order_products]);

  const handleOnSpliceProduct = useCallback(
    (from: number, end: number, product?: AptlyUnitTemplateCategorySectionProduct) => {
      setState(onUnitTemplateSlice({ level: 'product', from, end, category, section, product }));
    },
    [setState, category, section]
  );
  const handleOnSplicePackage = useCallback(
    (
      from: number,
      end: number,
      pack?: AptlyUnitTemplateCategorySectionPackage,
      products?: AptlyUnitTemplateCategorySectionProduct[]
    ) => {
      setState(
        onUnitTemplateSlice({ level: 'package', from, end, category, section, package: pack, products })
      );
    },
    [setState, category, section]
  );

  const pushProduct = useCallback(
    (product: AptlyUnitTemplateCategorySectionProduct) => {
      setState(onUnitTemplatePush({ level: 'product', category, section, product }));
    },
    [setState, category, section]
  );
  const pushPackage = useCallback(
    async (
      pack: AptlyUnitTemplateCategorySectionPackage,
      products: AptlyUnitTemplateCategorySectionProduct[] = []
    ) => {
      await setState(onUnitTemplatePush({ level: 'package', category, section, package: pack, products }));
      setActivePackage(pack._id);
    },
    [setState, category, section, setActivePackage]
  );
  const setSection = useCallback(
    (s: AptlyUnitTemplateCategorySection) => {
      setState(onUnitTemplatePatch({ section: s }));
    },
    [setState, category]
  );

  const handleSectionCopy = useCallback(
    () =>
      simpleRequest({
        endpoint: `${rootPath}/copy`,
        method: 'POST',
        onRequestDone: onPush,
        onRequestDoneText: i18n.t('statuses.duplicated'),
      }),
    [rootPath, onPush]
  );
  const handleProductCopy = useCallback(
    (sectionProduct: AptlyUnitTemplateCategorySectionProduct, _index: number) =>
      simpleRequest({
        endpoint: `${rootPath}/products/${sectionProduct._id}/copy`,
        method: 'POST',
        onRequestDone: pushProduct,
        onRequestDoneText: i18n.t('statuses.duplicated'),
      }),
    [rootPath, onPush]
  );
  const handleOnAddProducts = useCallback(
    async (products: AptlyProduct[], algorithm?: string, addAss?: IProductSearchAddAsProps) => {
      const data: any = { products: products.map((x) => x._id), algorithm };
      if (addAss) {
        data.partOfPackage = addAss.package ? addAss.package._id : null;
        data.partOfAssortment = addAss.assortment ? addAss.assortment._id : null;
        switch (addAss.as) {
          case ProductsAddAs.Package:
            data.package = { name: `Pakke #${(section.packages?.length || 0) + 1}` };
            break;
          case ProductsAddAs.Assortment:
            data.assortment = { color: assortment.options[0].value || APTLY_PRIMARY_HEX_COLOR };
            break;
        }
      }
      await simpleRequest({
        endpoint: `${productPath}/list`,
        method: 'POST',
        data,
        onRequestDone: setSection,
        onRequestDoneText: i18n.t('statuses.saved'),
      });
    },
    [productPath, setSection, assortment.options, section.packages]
  );
  const newProduct = useSimplePost(productPath, productSchema, {
    fields: productCrudFields,
    title: i18n.t('actions.newProduct'),
  });
  const editProduct = useSimplePatch(() => productPath, productSchema, handleOnSpliceProduct, {
    fields: productCrudFields,
    title: (data) => String((data.product as any)?.name || data.text),
    useFieldsProps: { initWithGet: true },
    preData: toProductData,
  });
  const handleOnPatchProduct = useCallback(
    (
      product: AptlyUnitTemplateCategorySectionProduct,
      index: number,
      data: Partial<AptlyUnitTemplateCategorySectionProduct>
    ) => {
      return simpleRequest({
        endpoint: `${productPath}/${product._id}`,
        method: 'PATCH',
        data,
        onRequestDone: () => {
          handleOnSpliceProduct(index, 1, { ...product, ...data });
        },
      });
    },
    [productPath, handleOnSpliceProduct]
  );
  const newPackage = useSimplePost(packagePath, unitTemplatePackageSchema(), {
    fields: packageCrudFields,
    title: i18n.t('actions.newPackage'),
  });
  const handleOnCreateProduct = useCallback(
    (p: any) => newProduct(pushProduct, p),
    [newProduct, pushProduct]
  );
  const handleOnEditPackage = useCallback(
    (p: any, i: any) => handleOnSplicePackage(i, 1, p),
    [handleOnSplicePackage]
  );
  const handleOnPatchPackage = useCallback(
    (
      pack: AptlyUnitTemplateCategorySectionPackage,
      index: number,
      data: Partial<AptlyUnitTemplateCategorySectionPackage>
    ) => {
      return simpleRequest({
        endpoint: `${packagePath}/${pack._id}`,
        method: 'PATCH',
        data,
        onRequestDone: () => handleOnSplicePackage(index, 1, { ...pack, ...data }),
      });
    },
    [packagePath, handleOnSpliceProduct]
  );
  const handleOnDeleteProduct = useSimpleDelete(() => productPath, handleOnSpliceProduct, {
    actionProps: { autoFocus: true },
  });
  const handleOnDeletePackage = useSimpleDelete(() => packagePath, handleOnSplicePackage, {
    actionProps: { autoFocus: true },
  });
  const handlePackageProductEdit = useCallback(
    (p: any, i: any) => {
      const index = products.findIndex((x) => x._id === p._id);
      editProduct(p, index > -1 ? index : i);
    },
    [editProduct, products]
  );
  const handlePackageProductDelete = useCallback(
    (p: any, i: any) => {
      const index = products.findIndex((x) => x._id === p._id);
      handleOnDeleteProduct(p, index > -1 ? index : i);
    },
    [handleOnDeleteProduct, products]
  );
  const importPackage = useSimplePost<any>(
    `${packagePath}/import`,
    unitTemplateImportPackageSchema(algorithms),
    {
      fields: ['name', 'standard', 'algorithm', 'file'],
      title: i18n.t('actions.newPackage'),
      useFieldsProps: { reachOptions: { fileKeys: ['file'], type: 'multipart/form-data' } },
    }
  );
  const rowProps = useMemo(
    () => ({
      rootPath,
      category,
      section,
      onEdit: handlePackageProductEdit,
      onDelete: handlePackageProductDelete,
      onCreate: handleOnCreateProduct,
      onPackageDelete: handleOnDeletePackage,
      onPackageEdit: handleOnEditPackage,
    }),
    [
      rootPath,
      category,
      section,
      handlePackageProductEdit,
      handlePackageProductDelete,
      handleOnCreateProduct,
      handleOnDeletePackage,
      handleOnEditPackage,
      handleOnPatchProduct,
    ]
  );

  const itemsToRender = useMemo(() => {
    const foundPackages: string[] = [];
    const sorted = products.sort(byOrderIds(section._order_products));
    const renderItems = sorted.map((product) => {
      if (product.partOfPackage) {
        const packIndex = section.packages.findIndex((x) => x._id === product.partOfPackage);
        const pack = section.packages[packIndex];
        if (!pack || foundPackages.includes(pack._id)) return null;
        foundPackages.push(pack._id);
        return (
          <UnitTemplatePackage
            key={pack._id}
            {...rowProps}
            products={products}
            package={pack}
            index={packIndex}
            checkbox={assortment.state.active}
            onAddProducts={handleOnAddProducts}
            onPatch={handleOnPatchPackage}
          />
        );
      }
      const sectionAssortment =
        product.partOfAssortment && section.assortments
          ? section.assortments.find((x) => x._id === product.partOfAssortment)
          : undefined;
      return (
        <UnitTemplateProduct
          key={product._id}
          {...rowProps}
          checkbox={assortment.checkboxProps(product)}
          assortment={sectionAssortment}
          onEdit={editProduct}
          product={product}
          index={products.findIndex((x) => x._id === product._id)}
          onCopy={handleProductCopy}
          onPatch={handleOnPatchProduct}
        />
      );
    });

    const restPackages = section.packages
      .filter((x) => !foundPackages.includes(x._id))
      .map((pack) => (
        <UnitTemplatePackage
          key={pack._id}
          {...rowProps}
          products={[]}
          package={pack}
          disabled
          checkbox={assortment.state.active}
          index={section.packages.findIndex((x) => x._id === pack._id)}
          onAddProducts={handleOnAddProducts}
          onPatch={handleOnPatchPackage}
        />
      ));
    return [...renderItems, ...restPackages];
  }, [
    products,
    section,
    rowProps,
    editProduct,
    assortment,
    handleOnAddProducts,
    handleProductCopy,
    handleOnPatchProduct,
    handleOnPatchPackage,
  ]);

  const handleOnNewService = useCallback(() => {
    newProduct(pushProduct, { algorithm: algorithms[0]?._id || null });
  }, [newProduct, pushProduct, algorithms]);

  return (
    <ErrorBoundary>
      <Wrapper>
        <ProductTable>
          <ProductHead checkbox={assortment.state.active} />
          <TableBody>
            <SortableContext items={items} strategy={rectSortingStrategy}>
              {itemsToRender}
            </SortableContext>
          </TableBody>
        </ProductTable>
      </Wrapper>
      <ActionBar $between>
        <ActionBar $left>
          <AddProducts onSave={handleOnAddProducts} />
          <AddIconButton onClick={handleOnNewService} color="primary" title={i18n.t('actions.newService')} />
          <HorizontalDivider />
          <AssortmentToggleButton assortment={assortment} />
          <HorizontalDivider />
          <ExpandableButton label={i18n.t('singles.package')} color="primary">
            {(close) => (
              <List>
                <ListItem
                  button
                  icon={<Add />}
                  onClick={() => {
                    newPackage(pushPackage);
                    close();
                  }}
                >
                  {i18n.t('actions.createNew')}
                </ListItem>
                <ListItem
                  button
                  icon={<CloudUploadOutlinedIcon />}
                  onClick={() => {
                    importPackage(pushPackage);
                    close();
                  }}
                >
                  {i18n.t('actions.import')}
                </ListItem>
              </List>
            )}
          </ExpandableButton>
        </ActionBar>
        <ActionBar>
          <CopyIconButton title={i18n.t('actions.copySection')} onClick={handleSectionCopy} />
          <DownloadOnClickIcon title={i18n.t('actions.exportSection')} endpoint={rootPath} />
          <HorizontalDivider />
          <EditIconButton title={i18n.t('actions.editSection')} onClick={() => onEdit(section, index)} />
          <DeleteIconButton
            title={i18n.t('actions.deleteSection')}
            onClick={() => onDelete(section, index)}
          />
        </ActionBar>
      </ActionBar>
    </ErrorBoundary>
  );
}

const Wrapper = styled.div`
  margin: 12px 0;
  min-height: 100px;
`;
