import styled, { css } from 'styled-components';
import Cake from '@mui/icons-material/Cake';
import { gutter } from '../../utils/spacing';
import { ReactNode } from 'react';

const Wrapper = styled.div<{ height?: string; $tiny?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  opacity: 0.8;
  box-sizing: border-box;

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `};

  ${(props) =>
    props.$tiny &&
    css`
      font-size: 1.5rem;
      padding: 0;
    `};
`;

const Gfx = styled.div<{ $tiny?: boolean }>`
  height: 10rem;
  width: 10rem;
  max-width: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.24),
    0 4px 8px rgba(0, 0, 0, 0.32);

  svg {
    font-size: 4rem;
  }

  ${(props) =>
    props.$tiny &&
    css`
      height: 5rem;
      width: 5rem;

      svg {
        font-size: 2rem;
      }
    `};
`;

const Text = styled.div<{ $tiny?: boolean }>`
  margin: ${gutter} 0 0;

  ${(props) =>
    props.$tiny &&
    css`
      margin-top: calc(${gutter} / 2);
    `};
`;

export interface EmptyStateProps {
  icon?: ReactNode;
  height?: string;
  tiny?: boolean;
  children: ReactNode;
}

function EmptyState({ children, height, tiny, icon = <Cake /> }: EmptyStateProps) {
  return (
    <Wrapper height={height} $tiny={tiny}>
      <Gfx $tiny={tiny}>{icon}</Gfx>
      <Text $tiny={tiny}>{children}</Text>
    </Wrapper>
  );
}

export default EmptyState;
