import { createContext } from 'react';
import { IReachQuery, IUseSearchActions, IUseSearchInfo, IUseSearchNextFn } from '@ewb/reach-react';
import { AptlyOrganization } from '@aptly-as/types';
import { IPaginatedSearchObject } from '../../libraries/reach/usePaginatedSearch';
import { IUseCrudSearchActions } from '../../libraries/reach/useCrudSearch';
import { IUseScope } from '../../libraries/scope/useScopeModel';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import { SearchHeaderProps } from './SearchHeader';

export interface SubAction<T extends IPaginatedSearchObject> {
  label: string;
  onClick: (items: T[], props: IUseSearchActions<T>) => Promise<void> | void;
  icon?: JSX.Element;
}

export interface ISearchCrudActions<T extends IPaginatedSearchObject> extends IUseCrudSearchActions<T> {
  setLimit: (limit: number) => void;
  refresh: () => void;
  next: IUseSearchNextFn<T>;
}

export interface ISearchCrudContext<T extends IPaginatedSearchObject> {
  path: string;
  patchPath: IToSearchPathPathFn;
  items: T[];
  actions: ISearchCrudActions<T>;
  query: IReachQuery;
  setQuery: (query: (query: IReachQuery) => IReachQuery) => void;
  options: ISearchOptions;
  info: ISearchInfo<T>;
  scope: IUseScope;
  level: SlugLevel;
}

export interface ISearchContext<T> {
  path: string;
  busy: boolean;
  data: T;
  refresh: () => void;
  query: IReachQuery;
  setQuery: (query: (query: IReachQuery) => IReachQuery) => void;
}

export interface IToSearchPatchPath {
  organization?: string;
  project?: string;
}

export type IToSearchPathPathFn = (data: any) => string;
export const toSearchPatchPath =
  (path: string, orgData?: AptlyOrganization) =>
  <T extends IToSearchPatchPath>(data: T) => {
    let newPath = 'api/v1';
    if ('organization' in data && data.organization) newPath += `/organizations/${data.organization}`;
    else if (orgData?.producer) newPath += `/organizations/${orgData._id}`;
    if ('project' in data && data.project) newPath += `/projects/${data.project}`;
    return `${newPath}/${path}`;
  };

export const SearchCrudContext = createContext<ISearchCrudContext<any>>({
  path: '',
  patchPath: () => '',
  items: [],
  actions: {} as ISearchCrudActions<any>,
  query: {},
  setQuery: () => {},
  options: {},
  info: {} as ISearchInfo<any>,
  scope: {} as IUseScope,
  level: SlugLevel.Base,
});

export const SearchContext = createContext<ISearchContext<any>>({
  path: '',
  busy: false,
  data: null,
  refresh: () => {},
  query: {},
  setQuery: () => {},
});

export interface ISearchInfo<T> extends IUseSearchInfo<T> {
  busy: boolean;
}

export interface ISearchHeaderProps extends SearchHeaderProps {
  description?: JSX.Element | string;
}

export interface ISearchOptions {
  defaultShow?: boolean;
  disableBulk?: boolean;
}
