import { GridColDef } from '@mui/x-data-grid';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { dataGridSearchNameColumn } from '../../components/Search/search-data-grid/data-grid.fields.js';
import i18n from '../../libraries/i18n.js';
import OrganizationDepartmentProjects from './OrganizationDepartmentProjects.js';
import OrganizationDepartmentUsers from './OrganizationDepartmentUsers.js';

export function useDepartmentColumns(): GridColDef[] {
  return [
    dataGridSearchNameColumn(),
    {
      type: 'number',
      field: 'projects',
      headerName: i18n.t('singles.projects'),
      valueGetter: (value: string[]) => (value as any[]).length,
      width: 150,
    },
    {
      type: 'number',
      field: 'users',
      headerName: i18n.t('singles.users'),
      valueGetter: (value: string[]) => (value as any[]).length,
      width: 100,
    },
    dataGridActionProps((params) => (
      <DataGridActionWrapper>
        <OrganizationDepartmentProjects params={params} />
        <OrganizationDepartmentUsers params={params} />
        <DataGridDeleteAction params={params} />
      </DataGridActionWrapper>
    )),
  ];
}