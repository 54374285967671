import { SlugLevel, useSlugs } from '../../hooks/useGetApiUrl';

export type ILevelItem = { unit?: string; project?: string; organization?: string };
export default function useSlugLevel(item?: ILevelItem): SlugLevel {
  const [o, p, u] = useSlugs();
  const unit = item ? item.unit : u;
  const project = item ? item.project : p;
  const organization = item ? item.organization : o;
  if (unit) return SlugLevel.Unit;
  if (project) return SlugLevel.Project;
  if (organization) return SlugLevel.Organization;
  return SlugLevel.Base;
}
