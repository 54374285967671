import { AptlyScopes } from '@aptly-as/types';
import Grid2 from '@mui/material/Grid2';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router-dom';
import ApiError from '../../components/ApiError';
import Avatar from '../../components/Avatar';
import { Main } from '../../components/Main';
import Header from '../../containers/Nav/Header';
import Search, { SearchFieldSelect, SearchFieldText } from '../../components/Search/Search';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import ComponentLink from '../../libraries/router/ComponentLink';
import Scope from '../../libraries/scope/Scope';
import Card, { CardHeader } from '../../mui/Card';
import Typography from '../../mui/Typography';
import { rawSpacing } from '../../utils/spacing';
import createMediaURL from '../Media/createMediaURL';
import { useOrganizationSchema } from './organization.schema';
import { organizationStatusOptionsAll, organizationTypeOptionsAll } from './organization.utils';
import OrganizationsEmpty from './OrganizationsEmpty';

const InfiniteScrollFix: any = InfiniteScroll;

function Organizations() {
  const { t } = useTranslation();
  const location = useLocation();
  const path = useApiUrl(SlugLevel.Base, 'organizations');
  const schema = useOrganizationSchema();
  const search = new URLSearchParams(location.search);
  const inviteError = useMemo(() => {
    const error = search.get('error');
    if (error === 'invite-email-mismatch') {
      return new Error(i18n.t('paragraphs.inviteMismatchError'));
    }
  }, [search]);
  const searchFields = useMemo(() => {
    if (!Scope.crud(AptlyScopes.Admin)) return undefined;
    return [
      [
        { xs: 4 },
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ],
      [
        { xs: 4 },
        <SearchFieldSelect
          key="type"
          field="type"
          label={i18n.t('singles.type')}
          options={organizationTypeOptionsAll()}
        />,
      ],
      [
        { xs: 4 },
        <SearchFieldSelect
          key="status"
          field="status"
          label={i18n.t('singles.status')}
          options={organizationStatusOptionsAll()}
        />,
      ],
    ];
  }, []);

  return (
    <>
      <Header breadcrumbs={[]} hideMenu />
      <Main noMenu>
        {inviteError && <ApiError error={inviteError} />}
        <Search
          path={path}
          patchPath={() => path}
          scope={AptlyScopes.Organization}
          header={{ title: t('paragraphs.myOrganizations') }}
          post={{ title: t('actions.newOrganization') }}
          patch={{ title: (d) => String(d.name) }}
          searchFields={searchFields}
          primaryAction={
            Scope.crud(AptlyScopes.AdminOrganizations, 'C') ? <SearchAddPrimaryAction key="create" /> : null
          }
          schema={schema}
          fields={['name', 'vat', 'type', 'status', 'logo', 'producer']}
          reach={{ limit: 40, query: { archived: false, sort: 'name' } }}
        >
          {(organizations, actions, info, query) => {
            if (organizations.length < 1 && !info.busy && !query.$name) {
              return <OrganizationsEmpty />;
            }

            return (
              <InfiniteScrollFix
                loadMore={() => actions.next()}
                hasMore={!info.busy && organizations.length < info.count}
              >
                <Grid2 container spacing={rawSpacing}>
                  {organizations.map((o) => {
                    const logo = o.logo ? createMediaURL(o.logo, { height: 80 }) : null;
                    return (
                      <Grid2 key={o._id} size={{ xs: 12, md: 3 }}>
                        <Card component={ComponentLink} to={`/organisasjoner/${o.slug}`}>
                          <CardHeader
                            avatar={<Avatar contain alt={o.name} src={logo} />}
                            title={<Typography>{o.name}</Typography>}
                          />
                        </Card>
                      </Grid2>
                    );
                  })}
                </Grid2>
              </InfiniteScrollFix>
            );
          }}
        </Search>
      </Main>
    </>
  );
}

export default Organizations;
