import { AptlyScopes } from '@aptly-as/types';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useCallback, useContext } from 'react';
import { EditButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { importSchema } from '../../components/crud/schema/extends.schemas';
import Search, { SearchFieldText } from '../../components/Search/Search';
import SearchImportAction from '../../components/Search/search-actions/SearchImportAction';
import SearchAddPrimaryAction from '../../components/Search/search-actions/SearchPrimaryAction';
import { toSearchPatchPath } from '../../components/Search/search.utils';
import SearchDataGrid from '../../components/Search/SearchDataGrid';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import useSlugLevel from '../../libraries/scope/useSlugLevel.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import { ProjectContext } from '../Project/ProjectContext';
import { usePageSchema } from './page.schema';
import { usePageColumns } from './page.columns.js';

export default function Pages() {
  const project = useContext(ProjectContext);
  const path = useApiUrl(SlugLevel.Project, 'pages');
  const schema = usePageSchema();
  const columns = usePageColumns();
  const scope = useScopeLevel({
    project: AptlyScopes.ProjectPages,
    organization: AptlyScopes.OrganizationPages,
    base: AptlyScopes.Admin,
  });

  const buttonActions = useCallback(() => {
    const a = [<SearchImportAction key="import" path={`${path}/import`} schema={importSchema()} />];

    if (project?.actions?.spawnPatch) {
      a.unshift(
        <EditButtonWithIcon
          onClick={() =>
            project.actions.spawnPatch(['pages'], {
              useFieldProps: {
                initWithGet: true,
                reachOptions: { query: { $populate: 'pages:_id,name', select: '_id,pages' } },
              },
            })
          }
          endIcon={<HelpOutlineOutlinedIcon />}
          title={i18n.t('singles.informationPages')}
        />
      );
    }

    return a;
  }, [project, path]);

  return (
    <Search
      path={path}
      patchPath={toSearchPatchPath('pages')}
      level={useSlugLevel()}
      scope={scope}
      header={{ title: i18n.t('singles.pages') }}
      post={{ title: i18n.t('singles.pages') }}
      patch={{ title: (data) => String(data.name) }}
      schema={schema}
      fields={['name', 'upsellTemplate', 'summary', 'icon']}
      options={{ disableBulk: true }}
      reach={{
        query: { sort: 'name', $populate: 'upsellTemplate:_id,name', archived: false, level: 'project' },
      }}
      buttonActions={buttonActions}
      primaryAction={<SearchAddPrimaryAction key="search" />}
      searchFields={[
        <SearchFieldText key="name" field="name" queryKey="$name" label={i18n.t('singles.name')} autoFocus />,
      ]}
    >
      {() => <SearchDataGrid columns={columns} />}
    </Search>
  );
}
