import { AptlyFieldType, AptlyOffer } from '@aptly-as/types';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { useNavigate } from 'react-router-dom';
import { SimpleModal } from '../../components/simple/SimpleCrud.js';
import { SlugLevel, useApiUrl, useTo } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import Typography from '../../mui/Typography.js';
import { getOfferLevelQuery } from './OfferContext.js';

interface OfferCopyModalProps {
  offer: AptlyOffer;
  isTemplate?: boolean;
  level: SlugLevel;
  onClose?: () => void;
}

export function OfferCopyModal({ offer, isTemplate, level, onClose }: OfferCopyModalProps): JSX.Element {
  const path = useApiUrl(SlugLevel.Project, `offers/${offer._id}/copy`);
  const to = useTo(SlugLevel.Project, '/tilbud');
  const navigate = useNavigate();

  return (
    <SimpleModal<OfferCopySchema, AptlyOffer>
      title={isTemplate ? i18n.t('actions.copy') : i18n.t('actions.edit')}
      description={
        <Typography>
          {isTemplate ? i18n.t('paragraphs.areYouSure') : i18n.t('offer.copyDescription')}
        </Typography>
      }
      useFieldsProps={{ reachOptions: { query: { level: getOfferLevelQuery(level) } } }}
      path={path}
      data={{ customer: false }}
      fields={isTemplate ? [] : ['customer']}
      schema={{
        customer: {
          type: AptlyFieldType.Switch,
          label: i18n.t('actions.copyCustomerData'),
          defaultValue: false,
        },
      }}
      actionButtonProps={{
        actionLabel: isTemplate ? i18n.t('actions.copy') : i18n.t('actions.edit'),
        actionIcon: isTemplate ? <FileCopyOutlined /> : <EditIcon />,
      }}
      onEdit={(newOffer) => {
        navigate(`${to}/${newOffer._id}`);
      }}
      onClose={onClose}
    />
  );
}

interface OfferCopySchema {
  _id?: string;
  customer: boolean;
}
