import { useMemo } from 'react';
import Input from '@mui/material/Input';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Save from '@mui/icons-material/Save';
import Block from '@mui/icons-material/Block';
import { InputProps } from '@mui/material/Input/Input';
import { ICrudFieldProps, ISimpleCrudFieldProps } from '../utils/crud.utils';
import useCrudState from '../../../hooks/useCrudState';

export interface ICrudTextFieldProps<T extends object, K extends keyof T> extends ICrudFieldProps<T, K> {
  showLabel?: boolean;
}

export interface ICrudFieldComponentProps<T extends object, V>
  extends ISimpleCrudFieldProps<T, V>,
    Omit<InputProps, 'onChange'>,
    Pick<FormControlProps, 'variant'> {
  showLabel?: boolean;
}

export function CrudFieldComponent<T extends object, V>({
  field,
  onChange,
  showLabel,
  simple,
  variant = 'standard',
  ...props
}: ICrudFieldComponentProps<T, V>) {
  const { id, defaultValue, label, edited, pattern, required, key, ...rest } = field;
  const [value, setValue, save] = useCrudState(field.value, onChange, simple);

  const adornment = useMemo(() => {
    if (simple) return null;
    if (required && !value) {
      return (
        <IconButton disabled edge="end">
          <Block />
        </IconButton>
      );
    }
    if (value !== field.value || edited) {
      return (
        <IconButton type="submit" edge="end">
          <Save />
        </IconButton>
      );
    }
    return null;
  }, [simple, required, edited, value, field.value]);

  return (
    <form onSubmit={save}>
      <FormControl variant={variant} fullWidth required={required} disabled={props.disabled}>
        {showLabel && label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <Input
          id={id}
          onChange={setValue}
          inputProps={pattern ? { pattern } : {}}
          required={required}
          {...rest}
          {...props}
          value={value}
          endAdornment={adornment && <InputAdornment position="end">{adornment}</InputAdornment>}
        />
      </FormControl>
    </form>
  );
}
