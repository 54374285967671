import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import React, { Dispatch, PropsWithChildren, ReactNode, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile';
import i18n from '../../libraries/i18n';
import { IconButton } from '../../mui/Button';
import Menu from '../../mui/Menu';
import { tightSpacing } from '../../utils/spacing';
import { SearchButtonWithIcon } from '../actions/buttons/Buttons';
import { SearchIconButton } from '../actions/icons/Icons';
import SubMenu, { SubMenuProps } from '../SubMenu';
import Grid2 from '@mui/material/Grid2';

export interface SearchActionsProps {
  showSearch?: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  subMenuOptions: SubMenuProps['options'];
  primaryAction?: ReactNode;
}

export default function SearchActions({
  showSearch,
  subMenuOptions,
  setShow,
  children,
  primaryAction,
}: PropsWithChildren<SearchActionsProps>) {
  const isMobile = useMobile();
  const handleOnSetShow = useCallback(() => setShow((s) => !s), [setShow]);

  return (
    <Grid2 container spacing={tightSpacing} alignItems="center" justifyContent="flex-end">
      {subMenuOptions && subMenuOptions.length > 0 && (
        <Grid2>
          <SubMenu options={subMenuOptions} />
        </Grid2>
      )}
      {isMobile ? (
        <Grid2>
          <ShowMoreActionsMobile>{children}</ShowMoreActionsMobile>
        </Grid2>
      ) : (
        children
      )}
      {primaryAction}
      {showSearch && (
        <Grid2>
          {isMobile ? (
            <SearchIconButton onClick={handleOnSetShow} />
          ) : (
            <SearchButtonWithIcon onClick={handleOnSetShow} />
          )}
        </Grid2>
      )}
    </Grid2>
  );
}

function ShowMoreActionsMobile({ children }: PropsWithChildren<{}>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  );
  return (
    <>
      <IconButton
        aria-label={i18n.t('actions.showMeMore')}
        aria-controls="long-menu"
        aria-haspopup="true"
        size="large"
        onClick={handleClick}
      >
        <ExpandCircleDownIcon
          sx={{ transform: anchorEl ? 'rotate(-180deg)' : '', transition: '200ms ease-in-out' }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <StyledGrid container flexDirection="column-reverse" spacing={1}>
          {children}
        </StyledGrid>
      </Menu>
    </>
  );
}

const StyledGrid = styled(Grid2)`
  padding: 0 0.5rem;
  button {
    width: 100%;
  }
`;
