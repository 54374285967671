import React, { FormEvent } from 'react';
import {
  createRenderableFields,
  createRenderableSubdocuments,
  Field,
  FieldGetField,
  FieldSetValue,
  RenderableFields,
  Subdocument,
} from './inputs';
import UpdateDelete from './UpdateDelete';
import BusyWrapper from '../components/BusyWrapper';
import { ModalActions, ModalForm } from '../mui/Dialog';
import Button from '../mui/Button';
import { FetchOpts } from '../components/Resource';
import i18n from '../libraries/i18n';

type CrudModalInterface = {
  editing: boolean;
  fields: RenderableFields;
  subdocuments: RenderableFields;
  getField: FieldGetField;
  setValue: FieldSetValue;
  save: () => Promise<void>;
};

type Props<T> = {
  endpoint: string;
  busy?: boolean;
  refresh?: (arg0: FetchOpts<T>) => void;
  onSave?: (arg0: T) => void;
  onDestroy?: (arg0: T) => void;
  onError?: (error: any) => void;
  children: (arg0: CrudModalInterface) => React.ReactNode;
  id?: string;
  fields?: Field[];
  subdocuments?: Subdocument[];
  actionLabel?: string;
  multipart?: boolean;
  editing?: boolean;
  cancelCloses?: boolean;
  disable?: boolean;
  onClose?: () => void;
  deleteAction?: boolean;
  customAuth?: string;

  deleteLabel?: string;
};

type State = {
  editing: boolean;
};

/**
 * @deprecated Old crud
 */
class CrudModal<T> extends React.Component<Props<T>, State> {
  state = {
    editing: this.props.editing || !this.props.id || false,
  };

  render() {
    const {
      endpoint,
      id,
      busy,
      refresh,
      onSave,
      onDestroy,
      fields,
      subdocuments,
      onClose,
      actionLabel,
      children,
      multipart,
      cancelCloses,
      disable,
      deleteAction,
      customAuth,
      deleteLabel = i18n.t('actions.delete'),
      onError,
    } = this.props;

    const { editing } = this.state;

    const resourceBusy = busy;

    return (
      <UpdateDelete
        endpoint={endpoint}
        multipart={multipart}
        customAuth={customAuth}
        id={id}
        fields={(fields || []).map((f) => ({
          name: f.name,
          value: f.value,
          label: f.label,
          forceSave: f.forceSave,
          meta: f.meta,
        }))}
        subdocuments={(subdocuments || []).map((s) => ({
          name: s.name,
          endpoint: s.endpoint,
          previews: s.previews,
        }))}
        onSuccessfulSave={(response) => {
          if (typeof onSave === 'function') {
            onSave(response as T);
          }

          if (typeof refresh === 'function') {
            refresh({
              background: true,
              endpoint: `${endpoint}/${response._id}`,
              cb: () => {
                this.setState({
                  editing: false,
                });
              },
            });
          }
        }}
        onSuccessfulDelete={(response) => {
          if (typeof onDestroy === 'function') {
            onDestroy(response as T);
          }

          if (typeof onClose === 'function') {
            onClose();
          }
        }}
        onError={onError}
      >
        {({
          busy,
          save,
          destroy,
          getField,
          setValue,
          getSubdocument,
          addToSubdocument,
          deleteFromSubdocument,
        }) => {
          const renderableFields = fields
            ? createRenderableFields(
                fields,
                {
                  getField,
                  setValue,
                },
                editing
              )
            : {};

          const renderableSubdocuments = subdocuments
            ? createRenderableSubdocuments(
                subdocuments,
                {
                  getSubdocument,
                  addToSubdocument,
                  deleteFromSubdocument,
                },
                editing
              )
            : {};

          return (
            <BusyWrapper $busy={resourceBusy || busy}>
              <ModalForm
                onSubmit={(e: FormEvent) => {
                  e.preventDefault();
                  save();
                }}
              >
                {children({
                  editing,
                  fields: renderableFields,
                  subdocuments: renderableSubdocuments,
                  getField,
                  setValue,
                  save,
                })}
                <ModalActions>
                  {editing ? (
                    <React.Fragment>
                      {deleteAction ? (
                        <Button
                          color="primary"
                          onClick={() => {
                            destroy();
                          }}
                        >
                          {deleteLabel}
                        </Button>
                      ) : null}
                      <Button
                        color="primary"
                        onClick={() => {
                          if (!cancelCloses && id) {
                            this.setState({
                              editing: false,
                            });
                          } else if (typeof onClose === 'function') {
                            onClose();
                          }
                        }}
                      >
                        {i18n.t('actions.cancel')}
                      </Button>
                      <Button color="primary" type="submit">
                        {id ? i18n.t('actions.save') : actionLabel || i18n.t('actions.create')}
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {!disable && (
                        <Button
                          color="primary"
                          onClick={() => {
                            this.setState({
                              editing: true,
                            });
                          }}
                        >
                          {i18n.t('actions.edit')}
                        </Button>
                      )}
                      <Button onClick={onClose} disabled={busy || resourceBusy} color="primary">
                        {i18n.t('actions.close')}
                      </Button>
                    </React.Fragment>
                  )}
                </ModalActions>
              </ModalForm>
            </BusyWrapper>
          );
        }}
      </UpdateDelete>
    );
  }
}

export default CrudModal;
