import Button, { ButtonProps } from '@mui/material/Button';
import { cloneElement, forwardRef, ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { PrimaryActionFab } from '../../components/actions/Primary';
import { useMobile } from '../../hooks/useMobile.js';
import Dialog, { DialogProps } from '../../mui/Dialog';

export interface IModalButtonProps extends UseModalButtonProps {
  children: ReactElement;
  label: string;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>;
  buttonProps?: Omit<ButtonProps, 'onClick' | 'children'>;
}

export const ModalButton = forwardRef<HTMLButtonElement, IModalButtonProps>(function ModalButton(
  { dialogProps, buttonProps, label, children, ...props },
  ref
) {
  const { open, onClose, fullScreen, onClick } = useModalButton(props);

  return (
    <>
      <Button variant="contained" color="primary" {...buttonProps} onClick={onClick} ref={ref}>
        {label}
      </Button>
      <Dialog open={open} onClose={onClose} {...dialogProps} fullScreen={fullScreen}>
        {cloneElement(children, { onClose })}
      </Dialog>
    </>
  );
});

interface ModalPrimaryActionProps extends IModalButtonProps {
  icon?: ReactNode;
}

export function ModalPrimaryAction({
  dialogProps,
  buttonProps,
  label,
  children,
  icon,
  ...props
}: ModalPrimaryActionProps) {
  const { open, onClose, onClick, fullScreen } = useModalButton(props);
  return (
    <>
      <PrimaryActionFab buttonProps={buttonProps} onClick={onClick} icon={icon}>
        {label}
      </PrimaryActionFab>
      <Dialog open={open} onClose={onClose} {...dialogProps} fullScreen={fullScreen}>
        {cloneElement(children, { onClose })}
      </Dialog>
    </>
  );
}

interface UseModalButtonProps {
  open?: boolean;
  onClose?: () => void;
}

function useModalButton(props: UseModalButtonProps) {
  const isMobile = useMobile();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.open) setOpen(true);
  }, [props.open]);

  const onClick = useCallback(() => setOpen(true), []);

  const onClose = useCallback(() => {
    setOpen(false);
    if (props.onClose) props.onClose();
  }, [props.onClose]);

  return { open, fullScreen: isMobile, onClick, onClose };
}
