import i18n from '../../../libraries/i18n';
import { tightSpacing } from '../../../utils/spacing';
import { BaseButtonWithIcon, CancelButtonWithIcon, DeleteButtonWithIcon } from './Buttons';
import { MouseEvent, PropsWithChildren, useCallback } from 'react';
import Save from '@mui/icons-material/Save';
import Grid2 from '@mui/material/Grid2';

export interface IActionButtonProps {
  actionLabel?: string;
  cancelLabel?: string;
  actionIcon?: React.ReactNode;
  onClose?: () => void;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDelete?: (onClose?: () => void) => void;
  submitType?: 'button' | 'submit';
  disabled?: boolean;
  disabledClose?: boolean;
  disabledSubmit?: boolean;
  autoFocus?: boolean;
  hideClose?: boolean;
}

export default function ActionButtons({
  onClose,
  onClick,
  onDelete,
  submitType = 'submit',
  disabled,
  disabledClose,
  disabledSubmit,
  actionLabel = i18n.t('actions.save'),
  actionIcon = <Save />,
  cancelLabel,
  autoFocus,
  hideClose,
  children,
}: PropsWithChildren<IActionButtonProps>) {
  const handleOnDelete = useCallback(() => {
    if (typeof onDelete === 'function') {
      onDelete(onClose);
    }
  }, [onDelete, onClose]);
  return (
    <Grid2 container justifyContent="flex-end" spacing={tightSpacing}>
      {onDelete && (
        <Grid2>
          <DeleteButtonWithIcon onClick={handleOnDelete} />
        </Grid2>
      )}
      {!hideClose && onClose && (
        <Grid2>
          <CancelButtonWithIcon onClick={onClose} disabled={disabled || disabledClose}>
            {cancelLabel}
          </CancelButtonWithIcon>
        </Grid2>
      )}
      {children}
      {(onClick || submitType === 'submit') && (
        <Grid2>
          <BaseButtonWithIcon
            type={onClick ? 'button' : submitType}
            onClick={onClick}
            disabled={disabled || disabledSubmit}
            endIcon={actionIcon}
            autoFocus={autoFocus}
            color="primary"
            variant="contained"
          >
            {actionLabel}
          </BaseButtonWithIcon>
        </Grid2>
      )}
    </Grid2>
  );
}
