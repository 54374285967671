import {
  AptlyAlgorithm,
  AptlyCategorySectionDisplayType,
  AptlyFieldType,
  AptlyOrganization,
  AptlyProduct,
  AptlyProjectPeriod,
  AptlyScopes,
  AptlyUnitTemplate,
  AptlyUnitTemplateCategory,
} from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { IApiError } from '../../components/ApiError';
import { schemaFile, schemaName, schemaSelectAlgorithm } from '../../components/crud/schema/fields.schema';
import { preValueToId, toId, toNameOptions } from '../../components/crud/schema/schema.utils';
import {
  ICrudComponentProps,
  ICrudField,
  ICrudFieldData,
  ICrudSchema,
} from '../../components/crud/utils/crud.utils';
import CrudProductAlgorithm from '../../components/simpleCruds/product/crudCreateProduct/CrudProductAlgorithm';
import {
  CustomLogicComponent,
  paramLogicTranslations,
  paramTranslations,
  productParamsToItems,
} from '../../components/simpleCruds/product/crudCreateProduct/logic';
import ProductVariantPrices from '../../components/simpleCruds/product/crudCreateProduct/ProductVariantPrices';
import { createModal } from '../../containers/Modal/ModalContext';
import { SlugLevel, useApiUrl, useSlugs } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n';
import { getId } from '../../libraries/mongoose';
import { acceptJson } from '../../libraries/react-dropzone/drop-zone.utils';
import Scope from '../../libraries/scope/Scope';
import { TextField } from '../../mui/Input';
import { useOptionLabelsSchemaField } from '../OptionLabel/option-label.schema.js';
import ChooseProductOld from '../Product/ChooseProductOld';
import { useRecommendationSchemaField } from '../Recommendation/recommendation.schema.js';
import { useSupplierSchemaField } from '../Supplier/supplier.schema.js';
import { useUpsellTemplateSchemaField } from '../UpsellTemplates/upsell-template.schema.js';
import getSectionDisplayTypes from './getSectionDisplayTypes';
import { sizeParamOptions, sizeParamPredefs } from './sizeParams';
import { Param } from './unit-template.types';

export type ICrudUnitTemplate = AptlyUnitTemplate;
type ICrudCategory = AptlyUnitTemplate['categories'][0];
type ICrudSection = ICrudCategory['sections'][0];
type ICrudProduct = ICrudSection['products'][0] & {
  __productFromDB: boolean;
  __previewCost: string;
  __unitSizeRoom?: boolean;
  __unitSizeParam?: string;
  __unitCostRoom?: boolean;
  __unitCostParam?: string;
  __consequenceRoom?: boolean;
  __consequenceParam?: string;
};

interface ImportSchema {
  _id: string;
  file: File | null;
  period: string;
  algorithm: string | null;
}

export const periodSchema = <T extends object>(periods: AptlyProjectPeriod[]): ICrudField<T, string> => ({
  type: AptlyFieldType.Select,
  defaultValue: periods[0]?._id,
  options: periods.map((x) => ({ value: x._id, label: x.name })),
  label: i18n.t('singles.deadlines'),
  preValue: preValueToId,
  required: true,
});

export const unitTemplateSchema = (): ICrudSchema<AptlyUnitTemplate> => ({
  name: schemaName({
    autoFocus: true,
  }),
});

export const unitTemplateCategorySchema = (): ICrudSchema<ICrudCategory> => ({
  name: schemaName({
    autoFocus: true,
  }),
  isRoom: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('paragraphs.isRoom'),
  },
  upsellTemplate: useUpsellTemplateSchemaField(),
});

export const unitTemplateImportSchema = (
  algorithms: AptlyAlgorithm[],
  periods: AptlyProjectPeriod[]
): ICrudSchema<ImportSchema> => ({
  file: schemaFile(acceptJson, {}),
  algorithm: schemaSelectAlgorithm(algorithms),
  period: periodSchema(periods),
});

export function useUnitTemplateSchemaField<T extends object>(
  override: Partial<ICrudField<T, AptlyUnitTemplate | string | undefined | null>> = {}
): ICrudField<T, AptlyUnitTemplate | string | undefined | null> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'unit-templates'),
      query: { archived: false, select: '_id,name' },
      multiple: false,
      fetchOnRender: true,
      setDefaultOnRender: true,
    }),
    label: i18n.t('singles.unitTemplate'),
    preOnChange: (v) => v._id,
    defaultValue: null,
    ...override,
  };
}

export const unitTemplateSectionSchema = (periods: AptlyProjectPeriod[]): ICrudSchema<ICrudSection> => ({
  name: schemaName({
    autoFocus: true,
  }),
  period: periodSchema(periods),
  description: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.textContent'),
  },
  standard: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.standard'),
    renderValidate: (_crud, field) => !!field.value,
  },
  displayType: {
    type: AptlyFieldType.Select,
    defaultValue: AptlyCategorySectionDisplayType.Default,
    label: i18n.t('singles.type'),
    options: getSectionDisplayTypes(),
    preOnChange: (value, crud) => {
      if (value === AptlyCategorySectionDisplayType.Compact) {
        crud.setField('multiSelect')(true);
      }
      return value;
    },
  },
  multiSelect: {
    type: AptlyFieldType.Switch,
    defaultValue: false,
    label: i18n.t('singles.multipleChoice'),
    renderValidate: (crud) => crud.getField('displayType').value !== AptlyCategorySectionDisplayType.Compact,
  },
});

export const getRoomParamOption = (v?: string | null) => {
  if (!v) return sizeParamOptions()[0].value;
  return sizeParamOptions().some((x) => x.value === v) ? v : '__custom';
};

function getMetaData<T extends ICrudProduct>(
  crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>,
  key: keyof T
): any {
  const fieldData = crud.getField(key);
  const value = fieldData.value;
  return value && typeof value === 'object' ? value : fieldData.meta;
}

function hasVariants<T extends ICrudProduct>(crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>) {
  const p = getMetaData(crud, 'product');
  if (crud.state.data.partOfAssortment) {
    if (
      crud.state.data.standardVariant &&
      crud.state.data.standardVariant !== toId(crud.state.data.product)
    ) {
      return true;
    }
    if (
      crud.state.data.availableProducts.length > 1 ||
      (crud.state.data.product &&
        crud.state.data.availableProducts.some((x) => x !== toId(crud.state.data.product)))
    ) {
      return true;
    }
    return false;
  }
  return Boolean(p && p.variants?.length > 0);
}

function getVariants<T extends ICrudProduct>(crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>) {
  const p = getMetaData(crud, 'product');
  return p
    ? [
        { value: p._id, label: p.name },
        ...p.variants.map((x: any) => ({ value: String(x._id), label: String(x.name) })),
      ]
    : [];
}

export const productCrudFields: (keyof ICrudProduct)[] = [
  ...(['availableProducts', 'standardVariant'] as (keyof ICrudProduct)[]),
  'text',
  ...(['customTitle', 'customDescription'] as (keyof ICrudProduct)[]),
  ...(['unitSizeRoom', '__unitSizeParam', 'unitSizeParam'] as (keyof ICrudProduct)[]),
  'amount',
  ...(['__unitSizeRoom', '__consequenceRoom'] as (keyof ICrudProduct)[]),
  ...(['consequenceRoom', '__consequenceParam', 'consequenceParam'] as (keyof ICrudProduct)[]),
  ...(['standard', 'algorithm'] as (keyof ICrudProduct)[]),
  ...(['unitCost', '__previewCost'] as (keyof ICrudProduct)[]),
  ...(['unitCostRoom', '__unitCostParam', 'unitCostParam'] as (keyof ICrudProduct)[]),
  '__unitCostRoom',
  'variantPrices',
  ...(['notifyWhenPicked', 'onlyShowInSelection'] as (keyof ICrudProduct)[]),
  ...(['supplier', 'recommendation', 'labels'] as (keyof ICrudProduct)[]),
  'params',
];

const ifOnlyShowInSelection = (crud: IUseFieldRet<ICrudProduct, IApiError, ICrudFieldData<ICrudProduct>>) =>
  crud.getField('onlyShowInSelection').value ||
  crud.getField('standard').value ||
  !!crud.state.data.partOfPackage;

export const unitTemplateProductSchema = (
  organization: AptlyOrganization,
  algorithms: AptlyAlgorithm[],
  unitTemplate: AptlyUnitTemplate,
  categories: ICrudCategory[],
  category: ICrudCategory,
  section: ICrudSection,
  projectID?: string
): ICrudSchema<ICrudProduct> => {
  const sizeParamPredefsLabel = sizeParamPredefs();
  const recommendationScope = Scope.crud(AptlyScopes.ProjectRecommendations);
  const labelsScope = Scope.crud(AptlyScopes.OrganizationOptionLabels);
  const recAndLabelsScope = Number(recommendationScope) + Number(labelsScope) + 1;
  const recAndLabelGrid = 'span ' + 12 / recAndLabelsScope;
  const categoriesRoom = unitTemplate.categories.map(toNameOptions);
  if (!categoriesRoom.some((x) => x.value === 'Felles')) {
    categoriesRoom.unshift({ value: 'Felles', label: 'Felles' });
  }

  return {
    __productFromDB: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: i18n.t('paragraphs.useProductFromDB'),
      renderValidate: () => false,
    },
    product: {
      type: AptlyFieldType.Custom,
      defaultValue: undefined,
      label: i18n.t('singles.product'),
      customRender: (crud, field) => <UnitTemplateProduct crud={crud} field={field} />,
      renderValidate: (crud) => Boolean(crud.getField('__productFromDB').value),
    },
    availableProducts: {
      type: AptlyFieldType.Select,
      defaultValue: [],
      label: i18n.t('paragraphs.availableVariants'),
      required: true,
      multiple: true,
      options: getVariants,
      renderValidate: hasVariants,
      gridProps: { $gridColumn: 'span 6' },
      preOnChange: (value, crud) => {
        const standardVariant = crud.getField('standardVariant').value;
        if (value && !value.includes(standardVariant)) {
          crud.setField('standardVariant')(value[0]);
        }
        return value;
      },
    },
    standardVariant: {
      type: AptlyFieldType.Select,
      defaultValue: undefined,
      label: i18n.t('singles.standardVariant'),
      required: true,
      options: (crud) => {
        const product = getMetaData(crud, 'product');
        const variants = product?.variants || [];
        const available = crud.getField('availableProducts').value;
        return available
          .map((x) => {
            if (x === product._id) return { value: x, label: product.name };
            const v = variants.find((y: any) => y._id === x);
            return v ? { value: v._id, label: v.name } : null;
          })
          .filter(Boolean) as any[];
      },
      renderValidate: hasVariants,
      gridProps: { $gridColumn: 'span 6' },
    },
    text: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.title'),
      required: true,
      renderValidate: (crud) => !crud.getField('__productFromDB').value,
    },
    customTitle: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: `${i18n.t('singles.custom')} ${i18n.t('singles.title').toLowerCase()}`,
      renderValidate: (crud) => !!crud.getField('__productFromDB').value,
      group: (crud) =>
        crud.getField('__productFromDB').value ? { name: i18n.t('paragraphs.overwriteContent') } : null,
    },
    customDescription: {
      type: AptlyFieldType.Markdown,
      defaultValue: '',
      label: `${i18n.t('singles.custom')} ${i18n.t('singles.description').toLowerCase()}`,
      group: { name: i18n.t('paragraphs.overwriteContent') },
    },
    standard: {
      type: AptlyFieldType.Switch,
      defaultValue: false,
      label: i18n.t('singles.standard'),
      gridProps: (crud) =>
        !crud.state.data.partOfPackage &&
        Scope.crud([AptlyScopes.OrganizationAlgorithms, AptlyScopes.ProjectAlgorithms], 'R')
          ? { $gridColumn: 'span 6', $alignSelf: 'center' }
          : { $gridColumn: 'span 12' },
      preOnChange: (standard, crud) => {
        if (standard) {
          crud.setField('unitCost')(0);
        } else {
          crud.setField('onlyShowInSelection')(false);
          const unitCost = (crud.getField('product').meta as AptlyProduct)?.unitCost;
          if (unitCost) {
            crud.setField('unitCost')(unitCost);
          }
        }
        return standard;
      },
      renderValidate: (crud) => !section.standard && !crud.state.data.partOfPackage,
    },
    algorithm: schemaSelectAlgorithm(algorithms, {
      gridProps: (crud) =>
        !crud.state.data.partOfPackage ? { $gridColumn: 'span 6' } : { $gridColumn: 'span 12' },
    }),
    variantPrices: {
      type: AptlyFieldType.Custom,
      defaultValue: [],
      label: i18n.t('singles.variants'),
      renderValidate: (crud) => {
        const product = crud.getField('product').value;
        if (!product) return false;
        if (crud.state.data.partOfAssortment) return false;
        const availableProducts = crud.getField('availableProducts').value || [];
        return availableProducts.filter((x) => x !== getId(product)).length > 0;
      },
      customRender: (crud, field) => (
        <UnitTemplateVariantPrices crud={crud} field={field} algorithms={algorithms} />
      ),
    },
    amount: {
      type: AptlyFieldType.Number,
      defaultValue: 1,
      label: i18n.t('singles.amount'),
      renderValidate: (crud) => !crud.getField('__unitSizeRoom').value,
    },
    notifyWhenPicked: {
      type: AptlyFieldType.Checkbox,
      defaultValue: false,
      label: i18n.t('paragraphs.notifyWhenProductSelected'),
      gridProps: (crud) => ({
        $gridColumn: ifOnlyShowInSelection(crud) ? 'span 6' : 'span 12',
      }),
    },
    onlyShowInSelection: {
      type: AptlyFieldType.Checkbox,
      defaultValue: false,
      label: i18n.t('paragraphs.onlyShowInSelection'),
      gridProps: { $gridColumn: 'span 6' },
      renderValidate: ifOnlyShowInSelection,
    },
    __unitSizeRoom: {
      type: AptlyFieldType.Checkbox,
      defaultValue: false,
      label: i18n.t('paragraphs.autoCalcAmountFromRoom'),
      gridProps: { $gridColumn: 'span 6' },
      preOnChange: (value, crud) => {
        if (value) {
          crud.setData({
            __unitSizeParam: sizeParamPredefsLabel[0],
            unitSizeRoom: category.name,
            unitSizeParam: sizeParamPredefsLabel[0],
          });
        } else {
          crud.setData({
            __unitSizeParam: '',
            unitSizeRoom: '',
            unitSizeParam: '',
          });
        }
        return value;
      },
    },
    unitSizeRoom: {
      type: AptlyFieldType.Select,
      defaultValue: undefined,
      options: categoriesRoom,
      label: i18n.t('singles.room'),
      renderValidate: (crud) => Boolean(crud.getField('__unitSizeRoom').value),
      placeholder: category.name,
      gridProps: (crud) =>
        crud.getField('__unitSizeParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
    },
    __unitSizeParam: {
      type: AptlyFieldType.Select,
      defaultValue: sizeParamPredefsLabel[0],
      label: i18n.t('singles.value'),
      gridProps: (crud) =>
        crud.getField('__unitSizeParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
      renderValidate: (crud) => Boolean(crud.getField('__unitSizeRoom').value),
      options: sizeParamOptions(),
      preOnChange: (value, crud) => {
        if (value !== '__custom') {
          crud.setField('unitSizeParam')(value);
        }
        return value;
      },
    },
    unitSizeParam: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.custom'),
      renderValidate: (crud) => crud.getField('__unitSizeParam').value === '__custom',
      gridProps: { $gridColumn: 'span 4' },
    },
    __consequenceRoom: {
      type: AptlyFieldType.Checkbox,
      defaultValue: false,
      label: i18n.t('paragraphs.quantityShouldAffectRoomValue'),
      gridProps: { $gridColumn: 'span 6' },
      preOnChange: (value, crud) => {
        if (value) {
          crud.setData({
            __consequenceParam: sizeParamPredefsLabel[0],
            consequenceRoom: category.name,
            consequenceParam: sizeParamPredefsLabel[0],
          });
        } else {
          crud.setData({
            __consequenceParam: '',
            consequenceRoom: '',
            consequenceParam: '',
          });
        }
        return value;
      },
    },
    consequenceRoom: {
      type: AptlyFieldType.Select,
      defaultValue: undefined,
      options: categoriesRoom,
      label: i18n.t('singles.room'),
      renderValidate: (crud) => Boolean(crud.getField('__consequenceRoom').value),
      required: true,
      gridProps: (crud) =>
        crud.getField('__consequenceParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
    },
    __consequenceParam: {
      type: AptlyFieldType.Select,
      defaultValue: sizeParamPredefsLabel[0],
      label: i18n.t('singles.value'),
      gridProps: (crud) =>
        crud.getField('__consequenceParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
      renderValidate: (crud) => Boolean(crud.getField('__consequenceRoom').value),
      options: sizeParamOptions(),
      preOnChange: (value, crud) => {
        if (value !== '__custom') {
          crud.setField('consequenceParam')(value);
        }
        return value;
      },
    },
    consequenceParam: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.custom'),
      renderValidate: (crud) => crud.getField('__consequenceParam').value === '__custom',
      gridProps: { $gridColumn: 'span 4' },
      autoFocus: true,
    },
    __unitCostRoom: {
      type: AptlyFieldType.Checkbox,
      defaultValue: false,
      label: i18n.t('paragraphs.autoCalcCostFromRoom'),
      preOnChange: (value, crud) => {
        if (value) {
          crud.setData({
            __unitCostParam: sizeParamPredefsLabel[0],
            unitCostRoom: category.name,
            unitCostParam: sizeParamPredefsLabel[0],
          });
        } else {
          crud.setData({
            __unitCostParam: '',
            unitCostRoom: '',
            unitCostParam: '',
          });
        }
        return value;
      },
    },
    unitCostRoom: {
      type: AptlyFieldType.Select,
      defaultValue: undefined,
      options: categoriesRoom,
      label: i18n.t('singles.room'),
      renderValidate: (crud) => Boolean(crud.getField('__unitCostRoom').value),
      placeholder: category.name,
      gridProps: (crud) =>
        crud.getField('__unitCostParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
    },
    __unitCostParam: {
      type: AptlyFieldType.Select,
      defaultValue: sizeParamPredefsLabel[0],
      label: i18n.t('singles.value'),
      gridProps: (crud) =>
        crud.getField('__unitCostParam').value === '__custom'
          ? { $gridColumn: 'span 4' }
          : { $gridColumn: 'span 6' },
      renderValidate: (crud) => Boolean(crud.getField('__unitCostRoom').value),
      options: sizeParamOptions(),
      preOnChange: (value, crud) => {
        if (value !== '__custom') {
          crud.setField('unitCostParam')(value);
        }
        return value;
      },
    },
    unitCostParam: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('singles.custom'),
      renderValidate: (crud) => crud.getField('__unitCostParam').value === '__custom',
      gridProps: { $gridColumn: 'span 4' },
      autoFocus: true,
    },
    unitCost: {
      type: AptlyFieldType.Number,
      defaultValue: 0,
      label: i18n.t('singles.netCost'),
      gridProps: { $gridColumn: 'span 6' },
      renderValidate: (crud) => !crud.getField('__unitCostRoom').value,
    },
    __previewCost: {
      type: AptlyFieldType.Custom,
      defaultValue: '',
      label: '',
      gridProps: { $gridColumn: 'span 6' },
      renderValidate: (crud) => !crud.getField('__unitCostRoom').value,
      customRender: (crud) => {
        const algorithm = algorithms.find((a) => a._id === crud.getField('algorithm').value);
        if (!algorithm) return <></>;
        return (
          <CrudProductAlgorithm
            baseCost={parseFloat(String(crud.getField('unitCost').value))}
            algorithm={algorithm}
          />
        );
      },
    },
    supplier: useSupplierSchemaField({
      gridProps: { $gridColumn: recAndLabelGrid },
    }),
    recommendation: useRecommendationSchemaField({
      gridProps: { $gridColumn: recAndLabelGrid },
      renderValidate: () => recommendationScope,
    }),
    labels: useOptionLabelsSchemaField({
      gridProps: { $gridColumn: recAndLabelGrid },
      renderValidate: () => labelsScope,
    }),
    params: {
      type: AptlyFieldType.Custom,
      defaultValue: [],
      label: '',
      customRender: (crud, field) => (
        <UnitTemplateProductParams
          organization={organization}
          projectID={projectID}
          categories={categories}
          sections={category.sections}
          crud={crud}
          field={field}
        />
      ),
    },
  };
};

interface IUnitTemplateProductParamsProps extends ICrudComponentProps<ICrudProduct, 'params'> {
  organization: AptlyOrganization;
  projectID?: string;
  categories: AptlyUnitTemplateCategory[];
  sections: ICrudSection[];
}

function UnitTemplateProductParams({
  organization,
  projectID,
  sections,
  categories,
  crud,
  field,
}: IUnitTemplateProductParamsProps) {
  let rawValue = field.value;

  if (!Array.isArray(rawValue)) {
    rawValue = [];
  }

  const partOfPackage = crud.state.data.partOfPackage;
  const params: Param[] = rawValue as Param[];
  const defaultItems = params.map(productParamsToItems);

  return (
    <CustomLogicComponent
      organizationSlug={organization.slug}
      projectID={projectID}
      categories={categories}
      sections={sections}
      items={defaultItems}
      onChange={(items) => {
        crud.setField('params')(items as any);
      }}
      itemsToSelect={partOfPackage ? paramLogicTranslations() : paramTranslations()}
    />
  );
}

function UnitTemplateProduct({ crud, field }: ICrudComponentProps<ICrudProduct, 'product'>) {
  const [orgSlug, projectID] = useSlugs();
  const value = field.value && typeof field.value === 'object' ? field.value.name : field.value || '';
  return (
    <TextField
      fullWidth
      label={i18n.t('singles.product')}
      type="text"
      required
      value={field.meta?.name || value}
      disabled
      onClick={() =>
        createModal(
          <ChooseProductOld
            organizationSlug={orgSlug}
            projectID={projectID}
            onSelect={async (p) => {
              const set: Partial<ICrudProduct> = {
                product: p._id as any,
                availableProducts: [p._id],
                standardVariant: p.variants?.length > 0 ? p._id : undefined,
                text: '',
              };
              if (p.unitCost) {
                set.unitCost = p.unitCost;
                const variantWithUnitCost = p.variants && p.variants.filter((x) => x.unitCost);
                if (variantWithUnitCost.length > 0) {
                  set.variantPrices = variantWithUnitCost.map((x) => ({
                    variant: x._id,
                    price: x.unitCost || 0,
                  }));
                }
              }
              await crud.setData(set, { product: p });
            }}
          />,
          {
            fullScreen: true,
          }
        )
      }
    />
  );
}

interface IUnitTemplateVariantPricesProps extends ICrudComponentProps<ICrudProduct, 'product'> {
  algorithms: AptlyAlgorithm[];
}

function UnitTemplateVariantPrices({ crud, field, algorithms }: IUnitTemplateVariantPricesProps) {
  const availableProducts = crud.getField('availableProducts');
  const data = field.value || [];
  const selectedProduct = crud.getField('product');
  const defaultCost = crud.getField('unitCost').value || 0;
  const algorithm = algorithms.find((a) => a._id === crud.getField('algorithm').value);

  let productData: AptlyProduct | null = null;

  if (selectedProduct.value && typeof selectedProduct.value === 'object') {
    productData = selectedProduct.value as any;
  } else if (selectedProduct.meta) {
    productData = selectedProduct.meta as AptlyProduct;
  }

  if (productData && Array.isArray(availableProducts.value)) {
    const values: string[] = availableProducts.value;
    const variants = productData.variants?.filter((v) => values.includes(v._id));

    if (!variants || variants.length === 0) return null;

    return (
      <ProductVariantPrices
        variants={variants}
        variantPrices={data as any[]}
        unitCost={defaultCost}
        onChange={crud.setField('variantPrices')}
        showLabels
        algorithm={algorithm}
      />
    );
  }

  return null;
}
