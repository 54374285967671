import { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AptlyUnitTemplateCategory, AptlyUnitTemplateCategorySection } from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import { CreateButtonWithIcon } from '../../components/actions/buttons/Buttons';
import {
  CopyIconButton,
  DeleteIconButton,
  EditIconButton,
  UploadIconButton,
} from '../../components/actions/icons/Icons';
import { byOrderIds } from '../../utils/array';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import { usePeriods } from '../Period/usePeriods';
import { IUnitTemplateSectionProps, UnitTemplateSection } from './Section';
import useSimplePost from '../../components/simple/useSimplePost';
import { unitTemplateImportSchema, unitTemplateSectionSchema } from './unit-template.schema';
import i18n from '../../libraries/i18n';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import useSimpleDelete, { IUseSimpleDeleteFn } from '../../components/simple/useSimpleDelete';
import useSimplePatch, { IUseSimplePatchFn } from '../../components/simple/useSimplePatch';
import { AccordionActions } from '../../mui/Accordion';
import { DownloadOnClickIcon } from '../../components/actions/icons/ExportIcon';
import { HorizontalDivider } from '../../mui/Divider';
import { UnitTemplateContext } from './utils/UnitTemplateContext';
import ActionBar from '../../components/ActionBar';
import { onUnitTemplatePush, onUnitTemplateSlice } from './utils/unit-template.state';
import ErrorBoundary from '../../components/ErrorBoundary';
import { ProjectContext } from '../Project/ProjectContext';

export interface IUnitTemplateSectionsProps
  extends Omit<IUnitTemplateSectionProps, 'section' | 'onEdit' | 'onDelete' | 'onPush'> {
  onDelete: IUseSimpleDeleteFn<AptlyUnitTemplateCategory>;
  onEdit: IUseSimplePatchFn<AptlyUnitTemplateCategory>;
  onPush: (category: AptlyUnitTemplateCategory) => void;
}
const confirmInput = (data: AptlyUnitTemplateCategorySection) =>
  data.products.length > 0 ? data.name : undefined;

export function UnitTemplateSections({
  rootPath,
  category,
  onDelete,
  onEdit,
  onPush,
  index,
}: IUnitTemplateSectionsProps) {
  const { setState } = useContext(UnitTemplateContext);
  const project = useContext(ProjectContext);
  const algorithms = useAlgorithms();
  const periods = usePeriods();
  const { sections, _order_sections } = category;
  const path = `${rootPath}/sections`;
  const sectionFields: (keyof AptlyUnitTemplateCategorySection)[] = useMemo(
    () =>
      project.data
        ? ['name', 'period', 'standard', 'displayType', 'multiSelect', 'description']
        : ['name', 'standard', 'displayType', 'multiSelect', 'description'],
    [project]
  );
  const items = useMemo(() => {
    const ss = sections.map((x) => ({ ...x, id: `section-${x._id}` }));
    return _order_sections ? ss.sort(byOrderIds(_order_sections)) : ss;
  }, [sections, _order_sections]);

  const handleOnSpliceSection = useCallback(
    (from: number, end: number, section?: AptlyUnitTemplateCategorySection) => {
      setState(onUnitTemplateSlice({ level: 'section', from, end, category, section }));
    },
    [setState, category]
  );

  const pushSection = useCallback(
    (section: AptlyUnitTemplateCategorySection) => {
      setState(onUnitTemplatePush({ level: 'section', category, section }));
    },
    [category, setState]
  );

  const handleOnDelete = useSimpleDelete(() => path, handleOnSpliceSection, { confirmInput });
  const sectionSchema = useMemo(() => unitTemplateSectionSchema(periods), [periods]);
  const newSection = useSimplePost(path, sectionSchema, {
    fields: sectionFields,
    title: i18n.t('actions.newSection'),
  });
  const importSection = useSimplePost<any>(`${path}/import`, unitTemplateImportSchema(algorithms, periods), {
    fields: project.data ? ['file', 'period', 'algorithm'] : ['file', 'algorithm'],
    title: i18n.t('actions.newSection'),
    useFieldsProps: { reachOptions: { fileKeys: ['file'], type: 'multipart/form-data' } },
  });
  const editSection = useSimplePatch(() => path, sectionSchema, handleOnSpliceSection, {
    fields: sectionFields,
    title: (data) => String(data.name),
  });

  const handleCopy = useCallback(
    () =>
      simpleRequest({
        endpoint: `${rootPath}/copy`,
        method: 'POST',
        onRequestDone: onPush,
        onRequestDoneText: i18n.t('statuses.duplicated'),
      }),
    [rootPath, onPush]
  );

  return (
    <ErrorBoundary>
      <Wrapper container flexDirection="column">
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((section) => (
            <UnitTemplateSection
              key={section._id}
              rootPath={`${path}/${section._id}`}
              category={category}
              section={section}
              onDelete={handleOnDelete}
              onEdit={editSection}
              onPush={pushSection}
              index={sections.findIndex((x) => x._id === section._id)}
            />
          ))}
        </SortableContext>
      </Wrapper>
      <AccordionActions>
        <ActionBar $between>
          <ActionBar $left>
            <CreateButtonWithIcon
              title={i18n.t('actions.newSection')}
              onClick={() => newSection(pushSection)}
            />
            <UploadIconButton
              title={i18n.t('actions.uploadSection')}
              onClick={() => importSection(pushSection)}
            />
          </ActionBar>
          <ActionBar>
            <CopyIconButton title={i18n.t('actions.copyCategory')} onClick={handleCopy} />
            <DownloadOnClickIcon title={i18n.t('actions.exportCategory')} endpoint={rootPath} />
            <HorizontalDivider />
            <EditIconButton title={i18n.t('actions.editCategory')} onClick={() => onEdit(category, index)} />
            <DeleteIconButton
              title={i18n.t('actions.deleteCategory')}
              onClick={() => onDelete(category, index)}
            />
          </ActionBar>
        </ActionBar>
      </AccordionActions>
    </ErrorBoundary>
  );
}

const Wrapper = styled(Grid)`
  margin: 12px 0;
  padding: 0 12px;
  min-height: 100px;
`;
