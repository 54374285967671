import {
  AptlyAdminInjection,
  AptlyApp,
  AptlyAppMenu,
  AptlyOrganizationInjection,
  AptlyProjectInjection,
  AptlyUnitInjection,
  AptlyUserInjection,
} from '@aptly-as/types';
import { ListItemProps as MUIListItemProps } from '@mui/material';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { RouteProps } from 'react-router-dom';
import { ListItemProps } from '../../components/List/List';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import { ScopeProps } from '../../libraries/scope/scope.utils';
import { OrganizationContext } from '../../models/Organization/OrganizationContext';

export type SidebarInjection =
  | AptlyUserInjection
  | AptlyAdminInjection
  | AptlyOrganizationInjection
  | AptlyProjectInjection
  | AptlyUnitInjection;

export interface SidebarList<I extends SidebarInjection> extends ScopeProps {
  injection: I;
  items: (ISidebarListItem | SidebarList<I>)[];

  subheader?: string;
  label?: ReactNode;
  icon?: JSX.Element;
  mobile?: boolean;
  hide?: boolean;
}

export interface ISidebarListItem extends Omit<ISidebarListItemLinkProps, 'to'>, Pick<RouteProps, 'element'> {
  label: ReactNode;
  to?: string;
  menuExact?: boolean;
  nested?: boolean;
  mobile?: boolean;
  hide?: boolean;
}

interface ISidebarListItemLinkProps
  extends Omit<MUIListItemProps, 'component' | 'button'>,
    ScopeProps,
    Pick<ListItemProps, 'icon'> {
  path: string;
}

interface ISidebarContext {
  menus: (Omit<AptlyAppMenu, 'menu'> & { app: string; menu: SidebarInjection })[];
  active: ISidebarActive;
  setActive: Dispatch<SetStateAction<ISidebarActive>>;
  level: SlugLevel;
}

export const SidebarContext = createContext<ISidebarContext>({
  menus: [],
  active: {},
  setActive: () => {},
  level: SlugLevel.Base,
});

export interface ISidebarActive {
  [key: string]: boolean | undefined;
}

export interface ISidebarProviderProps {
  level: SlugLevel;
  injection: string;
}

export function SidebarProvider({ level, injection, children }: PropsWithChildren<ISidebarProviderProps>) {
  const [active, setActive] = useState<ISidebarActive>({});
  const org = useContext(OrganizationContext);
  const menus = useMemo(() => {
    const apps = org?.data?.apps || [];
    const reg = new RegExp(`^${injection}-?`);
    return apps
      .map((app) =>
        app.app && typeof app.app === 'object'
          ? app.app.menus.map((x) => ({ ...x, app: (app.app as AptlyApp).slug }))
          : []
      )
      .flat()
      .filter((x) => x.menu.some((x) => x.match(reg)))
      .map((x) => x.menu.map((m) => ({ ...x, menu: m.replace(reg, '') as SidebarInjection })))
      .flat();
  }, [injection, org]);

  return (
    <SidebarContext.Provider value={{ active, setActive, menus, level }}>{children}</SidebarContext.Provider>
  );
}
