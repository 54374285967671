import {
  AptlyFieldType,
  AptlyMediaSrc,
  AptlyPage,
  AptlyPageSection,
  AptlyPageSectionType,
  AptlyScopes,
} from '@aptly-as/types';
import Typography from '@mui/material/Typography';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { acceptImages } from '../../libraries/react-dropzone/drop-zone.utils';
import { useScope } from '../../libraries/scope/ScopeContext.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import Grid from '../../mui/Grid';
import { useRecommendationAutocompleteQueryProps } from '../Recommendation/recommendation.schema.js';
import { useUpsellTemplateSchemaField } from '../UpsellTemplates/upsell-template.schema.js';
import { StyledIconButton } from './page.styles';
import { pageIcons } from './page.types';

export type ICrudPage = AptlyPage;

export function usePageSchema(): ICrudSchema<ICrudPage> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
    upsellTemplate: useUpsellTemplateSchemaField(),
    summary: {
      type: AptlyFieldType.TextArea,
      defaultValue: '',
      label: i18n.t('paragraphs.linkDescription'),
    },
    icon: {
      type: AptlyFieldType.Custom,
      defaultValue: undefined,
      label: i18n.t('singles.icon'),
      customRender: (crud, field) => {
        const active = field.value;

        return (
          <>
            <Typography variant="subtitle2">{i18n.t('singles.icon')}</Typography>
            <Grid container spacing={2} wrap="wrap">
              {pageIcons.map((icon) => (
                <Grid item key={icon.value}>
                  <StyledIconButton
                    size="large"
                    active={active === icon.value}
                    onClick={() => crud.setField('icon')(icon.value)}
                  >
                    {icon.label}
                  </StyledIconButton>
                </Grid>
              ))}
            </Grid>
          </>
        );
      },
    },
  };
}

export function getPageSectionSchema(type: AptlyPageSectionType) {
  switch (type) {
    case AptlyPageSectionType.Text:
      return pageSectionTextSchema();
    case AptlyPageSectionType.Card:
      return pageSectionTextSchema();
    case AptlyPageSectionType.Gallery:
      return pageSectionGallerySchema();
    case AptlyPageSectionType.Image:
      return pageSectionImageSchema();
    case AptlyPageSectionType.Spacer:
      return pageSectionSpacesSchema();
    case AptlyPageSectionType.Links:
      return pageSectionLinksSchema();
    case AptlyPageSectionType.Recommendation:
      return pageSectionRecommendationSchema();
    case AptlyPageSectionType.Iframe:
      return pageSectionIframeSchema();
  }
}

export const pageSectionSchema = (): ICrudSchema<AptlyPageSection<any>> => ({
  type: {
    type: AptlyFieldType.Hidden,
    defaultValue: AptlyPageSectionType.Text,
    label: i18n.t('singles.type'),
  },
  name: schemaName(),
});

export const pageSectionTextSchema = (): ICrudSchema<AptlyPageSection<string>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Markdown,
    defaultValue: '',
    label: i18n.t('singles.textContent'),
  },
});

export const pageSectionImageSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc | null>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Image,
    defaultValue: null,
    label: i18n.t('singles.image'),
    preOnChange: (value) => {
      if (value) return value.src;
      return value;
    },
    image: {
      width: 540,
      accept: acceptImages,
      srcKey: 'image',
      crop: 'pad',
    },
  },
});

export const pageSectionIframeSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc | null>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Text,
    defaultValue: null,
    label: i18n.t('singles.embed'),
  },
});

export const pageSectionRecommendationSchema = (): ICrudSchema<AptlyPageSection<string[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useRecommendationAutocompleteQueryProps,
    label: i18n.t('singles.recommendation'),
    defaultValue: null as any,
  },
});

export const pageSectionLinksSchema = (): ICrudSchema<AptlyPageSection<string[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'pages'),
      query: { archived: false, select: '_id,name', limit: 7, level: 'project' },
      multiple: true,
      queryOnFocus: true,
    }),
    defaultValue: [],
    label: i18n.t('singles.links'),
  },
});

export const pageSectionGallerySchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Images,
    defaultValue: [],
    label: i18n.t('singles.gallery'),
    preOnChange: (value) => {
      if (Array.isArray(value)) return value.map((x) => (typeof x === 'string' ? x : x.src));
      return [];
    },
    image: {
      width: 540,
      accept: acceptImages,
      srcKey: 'image',
      crop: 'pad',
    },
  },
});

export const pageSectionSpacesSchema = (): ICrudSchema<AptlyPageSection<AptlyMediaSrc[]>> => ({
  ...pageSectionSchema(),
  value: {
    type: AptlyFieldType.Select,
    defaultValue: [],
    label: i18n.t('singles.spacing'),
    options: [
      {
        value: '1',
        label: i18n.t('actions.small'),
      },
      {
        value: '2',
        label: i18n.t('statuses.medium'),
      },
      {
        value: '3',
        label: i18n.t('statuses.large'),
      },
    ],
  },
});

export function usePagesSchemaField<T extends object>(
  override?: Partial<ICrudField<T, (AptlyPage | string)[]>>
): ICrudField<T, any[]> {
  const scope = useScope();
  const scopeLevel = useScopeLevel({
    base: AptlyScopes.Admin,
    organization: AptlyScopes.OrganizationPages,
    project: AptlyScopes.ProjectPages,
  });
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'pages'),
      query: { archived: false, select: '_id,name', limit: 7, level: 'project' },
      multiple: true,
      queryOnFocus: true,
    }),
    label: i18n.t('singles.pages'),
    defaultValue: [],
    renderValidate: () => scope.crud(scopeLevel),
    ...override,
  };
}
