import { GridColDef } from '@mui/x-data-grid';
import { AptlyPage, AptlyUpsellTemplate } from '@aptly-as/types';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridCopyAction,
  DataGridDeleteAction,
  DataGridEditAction,
  DataGridExportAction,
  DataGridGoToAction,
  DataGridPreviewAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import {
  dataGridFieldClickColumn,
  dataGridLevelColumn,
  dataGridSearchNameColumn,
} from '../../components/Search/search-data-grid/data-grid.fields.js';
import { SlugLevel, useSlugs, useTo } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';
import { ICrudPage } from './page.schema.js';
import { pageIcons } from './page.types.js';

export function usePageColumns(): GridColDef<AptlyPage>[] {
  return [
    dataGridFieldClickColumn<ICrudPage, 'icon'>('icon', i18n.t('singles.icon'), (params) => {
      if (params.value) {
        const icon = pageIcons.find((x) => x.value === params.value);
        return icon?.label || params.value || 'N/A';
      }
      return 'N/A';
    }),
    dataGridSearchNameColumn(),
    {
      type: 'string',
      field: 'summary',
      flex: 1,
      headerName: i18n.t('paragraphs.linkDescription'),
    },
    {
      type: 'string',
      field: 'upsellTemplate',
      headerName: i18n.t('singles.upsellTemplate'),
      width: 150,
      valueGetter: (v?: AptlyUpsellTemplate) => v?.name || '',
    },
    dataGridLevelColumn(),
    dataGridActionProps(
      (params) => {
        const [slug, project] = useSlugs();
        const preTo = useTo(SlugLevel.Project, '/sider');
        let to = `dashboard/organizations/${slug}`;
        if (project) {
          to += `/projects/${project}`;
        }
        to += `/page/${params.row._id}`;
        return (
          <DataGridActionWrapper>
            <DataGridPreviewAction to={to} />
            <DataGridEditAction params={params} checkIfDisabledInProject />
            <DataGridCopyAction<AptlyPage>
              params={params}
              preCopy={(data) => ({
                name: `${data.name} - kopi`,
                icon: data.icon,
                summary: data.summary,
                sections: data.sections,
                upsellTemplate: data.upsellTemplate,
              })}
            />
            <DataGridExportAction params={params} />
            <DataGridDeleteAction params={params} checkIfDisabledInProject />
            <DataGridGoToAction params={params} preTo={preTo} />
          </DataGridActionWrapper>
        );
      },
      { minWidth: 330 }
    ),
  ];
}
