import { Grid2 } from '@mui/material';
import Alert from '@mui/material/Alert';
import { GridColDef, DataGrid as MuiDataGrid, DataGridProps, GridRowsProp } from '@mui/x-data-grid';
import { nbNO } from '@mui/x-data-grid/locales';
import { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile.js';

type Doc = { _id: string };

export const setDataGridId = (x: Doc) => ({ ...x, id: x._id });
export const memoSetDataGridIds = (docs?: Doc[] | null) => (docs ? docs.map(setDataGridId) : []);

export default function DataGrid({ slots, ...props }: DataGridProps) {
  const mobile = useMobile();
  const hideColumn: boolean = mobile && !props.checkboxSelection;
  const _slots: DataGridProps['slots'] = useMemo(
    () =>
      hideColumn
        ? {
            ...slots,
            columnHeaders: () => <></>,
          }
        : slots,
    [hideColumn]
  );
  return (
    <MuiDataGrid
      pageSizeOptions={[10, 20, 50, 100]}
      localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      disableAutosize
      disableColumnFilter
      disableColumnMenu
      disableColumnSorting
      disableColumnSelector
      slots={_slots}
      columnHeaderHeight={hideColumn ? 0 : undefined}
      {...props}
    />
  );
}

export function dataGridNoRowsOverlay(label: string): () => ReactNode {
  return function noRowsOverlay(): ReactNode {
    return (
      <StyledGrid2 container flexDirection="column" justifyContent="flex-end">
        <Alert color="info">{label}</Alert>
      </StyledGrid2>
    );
  };
}

const StyledGrid2 = styled(Grid2)`
  height: 100%;
`;

export type { GridColDef, GridRowsProp, DataGridProps };
