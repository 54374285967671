import { AptlyPeriod, AptlyRedirectActionError, AptlyScopes } from '@aptly-as/types';
import { Alert, AlertProps, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExportButton from '../../components/actions/buttons/ExportButton';
import Dashboard from '../../components/Dashboard';
import { HideMobileFragment } from '../../hooks/useMobile';
import { useScope } from '../../libraries/scope/ScopeContext';
import InquiryRecent from '../Inquiry/InquiryRecent';
import useGetApiUrl, { SlugLevel } from '../../hooks/useGetApiUrl';
import { FragmentScope } from '../../libraries/scope/ScopeComponets';
import Button from '../../mui/Button';
import Card, { CardActions, CardContent, CardHeader } from '../../mui/Card';
import Centered from '../../components/Centered';
import Grid from '../../mui/Grid';
import List, { ListItem, ListSubheader } from '../../components/List/List';
import Section from '../../components/Section';
import StatusText from '../../components/StatusText';
import Typography from '../../mui/Typography';
import { FORMAT_DATE } from '../../env';
import { splitByTiming } from '../Period/periods.utils';
import i18n from '../../libraries/i18n';
import { Bookings } from '../Booking/Bookings';
import { Notes } from '../Note/Notes';
import ProjectDashboardOrders from '../Order/ProjectDashboardOrders';
import { ProjectContext } from './ProjectContext';

const LinkedButton: any = Button;
function ProjectDashboard() {
  const scope = useScope();
  const project = useContext(ProjectContext);
  const { data } = project;
  const endpoint = useGetApiUrl('project');

  return (
    <React.Fragment>
      <Section>
        <Grid container justifyContent="space-between">
          <Typography variant="h1">{i18n.t('singles.dashboard')}</Typography>
          <HideMobileFragment>
            <FragmentScope scope={AptlyScopes.Project} crud="U">
              <ExportButton endpoint={endpoint} name={data ? data.name : ''} />
            </FragmentScope>
          </HideMobileFragment>
        </Grid>
      </Section>
      <QueryError />
      {data !== null && (
        <FragmentScope scope={AptlyScopes.ProjectOrders} crud="R">
          <Section>
            <ProjectDashboardOrders />
          </Section>
        </FragmentScope>
      )}
      <Section>
        {data !== null && (
          <Dashboard
            config={{
              elements: [
                ...(scope.crud(AptlyScopes.ProjectInquiry, 'R')
                  ? [
                      {
                        renderFn: () => <InquiryRecent level={SlugLevel.Project} limit={5} />,
                      },
                    ]
                  : []),
                ...(scope.crud(AptlyScopes.ProjectBooking, 'R')
                  ? [
                      {
                        renderFn: () => <Bookings level={SlugLevel.Project} />,
                      },
                    ]
                  : []),
                ...(scope.crud(AptlyScopes.UnitNotes, 'R')
                  ? [
                      {
                        renderFn: () => <Notes level={SlugLevel.Project} />,
                      },
                    ]
                  : []),
                ...(scope.crud(AptlyScopes.ProjectPeriods)
                  ? [
                      {
                        renderFn: () => {
                          const periods = splitByTiming(data.periods);
                          const hasRelevantPeriods =
                            periods.current.length > 0 || periods.upcoming.length > 0;

                          return (
                            <Card>
                              <CardHeader title={i18n.t('singles.deadlines')} />
                              {hasRelevantPeriods ? (
                                <List>
                                  {periods.current.length > 0 && (
                                    <React.Fragment>
                                      <ListSubheader>{i18n.t('paragraphs.currentPeriods')}</ListSubheader>
                                      {periods.current.map((p) => (
                                        <ListItem key={p._id} secondaryText={renderPeriodTimes(p)}>
                                          {p.name}
                                        </ListItem>
                                      ))}
                                    </React.Fragment>
                                  )}
                                  {periods.upcoming.length > 0 && (
                                    <React.Fragment>
                                      <ListSubheader>{i18n.t('paragraphs.upcomingPeriods')}</ListSubheader>
                                      {periods.upcoming.map((p) => (
                                        <ListItem key={p._id} secondaryText={renderPeriodTimes(p)}>
                                          {p.name}
                                        </ListItem>
                                      ))}
                                    </React.Fragment>
                                  )}
                                  {periods.closed.length > 0 && (
                                    <React.Fragment>
                                      <ListSubheader>{i18n.t('paragraphs.closedPeriods')}</ListSubheader>
                                      {periods.closed.map((p) => (
                                        <ListItem key={p._id} secondaryText={renderPeriodTimes(p)}>
                                          {p.name}
                                        </ListItem>
                                      ))}
                                    </React.Fragment>
                                  )}
                                </List>
                              ) : (
                                <CardContent>
                                  <Centered>
                                    <StatusText>{i18n.t('statuses.noPeriods')}</StatusText>
                                  </Centered>
                                </CardContent>
                              )}
                              <FragmentScope scope={AptlyScopes.ProjectPeriods} crud="R">
                                <CardActions>
                                  <LinkedButton
                                    size="small"
                                    color="primary"
                                    component={Link}
                                    to={`${data._id}/perioder`}
                                  >
                                    {i18n.t('paragraphs.showAllPeriods')}
                                  </LinkedButton>
                                </CardActions>
                              </FragmentScope>
                            </Card>
                          );
                        },
                      },
                    ]
                  : []),
              ],
            }}
          />
        )}
      </Section>
    </React.Fragment>
  );
}

function renderPeriodTimes(p: AptlyPeriod) {
  const open = dayjs(p.open);
  const close = dayjs(p.close);

  return `${open.format(FORMAT_DATE)} - ${close.format(FORMAT_DATE)}`;
}

function QueryError() {
  const location = useLocation();
  const search = location.search;
  const { title, description, severity } = useMemo(() => {
    if (!search) return {};
    const params = new URLSearchParams(search);
    const action = params.get('action');
    let title = params.get('error');
    let description = params.get('error_description');
    let severity: AlertProps['severity'] = 'error';

    if (action === 'sign') {
      switch (title) {
        case AptlyRedirectActionError.SignCancel:
          title = i18n.t('errors.signCanceled');
          severity = 'warning';
          break;
        case AptlyRedirectActionError.SignError:
          title = i18n.t('errors.signError');
          description = description || i18n.t('errors.signErrorDescription');
          break;
        default:
          title = i18n.t('statuses.signed');
          severity = 'success';
      }
    }

    return { title, description, severity };
  }, [search]);
  if (!title) {
    return null;
  }

  return (
    <Section>
      <Alert severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    </Section>
  );
}

export default ProjectDashboard;
