import { AptlyCustomer, AptlyOffer, AptlyOption, AptlyUnit, AptlyUser } from '@aptly-as/types';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { useContext } from 'react';
import { CopyIconButton } from '../../components/actions/icons/Icons.js';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { useFormat } from '../../containers/Format/Format.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { SlugLevel } from '../../hooks/useGetApiUrl';
import i18n from '../../libraries/i18n.js';
import {
  dataGridImageColumn,
  dataGridNameCol,
  useColumnCreatedAt,
} from '../../mui/x-data-grid/dataGrid.cols.js';
import { useAlgorithmPrice } from '../Algorithm/useAlgorithms.js';
import { customerToLabel } from '../Customer/customer';
import createMediaURL from '../Media/createMediaURL';
import { orderStatusLabel, orderStatusPaymentLabel } from '../Order/order.utils.js';
import { OfferItemSchema, OfferSchema } from './offer.schema.js';
import { OfferContext } from './OfferContext.js';
import { OfferCopyModal } from './OfferCopy.js';

export const offerDesktopsColumns: (keyof OfferSchema | 'actions')[] = [
  'createdAt',
  'unit',
  'status',
  'createdBy',
  'customer',
  'actions',
];
export function useOfferColumns(level: SlugLevel): GridColDef<OfferSchema>[] {
  const isOrg = level === SlugLevel.Organization;
  return [
    {
      field: 'number',
      headerName: '#',
      width: 60,
    },
    {
      field: 'customer',
      headerName: i18n.t('singles.name'),
      width: 200,
      valueGetter: (customer: AptlyCustomer | string, row: OfferSchema) => {
        if (!row.project) return i18n.t('singles.template');
        if (!customer || typeof customer === 'string') return '';
        return customer.fullName || customerToLabel(customer);
      },
    },
    {
      field: 'unit',
      headerName: i18n.t('singles.customer'),
      width: 250,
      valueGetter: (value: AptlyUnit | string, row) => {
        if (!row.project) return i18n.t('singles.template');
        if (!value || typeof value === 'string') return '';
        return value.name;
      },
    },
    dataGridNameCol({
      flex: 1,
      headerName: i18n.t('singles.offers'),
    }),
    {
      field: 'createdBy',
      headerName: i18n.t('statuses.ourContact'),
      valueGetter: (v?: AptlyUser | string) => (v && typeof v === 'object' ? v.fullName : ''),
      width: 200,
    },
    {
      field: 'status',
      headerName: i18n.t('singles.status'),
      width: 150,
      valueGetter: (_, row) => {
        if (!row.project) return i18n.t('singles.template');
        if (row.order && typeof row.order === 'object') {
          if (row.order.paymentSession) {
            return orderStatusPaymentLabel(row.order.paymentSession.status);
          }
          return orderStatusLabel(row.order.status);
        }
        if (row.sentAt) {
          return i18n.t('statuses.sent');
        }
        return i18n.t('statuses.notSent');
      },
    },
    useColumnCreatedAt(),
    dataGridActionProps(
      (params) => (
        <DataGridActionWrapper>
          {isOrg && <DataGridDeleteAction params={params} />}
          <CopyOfferItem params={params} level={level} />
        </DataGridActionWrapper>
      ),
      {
        minWidth: isOrg ? 150 : 75,
        width: isOrg ? 150 : 75,
      }
    ),
  ] satisfies GridColDef<OfferSchema>[];
}

interface CopyOfferItemProps {
  params: GridRenderCellParams<AptlyOffer>;
  level: SlugLevel;
}
function CopyOfferItem({ params, level }: CopyOfferItemProps) {
  const offer = params.row as AptlyOffer;
  const spawnCopy = useModal(() => <OfferCopyModal offer={offer} level={level} isTemplate />);
  return <CopyIconButton onClick={spawnCopy} />;
}

export const offerItemsDesktopColumns = ['note', 'identification', 'algorithm'];
export function useOfferItemColumns(): GridColDef<OfferItemSchema>[] {
  const offer = useContext(OfferContext);
  const format = useFormat();
  return [
    dataGridImageColumn('image', {}, (params) => {
      const { option } = params.row;
      if (!option?.thumbnail) return undefined;
      return createMediaURL(option.thumbnail.src, { width: 80 });
    }),
    {
      field: 'option',
      headerName: i18n.t('singles.option'),
      valueGetter: (option: AptlyOption | string, row: OfferItemSchema) => {
        if (!option || typeof option === 'string') return '';
        const title = row.title || option?.title || '';
        return title ? `${title || ''} ( ${option.name} )` : option.name;
      },
      flex: 1,
    },
    {
      field: 'note',
      headerName: i18n.t('singles.comment'),
      width: 175,
    },
    {
      type: 'string',
      field: 'identification',
      headerName: i18n.t('singles.identification'),
      width: 120,
      valueGetter: (_, row: OfferItemSchema) => {
        if (row.option && typeof row.option === 'object') {
          return row.option.identification;
        }
        return '-';
      },
    },
    {
      type: 'number',
      field: 'quantity',
      headerName: i18n.t('singles.quantity'),
      valueGetter: (quantity, row) => {
        if (!row.option || typeof row.option === 'string') return '-';
        return format.quantity(row.option, quantity);
      },
      width: 85,
    },
    {
      type: 'number',
      field: 'algorithm',
      headerName: i18n.t('singles.amount'),
      renderCell: ({ row }) => {
        const price = useAlgorithmPrice(row.amount, row.algorithm, row.quantity);
        if (!row.option || typeof row.option === 'string') {
          return format.amount(0, price.currency);
        }
        if (offer.data.amount) {
          return i18n.t('singles.sum');
        }
        return format.amount(price.amount, price.currency);
      },
    },
  ];
}
