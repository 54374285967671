import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import AppBar from '@mui/material/AppBar';
import Grid2 from '@mui/material/Grid2';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { NavContext } from './NavContext';
import { useMobile } from '../../hooks/useMobile';
import UserMenu from '../../models/User/UserMenu';
import ComponentLink from '../../libraries/router/ComponentLink';
import { MOBILE_BREAK } from '../../utils/themes';
import AptlyLogo from '../../components/AptlyLogo';
import Changelogfy from '../../libraries/changelogfy/Changelogfy';
import LanguageSelect from '../../components/LanguageSelect';
import { ReactNode, useContext } from 'react';

type HeaderProps = {
  breadcrumbs: ReactNode[];
  hideMenu?: boolean;
};

export default function Header(props: HeaderProps) {
  const isMobile = useMobile();
  const nav = useContext(NavContext);
  const { breadcrumbs, hideMenu } = props;

  return (
    <AppBar position="fixed" color="inherit" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <StyledToolbar>
        <Grid2 container justifyContent="space-between" flex={1}>
          <Grid2 container alignItems="center" gap={{ xs: 2, md: 4 }}>
            <Grid2>
              {!hideMenu && isMobile ? (
                <IconButton onClick={nav.toggle}>{nav.state.open ? <CloseIcon /> : <MenuIcon />}</IconButton>
              ) : (
                <ComponentLink to="/" key="tp">
                  <AptlyLogo $small $isMobile={isMobile} />
                </ComponentLink>
              )}
            </Grid2>
            <StyledBreadcrumbs separator=">">{breadcrumbs}</StyledBreadcrumbs>
          </Grid2>
          <Grid2 container alignItems="center" justifyContent="flex-end" gap={2} flex={1}>
            {/*<Recruit />*/}
            {!isMobile && (
              <Grid2>
                <LanguageSelect />
              </Grid2>
            )}
            {!isMobile && (
              <Grid2>
                <Changelogfy />
              </Grid2>
            )}
            <Grid2>
              <UserMenu />
            </Grid2>
          </Grid2>
        </Grid2>
      </StyledToolbar>
    </AppBar>
  );
}

const StyledToolbar = styled(Toolbar)`
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  height: 40px;
  align-items: center;
  @media only screen and (max-width: ${MOBILE_BREAK}px) {
    li:not(:last-child) {
      display: none;
    }
  }
`;
