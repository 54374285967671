import { useGet } from '@ewb/reach-react';
import { AptlyUnitTemplateCategorySectionProduct } from '@aptly-as/types';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import ActionButtons from '../../components/actions/buttons/ActionButtons';
import ApiError from '../../components/ApiError';
import Avatar from '../../components/Avatar';
import simpleRequest from '../../libraries/fetch/simpleRequest';
import { ModalActions, ModalContent, ModalTitle } from '../../mui/Dialog';
import { TextField } from '../../mui/Input';
import Paper from '../../mui/Paper';
import Table, { TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../mui/Table';
import Typography from '../../mui/Typography';
import i18n from '../../libraries/i18n';
import { onWheelCaptureBlur } from '../../utils/input';
import createMediaURL from '../Media/createMediaURL';
import extractFeaturedImage from '../Product/extractFeaturedImage';
import { sizeParamLabel } from './sizeParams';

interface UnitTemplateCostProps {
  endpoint: string;
  onSave: () => void;
  onClose?: () => void;
}

interface StateItem {
  updated: boolean;
  product?: string;
  unitCost: number;
}

export default function UnitTemplateCost({ endpoint, onSave, onClose }: UnitTemplateCostProps) {
  const path = endpoint + '/products';
  const [state, setState] = useState<StateItem[]>([]);
  const [products] = useGet<AptlyUnitTemplateCategorySectionProduct[]>(path, {
    onGet: (products) => {
      if (!products) return;
      setState(
        products.map((x) => ({
          _id: x._id,
          product: x.product?._id,
          unitCost: x.unitCost || 0,
          updated: false,
        }))
      );
    },
  });

  const handleOnSubmit = useCallback(async () => {
    return simpleRequest({
      endpoint: path + '/unit-cost',
      method: 'POST',
      data: { products: state.filter((x) => x.updated) },
      onRequestDone: () => {
        onSave();
        if (typeof onClose === 'function') {
          onClose();
        }
      },
    });
  }, [onSave, onClose, state]);

  const handleOnChange = useCallback((index: number, unitCost: number) => {
    setState((s) => {
      s[index].unitCost = unitCost;
      s[index].updated = true;
      return [...s];
    });
  }, []);

  return (
    <>
      <ModalTitle>
        <Typography variant="h3">{i18n.t('singles.netCost')}</Typography>
        <Typography>
          <Trans i18nKey="paragraphs.basePriceUpdate">
            Update the base price for the product in the options list. The price extracted is the smallest
            value published. The price is saved for all similar products.
          </Trans>
        </Typography>
      </ModalTitle>
      <ModalContent>
        {products.error && <ApiError error={products.error} />}
        <TableContainer component={Paper}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell width="50">{i18n.t('singles.image')}</TableCell>
                <TableCell width="150">{i18n.t('singles.productNumber')}</TableCell>
                <TableCell>{i18n.t('singles.name')}</TableCell>
                <TableCell align="right" width="100">
                  {i18n.t('singles.quantity')}
                </TableCell>
                <TableCell align="right" width="100">
                  {i18n.t('singles.netCost')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(products.data || []).map((item, i) => (
                <ProductItem key={item._id} sectionProduct={item} index={i} onSave={handleOnChange} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContent>
      <ModalActions>
        <ActionButtons onClose={onClose} onClick={handleOnSubmit} />
      </ModalActions>
    </>
  );
}

interface ProductItemProps {
  sectionProduct: AptlyUnitTemplateCategorySectionProduct;
  index: number;
  onSave: (index: number, unitCost: number) => void;
}

function ProductItem({ sectionProduct, index, onSave }: ProductItemProps) {
  const title = sectionProduct.product?.name || sectionProduct.text;
  const productNumber = sectionProduct.product?.productNumber || '';
  const amount = sectionProduct.unitSizeParam
    ? sizeParamLabel(sectionProduct.unitSizeParam)
    : sectionProduct.amount || 0;

  const imageSrc = useMemo(() => {
    if (!sectionProduct.product) return null;
    const featuredImage = extractFeaturedImage(sectionProduct.product.images || []);
    return featuredImage ? createMediaURL(featuredImage.image, { width: 80 }) : undefined;
  }, [sectionProduct]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSave(index, Number(e.target.value));
    },
    [onSave, index]
  );

  return (
    <TableRow>
      <TableCell>
        {imageSrc ? (
          <Avatar src={imageSrc} alt={title} imgProps={{ loading: 'lazy' }} variant="rounded" contain />
        ) : null}
      </TableCell>
      <TableCell>{productNumber}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell align="right">{amount}</TableCell>
      <TableCell align="right">
        <TextField
          defaultValue={sectionProduct.unitCost || 0}
          onChange={handleOnChange}
          label={i18n.t('singles.netCost')}
          type="number"
          onWheelCapture={onWheelCaptureBlur}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
        />
      </TableCell>
    </TableRow>
  );
}
