import { AptlyFieldType, AptlyCategory } from '@aptly-as/types';
import { schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n.js';

export const categorySchemaFields: (keyof AptlyCategory)[] = ['name'];

export function useCategorySchema(): ICrudSchema<AptlyCategory> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
  };
}

export function useCategorySchemaField<T extends object>(
  override: Partial<ICrudField<T, AptlyCategory | string | null>> = {}
): ICrudField<T, AptlyCategory | string | null> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'categories'),
      query: { archived: false, sort: 'name', select: '_id,name', level: 'project', limit: '5' },
      multiple: false,
    }),
    label: i18n.t('singles.category'),
    defaultValue: null,
    ...override,
  };
}
