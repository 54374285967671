import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 0.15
  }

  50% {
    opacity: 0.3
  }

  100% {
    opacity: 0.15
  }
`;

const BusyWrapper = styled.div<{ $busy?: boolean }>`
  transition: opacity 0.15s ease-in-out;

  ${(props) =>
    props.$busy &&
    css`
      cursor: wait;
      animation: ${pulse} 0.75s infinite ease-in-out;
    `};
`;

export default BusyWrapper;
