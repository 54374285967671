import { useFields } from '@ewb/reach-react';
import { PropsWithChildren, useMemo } from 'react';
import ApiError, { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl';
import { useOrganizationSchema, IOrganizationSchema } from './organization.schema';
import { OrganizationContext } from './OrganizationContext';

export function OrganizationProvider({ slug, children }: PropsWithChildren<{ slug?: string }>) {
  const path = useApiUrl(SlugLevel.Base, 'organizations');
  const org = useFields<IOrganizationSchema, IApiError, ICrudFieldData<IOrganizationSchema>>(
    path,
    useMemo(() => ({ _id: slug, slug }), [slug]),
    useOrganizationSchema(),
    useMemo(() => ({ idKey: '_id', initWithGet: true }), [])
  );

  const value = useMemo(() => ({ data: org.state.data, ...org }), [org]);

  if (org.state.error) return <ApiError error={org.state.error} />;
  if (!org.state.data.name) return null;

  return <OrganizationContext.Provider value={value}>{children}</OrganizationContext.Provider>;
}
