import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const PREFIX = 'Table';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledMUITableCell = styled(MUITableCell)({
  [`& .${classes.root}`]: {
    position: 'relative',
  },
});

const StylesMUITableCell = styled((props: any) => <StyledMUITableCell {...props} />)`
  .MuiIconButton-root {
    padding: 6px;
    margin: -6px 6px;
  }
`;

const TableCell: any = StylesMUITableCell;

export interface TableRowCellProps {
  label: string;
}
export function TableRowCell({ label, children }: PropsWithChildren<TableRowCellProps>): JSX.Element {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <StyledTableCellValue>{children}</StyledTableCellValue>
    </TableRow>
  );
}

const StyledTableCellValue = styled(TableCell)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default Table;
export { TableContainer, TableHead, TableRow, TableBody, TableCell, TableFooter };
