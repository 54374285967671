import { signal } from '@preact/signals-react';
import React from 'react';
import { v4 } from 'uuid';
import { ICreateModelOptions, IModal } from './modal.types';

export const modals = signal<IModal[]>([]);

// @ts-ignore
window.modals = modals;

export function newModal(element: React.ReactElement, opts: ICreateModelOptions = {}): IModal {
  const id = v4();
  const newModalData: IModal = {
    id,
    element: React.cloneElement(element, {
      onClose: () => {
        if (element.props.onClose) {
          element.props.onClose();
        }
        deleteModal(id);
      },
    }),
    width: opts.width || 'sm',
    fullScreen: opts.fullScreen || false,
    naked: opts.naked || false,
    disableEscape: opts.disableEscape || false,
    enableBackdropOnClose: opts.enableBackdropOnClose || false,
  };

  modals.value = [...modals.value, newModalData];
  return newModalData;
}

export function deleteModal(id: string) {
  modals.value = modals.value.filter((modal) => modal.id !== id);
}
