import Done from '@mui/icons-material/Done';
import Face from '@mui/icons-material/Face';
import Warning from '@mui/icons-material/Warning';
import { signal } from '@preact/signals-react';
import { ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import { ErrorResponse } from '../../libraries/cloudinary/cloudinary';
import i18n from '../../libraries/i18n';
import Progresser from '../../mui/Progresser';
import { error, info, success } from '../../utils/themes';
import Notification, { NotificationProps } from './Notification';
import { NotificationContent, NotificationVisual } from './notification.styles';
import { INotification } from './notification.types';

export const notifications = signal<INotification[]>([]);

export function notificationRequestProtocol(successMessage?: string): (arg0?: ErrorResponse | null) => void {
  return (errorResponse) => {
    let isSuccess = true;
    let message = successMessage || i18n.t('statuses.saved');

    if (errorResponse) {
      isSuccess = false;
      message = 'error' in errorResponse ? errorResponse.error : i18n.t('paragraphs.somethingWentWrong');
    }

    return newNotification({
      text: message,
      color: isSuccess ? success : error,
      timing: 3000,
      icon: isSuccess ? <Done /> : <Face />,
    });
  };
}

export function errorNotification(text: string, timing = 10000) {
  return newNotification({ text, color: error, timing, icon: <Warning /> });
}

export function successNotification(text: string, timing = 3000) {
  return newNotification({ text, color: success, timing, icon: <Done /> });
}

export function busyNotification(text: string = i18n.t('statuses.loading')) {
  const id = newNotification({ text });
  return () => removeNotification(id);
}

interface NewNotificationProps extends NotificationProps {
  text: string;
  timing?: number;
  color?: string;
  icon?: ReactNode;
}

export function newNotification({
  text,
  timing = 10000,
  icon,
  color = info,
  ...props
}: NewNotificationProps): string {
  const id = uuid();
  notifications.value = [
    ...notifications.value,
    {
      id,
      timing,
      element: (
        <Notification key={id} {...props}>
          <NotificationVisual $backgroundColor={color}>
            {icon || <Progresser style={{ color: '#fff' }} />}
          </NotificationVisual>
          <NotificationContent>{text}</NotificationContent>
        </Notification>
      ),
    },
  ];

  setTimeout(() => removeNotification(id), timing);

  return id;
}

export function removeNotification(id: string) {
  notifications.value = notifications.value.filter((x) => x.id !== id);
}
