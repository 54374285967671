import MuiCheckbox from '@mui/material/Checkbox/Checkbox';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import styled, { css } from 'styled-components';
import List from '@mui/material/List';
import MUIListItem, { ListItemProps as MUIListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '../../mui/Typography';
import Grid from '../../mui/Grid';
import { rawSpacing } from '../../utils/spacing';
import StatusText from '../StatusText';

export interface ListItemProps extends MUIListItemProps {
  icon?: ReactNode;
  disableGutters?: boolean;
  secondaryText?: string | ReactNode;
  secondaryAction?: ReactNode;
  secondaryIcon?: ReactNode;
  nested?: boolean;
  button?: boolean;
  onClick?: (e: any) => void;
  component?: any;
  to?: string;
  href?: string;
  activeStyle?: any;
  activeStyles?: any;
  type?: any;
  name?: string;
}

export type MenuListItemProps = ListItemButtonProps<
  'button',
  {
    icon?: ReactNode;
  }
>;

export function MenuListItem({ children, icon, ...props }: PropsWithChildren<MenuListItemProps>) {
  return (
    <ListItemButton component="button" {...props}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={<Typography variant="body2">{children}</Typography>} />
    </ListItemButton>
  );
}

function BareListItem(props: ListItemProps) {
  const { children, icon, secondaryText, secondaryAction, secondaryIcon, nested, classes, button, ...rest } =
    props;

  return (
    <StyledMUIListItem {...rest} $nested={nested} button={button ? String(button) : undefined}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={children} secondary={secondaryText} disableTypography />
      {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
      {secondaryIcon}
    </StyledMUIListItem>
  );
}

const StyledMUIListItem = styled(MUIListItem)<{
  // TS Fix for old code.
  button?: string;
  $nested?: boolean;
}>`
  ${(props) =>
    props.$nested &&
    css`
      padding-left: ${props.theme.spacing(2)};
      border-left: ${props.theme.spacing(1)} solid ${props.theme.palette.primary.main};
    `};
`;

/**
 * @deprecated This should not be used for buttons. Prop handling on this is a mess
 */
const ListItem = BareListItem;

interface ListItemEditProps extends JSX.ElementChildrenAttribute {
  action: JSX.Element;
  status: string;
}

export function ListItemEdit({ action, status, children }: ListItemEditProps) {
  return (
    <ListItem secondaryAction={action}>
      <Grid container spacing={rawSpacing}>
        <Grid item sm={3}>
          {children as any}
        </Grid>
        <Grid item sm={9}>
          <StatusText>{status}</StatusText>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export interface ListItemCheckboxProps<T> {
  checked: boolean;
  item: T;
  onClick: (item: T) => void;
  primary: ReactNode;
  secondary?: ReactNode;
}

function ListItemCheckbox<T>({ checked, onClick, primary, secondary, item }: ListItemCheckboxProps<T>) {
  const handleOnClick = useCallback(() => onClick(item), [onClick, item]);

  return (
    <ListItem
      secondaryAction={<MuiCheckbox edge="end" checked={checked} onClick={handleOnClick} />}
      disablePadding
    >
      <ListItemButton selected={checked} onClick={handleOnClick}>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItemButton>
    </ListItem>
  );
}

export default List;
export { ListItem, ListSubheader, ListItemText, ListItemIcon, ListItemButton, ListItemCheckbox };
