import { useCallback } from 'react';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { createModal } from '../../containers/Modal/ModalContext';
import { useAlgorithms } from '../Algorithm/useAlgorithms';
import i18n from '../../libraries/i18n';
import { IProductSearchProps, ProductSearch } from '../Product/ProductSearch';

type IAddProductsProps = Pick<IProductSearchProps, 'onSave' | 'addAs'>;

export function AddProducts({ onSave, addAs }: IAddProductsProps) {
  const algorithms = useAlgorithms();
  const spawnProductsModal = useCallback(() => {
    createModal(
      <ProductSearch onSave={onSave} algorithms={algorithms} addAs={addAs} fullScreen showAddAs />,
      {
        fullScreen: true,
      }
    );
  }, [onSave, addAs, algorithms]);

  return <AddButtonWithIcon onClick={spawnProductsModal}>{i18n.t('actions.addProducts')}</AddButtonWithIcon>;
}
