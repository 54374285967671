import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert } from '@mui/material';
import { FormEvent, useCallback, useContext, useMemo } from 'react';
import { IUseFieldRet } from '@ewb/reach-react';
import { AptlyProduct, AptlyProductVariant } from '@aptly-as/types';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import Add from '@mui/icons-material/Add';
import { AddButtonWithIcon, SearchButtonWithIcon } from '../../components/actions/buttons/Buttons';
import { createModal } from '../../containers/Modal/ModalContext';
import useScreenMediaDropzone, { ScreenDropzone } from '../../hooks/useScreenMediaDropzone';
import Typography from '../../mui/Typography';
import { OrganizationContext } from '../Organization/OrganizationContext';
import {
  productEditKeys,
  useProductSchema,
  variantEditKeys,
  variantNewKeys,
  productVariantSchema,
  IProductSchema,
  AptlyProductVariantSchema,
} from './product.schema';
import extractFeaturedImage from './extractFeaturedImage';
import createMediaURL from '../Media/createMediaURL';
import { productGroupProps } from './product.utils';
import { ICrudFieldData, ICrudSchema } from '../../components/crud/utils/crud.utils';
import ApiError, { IApiError } from '../../components/ApiError';
import { useSimpleCrudFields } from '../../components/simple/useSimpleCrud';
import { SimpleCrudContent } from '../../components/simple/SimpleCrud';
import { ModalContent, ModalForm } from '../../mui/Dialog';
import i18n from '../../libraries/i18n';
import { successNotification } from '../../containers/Notification/notification.utils';
import ChooseProductOld from './ChooseProductOld';
import { useSlugs } from '../../hooks/useGetApiUrl';
import Spacer from '../../components/Spacer';
import DeleteModal from '../../containers/Modal/DeleteModal';
import { ProductContext } from './ProductContext';

export interface IVariantsProps extends Pick<IVariantProps<AptlyProductVariant>, 'children'> {
  update?: boolean;
  edit?: boolean;
}

export default function Product({ update, children }: IVariantsProps) {
  const org = useContext(OrganizationContext);
  const {
    product,
    active,
    setActive,
    addVariant,
    productChange,
    variantChange,
    deleteVariant,
    deleteProduct,
  } = useContext(ProductContext);
  const schema = useMemo(() => useProductSchema(org.data.producer, update), [org.data.producer, update]);
  const vSchema = useMemo(() => productVariantSchema(product), [product]);

  const Item = useMemo(() => {
    if (active === 0) {
      return (
        <ProductItem
          key={product._id}
          product={product}
          onDelete={deleteProduct}
          schema={schema}
          keys={productEditKeys}
          onChange={productChange}
          update={update}
        >
          {children as any}
        </ProductItem>
      );
    }
    if (active <= product.variants.length) {
      const variant = product.variants[active - 1];
      return (
        <ProductItem<AptlyProductVariantSchema>
          key={String(`variant-${active}`)}
          product={product}
          variant={variant}
          schema={vSchema}
          keys={variant._id ? variantEditKeys : variantNewKeys}
          onChange={variantChange(active - 1)}
          onDelete={deleteVariant(active - 1)}
          isVariant
          update={update}
        >
          {children}
        </ProductItem>
      );
    }
    return <NewVariant onNew={addVariant} />;
  }, [
    active,
    product,
    deleteProduct,
    productChange,
    variantChange,
    deleteVariant,
    children,
    addVariant,
    schema,
    vSchema,
    update,
  ]);

  const variants = product.variants || [];

  return (
    <Grid container direction="column">
      <Grid item>
        {variants.length ? (
          <Tabs value={active} onChange={(_e, v) => setActive(v)} variant="scrollable">
            <VariantTab item={product} index={0} />
            {variants.map((variant, index) => (
              <VariantTab key={index + 1} item={variant} index={index} />
            ))}
            {product.variants.length > 0 && update && (
              <Tab value={product.variants.length + 1} icon={<Add />} label={i18n.t('singles.variant')} />
            )}
          </Tabs>
        ) : null}
      </Grid>
      <Grid item>{Item}</Grid>
    </Grid>
  );
}

function VariantTab({ item, index, ...rest }: { item: AptlyProduct | AptlyProductVariant; index: number }) {
  const featuredImage = extractFeaturedImage(item.images || []);
  return (
    <Tab
      value={index}
      icon={
        <Avatar
          src={featuredImage ? createMediaURL(featuredImage.image, { width: 80 }) : undefined}
          imgProps={{ loading: 'lazy' }}
          alt={item.name}
          variant="rounded"
        />
      }
      label={item.name}
      {...rest}
    />
  );
}

interface NewVariantProps {
  onNew: (variant?: AptlyProductVariant) => void;
}

function NewVariant({ onNew }: NewVariantProps) {
  const [organizationSlug, projectID] = useSlugs();
  const handleOnSearch = useCallback(() => {
    createModal(
      <ChooseProductOld
        organizationSlug={organizationSlug}
        projectID={projectID}
        // @ts-ignore old types
        onSelect={({ _id, variants, producer, tags, organization, project, ...p }) => onNew(p)}
      />,
      {
        fullScreen: true,
      }
    );
  }, [organizationSlug, projectID, onNew]);

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center" gap={4}>
      <Alert severity="info" icon={<ListAltIcon fontSize="small" />}>
        Varianter fases ut og vil erstattes med sortiment. Se etter ikonet <ListAltIcon fontSize="small" /> i
        tilvalglisten
      </Alert>
      <Spacer $hugTop />
      <Grid container item xs={6}>
        <AddButtonWithIcon onClick={() => onNew()} fullWidth>
          {i18n.t('actions.createVariant')}
        </AddButtonWithIcon>
      </Grid>
      <Grid container item xs={6}>
        <SearchButtonWithIcon onClick={handleOnSearch} fullWidth />
      </Grid>
      <Spacer $hugBottom />
    </Grid>
  );
}

interface IVariantProps<T extends IProductSchema | AptlyProductVariantSchema> {
  product: AptlyProduct;
  variant?: AptlyProductVariant;
  schema: ICrudSchema<T>;
  keys: (keyof T)[];
  onChange: (data: T) => void;
  onDelete?: () => void;
  children: (crud: IUseFieldRet<T, IApiError, ICrudFieldData<T>>, onDelete?: () => void) => JSX.Element;
  isVariant?: boolean;
  update?: boolean;
}

function ProductItem<T extends IProductSchema | AptlyProductVariantSchema>({
  product,
  variant: propsVariant,
  schema,
  keys,
  onChange,
  onDelete,
  children,
  isVariant,
  update,
}: IVariantProps<T>) {
  const { path } = useContext(ProductContext);
  const key = propsVariant ? propsVariant._id || 'new' : product._id;
  const [crud, crudFields] = useSimpleCrudFields(
    isVariant ? `${path}/${product._id}/variants` : path,
    isVariant && propsVariant
      ? '_id' in propsVariant
        ? { _id: propsVariant._id, name: propsVariant.name }
        : propsVariant
      : (product as any),
    schema,
    keys,
    useMemo(
      () => ({
        initWithGet: true,
        reachOptions: { query: !isVariant ? { $populate: 'producer:_id,name;tags:_id,name;products' } : {} },
      }),
      [isVariant]
    )
  );
  const onDrop = useCallback(
    async (newImages: any[]) => {
      const images = crud.getField('images').value;
      crud.setField('images')([...images, ...newImages.map((x) => ({ ...x, image: x.src }))]);
      const data = await crud.save();
      if (data) onChange(data);
    },
    [crud, onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useScreenMediaDropzone(
    useMemo(
      () => ({ onDrop, multiple: true, accept: { 'image/*': [] }, disabled: !update }),
      [onDrop, update]
    )
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = await crud.save();
      if (data) {
        successNotification(i18n.t('statuses.saved'));
        onChange(data);
      }
    },
    [crud, onChange]
  );

  const handleOnDelete = useCallback(() => {
    createModal(
      <DeleteModal
        onConfirm={async () => {
          // todo: Fix typing in reach-react.
          if (((await crud.actions.delete()) as any) && onDelete) {
            onDelete();
          }
        }}
      />
    );
  }, [crud, onDelete]);

  return (
    <ModalForm key={key} onSubmit={handleSubmit} style={{ maxHeight: '82vh' }} {...getRootProps()}>
      <input {...getInputProps()} />
      <ModalContent style={{ maxWidth: '100%' }}>
        {isVariant && (
          <Alert severity="info" icon={<ListAltIcon fontSize="small" />}>
            Varianter fases ut og vil erstattes med sortiment. Se etter ikonet{' '}
            <ListAltIcon fontSize="small" /> i tilvalglisten
          </Alert>
        )}
        <SimpleCrudContent<T> crud={crud as any} schema={crudFields} groupProps={productGroupProps()} />
        {crud.state.error && <ApiError error={crud.state.error} />}
        <ScreenDropzone active={isDragActive}>
          <Typography variant="h1">
            <CloudUploadOutlinedIcon /> {i18n.t('actions.uploadImage')}
          </Typography>
        </ScreenDropzone>
      </ModalContent>
      {children(crud, handleOnDelete)}
    </ModalForm>
  );
}
