import { aptlyApiFetch, AptlyQueryKey } from '@aptly-as/sdk-react';
import { AptlyApiFetchRequestInit } from '@aptly-as/sdk-react/aptly/aptly.fetch';
import { QueryClient, QueryFunctionContext } from '@tanstack/react-query';
import { API_URL } from '../env.js';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn,
    },
  },
});

export function queryFn<T>({ queryKey }: Pick<QueryFunctionContext, 'queryKey'>): Promise<T> {
  const [path, id, query] = queryKey as AptlyQueryKey;
  let url = `${API_URL}/${path}`;
  if (id) {
    url += `/${id}`;
  }
  if (query) {
    url += `?${new URLSearchParams(query).toString()}`;
  }

  return aptlyApiFetch(url);
}

export function queryAptlyApi<T>(path: string, init?: AptlyApiFetchRequestInit): Promise<T> {
  return aptlyApiFetch<T>(`${API_URL}/${path}`, init);
}
