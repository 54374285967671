import { IPaginatedSearchObject } from '../libraries/reach/usePaginatedSearch';
import { IUseCrudSearchActions } from '../libraries/reach/useCrudSearch';
import { useCallback, useContext } from 'react';
import { SearchCrudContext } from '../components/Search/search.utils';

export function useSearchActionProps<T extends IPaginatedSearchObject>(
  id: string | number,
  action: (actions: IUseCrudSearchActions<T>, item: T, index: number) => void
) {
  const { items, actions } = useContext(SearchCrudContext);
  return useCallback(() => {
    const index = items.findIndex((x) => x._id === id);
    if (index > -1) {
      action(actions, items[index], index);
    }
  }, [id, items, actions, action]);
}
