import Typography from '@mui/material/Typography';
import { AptlyProduct, AptlyTag } from '@aptly-as/types';
import { CloseButtonWithIcon } from '../components/actions/buttons/Buttons.js';
import ColorPalette from '../components/ColorPalette';
import { EditorText } from '../components/EditorText';
import Resource from '../components/Resource';
import StatusText from '../components/StatusText';
import { ModalActions, ModalContent, ModalHeader } from '../mui/Dialog';
import Grid from '../mui/Grid';
import { Label } from '../mui/Input';
import { objectField } from '../utils/object';
import { rawSpacing } from '../utils/spacing';
import ImagesController from './ImagesController';
import Accordion from '../components/Accordion';
import { AccordionBodyNegator, AccordionDetails } from '../mui/Accordion';
import Documents_old from '../models/Document/Documents_old';
import { UpdateDeleteResponse } from './UpdateDelete';
import i18n from '../libraries/i18n';
import React, { PropsWithChildren } from 'react';

interface Props {
  id?: string;
  variant?: boolean;
  parentID?: string;
  onClose?: () => void;
  onSave?: (arg0: UpdateDeleteResponse, isNew?: boolean) => void;
  onDelete?: () => void;
  isOrganizationBased?: boolean;
  isProjectBased?: boolean;
  organizationID?: string;
  projectID?: string;
  organizationSlug?: string;
}

type State = {
  id?: string;
};

/**
 * @deprecated. This should not be used.
 * This needs to be rewritten to only use display data and not have any logic.
 */
export default class SingleProduct extends React.Component<Props, State> {
  static defaultProps = {
    isProjectBased: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.id && nextProps.id !== prevState.id) {
      return {
        id: nextProps.id,
      };
    }
    return prevState;
  }

  state = {
    id: this.props.id,
  };

  render() {
    const {
      variant,
      parentID,
      onSave,
      onClose,
      isOrganizationBased,
      isProjectBased,
      organizationSlug,
      projectID,
    } = this.props;
    const { id } = this.state;

    let baseEndpoint = 'products';

    if (isOrganizationBased) {
      if (organizationSlug) {
        if (variant && parentID) {
          baseEndpoint = `organizations/${organizationSlug}/products/${parentID}/variants`;
        } else {
          baseEndpoint = `organizations/${organizationSlug}/products`;
        }
      }
    }

    if (isProjectBased) {
      if (organizationSlug && projectID) {
        if (variant && parentID) {
          baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}/products/${parentID}/variants`;
        } else {
          baseEndpoint = `organizations/${organizationSlug}/projects/${projectID}/products`;
        }
      }
    } else if (!isOrganizationBased) {
      if (variant && parentID) {
        baseEndpoint = `products/${parentID}/variants`;
      }
    }

    return (
      <Resource endpoint={`${baseEndpoint}/${id || ''}`} dontFetch={!id}>
        {({ data, refresh }) => {
          if (Array.isArray(data)) {
            return null;
          }

          const product: AptlyProduct | null = (data as AptlyProduct) || null;
          const numImages = product ? product.images.length : 0;
          const documents = product?.documents || [];

          if (!product) return null;

          return (
            <React.Fragment>
              {variant && (
                <ModalContent>
                  <Typography>{i18n.t('paragraphs.variantHelpText')}</Typography>
                </ModalContent>
              )}
              <ModalHeader onClose={onClose} title={product.name || ''} />
              <ModalContent>
                <Grid container direction="column" spacing={rawSpacing}>
                  <Grid item>
                    <Grid container spacing={rawSpacing}>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.productNumber')}>{product.productNumber}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Typography>{i18n.t('singles.colorCode')} (ncs / hex)</Typography>
                        <ColorPalette color={product.color} />
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.colorLabel')}>{product.colorLabel}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.series')}>{product.series}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.style')}>{product.style}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.material')}>{product.material}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.length')}>{product.length}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.width')}>{product.width}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.height')}>{product.height}</Value>
                      </Grid>
                      <Grid item sm={4}>
                        <Value label={i18n.t('singles.netCost')}>{product.unitCost}</Value>
                      </Grid>
                      {!variant && (
                        <>
                          <Grid item sm={4}>
                            <Value label={i18n.t('singles.producer')}>
                              {objectField(product.producer, 'name')}
                            </Value>
                          </Grid>
                          <Grid item sm={4}>
                            <Value label={i18n.t('singles.tags')}>
                              {product.tags ? (product.tags as AptlyTag[]).map((x) => x.name).join(', ') : ''}
                            </Value>
                          </Grid>
                          <Grid item sm={4}>
                            <Value label={i18n.t('singles.url')}>{product.url}</Value>
                          </Grid>
                        </>
                      )}
                      <Grid item sm={12}>
                        <Typography>{i18n.t('singles.description')}</Typography>
                        <EditorText value={product.description} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {product && (
                    <React.Fragment>
                      <Grid item>
                        <Accordion
                          title={i18n.t('singles.images')}
                          subtitle={
                            numImages > 0
                              ? `${numImages} ${i18n.t('singles.images').toLowerCase()}`
                              : i18n.t('paragraphs.noImages')
                          }
                        >
                          {() => (
                            <AccordionDetails>
                              <ImagesController
                                key={product._id}
                                disableEditing
                                columns={3}
                                endpoint={`${baseEndpoint}/${product._id}/images`}
                                images={product.images.map((i) => ({
                                  id: i._id,
                                  url: i.image,
                                  description: i.description,
                                  featured: i.featured,
                                }))}
                                featurable
                                onSave={() => {
                                  id &&
                                    refresh({
                                      background: true,
                                      cb: (r) => typeof onSave === 'function' && onSave(r as any),
                                    });
                                }}
                              />
                            </AccordionDetails>
                          )}
                        </Accordion>
                        <Accordion
                          title={i18n.t('singles.documents')}
                          subtitle={
                            documents.length > 0
                              ? `${documents.length} ${i18n.t('singles.documents').toLowerCase()}`
                              : i18n.t('statuses.noDocuments')
                          }
                        >
                          {() => (
                            <AccordionDetails>
                              <AccordionBodyNegator>
                                <Documents_old
                                  hideSearch
                                  noTitle
                                  isProduct
                                  endpointSuffix={`${baseEndpoint}/${product._id}`}
                                  onSave={() =>
                                    refresh({
                                      background: true,
                                      cb: (r) => typeof onSave === 'function' && onSave(r as any),
                                    })
                                  }
                                />
                              </AccordionBodyNegator>
                            </AccordionDetails>
                          )}
                        </Accordion>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </ModalContent>
              <ModalActions>
                <CloseButtonWithIcon onClick={onClose} />
              </ModalActions>
            </React.Fragment>
          );
        }}
      </Resource>
    );
  }
}

interface ValueProps {
  label: string;
  children?: string | number | null;
}

function Value({ label, children }: PropsWithChildren<ValueProps>) {
  return (
    <React.Fragment>
      <Label>{label}</Label>
      <StatusText block>{children || i18n.t('statuses.missing')}</StatusText>
    </React.Fragment>
  );
}
