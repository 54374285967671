import { AptlyCurrency, AptlyQuantity, AptlyLanguage } from '@aptly-as/types';
import { createContext, useContext } from 'react';
import { UserContext } from '../../models/User/UserContext.js';

export interface IFormatContext {
  amount: (amount: number, currency?: AptlyCurrency) => string;
  quantity: (item: Partial<AptlyQuantity>, quantity?: number) => string;
  date: (date: string) => string;
  dateTime: (date: string) => string;
}

export const FormatContext = createContext<IFormatContext>({
  amount: () => 'Missing provider',
  quantity: () => 'Missing provider',
  date: () => 'Missing provider',
  dateTime: () => 'Missing provider',
});

export function useIntlLocale() {
  const { locale } = useContext(UserContext);
  switch (locale) {
    case AptlyLanguage.English:
      return 'en-US';
    default:
      return 'no-NB';
  }
}

export function useFormat() {
  return useContext(FormatContext);
}
