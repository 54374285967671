import { useFields, IUseFieldRet } from '@ewb/reach-react';
import { IUseCrudProps } from '@ewb/reach-react/core/useCrud';
import { createContext, FormEvent, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import ApiError, { IApiError } from '../ApiError';
import { ICrudFieldCustomGroupField, ICrudFieldData, ICrudSchema } from '../crud/utils/crud.utils';
import { ISimpleComponentProps } from './simple-components/simple-components.utils';
import SimpleCheckbox from './simple-components/SimpleCheckbox';
import SimpleDateTime from './simple-components/SimpleDateTime';
import SimpleMarkdown from './simple-components/SimpleMarkdown';
import SimpleSelect from './simple-components/SimpleSelect';
import SimpleTextField, { ICrudTextFieldProps } from './simple-components/SimpleTextField';
import SimpleTime from './simple-components/SimpleTime';
import { SimpleCrudFields } from './SimpleCrudFields.js';

export interface CrudFormProps<T extends object, RET = T> {
  path: string;
  data: Partial<T>;
  schema: ICrudSchema<T>;
  onEdit?: (data: RET) => void;
  disableFields?: (keyof T)[];
  crudProps?: Partial<IUseCrudProps<T>>;
}

export type CrudFormContextType<T extends object, RET = T> = IUseFieldRet<
  T,
  IApiError,
  ICrudFieldData<T>,
  RET
>;
export const CrudFormContext = createContext<CrudFormContextType<any>>({
  save: () => Promise.resolve({} as object),
  state: {
    idKey: '_id',
    path: '',
    busy: false,
    dirty: false,
    schema: {},
    initialData: {},
    data: { _id: '' },
    edited: {},
    meta: {},
  },
  setData: () => {},
  setField: () => () => {},
  getField: () => ({}) as any,
  actions: {
    read: () => Promise.resolve(),
    delete: () => Promise.resolve(),
  },
  setState: () => {},
});

export default function CrudForm<T extends object, RET = T>(props: PropsWithChildren<CrudFormProps<T, RET>>) {
  const { path, data, schema, children, onEdit, crudProps } = props;

  const crud = useFields<T, IApiError, ICrudFieldData<T>, RET>(
    path,
    data,
    schema,
    useMemo(
      () => ({
        idKey: '_id' as keyof T,
        disableAutoSave: true,
        initWithGet: false,
        ...crudProps,
      }),
      [crudProps]
    )
  );

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const data = await crud.save();
      if (data && typeof onEdit === 'function') {
        onEdit(data);
      }
    },
    [crud, props, onEdit]
  );

  return (
    <CrudFormContext.Provider value={crud as any}>
      <form onSubmit={handleSubmit} style={{ display: 'contents' }}>
        {children}
        {crud.state.error && <ApiError error={crud.state.error} />}
      </form>
    </CrudFormContext.Provider>
  );
}

export function useCrudFormField<T extends object, K extends keyof T>(key: K): ISimpleComponentProps<T, K> {
  const crud: CrudFormContextType<T> = useContext(CrudFormContext);
  const field = crud.getField(key) as ICrudFieldCustomGroupField<T, K>;

  const onChange = useCallback(
    (value: T[K], autoSave?: boolean) => {
      if (typeof field.preOnChange === 'function') {
        crud.setField(key)(field.preOnChange(value, crud));
      } else {
        crud.setField(key)(value);
      }
      if ('_id' in crud.state.data && crud.state.data._id && autoSave && field.saveAfterSet) {
        crud.save().then();
      }
    },
    [crud, field, key]
  );

  return useMemo(() => ({ field, onChange }), [field, onChange]);
}

export interface ICrudFormFieldProps<T extends object, K extends keyof T> {
  name: K;
}

export function CrudFormField<T extends object, K extends keyof T = keyof T>({
  name,
}: ICrudFormFieldProps<T, K>) {
  const crud: CrudFormContextType<T> = useContext(CrudFormContext);
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleCrudFields<T, K> crud={crud} field={field} onChange={onChange} />;
}

interface ICrudFormTextFieldProps<T extends object, K extends keyof T> {
  name: K;
}
export function CrudFormTextField<T extends object = any, K extends keyof T = keyof T>({
  name,
  ...rest
}: ICrudFormTextFieldProps<T, K> & Omit<ICrudTextFieldProps<T>, 'field' | 'onChange'>) {
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleTextField<T> field={field} onChange={onChange} {...rest} />;
}
export function CrudFormDateTimeField<T extends object = any, K extends keyof T = keyof T>({
  name,
  ...rest
}: ICrudFormTextFieldProps<T, K> & Omit<ICrudTextFieldProps<T>, 'field' | 'onChange'>) {
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleDateTime<T> field={field} onChange={onChange} {...rest} />;
}
export function CrudFormTimeField<T extends object = any, K extends keyof T = keyof T>({
  name,
  ...rest
}: ICrudFormTextFieldProps<T, K> & Omit<ICrudTextFieldProps<T>, 'field' | 'onChange'>) {
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleTime<T> field={field} onChange={onChange} {...rest} />;
}
export function CrudFormCheckbox<T extends object = any, K extends keyof T = keyof T>({
  name,
  ...rest
}: ICrudFormTextFieldProps<T, K> & Omit<ICrudTextFieldProps<T>, 'field' | 'onChange'>) {
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleCheckbox<T> field={field} onChange={onChange} {...rest} />;
}
export function CrudFormSelect<T extends object = any, K extends keyof T = keyof T>({
  name,
}: ICrudFormTextFieldProps<T, K>) {
  const crud: CrudFormContextType<T> = useContext(CrudFormContext);
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleSelect<T> crud={crud} field={field} onChange={onChange} />;
}
export function CrudFormMarkdown<T extends object = any, K extends keyof T = keyof T>({
  name,
}: ICrudFormTextFieldProps<T, K>) {
  const { field, onChange } = useCrudFormField<T, K>(name);
  return <SimpleMarkdown<T> field={field} onChange={onChange} />;
}
