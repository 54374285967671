import { AptlyAutocompleteQueryProps } from '@aptly-as/sdk-react/material/Autocomplete/AutocompleteQuery';
import { AptlyFieldType, AptlyScopes, AptlyUnit } from '@aptly-as/types';
import {
  AptlyAddressSchema,
  schemaAddressCity,
  schemaAddressLine1,
  schemaAddressLine2,
  schemaAddressZip,
} from '../../components/crud/schema/extends.schemas.js';
import {
  schemaBulkIds,
  schemaEmail,
  schemaFile,
  schemaName,
  schemaPhone,
} from '../../components/crud/schema/fields.schema';
import { IBulkEdit, ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import LinkDocument from '../../components/LinkDocument';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { acceptCsv } from '../../libraries/react-dropzone/drop-zone.utils';
import { useScope } from '../../libraries/scope/ScopeContext';
import { useUnitTemplateSchemaField } from '../UnitTemplate/unit-template.schema.js';

export type ICrudUnit = AptlyUnit &
  IBulkEdit & {
    email: string;
    fullName: string;
    phone: string;
  } & AptlyAddressSchema;

export const unitSchemaFields: (keyof ICrudUnit)[] = [
  'name',
  'unitTemplate',
  'fullName',
  'email',
  'phone',
  'shipping.line1',
  'shipping.line2',
  'shipping.zip',
  'shipping.city',
];
export function useUnitSchema(): ICrudSchema<ICrudUnit> {
  const scope = useScope();
  return {
    ids: schemaBulkIds(),
    name: schemaName({
      autoFocus: true,
      label: i18n.t('singles.customer'),
    }),
    unitTemplate: useUnitTemplateSchemaField({
      renderValidate: () => scope.crud(AptlyScopes.ProjectUnitTemplates),
    }),
    email: schemaEmail({
      group: { name: i18n.t('singles.invitation') },
      required: false,
    }),
    fullName: schemaName({
      group: { name: i18n.t('singles.invitation') },
      required: false,
    }),
    phone: schemaPhone({
      group: { name: i18n.t('singles.invitation') },
      required: false,
    }),
    'shipping.line1': schemaAddressLine1({
      group: { name: i18n.t('singles.address') },
    }),
    'shipping.line2': schemaAddressLine2({
      group: { name: i18n.t('singles.address') },
    }),
    'shipping.zip': schemaAddressZip({
      group: { name: i18n.t('singles.address') },
    }),
    'shipping.city': schemaAddressCity({
      group: { name: i18n.t('singles.address') },
    }),
  };
}

export type ICrudUnitImportSchema = ICrudUnit & {
  _description?: string;
  type: string;
  csv: File | null;
};

export function useUnitImportSchema(): ICrudSchema<ICrudUnitImportSchema> {
  return {
    _description: {
      type: AptlyFieldType.Custom,
      defaultValue: '',
      label: '',
      customRender: () => (
        <LinkDocument
          label="Enhet-import.csv"
          href="https://docs.google.com/spreadsheets/d/1lRbHsUWidXFgWmFik4NYvbB2ro9W7MJE9uBgKkhZcGE/template/preview?usp=sharing"
        />
      ),
    },
    type: {
      type: AptlyFieldType.Select,
      defaultValue: 'import',
      label: i18n.t('singles.type'),
      options: [
        {
          label: `${i18n.t('actions.import')}`,
          value: 'import',
        },
        {
          label: i18n.t('actions.edit'),
          value: 'edit',
        },
      ],
    },
    unitTemplate: useUnitTemplateSchemaField({
      requiredValidate: (crud) => crud.getField('type').value === 'import',
    }),
    csv: schemaFile(acceptCsv),
  };
}

export function useUnitSchemaField<T extends object>(
  override: Partial<ICrudField<T, string | null | any>> = {}
): ICrudField<T, string | null | any> {
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: useUnitAutocompleteQueryProps,
    label: i18n.t('singles.unit'),
    defaultValue: null,
    preOnChange: (value) => value._id,
    ...override,
  };
}

export function useUnitAutocompleteQueryProps(): Omit<
  AptlyAutocompleteQueryProps<AptlyUnit, boolean>,
  'onChange' | 'defaultValue'
> {
  return {
    path: useApiUrl(SlugLevel.Project, 'units'),
    query: { archived: false, select: '_id,name', limit: '7' },
    autocompleteSearchProps: {
      label: i18n.t('singles.unit'),
    },
    multiple: false,
    queryOnFocus: true,
  };
}
