import { useContext, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSlugs } from '../../hooks/useGetApiUrl';
import { AppContext } from './AppContext';
import { OrganizationContext } from '../Organization/OrganizationContext';
import { CLOUDINARY_KEY } from '../../env';

export default function AppInject() {
  const { inject } = useParams<{ inject: string }>();
  const theme = useTheme();
  const ref = useRef<HTMLIFrameElement>(null);
  const org = useContext(OrganizationContext);
  const [, projectID, unitID] = useSlugs();
  const { app, config } = useContext(AppContext);
  const injection = useMemo(() => app.menus.find((x) => x._id === inject), [app, inject]);
  const media = useMemo(() => ({ cloudinaryKey: CLOUDINARY_KEY }), []);
  const sandbox = useMemo(() => ['allow-scripts', ...(injection?.sandbox || [])].join(' '), [injection]);
  const src = useMemo(() => {
    if (injection && injection.src) {
      let src = injection.src;
      if (projectID) {
        src = src.replace(/:project/, projectID);
      }
      if (unitID) {
        src = src.replace(/:unit/, unitID);
      }
      return `${src}?organization=${org.data._id}`;
    }
  }, [injection, org, projectID, unitID]);

  const data = useMemo(
    () => ({
      organization: { _id: org.state.data._id, slug: org.state.data.slug, name: org.state.data.name },
      ...(projectID ? { project: projectID } : {}),
      ...(unitID ? { unit: unitID } : {}),
      app,
      config,
      theme,
      media,
    }),
    [org, app, config, theme, media]
  );

  useEffect(() => {
    if (ref.current && injection!.src) {
      ref.current.onload = () => {
        ref.current!.contentWindow!.postMessage(JSON.stringify(data), '*');
      };
    }
  }, [data, injection]);

  if (!injection) {
    return <>Injection not found</>;
  }
  if (!src) {
    return null;
  }

  return <Iframe ref={ref} src={src} sandbox={sandbox} />;
}

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100% + 150px);
  min-height: 100vh;
  border: none;
  margin-bottom: -4px;
`;
