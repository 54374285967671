import {
  DndContext,
  DraggableAttributes,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Props as DndContextProps } from '@dnd-kit/core/dist/components/DndContext/DndContext';
import type { DragEndEvent } from '@dnd-kit/core/dist/types/index';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Props as SortableContextProps } from '@dnd-kit/sortable/dist/components/SortableContext';
import { Arguments as UseSortableProps } from '@dnd-kit/sortable/dist/hooks/useSortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconButtonProps } from '@mui/material/IconButton';
import List from '@mui/material/List';
import { ListProps } from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem';
import { TableBodyProps } from '@mui/material/TableBody/TableBody';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { IconButton } from '../../mui/Button';
import { TableBody, TableRow } from '../../mui/Table';

type IdObject = { _id: string };
type SortableItem<T extends IdObject> = T & {
  id: UniqueIdentifier;
};

const getIdentifier = (item: SortableItem<IdObject>): UniqueIdentifier => {
  if (item && typeof item === 'object') return item.id;
  return item;
};

export function useSortableItems<T extends { _id: string }>(items: T[]): SortableItem<T>[] {
  return useMemo(() => items.map((x) => ({ ...x, id: x._id })), [items]);
}

export interface OnDragDropProps<T> {
  active: number;
  over: number;
  items: T[];
}

export interface SortableProviderProps<T extends SortableItem<IdObject>>
  extends Omit<DndContextProps, 'onDragEnd'> {
  items: T[];
  onDragEnd?: (items: T[]) => void;
  onDragDrop?: (props: OnDragDropProps<T>) => void;
}

export function SortableProvider<T extends SortableItem<IdObject>>({
  items,
  children,
  onDragEnd,
  onDragDrop,
  ...props
}: PropsWithChildren<SortableProviderProps<T>>) {
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOnDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.active && event.over) {
        const copy = [...items];
        const activeIndex = copy.findIndex((x) => getIdentifier(x) === event.active!.id);
        const overIndex = copy.findIndex((x) => getIdentifier(x) === event.over!.id);
        const active = copy.splice(activeIndex, 1);
        const over = overIndex >= activeIndex ? overIndex + 1 : overIndex;
        copy.splice(over, 0, active[0]);

        if (typeof onDragDrop === 'function') {
          onDragDrop({
            active: activeIndex,
            over: overIndex,
            items: copy,
          });
        }
        if (typeof onDragEnd === 'function') {
          onDragEnd(copy);
        }
      }
    },
    [items, onDragEnd, onDragDrop]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleOnDragEnd}
      {...props}
    >
      {children}
    </DndContext>
  );
}

export type SortableTableBodyProps = Pick<SortableContextProps, 'items'> & TableBodyProps;

export function SortableTableBody({ items, ...props }: SortableTableBodyProps) {
  return (
    <TableBody {...props}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {props.children}
      </SortableContext>
    </TableBody>
  );
}

export function SortableTableRow(props: PropsWithChildren<UseSortableProps>) {
  const { setNodeRef, transition, isDragging, transform } = useSortable(props);
  const style = useMemo(
    () => ({
      transition: transition || '',
      transform: CSS.Transform.toString(transform),
      opacity: isDragging ? 0.5 : 1,
      zIndex: isDragging ? 1300 : undefined,
    }),
    [transition, transform, isDragging]
  );

  return (
    <TableRow ref={setNodeRef} style={style}>
      {props.children}
    </TableRow>
  );
}

export type SortableSortableListProps = Pick<SortableContextProps, 'items'> & ListProps;
export function SortableList({ items, ...props }: SortableSortableListProps) {
  return (
    <List {...props}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {props.children}
      </SortableContext>
    </List>
  );
}
export function SortableListItem(props: PropsWithChildren<UseSortableProps>) {
  const { setNodeRef, transition, isDragging, transform } = useSortable(props);
  const style = useMemo(
    () => ({
      transition: transition || '',
      transform: CSS.Transform.toString(transform),
      opacity: isDragging ? 0.5 : 1,
      zIndex: isDragging ? 1300 : undefined,
    }),
    [transition, transform, isDragging]
  );

  return (
    <ListItem ref={setNodeRef} style={style}>
      {props.children}
    </ListItem>
  );
}

interface SortableIconProps extends UseSortableProps {
  buttonProps?: Omit<IconButtonProps, keyof DraggableAttributes>;
}

export function SortableIcon({ buttonProps, ...props }: SortableIconProps) {
  const sortable = useSortable(props);

  return (
    <IconButton {...buttonProps} {...sortable.listeners} {...sortable.attributes}>
      <StyledDragIndicatorIcon $grabbing={sortable.isDragging} />
    </IconButton>
  );
}

const StyledDragIndicatorIcon = styled(DragIndicatorIcon)<{ $grabbing: boolean }>`
  cursor: ${(props) => (props.$grabbing ? 'grabbing' : 'grab')};
`;
