import { FormEvent, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMobile } from '../../hooks/useMobile.js';
import { AccordionDetails } from '../../mui/Accordion';
import { ModalActions, ModalContent, ModalForm, ModalHeader } from '../../mui/Dialog';
import Grid from '../../mui/Grid';
import Accordion, { IAccordionProps } from '../Accordion';
import ActionButtons, { IActionButtonProps } from '../actions/buttons/ActionButtons';
import ApiError from '../ApiError';
import { ICrudFieldCustomGroupField, ICrudSchema, ISimpleCrudObject } from '../crud/utils/crud.utils';
import { CSSGrid, CSSGridItem } from '../CSSGrid';
import SimpleProvider, { ISimpleProviderProps } from './SimpleContext';
import { SimpleCrudFields } from './SimpleCrudFields.js';
import {
  ISimpleCrudGroupSchema,
  IUseSimpleCrudFieldsProps,
  SimpleCrudModalProps,
  useSimpleCrud,
  useSimpleCrudFields,
} from './useSimpleCrud';

export interface ISimpleModalProps<T extends ISimpleCrudObject, RET = T> {
  path: string;
  data: Partial<T>;
  schema: ICrudSchema<T>;
  fields: (keyof T)[];
  title: string;
  description?: JSX.Element | string;
  onEdit: (data: RET) => void;
  useFieldsProps?: IUseSimpleCrudFieldsProps<T>;
  onClose?: () => void;
  onDelete?: () => void;
  disableFields?: (keyof T)[];
  contentProps?: Omit<ISimpleCrudContentProps<T>, 'crud' | 'schema'>;
  actionButtonProps?: Omit<IActionButtonProps, 'onClose' | 'disabled' | 'disabledSubmit'>;
}

export function SimpleModal<T extends ISimpleCrudObject, RET = T>(props: ISimpleModalProps<T, RET>) {
  const { path, data, schema, fields, useFieldsProps, onClose, contentProps, description } = props;

  const editedFields = useMemo(() => {
    if (!props.disableFields) return schema;
    const copy = { ...schema };
    for (const key of props.disableFields) {
      if (key in copy && copy[key]) {
        copy[key] = { ...copy[key], disabled: true };
      }
    }
    return copy;
  }, [props.disableFields, schema]);

  const [crud, crudFields] = useSimpleCrudFields<T, keyof T, RET>(
    path,
    data,
    editedFields,
    fields,
    useFieldsProps
  );

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const data = await crud.save();
      if (data) {
        props.onEdit(data);
        props.onClose!();
      }
    },
    [crud, props]
  );

  const actionButtonProps: SimpleCrudModalProps<T>['actionButtonProps'] = useMemo(
    () => ({
      onDelete: props.onDelete,
      ...props.actionButtonProps,
      disabled: crud.state.busy,
      disabledSubmit: crud.state.data[crud.state.idKey] && !crud.state.dirty,
    }),
    [crud.state.busy, crud.state.dirty, props.actionButtonProps, props.onDelete]
  );
  return (
    <SimpleCrudForm
      onSubmit={handleSubmit}
      title={props.title}
      actionButtonProps={actionButtonProps}
      onClose={onClose}
      description={description}
    >
      <SimpleCrudContent<T> crud={crud as any} schema={crudFields} {...contentProps} />
      {crud.state.error && <ApiError error={crud.state.error} />}
    </SimpleCrudForm>
  );
}

export interface ISimpleCrudModalProps<T extends ISimpleCrudObject> extends SimpleCrudModalProps<T> {
  onCreate?: (data: T) => void;
  data?: Partial<T>;
  useFieldProps?: IUseSimpleCrudFieldsProps<T>;
  meta?: object;
  description?: JSX.Element | string;
}

export function SimpleCrudModal<T extends ISimpleCrudObject>({
  crud,
  meta,
  fields: propsFields,
  onCreate,
  onClose,
  title,
  data,
  actionButtonProps = {},
  useFieldProps,
  description,
}: ISimpleCrudModalProps<T>) {
  const [simpleCrud, fields] = useSimpleCrud<T, keyof T>(crud, propsFields, data, useFieldProps);

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const data = await simpleCrud.save();
      if (data) {
        if (typeof onCreate === 'function') {
          onCreate(data);
        }
        onClose!();
      }
    },
    [simpleCrud, onClose, onCreate]
  );

  return (
    <SimpleCrudForm
      onSubmit={handleSubmit}
      title={title}
      actionButtonProps={actionButtonProps}
      disabled={simpleCrud.state.busy}
      onClose={onClose}
      description={description}
    >
      <SimpleCrudContent crud={simpleCrud} schema={fields} meta={meta} />
      {simpleCrud.state.error && <ApiError error={simpleCrud.state.error} />}
    </SimpleCrudForm>
  );
}

interface SimpleCrudFormProps<T extends ISimpleCrudObject>
  extends Pick<ISimpleCrudModalProps<T>, 'title' | 'actionButtonProps' | 'onClose'>,
    JSX.ElementChildrenAttribute {
  onSubmit: (e: FormEvent) => void;
  description?: JSX.Element | string;
  disabled?: boolean;
}

export function SimpleCrudForm<T extends ISimpleCrudObject>({
  onSubmit,
  title,
  description,
  children,
  actionButtonProps,
  onClose,
  disabled,
}: SimpleCrudFormProps<T>) {
  const isMobile = useMobile();
  return (
    <ModalForm onSubmit={onSubmit}>
      <ModalHeader onClose={onClose} title={title}>
        {isMobile ? (
          <ActionButtons disabled={disabled} onClose={onClose} hideClose {...actionButtonProps} />
        ) : null}
      </ModalHeader>
      <ModalContent style={{ maxWidth: '100%' }}>
        {description && (
          <Grid item sx={{ marginBottom: (theme) => theme.spacing(2) }}>
            {description}
          </Grid>
        )}
        {children as any}
      </ModalContent>
      {!isMobile && (
        <ModalActions>
          <ActionButtons disabled={disabled} {...actionButtonProps} onClose={onClose} />
        </ModalActions>
      )}
    </ModalForm>
  );
}

export type ISimpleCrudContentGroupProps<T extends ISimpleCrudObject> = {
  [key: string]: {
    title?: (data: T) => string;
    subtitle?: (data: T) => string;
  };
};

export interface ISimpleCrudContentProps<T extends ISimpleCrudObject>
  extends Omit<ISimpleProviderProps<T>, 'children'> {
  schema: ISimpleCrudGroupSchema<T, keyof T>;
  accordionProps?: Omit<IAccordionProps, 'children' | 'key'>;
  groupProps?: ISimpleCrudContentGroupProps<T>;
}

export function SimpleCrudContent<T extends ISimpleCrudObject>({
  crud,
  meta,
  schema,
  accordionProps,
  groupProps,
}: ISimpleCrudContentProps<T>) {
  const [active, setActive] = useState('');
  const handleOnChange = useCallback(
    <K extends keyof T>(groupItem: ICrudFieldCustomGroupField<T, K>) =>
      async (value: T[K], autoSave?: boolean) => {
        if (typeof groupItem.preOnChange === 'function') {
          await crud.setField(groupItem.key)(groupItem.preOnChange(value, crud));
        } else {
          await crud.setField(groupItem.key)(value);
        }
        if (crud.state.data._id && autoSave && groupItem.saveAfterSet) {
          await crud.save();
        }
      },
    [crud]
  );
  return (
    <SimpleProvider crud={crud} meta={meta}>
      <Wrapper>
        {schema.map(([group, fields], index) => {
          const groupFields = fields
            .filter((groupItem) => {
              if (typeof groupItem.renderValidate === 'function') {
                return groupItem.renderValidate(crud, groupItem);
              }
              return true;
            })
            .map((field) => {
              const props =
                field.gridProps && typeof field.gridProps === 'function'
                  ? field.gridProps(crud)
                  : field.gridProps;
              let component;
              if (field.requiredValidate) {
                field.required = field.requiredValidate(crud, field);
              }
              if (field.placeholderFn) {
                field.placeholder = field.placeholderFn(crud, field);
              }
              if (field.customRender) {
                component = field.customRender(crud, field);
              } else if (field.CustomComponent) {
                component = <field.CustomComponent crud={crud} field={field} />;
              } else {
                component = (
                  <SimpleCrudFields<T, keyof T> crud={crud} field={field} onChange={handleOnChange(field)} />
                );
              }

              return (
                <CSSGridItem key={field.id} {...(props || {})}>
                  {component}
                </CSSGridItem>
              );
            });

          if (group) {
            const props = groupProps ? groupProps[group] : null;
            const isActive = active === group;
            return (
              <Accordion
                key={group}
                expanded={isActive}
                onChange={() => setActive(isActive ? '' : group)}
                title={props?.title ? props?.title(crud.state.data) : group}
                subtitle={props?.subtitle ? props?.subtitle(crud.state.data) : ''}
                {...accordionProps}
              >
                {() => (
                  <AccordionDetails>
                    <CSSGrid>{groupFields}</CSSGrid>
                  </AccordionDetails>
                )}
              </Accordion>
            );
          }

          return <CSSGrid key={index}>{groupFields}</CSSGrid>;
        })}
      </Wrapper>
    </SimpleProvider>
  );
}

const Wrapper = styled.div`
  display: grid;
  gap: 0.5rem;
`;
