import { AptlyScopes, AptlyUnit, AptlyUnitTemplate, AptlyUser } from '@aptly-as/types';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { CopyIconButton, PreviewIconButton, SendIconButton } from '../../components/actions/icons/Icons.js';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridGoToAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { dataGridSearchNameColumn } from '../../components/Search/search-data-grid/data-grid.fields.js';
import { SearchCrudContext } from '../../components/Search/search.utils.js';
import { SimpleModal } from '../../components/simple/SimpleCrud.js';
import CrudUnitInvite from '../../components/simpleCruds/unit/CrudUnitInvite.js';
import { createModal } from '../../containers/Modal/ModalContext.js';
import { SlugLevel, useSlugs, useTo } from '../../hooks/useGetApiUrl.js';
import { useMobile } from '../../hooks/useMobile.js';
import i18n from '../../libraries/i18n.js';
import { FragmentScope } from '../../libraries/scope/ScopeComponets.js';
import { openPreview } from '../../utils/window.js';
import { unitSchemaFields, useUnitSchema } from './unit.schema.js';
import { unitStatusLabel } from './unit.utils.js';

export const unitDesktopColumns = ['status', 'users', 'invites', 'unitTemplate'];
export function useUnitColumns(): GridColDef<AptlyUnit>[] {
  const isMobile = useMobile();

  return [
    { ...dataGridSearchNameColumn(), headerName: i18n.t('singles.unit') },
    {
      type: 'string',
      field: 'invites',
      minWidth: 250,
      headerName: i18n.t('singles.invitations'),
      renderCell: (params) => {
        const count = params.row.queueInvites?.length || 0;
        const invites = params.value.filter((x: AptlyUnit['invites'][0]) => !x.claim.claimed);
        return invites.length > 0 ? (
          invites.map((x: AptlyUnit['invites'][0]) => x.claim.email).join(', ')
        ) : (
          <Trans i18nKey="statuses.queuedInvites" count={count}>
            {{ count }} invites in queue
          </Trans>
        );
      },
    },
    {
      type: 'string',
      field: 'users',
      headerName: i18n.t('singles.users'),
      minWidth: 250,
      renderCell: (params) =>
        params.value.length > 0 ? params.value.map((x: AptlyUser) => x.fullName).join(', ') : 'N/A',
    },
    {
      type: 'string',
      field: 'status',
      minWidth: 150,
      headerName: i18n.t('singles.status'),
      renderCell: (params) => unitStatusLabel(params.value),
    },
    {
      type: 'string',
      field: 'unitTemplate',
      headerName: i18n.t('singles.unitTemplate'),
      valueGetter: (v?: AptlyUnitTemplate) => v?.name || '',
    },
    dataGridActionProps((params) => <UnitActions params={params} />, {
      minWidth: isMobile ? 75 : 280,
      width: isMobile ? 75 : 280,
    }),
  ];
}

function UnitActions({ params }: { params: GridRenderCellParams }) {
  const to = useTo(SlugLevel.Project, '/enheter');
  const isMobile = useMobile();
  const schema = useUnitSchema();
  const [organizationSlug, projectID] = useSlugs();
  const projectEndpoint = `organizations/${organizationSlug}/projects/${projectID}`;
  const baseEndpoint = `${projectEndpoint}/units`;
  const { items, actions } = useContext(SearchCrudContext);
  const { unshift, splice } = actions;
  const unit = params.row;

  const handleOnUpdate = useCallback(
    ({ _id, invites, queueInvites }: AptlyUnit) => {
      const index = items.findIndex((x) => x._id === _id);
      if (index > -1) {
        splice(index, 1, { ...items[index], invites, queueInvites });
      }
    },
    [items, splice]
  );

  return (
    <DataGridActionWrapper>
      <FragmentScope scope={AptlyScopes.ProjectUnits} crud="U">
        <PreviewIconButton
          onClick={() => {
            openPreview(`${unit._id}/velkommen/`);
          }}
        />
      </FragmentScope>
      {!isMobile && (
        <>
          <FragmentScope scope={AptlyScopes.ProjectUnits} crud="C">
            <CopyIconButton
              onClick={() =>
                createModal(
                  <SimpleModal
                    title={i18n.t('actions.copy')}
                    schema={schema}
                    fields={unitSchemaFields}
                    path={baseEndpoint}
                    data={unit}
                    onEdit={unshift}
                    useFieldsProps={{
                      subPath: 'copy',
                      forcePatch: ['name', 'unitTemplate'],
                      alwaysPost: true,
                    }}
                  />
                )
              }
            />
            <DataGridDeleteAction params={params} />
          </FragmentScope>
          <FragmentScope scope={AptlyScopes.ProjectUnitInvites} crud="C">
            <SendIconButton
              title={i18n.t('actions.sendInvitation')}
              onClick={() =>
                createModal(<CrudUnitInvite unit={unit} endpoint={baseEndpoint} onCreate={handleOnUpdate} />)
              }
            />
          </FragmentScope>
          <DataGridGoToAction params={params} preTo={to} />
        </>
      )}
    </DataGridActionWrapper>
  );
}
