import { AptlyFieldOption, AptlyQuantityUnitCode } from '@aptly-as/types';
import i18n from '../libraries/i18n.js';

const quantityLabel = (code: AptlyQuantityUnitCode, count = 0): string =>
  i18n.t('types:standards.quantityUnitCode.' + code, { count });

const quantityOption = (code: AptlyQuantityUnitCode, label: string, count = 0): AptlyFieldOption => ({
  value: code,
  label: `${label} ( ${quantityLabel(code, count)} )`,
});

export const quantityUnitCodeOptions = (): AptlyFieldOption[] => [
  quantityOption(AptlyQuantityUnitCode.Piece, i18n.t('singles.piece')),
  quantityOption(AptlyQuantityUnitCode.SquareMeter, i18n.t('singles.squareMeter')),
  quantityOption(AptlyQuantityUnitCode.Meter, i18n.t('singles.meter')),
  quantityOption(AptlyQuantityUnitCode.Kilometre, i18n.t('singles.kilometre')),
  quantityOption(AptlyQuantityUnitCode.Hour, i18n.t('singles.hour')),
];

export function quantityUnitCodeOptionsLabel(value: AptlyQuantityUnitCode) {
  const found = quantityUnitCodeOptions().find((x) => x.value === value);
  return found?.label || value;
}
