import styled, { css } from 'styled-components';

interface ActionBarProps {
  $left?: boolean;
  $center?: boolean;
  $between?: boolean;
  $stretch?: boolean;
  $inline?: boolean;
}

const ActionBar = styled.div<ActionBarProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0.25rem;
  width: 100%;
  gap: 0.25rem;

  ${(props) =>
    props.$left &&
    css`
      justify-content: flex-start;
    `};

  ${(props) =>
    props.$center &&
    css`
      justify-content: center;
    `};

  ${(props) =>
    props.$between &&
    css`
      justify-content: space-between;
    `};

  ${(props) =>
    props.$stretch &&
    css`
      > * {
        flex: 1;
      }
    `};

  ${(props) =>
    props.$inline &&
    css`
      display: inline-flex;
    `};
`;

export default ActionBar;
