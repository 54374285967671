import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import { ChangeEvent, useCallback, useMemo } from 'react';
import i18n from '../../../libraries/i18n.js';
import { Algorithm } from '../../../models/Algorithm/algorithm.pipeline.js';
import { useAlgorithms } from '../../../models/Algorithm/useAlgorithms.js';
import { onWheelCaptureBlur } from '../../../utils/input';
import { CalculateIconButton } from '../../actions/icons/Icons.js';
import { ISimpleCrudComponentProps } from './simple-components.utils';
import { ITextFieldProps, TextField } from '../../../mui/Input';

export type ICrudTextFieldProps<T extends object> = ISimpleCrudComponentProps<T, any> &
  Partial<Omit<ITextFieldProps, 'value' | 'onChange'>>;

export default function SimpleAmountField<T extends object>({
  crud,
  field,
  onChange,
  ...props
}: ICrudTextFieldProps<T>) {
  const { id, value, defaultValue, edited, gridProps, renderValidate, placeholderFn, type, key, ...rest } =
    field;

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = e.target.value;
      onChange(v ? Number(v) * 100 : v);
    },
    [onChange]
  );

  const algorithms = useAlgorithms();
  const _value = value === '' ? '' : value / 100;
  const algorithm = crud.getField('algorithm' as keyof T).value;
  const slotProps: TextFieldProps['slotProps'] = useMemo(() => {
    const found = algorithms.find((x) => x._id === algorithm);
    if (!algorithm || !found || found.reversed || !value) return undefined;
    return {
      input: {
        endAdornment: (
          <InputAdornment position="end">
            <CalculateIconButton
              size="small"
              title={i18n.t('actions.calculateNetCost')}
              onClick={() => {
                const algorithmCalc = new Algorithm(found).amount(value).quantity(1);
                onChange(algorithmCalc.revertAmount(value));
              }}
            />
          </InputAdornment>
        ),
      },
    };
  }, [algorithms, algorithm, _value]);

  return (
    <TextField
      id={id}
      type="number"
      value={_value}
      onChange={handleOnChange}
      variant="outlined"
      fullWidth
      onWheelCapture={onWheelCaptureBlur}
      shrink
      slotProps={slotProps}
      {...rest}
      {...props}
    />
  );
}
