import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { enter, leave } from '../../utils/animations';
import { smallDimShadow } from '../../utils/shadows';

export * from './notification.utils';

export type NotificationProps = {
  fadeOut?: boolean;
};

export default function Notification(props: PropsWithChildren<NotificationProps>) {
  const { children, fadeOut } = props;

  return <Wrapper $fadeOut={fadeOut || false}>{children}</Wrapper>;
}

const Wrapper = styled.div<{ $fadeOut?: boolean }>`
  box-shadow: ${smallDimShadow};
  background-color: ${(props) => props.theme.palette.background.default};
  border-radius: 2px;
  display: inline-flex;
  width: 25rem;
  animation: ${enter};

  ${(props) =>
    props.$fadeOut &&
    css`
      animation: ${leave};
    `};
`;
