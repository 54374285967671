import { AptlyProjectInvite } from '@aptly-as/types';
import { ICrudSchema } from '../../../components/crud/utils/crud.utils';
import { useUnitSchemaField } from '../../Unit/unit.schema.js';

export function useProjectInvitesSchema(): ICrudSchema<AptlyProjectInvite> {
  return {
    unit: useUnitSchemaField({
      required: true,
    }) as any,
  };
}
