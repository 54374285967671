import { AptlyFieldType, AptlyScopes, AptlyUpsellTemplate } from '@aptly-as/types';
import { schemaFile, schemaName } from '../../components/crud/schema/fields.schema';
import { ICrudField, ICrudSchema } from '../../components/crud/utils/crud.utils';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import i18n from '../../libraries/i18n';
import { acceptImages, acceptJson } from '../../libraries/react-dropzone/drop-zone.utils';
import { useScope } from '../../libraries/scope/ScopeContext.js';
import useScopeLevel from '../../libraries/scope/useScopeLevel.js';
import { usePagesSchemaField } from '../Page/page.schema.js';

export type ICrudUpsellTemplate = AptlyUpsellTemplate;

export function useUpsellTemplateSchemaField<T extends object>(
  override: Partial<ICrudField<T, string | null>> = {}
): ICrudField<T, string | null> {
  const scope = useScope();
  const scopeLevel = useScopeLevel({
    base: AptlyScopes.Admin,
    organization: AptlyScopes.OrganizationUpsellTemplates,
    project: AptlyScopes.ProjectUpsellTemplates,
  });
  return {
    type: AptlyFieldType.AutocompleteQuery,
    useAutocompleteQuery: () => ({
      path: useApiUrl(SlugLevel.Project, 'upsell-templates'),
      query: { select: '_id,name', limit: '5', sort: 'name', level: 'project', archived: 'false' },
      multiple: false,
    }),
    label: i18n.t('singles.upsell-template'),
    defaultValue: null,
    renderValidate: () => scope.crud(scopeLevel),
    ...override,
  };
}

export function useUpsellTemplateSchema(): ICrudSchema<ICrudUpsellTemplate> {
  return {
    name: schemaName({
      autoFocus: true,
    }),
    text: {
      type: AptlyFieldType.Markdown,
      defaultValue: '',
      label: '',
    },
    image: {
      type: AptlyFieldType.Image,
      defaultValue: null,
      label: i18n.t('singles.image'),
      image: { mediaKey: 'imageMedia', width: 720, height: 350, accept: acceptImages },
    },
    videoEmbed: {
      type: AptlyFieldType.Text,
      defaultValue: '',
      label: i18n.t('paragraphs.videoEmbedCode'),
    },
    links: usePagesSchemaField({
      label: i18n.t('singles.links'),
    }),
  };
}

interface ICrudUpsellImport extends Pick<ICrudUpsellTemplate, 'name'> {
  file: File | null;
}

export function useUpsellImportSchema(): ICrudSchema<ICrudUpsellImport> {
  return {
    name: schemaName({ autoFocus: true }),
    file: schemaFile(acceptJson, { required: true }),
  };
}
