import { AptlyUnit } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react';
import { createContext, useContext } from 'react';
import { IApiError } from '../../components/ApiError';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils';
import { ICrudUnit } from './unit.schema';

type Context = IUseFieldRet<ICrudUnit, IApiError, ICrudFieldData<ICrudUnit>> & {
  data: AptlyUnit;
  init: boolean;
};
export const UnitContext = createContext<Context>({ data: {}, init: false } as any);

export function useUnit() {
  const { data } = useContext(UnitContext);
  return data;
}
