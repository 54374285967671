import { AptlyApp, AptlyAppAction, AptlyAppActionModel, AptlyWebhookEvent } from '@aptly-as/types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createContext, MouseEvent, useCallback, useContext, useMemo, useState } from 'react';
import { SmartButtonWithIcon } from '../../components/actions/buttons/Buttons.js';
import ApiError from '../../components/ApiError.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import simpleRequest from '../../libraries/fetch/simpleRequest.js';
import i18n from '../../libraries/i18n.js';
import { getId } from '../../libraries/mongoose.js';
import { useSimpleSearch } from '../../libraries/reach/useSimpleSearch.js';
import { OrganizationContext } from '../Organization/OrganizationContext.js';

interface WebhookActionsProps<T extends ActionDocument> {
  model: AptlyAppActionModel;
  document: T;
}

interface ActionDocument {
  _id: string;
}

type WebhookAppActionsContent<T extends ActionDocument> = WebhookActionsProps<T> &
  IUseWebhookAppActionsOutput;

const WebhookAppActionsContext = createContext<WebhookAppActionsContent<ActionDocument>>({
  model: AptlyAppActionModel.Order,
  onAction: () => Promise.resolve(),
  document: { _id: '' },
  actions: [],
});

export function WebhookAppActions<T extends ActionDocument>({ model, document }: WebhookActionsProps<T>) {
  const { actions, onAction } = useWebhookAppActions(model, document);
  if (actions.length === 0) {
    return null;
  }

  return (
    <WebhookAppActionsContext.Provider value={{ model, actions, onAction, document }}>
      <Actions />
    </WebhookAppActionsContext.Provider>
  );
}

function Actions() {
  const { model, actions, document } = useContext(WebhookAppActionsContext);
  const url = useApiUrl(SlugLevel.Organization, 'webhooks/events');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [busy, data, error] = useSimpleSearch<AptlyWebhookEvent>(
    url,
    useMemo(
      () => ({
        query: {
          $in_app: actions.map((x) => getId(x.app)).join(','),
          [model.toLowerCase()]: document._id,
          $in_type: actions.map((x) => x.webhook).join(','),
          select: '_id,app,order,type',
        },
      }),
      [actions, model, document._id]
    )
  );

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const filteredActions = useMemo(() => {
    return actions.filter((action) => {
      const found = data.find((x) => x.app === action.app && action.webhook === x.type);
      if (found) {
        return null;
      }
      try {
        const state: { [key: string]: string } = action.stateJSON ? JSON.parse(action.stateJSON) : {};
        for (const key in state) {
          if (key in document) {
            const v = document[key as keyof ActionDocument];
            if (v !== state[key]) {
              return false;
            }
          }
        }
      } catch {
        return false;
      }
      return true;
    });
  }, [actions, document, data]);

  if (busy || filteredActions.length === 0) {
    return null;
  }

  return (
    <>
      {error && <ApiError error={error} />}
      <SmartButtonWithIcon onClick={handleOpen}>{i18n.t('actions.smartActions')}</SmartButtonWithIcon>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        {filteredActions.map((action, index) => (
          <Action key={action._id} action={action} index={index} />
        ))}
      </Menu>
    </>
  );
}

interface ActionProps {
  action: AppAction;
  index: number;
}

function Action({ action, index }: ActionProps) {
  const [busy, setBusy] = useState(false);
  const { onAction } = useContext(WebhookAppActionsContext);
  const handleOnClick = useCallback(async () => {
    try {
      setBusy(true);
      await onAction(action, index);
    } finally {
      setBusy(false);
    }
  }, [action, index]);

  return (
    <MenuItem onClick={handleOnClick} disabled={busy}>
      {action.label}
    </MenuItem>
  );
}

interface IUseWebhookAppActionsOutput {
  actions: AppAction[];
  onAction: (action: AppAction, index: number) => Promise<void>;
}

interface AppAction extends AptlyAppAction {
  app: string;
}

export function useWebhookAppActions<T extends ActionDocument>(
  model: AptlyAppActionModel,
  document: T
): IUseWebhookAppActionsOutput {
  const url = useApiUrl(SlugLevel.Organization, '');
  const {
    data: { apps },
  } = useContext(OrganizationContext);

  const _actions = useMemo((): AppAction[] => {
    const actions: AppAction[] = [];
    for (const app of apps) {
      if ((app.app as AptlyApp).actions) {
        for (const action of (app.app as AptlyApp).actions) {
          if (action.model === model) {
            actions.push({
              ...action,
              app: getId(app.app),
            });
          }
        }
      }
    }
    return actions;
  }, [apps, model]);
  const [actions, setActions] = useState(_actions);

  const onAction = useCallback(
    async (action: AppAction, index: number) => {
      if (index > -1) {
        simpleRequest({
          endpoint: `${url}/apps/${action.app}/actions/${action._id}`,
          onRequestDoneText: i18n.t('statuses.sent'),
          onRequestDone: () => {
            setActions((s) => s.filter((x) => x._id !== action._id));
          },
          method: 'POST',
          data: {
            [model.toLowerCase()]: document._id,
          },
        });
      }
    },
    [url, actions]
  );

  return { actions, onAction };
}
