import { AptlyProduct } from '@aptly-as/types';
import { useCallback } from 'react';
import { AddButtonWithIcon } from '../../components/actions/buttons/Buttons.js';
import { ISearchCrudActions } from '../../components/Search/search.utils.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import simpleRequest from '../../libraries/fetch/simpleRequest.js';
import i18n from '../../libraries/i18n.js';
import { useAlgorithms } from '../Algorithm/useAlgorithms.js';
import { ProductSearch } from '../Product/ProductSearch.js';
import { IOptionSchema } from './option.schema.js';

interface OptionProductsAddProps {
  actions: ISearchCrudActions<IOptionSchema>;
}

export function OptionsProductAdd({ actions }: OptionProductsAddProps) {
  const algorithms = useAlgorithms(SlugLevel.Organization);
  const onSave = useOptionsPostProducts(actions);
  const onModalSpawn = useModal(
    () => <ProductSearch fullScreen key="products" algorithms={algorithms} onSave={onSave} />,
    {
      fullScreen: true,
    }
  );
  return <AddButtonWithIcon onClick={onModalSpawn}>{i18n.t('actions.add')}</AddButtonWithIcon>;
}

function useOptionsPostProducts(actions: ISearchCrudActions<IOptionSchema>) {
  const endpoint = useApiUrl(SlugLevel.Project, 'options');
  return useCallback(
    async (products: AptlyProduct[], algorithm?: string) => {
      const options = await simpleRequest<IOptionSchema[]>({
        endpoint: `${endpoint}/bulk`,
        method: 'POST',
        data: { productIDs: products.map((x) => x._id), algorithm },
      });
      if (options) {
        actions.unshift(...options);
      }
    },
    [endpoint, actions]
  );
}
