import { Grid2 } from '@mui/material';
import Typography from '@mui/material/Typography';
import { PropsWithChildren } from 'react';

interface ActionHeaderProps {
  title: string;
  description?: string;
}

export function ActionHeader({ title, description, children }: PropsWithChildren<ActionHeaderProps>) {
  return (
    <Grid2 container justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid2 size={{ xs: 6, lg: 4 }}>
        <Typography variant="h1">{title}</Typography>
        {description && <Typography>{description}</Typography>}
      </Grid2>
      <Grid2 size={{ xs: 5, lg: 8 }}>{children}</Grid2>
    </Grid2>
  );
}
