import { GridColDef } from '@mui/x-data-grid';
import {
  dataGridActionProps,
  DataGridActionWrapper,
  DataGridDeleteAction,
  DataGridEditAction,
} from '../../components/Search/search-data-grid/data-grid.actions.js';
import { dataGridLevelColumn } from '../../components/Search/search-data-grid/data-grid.fields.js';
import i18n from '../../libraries/i18n.js';
import { emailTypeLabel } from './email.schema.js';
import EmailPreview from './EmailPreview.js';

export const useEmailColumns = (): GridColDef[] => {
  return [
    {
      field: 'type',
      headerName: i18n.t('singles.type'),
      flex: 1,
      renderCell: (params) => emailTypeLabel(params.value),
    },
    {
      field: 'subject',
      headerName: i18n.t('statuses.emailSubject'),
      minWidth: 400,
    },
    {
      field: 'actionLabel',
      headerName: i18n.t('actions.confirmLabel'),
      minWidth: 300,
    },
    dataGridLevelColumn(),
    dataGridActionProps((params) => (
      <DataGridActionWrapper>
        <DataGridEditAction params={params} checkIfDisabledInProject />
        <EmailPreview email={params.row} title={params.row.subject} />
        <DataGridDeleteAction params={params} checkIfDisabledInProject />
      </DataGridActionWrapper>
    )),
  ];
};
