import { AptlyAlgorithm, AptlyCurrency, AptlyError, AptlyOfferItem, AptlyOption } from '@aptly-as/types';
import { IUseFieldRet } from '@ewb/reach-react/core/useFields.js';
import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions } from '@tanstack/react-query/src/types';
import { createContext, useContext } from 'react';
import { IApiError } from '../../components/ApiError.js';
import { ICrudFieldData } from '../../components/crud/utils/crud.utils.js';
import { SimpleModal } from '../../components/simple/SimpleCrud.js';
import { useFormat } from '../../containers/Format/Format.js';
import { useModal } from '../../containers/Modal/Modal.js';
import { SlugLevel, useApiUrl } from '../../hooks/useGetApiUrl.js';
import { queryAptlyApi } from '../../libraries/react-query';
import { Algorithm } from '../Algorithm/algorithm.pipeline.js';
import { useAlgorithms } from '../Algorithm/useAlgorithms.js';
import { OfferSchema, offerSchemaFields } from './offer.schema.js';
import { OfferCopyModal } from './OfferCopy.js';

export interface IOfferContext {
  data: OfferSchema;
  crud: IUseFieldRet<OfferSchema, IApiError, ICrudFieldData<OfferSchema>>;
  canEdit: boolean;
  level: SlugLevel;
  isTemplate: boolean;
}

export const OfferContext = createContext<IOfferContext>({} as any);

interface ModalFnProps {
  items: AptlyOfferItem[];
  fields?: (keyof OfferSchema)[];
}

export function useEditOffer() {
  const offer = useContext(OfferContext);
  const format = useFormat();
  const algorithms = useAlgorithms();
  return useModal(({ items, fields = offerSchemaFields }: ModalFnProps) => {
    const price = offerItemsPrice(algorithms, items);
    offer.crud.state.schema.amount = {
      ...offer.crud.state.schema.amount!,
      placeholder: format.amount(price.amount, price.currency),
    };
    return (
      <SimpleModal
        path={offer.crud.state.path}
        data={offer.data}
        schema={offer.crud.state.schema}
        fields={fields}
        title={offer.data.name}
        onEdit={offer.crud.setData}
      />
    );
  });
}

export function getOfferLevelQuery(level: SlugLevel) {
  return level !== SlugLevel.Organization ? 'project' : undefined;
}

export function useOfferCopy() {
  const offer = useContext(OfferContext);
  return useModal(() => {
    return <OfferCopyModal offer={offer.data} level={offer.level} isTemplate={offer.isTemplate} />;
  });
}

export function useOfferItemsMutation(
  props: Omit<UseMutationOptions<AptlyOfferItem[], AptlyError, AptlyOption[]>, 'mutationKey' | 'mutationFn'>
) {
  const offer = useContext(OfferContext);
  const path = useApiUrl(SlugLevel.Project, `offers/${offer.data._id}/items/bulk`);
  return useMutation<AptlyOfferItem[], AptlyError, AptlyOption[]>({
    mutationKey: [path],
    mutationFn: (options) =>
      queryAptlyApi(path, {
        method: 'POST',
        json: { options: options.map((x) => x._id) },
      }),
    ...props,
  });
}

export function useOfferItemIndexMutation<T extends AptlyOfferItem>(
  props?: Omit<UseMutationOptions<AptlyOfferItem[], AptlyError, T[]>, 'mutationKey' | 'mutationFn'>
) {
  const offer = useContext(OfferContext);
  const path = useApiUrl(SlugLevel.Project, `offers/${offer.data._id}/items/order`);
  return useMutation<AptlyOfferItem[], AptlyError, T[]>({
    mutationKey: [path],
    mutationFn: (items) =>
      queryAptlyApi(path, {
        method: 'POST',
        json: { items: items.map((x, index) => ({ _id: x._id, index })) },
      }),
    ...props,
  });
}

export function useOfferItemsPrice(items: AptlyOfferItem[]) {
  const algorithms = useAlgorithms();
  return offerItemsPrice(algorithms, items);
}

export function offerItemsPrice(algorithms: AptlyAlgorithm[], items: AptlyOfferItem[]) {
  return items.reduce(
    (obj, item) => {
      const found = algorithms.find((x) => x._id === item.algorithm);
      const algo = new Algorithm(found).amount(item.amount).quantity(item.quantity);
      return { amount: algo.getTotalAmount() + obj.amount, currency: algo.currency };
    },
    { amount: 0, currency: AptlyCurrency.NorwegianKrone }
  );
}
