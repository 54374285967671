import Divider from '@mui/material/Divider';
import { useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import { Main } from '../../components/Main';
import { ModalProvider } from '../../containers/Modal/ModalProvider.js';
import { DrawerMenu } from '../../containers/Nav/DrawerMenu';
import Header from '../../containers/Nav/Header';
import { NavBackItem } from '../../containers/Nav/NavBackItem';
import { NavDrawer } from '../../containers/Nav/NavDrawer';
import SidebarRoutes from '../../containers/Nav/SidebarRoutes';
import { SlugLevel, useTo } from '../../hooks/useGetApiUrl';
import { ScopeProvider } from '../../libraries/scope/ScopeProvider';
import AppProject from '../App/AppProject';
import Offer from '../Offer/Offer.js';
import { Order } from '../Order/Order.js';
import { OrganizationContext } from '../Organization/OrganizationContext';
import OrganizationHeader from '../Organization/OrganizationHeader';
import Page from '../Page/Page';
import Unit from '../Unit/Unit';
import UnitTemplateV2 from '../UnitTemplate/UnitTemplate';
import useProjectRoutes from './project.routes';
import ProjectHeader from './ProjectHeader';
import { ProjectProvider } from './ProjectProvider.js';

function Project() {
  const { slug, projectID } = useParams<{ slug: string; projectID: string }>();

  const endpoint = `organizations/${slug}/projects/${projectID}`;

  return (
    <ProjectProvider key={projectID} id={projectID!}>
      <ScopeProvider path={`${endpoint}/scope`}>
        <Content />
      </ScopeProvider>
    </ProjectProvider>
  );
}

function Content() {
  const org = useContext(OrganizationContext);
  const routes = useProjectRoutes();
  const toOrganization = useTo(SlugLevel.Organization);

  return (
    <Routes>
      <Route path="enheter/:unitID/*" element={<Unit />} />
      <Route
        path="*"
        element={
          <ModalProvider>
            <Header breadcrumbs={[<OrganizationHeader key="org" />, <ProjectHeader key="project" />]} />
            <NavDrawer>
              <NavBackItem to={toOrganization} primary={org.data.name} />
              <Divider />
              <DrawerMenu level={SlugLevel.Project} routes={routes} injection="project" />
            </NavDrawer>
            <Main>
              <ErrorBoundary>
                <SidebarRoutes routes={routes}>
                  <Route path="tilvalg/:unitTemplateID" element={<UnitTemplateV2 />} />
                  <Route path="sider/:pageID" element={<Page />} />
                  <Route path="apps/:app/*" element={<AppProject />} />
                  <Route path="ordre/:order/*" element={<Order />} />
                  <Route path="tilbud/:offer/*" element={<Offer level={SlugLevel.Project} />} />
                </SidebarRoutes>
              </ErrorBoundary>
            </Main>
          </ModalProvider>
        }
      />
    </Routes>
  );
}

export default Project;
