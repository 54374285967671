import TextField from '@mui/material/TextField';
import { ISimpleComponentProps } from './simple-components.utils';

export interface ICrudTextFieldProps<T extends object> extends ISimpleComponentProps<T, any> {}

export default function SimpleTextArea<T extends object>({ field, onChange }: ICrudTextFieldProps<T>) {
  const { id, value, label } = field;
  return (
    <TextField
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      multiline
      minRows={3}
      variant="outlined"
      fullWidth
      label={label}
    />
  );
}
